import { replaceVar } from "./copy-utils";

export const copyStyles = (styles, attributes) => {
    const copy = JSON.parse(JSON.stringify(styles));
    for (const style of copy) {
        if (style.condition) {
            replaceVar(style.condition, attributes, "name");
        }
    }
    return copy;
};

export const pasteStyles = (styles, attributes) => {
    const copy = JSON.parse(JSON.stringify(styles));
    let error = false;
    for (const style of copy) {
        if (style.condition) {
            error = replaceVar(style.condition, attributes, "id");
        }
    }
    return { styles: copy, error };
};

import React from "react";
import classNames from "classnames";

import { getError, ErrorMessage } from "./Error";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    group: {
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    header: {
        display: "flex",
        alignItems: "center"
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.1rem",

        "&.level1": {
            fontSize: "1.2rem"
        },
        "&.level0": {
            fontSize: "1.3rem"
        }
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    divider: {
        width: "100%",
        height: "3px",
        backgroundColor: "#ccc"
    },
    error: {
        border: "1px solid red",
        padding: theme.spacing(1 / 2)
    }
}));

function Group({ schema, children, formik, level }) {
    const classes = useStyles();

    const error = getError(schema.key, formik.errors, formik.touched);

    return (
        <div className={classNames(classes.group, { [classes.error]: !!error })}>
            <div className={classes.divider} />
            {schema.title && (
                <Typography className={classNames(classes.title, `level${level}`)} variant="h6">
                    {schema.title}
                </Typography>
            )}
            {schema.subtitle && (
                <Typography className={classNames(classes.subtitle, `level${level}`)} variant="subtitle1">
                    {schema.subtitle}
                </Typography>
            )}
            {children}
            <ErrorMessage error={error} />
            <div className={classes.divider} />
        </div>
    );
}

export default Group;

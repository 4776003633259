import { EventEmitter } from "events";
import Api from "../Api";

import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
const EventSource = NativeEventSource || EventSourcePolyfill;

const MAX_BUFFER_SIZE = 3;
const SYNC_CHANNEL_RECONNECT_TIMEOUT = 2000;

class SyncChannel extends EventEmitter {
    constructor() {
        super();

        this._onError = this._onError.bind(this);
        this._onOpen = this._onOpen.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onConnected = this._onConnected.bind(this);
        this._onKeepAlive = this._onKeepAlive.bind(this);
        this._onChange = this._onChange.bind(this);

        this.state = {
            browserOnline: navigator.onLine,
            active: false // In the process of connecting, or connected
        };
        this.channel = null;
        this.hostname = null;
        this._eventBuffer = new Map();
        this._sequenceNumber = 0;

        window.addEventListener("online", this._onOnline.bind(this));
        window.addEventListener("offline", this._onOffline.bind(this));
        window.addEventListener("focus", async () => {
            console.log("SyncChannel: Window became active, connecting.");
            await this.register();
        });
    }

    async register() {
        try {
            if (this.state.active) {
                console.log("Sync channel: Already active, returning.");
                return;
            }
            this.state.active = true;
            if ((this.hostname = localStorage.getItem("hostname"))) {
                const connected = this._connect();
                if (!connected) {
                    this.state.active = false;
                }
                return;
            }
            if (!(this.hostname = await Api.getHostname())) {
                throw new Error("Sync channel: Could not register client with server!");
            }
            console.log("set hostname to...", this.hostname);
            localStorage.setItem("hostname", this.hostname);
            const mostChangingTimeBytes = this.hostname.slice(1, 4);
            const increment = this.hostname.slice(-3);
            const machineId = parseInt(mostChangingTimeBytes + increment, 16).toString();
            localStorage.setItem("mongoMachineId", machineId);
            return this._connect();
        } finally {
            localStorage.setItem("currentWindow", window.id);
        }
    }

    _closeIfNotActiveWindow() {
        const currentWindow = localStorage.getItem("currentWindow");
        if (currentWindow !== window.id) {
            console.log("Sync channel: Detected inactive window, disconnecting.");
            // Another browser window has initiated a sync channel. Close this one.
            // A new sync channel will be initiated in this window on `window.focus`.
            this._disconnect(true);
            return true;
        }
        return false;
    }

    online() {
        return this.state.browserOnline && this.channel && this.channel.readyState === 1;
    }

    _onOnline() {
        this.state.browserOnline = true;
        const currentWindow = localStorage.getItem("currentWindow");
        if (currentWindow === window.id) {
            console.log("Sync channel: Browser came online, reconnecting.");
            this.register();
        } else {
            console.log("Sync channel: Browser came online, but current window is inactive. Staying disconnected.");
        }
    }

    _onOffline() {
        this.state.browserOnline = false;
        this._disconnect();
    }

    _onError() {
        console.error("Sync channel: Received error event, disconnecting.");
        this._disconnect();
        setTimeout(() => {
            const currentWindow = localStorage.getItem("currentWindow");
            if (currentWindow === window.id) {
                console.log("Sync channel: Attempting reconnect after error event.");
                this.register();
            } else {
                console.log("Sync channel: Window no longer active, will not attempt reconnect after error event.");
            }
        }, SYNC_CHANNEL_RECONNECT_TIMEOUT);
    }

    _onOpen() {
        this.emit("connect", null);
    }

    _onClose() {
        // Server sent a close event because another browser window opened a new sync channel
        console.log("Sync channel: Received close event, closing.");
        this._disconnect(true);
    }

    _onConnected(event) {
        console.log(`Sync channel: Connected to server ${event.data}`);
    }

    _onKeepAlive() {
        /* PONG */
        this._closeIfNotActiveWindow();
    }

    _onChange(event) {
        if (this._closeIfNotActiveWindow()) {
            return;
        }
        this._handleChannelEvent("change", event);
    }

    _connect() {
        if (!this.state.browserOnline) {
            console.log("Sync channel: Browser is offline, will attempt to connect once online.");
            return false;
        }

        this._sequenceNumber = 0;
        if (this.channel) {
            this._removeChannel();
        }
        this.channel = new EventSource(Api.getSSEURL());
        this.channel.addEventListener("open", this._onOpen);
        this.channel.addEventListener("close", this._onClose);
        this.channel.addEventListener("connected", this._onConnected);
        this.channel.addEventListener("error", this._onError);
        this.channel.addEventListener("keepalive", this._onKeepAlive);
        this.channel.addEventListener("change", this._onChange);
        return true;
    }

    _removeChannel() {
        this.channel.removeEventListener("open", this._onOpen);
        this.channel.removeEventListener("close", this._onClose);
        this.channel.removeEventListener("connected", this._onConnected);
        this.channel.removeEventListener("error", this._onError);
        this.channel.removeEventListener("keepalive", this._onKeepAlive);
        this.channel.removeEventListener("change", this._onChange);
        this.channel.close();
        this.channel = null;
    }

    _disconnect(windowOnly = false) {
        this.state.active = false;
        if (this.channel) {
            this._removeChannel();
            // Don't emit disconnect if only this window was disconnected from sync channel
            if (!windowOnly) {
                this.emit("disconnect", null);
            }
        }
    }

    _handleChannelEvent(type, event) {
        event = JSON.parse(event.data);
        console.log(`Sync channel: Received ${type} event ${event.sequence} (type: ${event.type}).`);
        if (event.sequence < this._sequenceNumber) {
            throw new Error(`Sync channel: Sequence number "${event.sequence}" already received.`);
        }
        this._eventBuffer.set(event.sequence, { type, event });
        this._sendFromEventBuffer();
        if (this._eventBuffer.size > MAX_BUFFER_SIZE) {
            this._onError(`Sync channel: Event buffer exceeded limit (${MAX_BUFFER_SIZE}), disconnecting.`);
        }
    }

    _sendFromEventBuffer() {
        while (this._eventBuffer.has(this._sequenceNumber)) {
            const { type, event } = this._eventBuffer.get(this._sequenceNumber);
            this._eventBuffer.delete(this._sequenceNumber++);
            this.emit(type, event);
        }
    }
}

export default SyncChannel;

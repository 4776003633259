import React from "react";
import classNames from "classnames";
import { ErrorMessage } from "../Error";
import OutlinedContainer from "../../OutlinedContainer";

import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    error: {
        color: "red"
    }
});

function BooleanFormInput({ schema, field, error, enabled, setError, form }) {
    const classes = useStyles();

    const handleChange = event => {
        if (!enabled) {
            return;
        }
        const _event = {
            target: {
                name: field.name,
                value: event.target.value === "true"
            }
        };
        setError(field.name, null);
        form.setFieldTouched(field.name);
        if (_event.target.value === field.value) {
            _event.target.value = null;
        }
        field.onChange(_event);
    };

    const hasError = error && error.type === "error";

    return (
        <div>
            <OutlinedContainer label={schema.title}>
                <RadioGroup
                    classes={{ root: classNames({ [classes.error]: hasError }) }}
                    name={schema.key}
                    value={String(field.value)}
                    onClick={handleChange}
                >
                    <FormControlLabel
                        label="true"
                        value="true"
                        disabled={!enabled}
                        control={<Radio color="primary" />}
                    />
                    <FormControlLabel
                        label="false"
                        value="false"
                        disabled={!enabled}
                        control={<Radio color="primary" />}
                    />
                </RadioGroup>
            </OutlinedContainer>
            <ErrorMessage error={error} />
        </div>
    );
}

export default BooleanFormInput;

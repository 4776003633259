import React from "react";
import { isIOS, browserName } from "react-device-detect";
import { saveAs } from "file-saver";
import { copyToClipboard } from "./copyToClipboard";

import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";

const exportUsingWebShareAPI = async (text, title) => {
    try {
        await navigator.share({ text, title });
    } catch (e) {
        console.logError(e, "Could not share with web API");
    }
};
const exportUsingFileSaver = (text, title) => {
    return saveAs(new Blob([text]), title);
};

function FileExport({ open, onClose, fileString, filename }) {
    const handleSaveFile = async () => {
        if (navigator.share) {
            await exportUsingWebShareAPI(fileString, filename);
        } else {
            exportUsingFileSaver(fileString, filename);
        }
        onClose();
    };
    const handleCopyToClipboard = async () => {
        await copyToClipboard(fileString);
        onClose();
    };
    const canSave = !(isIOS && browserName === "Chrome" && !navigator.share);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Export "{filename}"</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleCopyToClipboard}>Copy to clipboard</Button>
                {canSave && (
                    <Button onClick={handleSaveFile} color="primary">
                        Save file
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default FileExport;

import React from "react";
import useWorkspaces from "./useWorkspaces";
import Container from "./Container";
import FileList from "./FileList";

import { Paper, Typography, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import useOnline from "./useOnline";

const useStyles = makeStyles(theme => ({
    paperContainer: {
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    spinnerContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.variables.navbarHeight,
        paddingTop: theme.spacing(1)
    },
    emptyText: {
        color: theme.colors.disabledText,
        fontStyle: "italic"
    }
}));

function Workspaces({ className, onOpenWorkspace }) {
    const classes = useStyles();
    const online = useOnline();

    const { offlineWorkspaces, availableWorkspaces, fetchError, fetchWorkspaces, fetchingOffline, fetchingAvailable } =
        useWorkspaces();

    return (
        <Container className={className}>
            {!offlineWorkspaces && !availableWorkspaces ? (
                <div className={classes.spinnerContainer}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <Paper className={classes.paperContainer}>
                        <Typography className={classes.bold} variant="h6">
                            Offline workspaces
                        </Typography>
                        {offlineWorkspaces.length > 0 ? (
                            <FileList
                                files={offlineWorkspaces}
                                fileItemProps={{ onClick: onOpenWorkspace }}
                                onChange={fetchWorkspaces}
                                disableDelete
                                disableMove
                                disableCopy
                            />
                        ) : (
                            <React.Fragment>
                                {fetchingOffline ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    <Typography variant="caption" className={classes.emptyText}>
                                        No offline workspaces
                                    </Typography>
                                )}
                            </React.Fragment>
                        )}
                    </Paper>
                    {fetchError ? (
                        <Paper className={classes.paperContainer}>
                            <Typography>{fetchError}</Typography>
                        </Paper>
                    ) : (
                        <Paper className={classes.paperContainer}>
                            <Typography className={classes.bold} variant="h6">
                                Available workspaces
                            </Typography>
                            {availableWorkspaces?.length > 0 ? (
                                <FileList
                                    files={availableWorkspaces}
                                    fileItemProps={{ onClick: onOpenWorkspace }}
                                    onChange={fetchWorkspaces}
                                    disableDelete
                                    disableMove
                                    disableCopy
                                />
                            ) : (
                                <React.Fragment>
                                    {fetchingAvailable ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <Typography variant="caption" className={classes.emptyText}>
                                            {online ? "No available workspaces" : "You are offline"}
                                        </Typography>
                                    )}
                                </React.Fragment>
                            )}
                        </Paper>
                    )}
                </>
            )}
        </Container>
    );
}

export default Workspaces;

import React, { useContext, forwardRef, useState } from "react";
import { ErrorContext } from "../../../error-context";
import { WorkspaceContext } from "../../workspace-context";
import { ObjectId } from "../../../id-generation";
import { saveOLFeature } from "./utility/utility";
import { useFeatureForm } from "../../../uncontrolled-forms/useFeatureForm";
import Form from "../../../forms/Form";
import UncontrolledForm from "../../../uncontrolled-forms/Form";

import { Dialog, DialogActions, Button, DialogTitle, DialogContent, DialogContentText, Box } from "@mui/material";

export const findInSchema = (key, schema) => {
    if (schema.key === key) {
        return schema;
    }
    for (const child of schema.children ?? []) {
        const result = findInSchema(key, child);
        if (result) {
            return result;
        }
    }
    return null;
};

function FormTool({ tool, onToolRequest, layers, workspace, dbManager }, ref) {
    const { setError } = useContext(ErrorContext);
    const { configuration } = useContext(WorkspaceContext);

    const [confirmCancelDialogOpen, setConfirmCancelDialogOpen] = useState(false);

    const olFeature = tool.feature;
    const layerId = olFeature.layer.get("id");
    const layer = layers.find(layer => layer._id === layerId);
    const attributeDefinitions = layer.attributes;
    const properties = olFeature.getProperties();
    delete properties.geometry;
    delete properties.__atlas;
    const attributes = Object.keys(properties).reduce((attributes, key) => {
        const attribute = attributeDefinitions.find(a => a._id === key || a.name === key);
        attributes[attribute._id] = properties[key];
        return attributes;
    }, {});
    const v2Attributes = { ...attributes };
    const arrayAttributes = attributeDefinitions.filter(a => !!a.array);
    arrayAttributes.forEach(attribute => {
        // react-hook-form doesn't like when arrays are null
        v2Attributes[attribute._id] = v2Attributes[attribute._id] ?? [];
    });

    const handleCancel = () => {
        if (!olFeature.getId()) {
            // This was a new feature & this form must be
            // filled out in order for the feature to be saved.
            // Cancelling this form will discard the feature.
            setConfirmCancelDialogOpen(true);
        } else {
            console.log("User cancelled form submission.");
            onToolRequest("normal");
        }
    };

    // Submission handler for old form structure (forms without "version: 2" flag)
    // Probably not used anymore.
    const handleSumbit = async values => {
        console.log("FormTool.handleSumbit");
        const oldProperties = olFeature.getProperties();
        const properties = {};
        Object.keys(values).forEach(key => {
            const attribute = attributeDefinitions.find(a => a._id === key || a.name === key);
            let schema = findInSchema(attribute._id, tool.schema);
            if (schema) {
                // Form uses _id in schema
                properties[attribute._id] = values[attribute._id];
            }
            schema = findInSchema(attribute.name, tool.schema);
            if (schema) {
                // Form uses name in schema
                console.log(`Attribute ${attribute.name} is referenced by name in form schema.`);
                properties[attribute._id] = values[attribute.name];
            }
        });
        olFeature.setProperties(properties);
        try {
            await saveOLFeature(olFeature, workspace, dbManager);
            onToolRequest("normal");
        } catch (error) {
            olFeature.setProperties(oldProperties);
            console.logError(error, "FormTool handleSubmit");
            setError(error);
        }
    };
    const handleFileUpload = async file => {
        const layerId = olFeature.layer.get("id");
        const idbFile = {
            id: ObjectId().toString(),
            localModified: Date.now(),
            layerId,
            file
        };
        await dbManager.saveLocalFile(idbFile, configuration._id);
        return { id: idbFile.id, name: file.name };
    };

    const { handleSubmit: _handleV2Submit } = useFeatureForm(layer, olFeature);
    const handleV2Submit = async (values, metadata) => {
        await _handleV2Submit(values, metadata);
        onToolRequest("normal");
    };

    return (
        <div ref={ref}>
            <Dialog fullScreen open={true}>
                {tool.schema?.version === 2 ? (
                    <Box p={1} boxSizing="border-box" maxWidth="1200px" ml="auto" mr="auto" pb={5} width="100%">
                        <UncontrolledForm
                            schema={tool.schema}
                            initialValues={v2Attributes}
                            attributeDefinitions={attributeDefinitions}
                            onCancel={handleCancel}
                            onSubmit={handleV2Submit}
                            layer={layer}
                        />
                    </Box>
                ) : (
                    <Form
                        schema={tool.schema}
                        initialValues={attributes}
                        attributeDefinitions={attributeDefinitions}
                        onCancel={handleCancel}
                        onSubmit={handleSumbit}
                        onUploadFile={handleFileUpload}
                    />
                )}
                <Dialog open={confirmCancelDialogOpen} onClose={() => setConfirmCancelDialogOpen(false)}>
                    <DialogTitle>Cancel feature creation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>The feature will be discarded. Are you sure?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setConfirmCancelDialogOpen(false)}>No</Button>
                        <Button
                            color="destructive"
                            onClick={() => {
                                olFeature.layer.getSource().removeFeature(olFeature);
                                console.log("User cancelled form submission for new feature.");
                                onToolRequest("normal");
                            }}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        </div>
    );
}

export default forwardRef(FormTool);

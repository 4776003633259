import { Box } from "@mui/material";
import { useForestandDateSpanUpdate } from "./useForestandDateSpanUpdate";
import SimpleNumericInput from "../../../inputs/SimpleNumericInput";

function ForestandDateSpan({ name, disabled, sx = {} }) {
    const { fromYear, toYear, setFromYear, setToYear } = useForestandDateSpanUpdate({ name });

    return (
        <Box sx={{ display: "flex", gap: 1, ...sx }}>
            <SimpleNumericInput
                sx={{ flexGrow: 1 }}
                label="Från"
                value={fromYear ?? ""}
                onChange={setFromYear}
                disabled={disabled}
            />
            <SimpleNumericInput
                sx={{ flexGrow: 1 }}
                label="Till"
                value={toYear ?? ""}
                onChange={setToYear}
                disabled={disabled}
            />
        </Box>
    );
}

export default ForestandDateSpan;

import React, { useMemo } from "react";
import prettyBytes from "pretty-bytes";
import moment from "moment";
import { useSyncStatus } from "./useSyncStatus";
import { useAnimatedValues } from "./useAnimatedValues";

import AnimatedNumber from "./AnimatedNumber";

import { LinearProgress, Table, TableCell, TableBody, TableRow, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SyncAlt as SyncAltIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    headerIcon: {
        marginRight: theme.spacing(1)
    },
    table: {
        "& td, & th": {
            borderBottom: "none",
            paddingLeft: 0,

            // Make the first column as narrow as possible
            "&:not(:first-child)": {
                width: "100%"
            },
            "&:first-child": {
                whiteSpace: "nowrap"
            }
        }
    },
    heading: {
        fontWeight: "bold"
    },
    progressCell: {
        // The default styling will override the padding for last-child...
        "&, &:last-child": {
            padding: 0
        }
    },
    caption: {
        color: "#888"
    }
}));

const UPDATE_INTERVAL = 5000; // ms
const STATUS_TEXT_VECTOR = {
    offline: "Currently offline",
    analyzing: "Processing",
    downloading: "Synchronizing",
    done: "Up to date"
};
const STATUS_TEXT_RASTER = {
    error: "Error",
    offline: "Currently offline",
    analyzing: "Processing",
    downloading: "Downloading",
    done: "Done"
};

const getVectorLastSyncedText = ({ status, lastUpToDate }) => {
    if (status === "offline") {
        if (typeof lastUpToDate === "string") {
            return lastUpToDate;
        } else {
            return moment(lastUpToDate).fromNow();
        }
    } else if (status === "done") {
        return moment(Date.now()).fromNow();
    } else {
        return "in progress";
    }
};
const integerFormatter = x => x.toLocaleString();
const bytesFormatter = x => prettyBytes(x);

function SyncStatus({ className, workspace }) {
    const classes = useStyles();
    const { vector, raster } = useSyncStatus(workspace, UPDATE_INTERVAL);
    const vectorAnimated = useAnimatedValues({ features: vector.features, localChanges: vector.localChanges });
    const rasterAnimated = useAnimatedValues({ tilesLeft: raster.tilesLeft, size: raster.size });
    const vectorLastSyncedText = useMemo(() => getVectorLastSyncedText(vector), [vector]);

    if (!vector || !raster || !workspace) {
        return null;
    }

    return (
        <div className={className}>
            <div className={classes.header}>
                <SyncAltIcon className={classes.headerIcon} />
                <Typography variant="h6" className={classes.heading}>
                    Sync status for {workspace.name}
                </Typography>
            </div>
            <Table className={classes.table} size="small">
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.heading} component="th">
                            Vector data
                        </TableCell>
                        <TableCell component="th">{STATUS_TEXT_VECTOR[vector.status]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.progressCell} colSpan={2}>
                            <LinearProgress
                                value={100}
                                variant={["done", "offline"].includes(vector.status) ? "determinate" : "indeterminate"}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Features:</TableCell>
                        <TableCell>
                            <AnimatedNumber integer value={vectorAnimated.features} formatter={integerFormatter} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Unsynced local changes:</TableCell>
                        <TableCell>
                            <AnimatedNumber integer value={vectorAnimated.localChanges} formatter={integerFormatter} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Last sync:</TableCell>
                        <TableCell>{vectorLastSyncedText}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell></TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell className={classes.heading} component="th">
                            Raster data
                        </TableCell>
                        <TableCell component="th">{STATUS_TEXT_RASTER[raster.status]}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.progressCell} colSpan={2}>
                            <LinearProgress
                                value={["offline", "error"].includes(raster.status) ? 0 : 100}
                                variant={
                                    ["done", "offline", "error"].includes(raster.status)
                                        ? "determinate"
                                        : "indeterminate"
                                }
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Downloaded:</TableCell>
                        <TableCell>
                            <AnimatedNumber integer value={rasterAnimated.size} formatter={bytesFormatter} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Remaining:</TableCell>
                        <TableCell>
                            <AnimatedNumber integer value={rasterAnimated.tilesLeft} /> parts
                            {raster.status === "downloading" && raster.analyzing && " (still counting...)"}
                        </TableCell>
                    </TableRow>
                    {!!raster.tilesFailed && (
                        <TableRow>
                            <TableCell>Failed:</TableCell>
                            <TableCell>{raster.tilesFailed}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {!!raster.tilesFailed && (
                <Typography className={classes.caption} variant="caption">
                    Reload page to retry download of failed tiles
                </Typography>
            )}
            {raster.status === "error" && (
                <Typography className={classes.caption} variant="caption">
                    An error occurred while downloading tiles. Try again later.
                    {raster.error?.message ? ` (Error: ${raster.error.message})` : ""}
                </Typography>
            )}
        </div>
    );
}

export default React.memo(SyncStatus);

import React from "react";
import OutlinedContainer from "../OutlinedContainer";
import NumberInput from "../inputs/NumberInput";
import OverrideWrapper from "./OverrideWrapper";

import { Slider } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    sliderContainer: {
        padding: theme.spacing(1),
        paddingBottom: 0
    }
}));

const DEFAULT_OPACITY = 1;

function Opacity({ opacity, onChange }) {
    const classes = useStyles();

    const handleChange = value => {
        const number = Number(value);
        if (Number.isInteger(number) && number >= 0 && number <= 100) {
            onChange(number / 100);
        }
    };

    return (
        <OutlinedContainer label="Opacity">
            <OverrideWrapper
                inherited={opacity === undefined}
                onOverride={() => onChange(DEFAULT_OPACITY)}
                onDelete={() => onChange(undefined)}
            >
                <NumberInput
                    name="Opacity"
                    value={Math.round(opacity * 100)}
                    onChange={handleChange}
                    step={1}
                    fullWidth
                    integer
                    min={0}
                    max={100}
                />
                <div className={classes.sliderContainer}>
                    <Slider value={opacity * 100} onChange={(_, newValue) => handleChange(newValue)} />
                </div>
            </OverrideWrapper>
        </OutlinedContainer>
    );
}

export default React.memo(Opacity, (prevProps, nextProps) => prevProps.opacity === nextProps.opacity);

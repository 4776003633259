import AkademiskaHusBuilding from "./akademiska-hus/AkademiskaHusBuilding";
import AkademiskaHusIndoorBuildingInfo from "./akademiska-hus/AkademiskaHusIndoorBuildingInfo";
import BarkbinTree from "./barkbin/BarkbinTree";
import BarkbinDamage from "./barkbin/BarkbinDamage";
import ForestandActivity from "./forestand/ForestandActivity";
import ForestandLandUse from "./forestand/ForestandLandUse";
import ForestandOccasion from "./forestand/ForestandOccasion";
import ForestandOccasions from "./forestand/ForestandOccasions";
import ForestandSpecies from "./forestand/ForestandSpecies";
import ForestandTreeLayer from "./forestand/ForestandTreeLayer";
import HTMLField from "../HTMLField";

const CustomInputs = {
    "akademiska-hus-building": { component: AkademiskaHusBuilding, array: false },
    "akademiska-hus-indoor-building-info": { component: AkademiskaHusIndoorBuildingInfo, array: false },
    "barkbin-tree": { component: BarkbinTree, array: false },
    "barkbin-damage": { component: BarkbinDamage, array: false },
    "forestand-activity": { component: ForestandActivity, array: false },
    "forestand-landuse": { component: ForestandLandUse, array: false },
    "forestand-occasion": { component: ForestandOccasion, array: false },
    "forestand-occasions": { component: ForestandOccasions, array: true },
    "forestand-species-compartmentwise": { component: ForestandSpecies, array: false },
    "forestand-species-treewise": { component: ForestandSpecies, array: false },
    "forestand-treelayer-compartmentwise": { component: ForestandTreeLayer, array: false },
    "forestand-treelayer-treewise": { component: ForestandTreeLayer, array: false },
    html: { component: HTMLField, array: false }
};

export default CustomInputs;

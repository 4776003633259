import { Component } from "react";
import { MapContext } from "../map-context";
import { addEventListeners, removeEventListeners, getOptions, hasChanged } from "../utility";

import OLModify from "ol/interaction/Modify";

class Modify extends Component {
    constructor(props) {
        super(props);

        this.options = {
            condition: undefined,
            deleteCondition: undefined,
            insertVertexCondition: undefined,
            pixelTolerance: undefined,
            style: undefined,
            source: undefined,
            features: undefined,
            wrapX: undefined
        };

        this.events = {};
        this.eventNames = {
            change: "change",
            changeactive: "change:active",
            propertychange: "propertychange",
            modifystart: "modifystart",
            modifyend: "modifyend"
        };
    }

    componentDidMount() {
        this.setup();
    }

    componentDidUpdate(prevProps) {
        if (hasChanged(this.props, prevProps, this.options)) {
            this.cleanup();
            this.setup();
        }
    }

    componentWillUnmount() {
        this.cleanup();
    }

    setup() {
        const options = getOptions(this.props, this.options);
        this.interaction = new OLModify(options);
        this.context.map.addInteraction(this.interaction);
        addEventListeners(this.props, this.events, this.eventNames, this.interaction);
        if (this.props.innerRef) {
            this.props.innerRef(this.interaction);
        }
    }

    cleanup() {
        this.context.map.removeInteraction(this.interaction);
        removeEventListeners(this.events, this.interaction);
    }

    render() {
        return null;
    }
}

Modify.contextType = MapContext;

export default Modify;

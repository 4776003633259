/**
 * Yo dawg I heard you like functions
 *
 * Example here: https://mui.com/x/react-data-grid/filtering/#wrap-built-in-operators
 */
export const wrapFilenameOperator = operator => {
    const getApplyFilterFn = (...args) => {
        const originalFn = operator.getApplyFilterFn(...args);
        if (!originalFn) {
            return null;
        }
        return params => {
            // Send the filename to the filter function
            // instead of the file object { id, name }
            params.value = params.value?.name;
            return originalFn(params);
        };
    };

    return {
        ...operator,
        getApplyFilterFn
    };
};

import React, { useState } from "react";
import Fill from "./Fill";
import Stroke from "./Stroke";
import Integer from "./Integer";
import ExpandingStyleListItem from "./ExpandingStyleListItem";
import OutlinedContainer from "../OutlinedContainer";
import IconPicker from "./IconPicker";

import { Tabs, Tab, Typography, List } from "@mui/material";

function Symbol({ symbol, onChange }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = key => value =>
        onChange(currentSymbol => ({
            ...currentSymbol,
            [key]: typeof value === "function" ? value(currentSymbol[key]) : value
        }));

    const listItemProps = {
        onClick: id => setExpanded(id === expanded ? false : id),
        onOverride: id => onChange(currentSymbol => ({ ...currentSymbol, [id]: { type: "none" } })),
        onDelete: id =>
            onChange(currentSymbol => {
                const newSymbol = { ...currentSymbol };
                delete newSymbol[id];
                if (id === expanded) {
                    setExpanded(false);
                }
                return newSymbol;
            })
    };

    return (
        <React.Fragment>
            <OutlinedContainer label="Type">
                <Tabs
                    value={symbol.type}
                    onChange={(_, value) => handleChange("type")(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="none" value="none" />
                    <Tab label="circle" value="circle" />
                    <Tab label="icon" value="icon" />
                </Tabs>
            </OutlinedContainer>
            {symbol.type === "circle" && (
                <React.Fragment>
                    <Integer label="Size" value={symbol.size} defaultValue={5} onChange={handleChange("size")} />
                    <Typography variant="overline">Colors</Typography>
                    <List dense sx={{ py: 0 }}>
                        <ExpandingStyleListItem
                            title="Fill"
                            id="fill"
                            expanded={expanded === "fill"}
                            inherited={symbol.fill === undefined}
                            {...listItemProps}
                        >
                            <Fill fill={symbol.fill} onChange={handleChange("fill")} />
                        </ExpandingStyleListItem>
                        <ExpandingStyleListItem
                            title="Stroke"
                            id="stroke"
                            expanded={expanded === "stroke"}
                            inherited={symbol.stroke === undefined}
                            {...listItemProps}
                        >
                            <Stroke stroke={symbol.stroke} onChange={handleChange("stroke")} />
                        </ExpandingStyleListItem>
                    </List>
                </React.Fragment>
            )}
            {symbol.type === "icon" && <IconPicker icon={symbol.icon} onChange={handleChange("icon")} />}
        </React.Fragment>
    );
}

export default React.memo(Symbol, (prevProps, nextProps) => prevProps.symbol === nextProps.symbol);

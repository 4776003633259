import React, { useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { ErrorContext } from "../error-context";
import { AtlasContext } from "../atlas-context";
import { generateLayerExport } from "../generateLayerExport";
import useOnline from "../useOnline";
import ExpandingListItem from "../ExpandingListItem";

import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    List,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: "20px"
    },
    centered: {
        display: "block",
        margin: "auto"
    },
    textInput: {
        marginTop: theme.spacing(2)
    },
    metadataInput: {
        margin: theme.spacing(1)
    },
    divider: {
        margin: theme.spacing(2)
    }
}));

const getMetadataOptions = type => {
    const options = [
        {
            key: "createdTimestamp",
            title: "Created timestamp"
        },
        {
            key: "modifiedTimestamp",
            title: "Modified timestamp"
        },
        {
            key: "workspaceName",
            title: "Workspace name"
        },
        {
            key: "parts",
            title: "Number of parts"
        }
    ];
    if (type === "line" || type === "polygon") {
        options.push({
            key: "perimeter",
            title: "Perimeter"
        });
        options.push({
            key: "vertices",
            title: "Vertices"
        });
    }
    if (type === "polygon") {
        options.push({
            key: "area",
            title: "Area"
        });
    }
    return options;
};

function ExportLayerDialog({ open, onClose, layer, workspace }) {
    const [downloadStatus, setDownloadStatus] = useState(null);
    const [downloadStatusText, setDownloadStatusText] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [format, setFormat] = useState("geojson");
    const [includeFileAttributes, setIncludeFileAttributes] = useState(false);
    // Format: { "metadataKey": "metadata attribute name" }
    const [metadata, setMetadata] = useState(null);
    const [metadataOptions, setMetadataOptions] = useState([]);
    const [expansion, setExpansion] = useState(null);
    const online = useOnline();
    const { setError } = useContext(ErrorContext);
    const { dbManager } = useContext(AtlasContext);

    const layerSourceType = layer?.source?.type;
    useEffect(() => {
        const options = getMetadataOptions(layerSourceType);
        setMetadataOptions(options);
        setMetadata(options.reduce((all, mOption) => ({ ...all, [mOption]: null }), {}));
    }, [layerSourceType]);

    useEffect(() => {
        let cancelled = false;
        const fetchStatus = async () => {
            const db = await dbManager.getVectorDB(layer._id, workspace._id);
            const upToDate = await db.getMeta("upToDate");
            let status;
            if (upToDate) {
                status = "upToDate";
            } else {
                status = "notUpToDate";
            }
            if (status !== downloadStatus) {
                setDownloadStatus(status);
            }
            if (!upToDate) {
                setTimeout(() => {
                    if (!cancelled) {
                        fetchStatus();
                    }
                }, 5000);
            }
        };
        fetchStatus();
        return () => {
            cancelled = true;
        };
    }, [downloadStatus, layer, workspace, online, dbManager]);
    useEffect(() => {
        if (downloadStatus === null) {
            setDownloadStatusText("Checking download status");
        } else if (!online) {
            setDownloadStatusText("Download status: Currently offline, export might not be complete.");
        } else if (downloadStatus === "upToDate") {
            setDownloadStatusText("Download status: Up to date.");
        } else {
            setDownloadStatusText("Download status: Still downloading features, export will be incomplete.");
        }
    }, [downloadStatus, online]);

    const handleExport = async () => {
        setExporting(true);
        try {
            const { content, extension } = await generateLayerExport(layer, workspace, dbManager, {
                format,
                metadata,
                includeFileAttributes
            });
            saveAs(new Blob([content]), `${layer.name}.${extension}`);
            onClose();
        } catch (error) {
            setError(error);
        } finally {
            setExporting(false);
        }
    };
    const setMetadataValue = (key, value) => {
        setMetadata(metadata => {
            const newMetadata = { ...metadata };
            if (value !== undefined) {
                newMetadata[key] = value;
                setExpansion(key);
            } else {
                delete newMetadata[key];
            }
            return newMetadata;
        });
    };

    const classes = useStyles();
    const hasFileAttributes = layer.attributes.some(attribute => ["file", "image"].includes(attribute.type));

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Box sx={{ pb: 1 }}>
                    <Typography variant="h5">Export "{layer.name}"</Typography>
                    {downloadStatusText && <Typography variant="caption">{downloadStatusText}</Typography>}
                </Box>
                <FormControl>
                    <Typography variant="subtitle2">Format</Typography>
                    <RadioGroup value={format} onChange={event => setFormat(event.target.value)}>
                        <FormControlLabel value="geojson" control={<Radio color="primary" />} label="GeoJSON" />
                        <FormControlLabel value="csv" control={<Radio color="primary" />} label="CSV" />
                    </RadioGroup>
                </FormControl>
                <Divider className={classes.divider} variant="middle" />
                {hasFileAttributes && (
                    <>
                        <FormControl>
                            <FormControlLabel
                                label="Include files and images"
                                disabled={exporting}
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={includeFileAttributes}
                                        onChange={event => setIncludeFileAttributes(event.target.checked)}
                                    />
                                }
                            />
                            <FormHelperText>
                                If you don't include files and images, attributes that contain them will be replaced
                                with a link to download the file or image manually later.
                            </FormHelperText>
                        </FormControl>
                        <Divider className={classes.divider} variant="middle" />
                    </>
                )}
                <Typography variant="subtitle2">Metadata</Typography>
                <FormHelperText>Select which metadata you want to include as attributes in the export.</FormHelperText>
                <List dense>
                    {metadataOptions.map(metaOption => (
                        <div key={metaOption.key}>
                            <ExpandingListItem
                                id={metaOption.key}
                                expanded={expansion === metaOption.key}
                                onClick={() => setExpansion(expansion === metaOption.key ? null : metaOption.key)}
                                title={metaOption.title}
                                icon={
                                    <Checkbox
                                        color="primary"
                                        checked={metadata?.hasOwnProperty(metaOption.key)}
                                        disabled={exporting}
                                        onClick={event => event.stopPropagation()}
                                        onFocus={event => event.stopPropagation()}
                                        onChange={event =>
                                            setMetadataValue(
                                                metaOption.key,
                                                event.target.checked ? metaOption.key : undefined
                                            )
                                        }
                                    />
                                }
                            >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    className={classes.metadataInput}
                                    disabled={exporting}
                                    label={`${metaOption.title} attribute name`}
                                    value={metadata?.[metaOption.key] ?? ""}
                                    onChange={event => setMetadataValue(metaOption.key, event.target.value)}
                                />
                            </ExpandingListItem>
                        </div>
                    ))}
                </List>
                {exporting && <CircularProgress className={classes.centered} color="primary" />}
            </DialogContent>
            {!exporting && (
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                    <Button onClick={handleExport} color="primary">
                        Save file
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
}

export default ExportLayerDialog;

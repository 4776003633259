import { memo } from "react";
import { useFieldController } from "./useFieldController";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import RenderCount from "../RenderCount";
import WarnableTextField from "./WarnableTextField";

function StringField({ className, name, rules, schema, disabled, defaultValue }) {
    const { field, fieldState } = useFieldController({
        name,
        rules,
        defaultValue: defaultValue === null ? "" : defaultValue
    });

    return (
        <>
            <RenderCount label="StringField" />
            <WarnableTextField
                className={className}
                fullWidth
                variant="outlined"
                label={schema.title}
                multiline={!!schema.multiline}
                {...field}
                disabled={disabled}
                value={field.value ?? ""}
                warning={hasWarning(fieldState.error)}
                error={hasError(fieldState.error)}
                helperText={getErrorMessages(fieldState.error)?.join(", ")}
            />
        </>
    );
}

export default memo(StringField);

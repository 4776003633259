import React, { useContext, useState } from "react";
import { postNativeMessage } from "../Native";
import { AtlasContext } from "./atlas-context";
import { ErrorContext } from "./error-context";
import useFileURL from "./useFileURL";
import { blobToBase64String } from "../NativeLocalDatabase";

import { Typography, Button, CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1)
    },
    image: {
        width: "100%"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

function FileViewer({ file }) {
    const classes = useStyles();
    const { dbManager } = useContext(AtlasContext);
    const { setError } = useContext(ErrorContext);

    const { url, isLocal } = useFileURL({ id: file && file.id, dbManager });
    const name = file && file.name;
    const type = file && file.type;

    const [downloading, setDownloading] = useState(false);
    const download = async () => {
        if (window.ATLAS_IS_NATIVE) {
            setDownloading(true);
            try {
                let nativeURL;
                if (isLocal) {
                    const blob = await dbManager.getLocalFile(file.id);
                    nativeURL = await blobToBase64String(blob);
                } else {
                    nativeURL = url;
                }
                await postNativeMessage("download-file", { url: nativeURL, name });
            } catch (error) {
                setError(error);
            }
            setDownloading(false);
        } else {
            window.open(url, "_blank");
        }
    };

    return (
        <div className={classes.container}>
            {url && type === "image" ? (
                <img className={classes.image} src={url} alt={name || ""} />
            ) : (
                <Typography variant="overline" component="p">
                    {name}
                </Typography>
            )}
            <div className={classes.buttonContainer}>
                {downloading ? (
                    <CircularProgress />
                ) : (
                    <Button variant="outlined" color="primary" onClick={download}>
                        Download
                    </Button>
                )}
            </div>
        </div>
    );
}

export default FileViewer;

import React, { useState, useContext } from "react";
import { deviceDetect } from "react-device-detect";
import { AtlasContext } from "./atlas-context.js";
import { ErrorContext } from "./error-context.js";
import Api from "../Api.js";
import LoadingDialog from "./LoadingDialog.js";
import packageInfo from "../../package.json";

import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircle } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center"
    },
    icon: {
        marginRight: theme.spacing(1)
    }
}));

const LOG_BATCH_LIMIT = 1000;

function UploadLogsDialog({ open, onClose }) {
    const classes = useStyles();
    const [sending, setSending] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [problemText, setProblemText] = useState("");
    const [logsSent, setLogsSent] = useState(false);
    const { dbManager } = useContext(AtlasContext);
    const { setError } = useContext(ErrorContext);

    const addMetadata = async (metadata, key, func) => {
        try {
            metadata[key] = await func();
        } catch (error) {
            console.logError(error, `gatherMetadata: error gathering ${key}`);
            metadata[key] = `Error: ${error?.message}`;
        }
    };

    const gatherMetadata = async () => {
        const metadata = {};
        await new Promise(resolve => setTimeout(resolve, 4000));
        await addMetadata(metadata, "windowId", () => window.windowId);
        await addMetadata(metadata, "deviceDetect", () => deviceDetect());
        await addMetadata(metadata, "workspaces", async () => dbManager?._atlasDB?.getAll("workspaces"));
        await addMetadata(metadata, "layers", async () => dbManager?._atlasDB?.getAll("layers"));
        await addMetadata(metadata, "failedUploads", async () => dbManager?._atlasDB?.getAll("failedUploads"));
        await addMetadata(metadata, "persisted", async () => navigator?.storage?.persisted?.());
        await addMetadata(metadata, "estimate", async () => navigator?.storage?.estimate?.());
        await addMetadata(metadata, "version", () => packageInfo?.version);
        await addMetadata(metadata, "localStorage", () => JSON.stringify(localStorage));
        return metadata;
    };

    const sendReport = async () => {
        setSending(true);
        setUploadProgress(0);
        console.log("Starting log upload");
        try {
            const metadata = await gatherMetadata();
            const { id } = await Api.uploadReport(problemText, metadata);
            console.log(`Report upload done. id: ${id}`);
            setUploadProgress(0.01);
            const totalLogCount = await dbManager._atlasDB.count("logs");
            console.log(`Uploading ${totalLogCount} logs`);
            let batch = 0;

            const uploadLogs = async () => {
                console.log(`Fetching log batch ${++batch}.`);
                const logs = await dbManager._atlasDB.getAt(0, "logs", null, null, LOG_BATCH_LIMIT);
                console.log(`Uploading log batch ${batch}.`);
                await Api.uploadLogs(logs, id);
                console.log(`Log batch ${batch} upload complete, removing locally.`);
                await dbManager._atlasDB.performChanges({
                    logs: {
                        delete: logs
                    }
                });
                console.log(`Log batch ${batch} done.`);
                setUploadProgress(progress =>
                    Math.max(0.01, Math.min((progress || 0) + logs.length / totalLogCount, 0.99))
                );
                if (logs.length === LOG_BATCH_LIMIT) {
                    await uploadLogs();
                }
            };
            await uploadLogs();
        } catch (error) {
            console.logError(error, "Error uploading logs");
            setError(new Error(`There was an error uploading the logs. Try again later. (${error.message})`));
            setSending(false);
            return;
        }
        console.log("Log upload complete");

        setSending(false);
        setLogsSent(true);
        setProblemText("");
        setUploadProgress(null);
    };

    return (
        <LoadingDialog
            fullWidth
            open={open}
            onClose={onClose}
            loading={sending}
            progress={uploadProgress}
            message="This may take a while. Please do not close this window."
        >
            {logsSent ? (
                <React.Fragment>
                    <DialogContent className={classes.container}>
                        <CheckCircle className={classes.icon} />
                        <Typography variant="h6">Logs uploaded</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setLogsSent(false);
                                onClose();
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <DialogTitle>Upload device logs</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Tell us about your problem</DialogContentText>
                        <TextField
                            fullWidth
                            variant="outlined"
                            value={problemText}
                            onChange={event => setProblemText(event.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button color="primary" onClick={sendReport}>
                            Upload
                        </Button>
                    </DialogActions>
                </React.Fragment>
            )}
        </LoadingDialog>
    );
}

export default UploadLogsDialog;

import React from "react";
import OverrideWrapper from "./OverrideWrapper";
import ExpandingListItem from "../ExpandingListItem";

import { Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    listItem: {
        width: "100%",
        position: "relative",
        paddingRight: theme.spacing(1),
        minHeight: "52px" // Match height of delete button even when it's missing
    },
    label: {
        fontWeight: "bold"
    },
    content: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1)
    }
}));

function ExpandingStyleListItem({
    id,
    title,
    icon,
    inherited,
    disableInherit,
    expanded,
    onClick,
    onOverride,
    onDelete,
    children
}) {
    const classes = useStyles();

    return (
        <ExpandingListItem
            nested
            id={id}
            icon={icon}
            expanded={expanded}
            title={title}
            listItemProps={{ className: classes.listItem }}
            onClick={() => onClick(id)}
            disabled={inherited}
            titleElement={
                !disableInherit && (
                    <OverrideWrapper
                        label={title}
                        inherited={inherited}
                        onOverride={() => onOverride(id)}
                        onDelete={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            onDelete(id);
                        }}
                    >
                        <Typography className={classes.label} variant="subtitle2">
                            {title}
                        </Typography>
                    </OverrideWrapper>
                )
            }
        >
            {!inherited && <React.Fragment>{children}</React.Fragment>}
        </ExpandingListItem>
    );
}

export default ExpandingStyleListItem;

import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { ErrorMessage } from "../Error";
import { TextField } from "@mui/material";

function DateFormInput({ schema, form: { setFieldValue }, field, enabled, error, setError }) {
    const [value, setValue] = useState(field.value);

    const errorProp = {};
    if (error && error.type === "error") {
        errorProp.error = true;
    }

    return (
        <div>
            <DatePicker
                disabled={!enabled}
                inputFormat="YYYY-MM-DD"
                label={schema.title}
                value={value ?? null}
                onChange={momentDate => {
                    setError(field.name, null);
                    setValue(momentDate);
                    if (momentDate === null) {
                        setFieldValue(field.name, null);
                    } else if (momentDate.isValid()) {
                        setFieldValue(field.name, momentDate.valueOf());
                    } else {
                        setError(field.name, "Invalid date format");
                    }
                }}
                renderInput={params => (
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="YYYY-MM-DD"
                        error={!!errorProp?.error}
                        {...params}
                    />
                )}
                componentsProps={{
                    actionBar: {
                        actions: ["clear", "cancel", "accept"]
                    }
                }}
            />
            <ErrorMessage error={error} />
        </div>
    );
}

export default DateFormInput;

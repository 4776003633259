import { memo, useContext, useEffect, useState } from "react";
import { FormContext } from "../../Form";
import { useController, useWatch } from "react-hook-form";
import {
    treeSpecies as FORESTAND_SPECIES,
    activities as FORESTAND_ACTIVITIES,
    activityRoles as FORESTAND_ROLES,
    treeLayers as FORESTAND_TREE_LAYERS,
    convertActivityCode,
    convertActivitySysnameAndCategory
} from "forestand-definitions";
import ArrayField from "../../ArrayField";
import EnumField from "../../EnumField";
import ExpandingListItem from "../../../ExpandingListItem";
import ForestandActivityAttribute from "./ForestandActivityAttribute";
import ForestandDateSpan from "./ForestandDateSpan";
import ForestandDateSpanDropdown from "./ForestandDateSpanDropdown";
import PercentageField from "../../PercentageField";
import StringField from "../../StringField";
import WarnableTextField from "../../WarnableTextField";

import { IconButton, MenuItem } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const CATEGORIES = [...new Set(FORESTAND_ACTIVITIES.map(activity => activity.category))].filter(c => !!c).sort();

const STRING_ATTRIBUTES = [
    { key: "technique", title: "Technique" },
    { key: "length", title: "Length" },
    { key: "session", title: "Session" },
    { key: "objective", title: "Objective" },
    { key: "substance", title: "Substance" },
    { key: "amount", title: "Amount" },
    { key: "method", title: "Method" }
];

const getTreeSpeciesDesctiption = treeSpecies => {
    const species = FORESTAND_SPECIES.find(species => species.code === treeSpecies);
    if (species) {
        return `${species.code}: ${species.name}`;
    }
};

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

const validNameSysNamesForCategory = category =>
    FORESTAND_ACTIVITIES.filter(activity => activity.category === category)
        .map(activity => activity["sysname"])
        .sort();

function ForestandActivity({ name, disabled, onRemoveFromArray, isNew, historical }) {
    const classes = useStyles();

    const { attributeDefinitions } = useContext(FormContext);
    const treeLayerAttribute = attributeDefinitions?.find(a => a.name === "treeLayer");
    const treeLayers = useWatch({ name: treeLayerAttribute?._id });
    const treeLayerOptions =
        treeLayers?.map(treeLayer => ({
            title: FORESTAND_TREE_LAYERS.find(tl => tl.code === treeLayer.treeLayer)?.name ?? treeLayer.treeLayer,
            subtitle: getTreeSpeciesDesctiption(treeLayer.treeSpecies) ?? "",
            value: treeLayer.id
        })) ?? [];

    const activityIdController = useController({ name: `${name}.activityId` });
    const activityId = activityIdController.field.value;
    const activity = FORESTAND_ACTIVITIES.find(activity => activity.activityId === activityId);
    const [category, setCategory] = useState(null);
    const [sysName, setSysName] = useState(null);

    useEffect(() => {
        const result = convertActivityCode(activityId);
        if (result) {
            const { name, category } = result;
            setSysName(name);
            setCategory(category);
        }
    }, [activityId]);

    const handleCategoryChange = event => {
        const newCategory = event.target.value;
        setCategory(newCategory);
        const newValidNameSysNames = validNameSysNamesForCategory(newCategory);
        if (!newValidNameSysNames.includes(sysName)) {
            setSysName(null);
        }
        const newActivityId = convertActivitySysnameAndCategory(sysName, newCategory);
        activityIdController.field.onChange(newActivityId);
    };
    const handleNameSysNameChange = event => {
        const newNameSysName = event.target.value;
        setSysName(newNameSysName);
        const newActivityId = convertActivitySysnameAndCategory(newNameSysName, category);
        activityIdController.field.onChange(newActivityId);
    };

    const SYSNAMES = validNameSysNamesForCategory(category);

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title="Aktivitet"
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <EnumField
                className={classes.input}
                name={`${name}.objectReference`}
                schema={{
                    title: "Skikt",
                    options: treeLayerOptions,
                    includeNull: true,
                    includeNullTitle: "Inget",
                    hideNullSubtitle: true
                }}
                disabled={disabled}
            />
            <WarnableTextField
                sx={{ mt: 1 }}
                select
                fullWidth
                variant="outlined"
                label="Kategori"
                disabled={disabled}
                value={category ?? ""}
                onChange={handleCategoryChange}
                onBlur={activityIdController.field.onBlur}
            >
                {CATEGORIES.map(c => (
                    <MenuItem key={c} value={c}>
                        {c}
                    </MenuItem>
                ))}
            </WarnableTextField>
            {SYSNAMES.length > 0 && (
                <WarnableTextField
                    sx={{ mt: 1 }}
                    select
                    fullWidth
                    variant="outlined"
                    label="Åtgärd"
                    disabled={disabled}
                    value={SYSNAMES.includes(sysName) ? sysName : ""}
                    onChange={handleNameSysNameChange}
                    onBlur={activityIdController.field.onBlur}
                >
                    {SYSNAMES.map(sysName => (
                        <MenuItem key={sysName} value={sysName}>
                            {sysName}
                        </MenuItem>
                    ))}
                </WarnableTextField>
            )}
            {historical ? (
                <StringField
                    className={classes.input}
                    name={`${name}.date`}
                    schema={{ title: "Datum" }}
                    disabled={disabled}
                />
            ) : isNew ? (
                <ForestandDateSpanDropdown
                    sx={{ mt: 1 }}
                    name={name}
                    select
                    fullWidth
                    variant="outlined"
                    label="Datum"
                    disabled={disabled}
                />
            ) : (
                <ForestandDateSpan name={name} sx={{ mt: 1 }} disabled={disabled} />
            )}
            <ForestandActivityAttribute
                activityId={activityId}
                defaultKey="harvestShareArea"
                name={`${name}.harvest.0.shareArea`}
                isNew={isNew}
            >
                <PercentageField
                    className={classes.input}
                    name={`${name}.harvest.0.shareArea`}
                    schema={{ title: "Uttagsprocent" }}
                    defaultValue={activity?.default.harvestShareArea}
                    disabled={disabled}
                />
            </ForestandActivityAttribute>
            <EnumField
                className={classes.input}
                name={`${name}.role`}
                schema={{
                    title: "Åtgärdstyp",
                    options: FORESTAND_ROLES,
                    hideValues: true
                }}
                defaultValue={activity?.default.role}
                disabled={disabled}
            />
            {STRING_ATTRIBUTES.map(({ key, title }) => (
                <ForestandActivityAttribute
                    key={key}
                    activityId={activityId}
                    defaultKey={key}
                    name={`${name}.${key}`}
                    isNew={isNew}
                >
                    <StringField
                        className={classes.input}
                        name={`${name}.${key}`}
                        schema={{ title }}
                        defaultValue={activity?.default[key]}
                        disabled={disabled}
                    />
                </ForestandActivityAttribute>
            ))}
            <ArrayField
                schema={{
                    key: `${name}.note`,
                    type: "string",
                    title: "Kommentar",
                    pluralTitle: "Kommentarer"
                }}
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(ForestandActivity);

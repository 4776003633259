import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopyContext } from "./copy-context";

/**
 * Use only strings (stringify) as type & value to avoid [object Object]
 */
function CopyManager({ children }) {
    const [type, setType] = useState(null);
    const [value, setValue] = useState(null);

    useEffect(() => {
        setType(localStorage.getItem("CopyManager.type"));
        setValue(localStorage.getItem("CopyManager.value"));
    }, []);

    const copy = useCallback((type, value) => {
        setType(type);
        setValue(value);
        localStorage.setItem("CopyManager.type", type);
        localStorage.setItem("CopyManager.value", value);
    }, []);
    const contextValue = useMemo(() => ({ type, value, copy }), [type, value, copy]);

    return <CopyContext.Provider value={contextValue}>{children}</CopyContext.Provider>;
}

export default React.memo(CopyManager);

import React, { useState } from "react";
import FileCellRenderer from "./FileCellRenderer";
import NullableCellRenderer from "./NullableCellRenderer";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    Typography
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    text: {
        cursor: "pointer"
    }
});

function FileArrayCellRenderer({ value, attribute, ...other }) {
    const classes = useStyles();
    const [listOpen, setListOpen] = useState(false);

    if (!value || value.length === 0) {
        return <NullableCellRenderer attribute={attribute} {...other} />;
    }
    if (value.length === 1) {
        return <FileCellRenderer value={value[0]} attribute={attribute} {...other} />;
    }

    return (
        <Box>
            <Typography className={classes.text} onClick={() => setListOpen(true)}>
                MULTIPLE
            </Typography>
            <Dialog fullWidth open={listOpen} onClose={() => setListOpen(false)}>
                <DialogTitle>{attribute.name}</DialogTitle>
                <DialogContent>
                    <List>
                        {value?.map((file, index) => (
                            <React.Fragment key={index}>
                                <ListItem>
                                    {file ? (
                                        <FileCellRenderer value={file} attribute={attribute} {...other} />
                                    ) : (
                                        <NullableCellRenderer attribute={attribute} {...other} />
                                    )}
                                </ListItem>
                                {index !== value.length - 1 && <Divider component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={() => setListOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default FileArrayCellRenderer;

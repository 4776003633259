import React, { useEffect, useState } from "react";
import FileItemIcon from "./FileItemIcon";

import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    CircularProgress
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useSyncStatus } from "./useSyncStatus";

function OfflineFileListItem({
    disabled,
    file,
    onClick,
    disableMenu,
    onMenuClick,
    loading,
    setSyncStatusFile
}) {
    const { raster, vector } = useSyncStatus(file, 5000);

    const [syncState, setSyncState] = useState("in-progress");
    const [syncAvatarColor, setSyncAvatarColor] = useState("orange");

    useEffect(() => {
        if (raster.status === "done" && vector.status === "done") {
            if (raster.tilesFailed > 0) setStateFailed();
            else setStateSuccess();
        } else if (raster.status === "error" || vector.status === "error") {
            setStateFailed();
        } else {
            setStateInProgress();
        }
    }, [raster, vector]);

    const handleClick = file => {
        if (!disabled) {
            onClick(file);
        }
    };

    const setStateSuccess = () => {
        setSyncState("sync-done");
        setSyncAvatarColor("green");
    };

    const setStateFailed = () => {
        setSyncState("sync-error");
        setSyncAvatarColor("red");
    };

    const setStateInProgress = () => {
        setSyncState("sync-in-progress");
        setSyncAvatarColor("gray");
    };

    return (
        <ListItem
            button={!!onClick}
            disabled={disabled}
            onClick={() => {
                handleClick(file);
            }}
        >
            <ListItemAvatar>
                <Avatar>
                    <FileItemIcon type={file.type} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={file.description} />

            {!disableMenu && (
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => {
                            setSyncStatusFile(file);
                        }}
                        sx={{ backgroundColor: syncAvatarColor }}
                    >
                        <FileItemIcon type={syncState} />
                    </IconButton>

                    <IconButton
                        onClick={event => onMenuClick(event, file)}
                        disabled={loading || disabled}
                        size="large"
                    >
                        {loading ? <CircularProgress size={22} /> : <MoreHoriz />}
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

export default OfflineFileListItem;

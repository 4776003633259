import { transform } from "ol/proj";
import { postNativeMessage } from "../../../../../Native";

async function openInGoogleMaps(coordinates, projection) {
    const [long, lat] = transform(coordinates, projection, "EPSG:4326");
    const url = `https://maps.google.com/?q=${lat},${long}`;
    if (window.ATLAS_IS_NATIVE) {
        await postNativeMessage("open-url", { url });
    } else {
        window.open(url, "_blank");
    }
}

export default openInGoogleMaps;

import React from "react";
import OverrideWrapper from "./OverrideWrapper";
import OutlinedContainer from "../OutlinedContainer";
import InheritIcon from "./InheritIcon";

import { TextField, IconButton } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative"
    },
    deleteButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: "6px"
    },
    helperText: {
        marginBottom: theme.spacing(1)
    }
}));

function PlainText({ label, text, defaultText = "", helperText, onChange }) {
    const classes = useStyles();

    const inherited = text === undefined;
    const Container = inherited ? OutlinedContainer : React.Fragment;
    const containerProps = inherited ? { label: "Text" } : {};

    return (
        <Container {...containerProps}>
            <OverrideWrapper inherited={inherited} onOverride={() => onChange(defaultText)}>
                <div className={classes.container}>
                    <TextField
                        variant="outlined"
                        type="text"
                        label={label}
                        helperText={helperText}
                        fullWidth
                        value={text}
                        onChange={event => onChange(event.target.value)}
                        FormHelperTextProps={{ className: classes.helperText }}
                    />
                    {!inherited && (
                        <IconButton className={classes.deleteButton} onClick={() => onChange(undefined)} size="large">
                            <InheritIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
            </OverrideWrapper>
        </Container>
    );
}

export default React.memo(PlainText);

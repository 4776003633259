const VECTOR_TYPES = new Set(["polygon", "line", "point"]);
const RASTER_TYPES = new Set(["raster", "external"]);
export const isVectorLayer = layer => VECTOR_TYPES.has(layer?.source?.type);
export const isRasterLayer = layer => RASTER_TYPES.has(layer?.source?.type);

export const getRequiredForm = layer => {
    const requiredForm = layer.forms?.find(form => form.required === true);
    if (requiredForm) {
        return requiredForm;
    }
    if (layer.attributes.some(attribute => attribute.required)) {
        // Layer has required attributes.
        return {
            title: `Create feature in ${layer.name}`,
            subtitle:
                "This layer has required attributes that need to be filled out in order for the feature to be created.",
            children: layer.attributes
                .filter(attribute => attribute.required)
                .map(attribute => ({
                    type: attribute.type,
                    key: attribute._id,
                    title: attribute.name,
                    required: true
                }))
        };
    }
    return null;
};

/**
 * @returns `true` if the current user does have the requested permission
 * in the specified workspace, else `false`.
 */
export const checkLayerPermission = (permission, layer, workspace) => {
    const layerOverrideRights =
        workspace &&
        workspace.layerOverrides &&
        workspace.layerOverrides[layer._id] &&
        workspace.layerOverrides[layer._id].userRights;
    if (layerOverrideRights) {
        return layerOverrideRights.includes(permission);
    } else {
        return layer.userRights.includes(permission);
    }
};

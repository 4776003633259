import React from "react";
import RasterLayer from "./RasterLayer";
import { useLayerVisible } from "../../../useLayerVisible";
import { useLayerZIndex } from "../../../useLayerZIndex";
import { useLayerOpacity } from "../../../useLayerOpacity";

function RasterLayerWrapper({ configuration, ...props }) {
    const visible = useLayerVisible(configuration);
    const zIndex = useLayerZIndex(configuration);
    const opacity = useLayerOpacity(configuration);

    return <RasterLayer configuration={configuration} visible={visible} zIndex={zIndex} opacity={opacity} {...props} />;
}

export default RasterLayerWrapper;

import React, { Component } from "react";
import { WorkspaceContext } from "../../workspace-context";
import { getFeaturesFromEvent } from "./utility/utility";
import { getRequiredForm, checkLayerPermission } from "../../../layer-tools";
import { createOLStyles } from "../styles/styles";
import { copyToClipboard } from "../../../copyToClipboard";
import openInGoogleMaps from "./utility/open-in-google-maps";
import SelectStyle from "../styles/SelectStyle";
import FeatureInfo from "../../info/FeatureInfo";
import MobileFriendlyDialog from "../../../MobileFriendlyDialog";
import MobileFriendlyDialogContent from "../../../MobileFriendlyDialogContent";

import { Button, Chip, DialogActions, Menu, MenuItem, Typography } from "@mui/material";
import { Add, Ballot, Delete, Directions, Edit, Info, Straighten, SwapHoriz } from "@mui/icons-material";
import MouseClick from "../../../../icons/MouseClick";

class Select extends Component {
    constructor(props) {
        super(props);

        this._handleAddFeature = this._handleAddFeature.bind(this);
        this._deleteSelectedFeature = this._deleteSelectedFeature.bind(this);
        this._editSelectedFeature = this._editSelectedFeature.bind(this);
        this._selectNextFeature = this._selectNextFeature.bind(this);
        this._handleCloseFeatureInfo = this._handleCloseFeatureInfo.bind(this);
        this._handleFeatureChanged = this._handleFeatureChanged.bind(this);
        this._handleMeasure = this._handleMeasure.bind(this);
        this._openForm = this._openForm.bind(this);
        this._selectActiveLayerAndAddFeature = this._selectActiveLayerAndAddFeature.bind(this);

        this._revolverCoordinates = null;

        this.state = {
            featureInfoOpen: false,
            selectActiveLayerMenuAnchor: null,
            selectActiveLayerLayers: null,
            selectActiveLayerCoordinates: null,
            selectFormMenuAnchor: null,
            selectFormForms: null
        };

        this._closed = false;
    }

    componentDidMount() {
        this._closed = false;
        this._setup();
        this._requestRevolver(this.props.tool.coordinates);
    }

    componentDidUpdate(prevProps) {
        const prevIds = prevProps.tool.features.map(f => f.getId()).join();
        const ids = this.props.tool.features.map(f => f.getId()).join();
        if (prevIds !== ids) {
            this._setup();
        }
        const prevCoordinates = prevProps.tool.coordinates.join();
        const coordinates = this.props.tool.coordinates.join();
        if (prevCoordinates !== coordinates) {
            this._requestRevolver(this.props.tool.coordinates);
        }
    }

    componentWillUnmount() {
        this._deselectFeature();
    }

    handleMapClick(event) {
        const features = getFeaturesFromEvent(event);
        const { onToolRequest } = this.props;
        if (features.length === 0) {
            onToolRequest("normal");
            const { onRevolverRequest } = this.props;
            onRevolverRequest(null);
        } else {
            onToolRequest("select", { features, coordinates: event.coordinate });
        }
    }

    async _deleteSelectedFeature() {
        const { onDeleteFeature, onToolRequest } = this.props;
        await onDeleteFeature(this._selectedFeature);
        onToolRequest("normal");
    }

    _handleMeasure(coordinates) {
        this.props.onToolRequest("measure", { coordinates });
    }

    _editSelectedFeature() {
        const { onToolRequest, onRevolverRequest } = this.props;
        onRevolverRequest(null, null, null);
        onToolRequest("edit", { feature: this._selectedFeature });
    }

    _openForm(form) {
        const originalId = this._selectedFeature?.getProperties()?.__atlas?.originalId;
        const id = this._selectedFeature?.getId();
        console.log(`Opening form ${form?.title} (${id ? `feature ${originalId}, version ${id}` : "new feature"})`);
        this._closed = true;
        this.props.onRevolverRequest(null);
        this.props.onToolRequest("form", { feature: this._selectedFeature, schema: form });
    }

    _selectForm(target, forms) {
        this.setState({ selectFormMenuAnchor: target, selectFormForms: forms });
    }

    _selectActiveLayerAndAddFeature({ coordinates, currentTarget }, activeLayerIds) {
        const { configuration } = this.context;
        const selectActiveLayerLayers = activeLayerIds.map(id => configuration.layers.find(l => l._id === id));
        this.setState({
            selectActiveLayerMenuAnchor: currentTarget,
            selectActiveLayerLayers,
            selectActiveLayerCoordinates: coordinates
        });
    }

    _handleAddFeature(coordinates, layerId) {
        this._closed = true;
        this.props.onRevolverRequest(null);
        this.props.onToolRequest("normal", { draw: true, coordinates, layerId });
    }

    async _handleCopyCoordinates(coordinates) {
        const text = coordinates.join(", ");
        await copyToClipboard(text);
    }

    _requestRevolver(coordinates) {
        const layerId = this._selectedFeature.layer.get("id");
        const { onRevolverRequest, layers, map } = this.props;
        const { configuration, activeLayerIds } = this.context;
        const featureLayer = layers.find(l => l._id === layerId);
        const editIsAllowed = checkLayerPermission("editfeatures", featureLayer, configuration);
        const deleteIsAllowed = checkLayerPermission("deletefeatures", featureLayer, configuration);

        let editDisabled = !editIsAllowed;
        let editDisabledExplanation = "You don't have permission to edit this feature.";
        if (!editDisabled) {
            if (!activeLayerIds || !activeLayerIds.includes(layerId)) {
                editDisabled = true;
                editDisabledExplanation = "Layer not active.";
            }
        }

        const topButtons = [
            {
                id: "info",
                icon: <Info />,
                label: "Feature info",
                onClick: () => this.setState({ featureInfoOpen: true })
            },
            {
                id: "edit",
                icon: <Edit />,
                label: "Edit feature",
                onClick: this._editSelectedFeature,
                disabled: editDisabled,
                disabledExplanation: editDisabledExplanation
            },
            {
                id: "googlemaps",
                icon: <Directions />,
                label: "Open in Google Maps",
                onClick: () =>
                    openInGoogleMaps(coordinates, map.getView().getProjection().getCode()).catch(error => {
                        console.logError(error, "Error opening coordinates in Google Maps");
                    })
            }
        ];
        const bottomButtons = [
            {
                id: "measure",
                icon: <Straighten />,
                label: "Measure",
                onClick: () => this._handleMeasure(coordinates)
            }
        ];
        if (activeLayerIds && activeLayerIds.length > 0) {
            if (activeLayerIds.length > 1) {
                topButtons.push({
                    id: "add",
                    icon: <Add />,
                    label: "Create features in...",
                    onClick: ({ currentTarget }) =>
                        this._selectActiveLayerAndAddFeature({ coordinates, currentTarget }, activeLayerIds),
                    doNotCloseOnClick: true
                });
            } else {
                const activeLayer = layers.find(l => l._id === activeLayerIds[0]);
                topButtons.push({
                    id: "add",
                    icon: <Add />,
                    label: `Create feature in ${activeLayer.name}`,
                    disabled: !checkLayerPermission("createfeatures", activeLayer, configuration),
                    disabledExplanation: `You don't have permission to create features in ${activeLayer.name}.`,
                    onClick: () => this._handleAddFeature(coordinates, activeLayer._id)
                });
            }
        } else {
            topButtons.push({
                id: "add",
                icon: <Add />,
                disabled: true,
                disabledExplanation: "No active layer"
            });
        }
        if (this._activeFeatures.length > 1) {
            bottomButtons.push({
                id: "swap",
                icon: <SwapHoriz />,
                label: "Switch overlapping features",
                doNotCloseOnClick: true,
                onClick: this._selectNextFeature
            });
        }

        topButtons.push({
            id: "copycoordinates",
            icon: <MouseClick />,
            label: "Copy coordinates",
            onClick: () => this._handleCopyCoordinates(coordinates)
        });

        let form;
        if (featureLayer.forms?.length > 1) {
            topButtons.push({
                id: "openForm",
                icon: <Ballot />,
                label: "Open form",
                onClick: ({ currentTarget }) => this._selectForm(currentTarget, featureLayer.forms),
                disabled: !editIsAllowed,
                disabledExplanation: "You need edit permissions to submit a form.",
                doNotCloseOnClick: true
            });
        } else if (featureLayer.forms?.length === 1) {
            form = featureLayer.forms[0];
        } else {
            form = getRequiredForm(featureLayer);
        }
        if (form) {
            topButtons.push({
                id: "openForm",
                icon: <Ballot />,
                label: "Open form",
                onClick: () => this._openForm(form),
                disabled: !editIsAllowed,
                disabledExplanation: "You need edit permissions to submit a form."
            });
        }

        bottomButtons.push({
            id: "delete",
            icon: <Delete />,
            label: "Delete feature",
            destructive: true,
            onClick: this._deleteSelectedFeature,
            disabled: !deleteIsAllowed,
            disabledExplanation: "You don't have permission to delete this feature."
        });

        this._revolverCoordinates = coordinates;
        onRevolverRequest(coordinates, { topButtons, bottomButtons }, closeButtonId => {
            this._revolverCoordinates = null;
            if (!this._closed && !closeButtonId) {
                const { onToolRequest } = this.props;
                onToolRequest("normal");
            }
        });
    }

    _setup() {
        const { features } = this.props.tool;
        this._activeFeatures = features;
        this._selectFeature(this._activeFeatures[0]);
    }

    _style(olFeature) {
        const type = olFeature.getGeometry().getType();
        const styles = new SelectStyle().createStyles(type, olFeature.originalStyle, {
            resolution: this.props.map.getView().getResolution()
        });
        olFeature.setStyle(styles.flatMap(style => createOLStyles(style)));
    }

    _selectFeature(olFeature) {
        this._deselectFeature();
        this._style(olFeature);
        this._selectedFeature = olFeature;
        this._selectedFeature.on("propertychange", this._handleFeatureChanged);

        const { layers, onStatusBarRequest } = this.props;
        const layer = layers.find(l => l._id === olFeature.layer.get("id"));
        let statusBarContent = `Feature layer: ${layer.name}`;
        if (this._activeFeatures.length > 1) {
            const index = this._activeFeatures.indexOf(olFeature);
            statusBarContent = (
                <>
                    <Chip
                        size="small"
                        label={`${index + 1}/${this._activeFeatures.length}`}
                        style={{ marginRight: "5px" }}
                    />
                    <Typography>{statusBarContent}</Typography>
                </>
            );
        }
        onStatusBarRequest(statusBarContent);
        this._requestRevolver(this._revolverCoordinates);
    }

    _deselectFeature() {
        if (!this._selectedFeature) {
            return;
        }
        this._selectedFeature.un("propertychange", this._handleFeatureChanged);
        this._selectedFeature.setStyle(null);
        this._selectedFeature = null;
    }

    _selectNextFeature() {
        const selected = this._selectedFeature;
        const index = this._activeFeatures.findIndex(f => f.getId() === selected.getId());
        const next = this._activeFeatures[(index + 1) % this._activeFeatures.length];
        this._selectFeature(next);
    }

    _handleCloseFeatureInfo() {
        this.setState({ featureInfoOpen: false });
        this.props.onToolRequest("normal");
    }

    _handleFeatureChanged() {
        this._selectedFeature.recalculateOriginalStyle();
        this._style(this._selectedFeature);
    }

    render() {
        const {
            featureInfoOpen,
            selectActiveLayerMenuAnchor,
            selectActiveLayerLayers,
            selectActiveLayerCoordinates,
            selectFormMenuAnchor,
            selectFormForms
        } = this.state;
        const layerId = featureInfoOpen && this._selectedFeature.layer.get("id");
        const layer = featureInfoOpen && this.props.layers.find(l => l._id === layerId);
        return (
            <React.Fragment>
                {layerId && layer && (
                    <MobileFriendlyDialog
                        fullWidth
                        maxWidth={false}
                        open={featureInfoOpen}
                        onClose={this._handleCloseFeatureInfo}
                    >
                        <MobileFriendlyDialogContent>
                            <FeatureInfo
                                olFeature={this._selectedFeature}
                                layer={layer}
                                attributeDefinitions={layer.attributes}
                                dbManager={this.props.dbManager}
                            />
                        </MobileFriendlyDialogContent>
                        <DialogActions>
                            <Button color="primary" variant="outlined" onClick={this._handleCloseFeatureInfo}>
                                Close
                            </Button>
                        </DialogActions>
                    </MobileFriendlyDialog>
                )}
                <Menu
                    anchorEl={selectActiveLayerMenuAnchor}
                    open={!!selectActiveLayerMenuAnchor}
                    onClose={() =>
                        this.setState({
                            selectActiveLayerMenuAnchor: null,
                            selectActiveLayerLayers: null,
                            selectActiveLayerCoordinates: null
                        })
                    }
                >
                    {selectActiveLayerLayers &&
                        selectActiveLayerLayers.map(layer => (
                            <MenuItem
                                key={layer._id}
                                onClick={() => {
                                    this.setState({
                                        selectActiveLayerMenuAnchor: null,
                                        selectActiveLayerLayers: null,
                                        selectActiveLayerCoordinates: null
                                    });
                                    this._handleAddFeature(selectActiveLayerCoordinates, layer._id);
                                }}
                            >
                                {layer.name}
                            </MenuItem>
                        ))}
                </Menu>
                <Menu
                    anchorEl={selectFormMenuAnchor}
                    open={!!selectFormMenuAnchor}
                    onClose={() => this.setState({ selectFormMenuAnchor: null })}
                >
                    {selectFormForms?.map((form, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                this.setState({
                                    selectFormMenuAnchor: null,
                                    selectFormForms: null
                                });
                                this._openForm(form);
                            }}
                        >
                            {form.title}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }
}

Select.contextType = WorkspaceContext;

export default Select;

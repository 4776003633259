import randomColor from "randomcolor";
import chroma from "chroma-js";
import ForanIcons from "foran-icons";

const SVGS = Array.from(Object.values(ForanIcons)).flat();

const DEFAULT_STYLE = {
    name: "Default style"
};

export const getRandomIcon = () => {
    const icons = SVGS.filter(icon => !icon.id.startsWith("backgrounds"));
    return icons[Math.floor(Math.random() * icons.length)];
};

export const getRandomBackgroundIcon = () => {
    const icons = SVGS.filter(icon => icon.id.startsWith("backgrounds"));
    return icons[Math.floor(Math.random() * icons.length)];
};

const getMatchingColor = baseColor => {
    const color = chroma(baseColor);
    if (color.luminance() > 0.7) {
        return color.darken(2).hex();
    } else {
        return color.brighten(2).hex();
    }
};

const getDefaultFill = color => ({
    type: "solid",
    color: color || randomColor(),
    opacity: 1
});

const getDefaultStroke = color => ({
    type: "solid",
    color: color || randomColor(),
    opacity: 1,
    width: 5.0
});

const getDefaultLabel = () => ({
    text: "",
    rotation: 0,
    font: "sans-serif",
    weight: "normal",
    size: 14,
    overflow: true,
    offsetX: 0,
    offsetY: 0,
    fill: getDefaultFill("#000000"),
    stroke: { type: "none" },
    backgroundFill: { type: "none" },
    backgroundStroke: { type: "none" }
});

export const getDefaultIcon = (color = randomColor(), backgroundColor = "#000000") => ({
    id: getRandomIcon().id,
    size: 15,
    color,
    rotation: 0,
    backgroundId: getRandomBackgroundIcon().id,
    backgroundSize: 30,
    backgroundColor,
    backgroundRotation: 0
});

function getDefaultPolygonStyle() {
    const style = { ...DEFAULT_STYLE };
    style.fill = [getDefaultFill(randomColor())];
    style.stroke = [getDefaultStroke(getMatchingColor(style.fill[0].color))];
    style.label = getDefaultLabel();
    return style;
}

function getDefaultLineStyle() {
    const style = { ...DEFAULT_STYLE };
    style.stroke = [getDefaultStroke(randomColor())];
    style.label = getDefaultLabel();
    return style;
}

const getDefaultSymbol = color => ({
    type: "circle",
    size: 5,
    fill: getDefaultFill(color || randomColor()),
    stroke: getDefaultStroke(color ? getMatchingColor(color) : randomColor()),
    icon: getDefaultIcon(color || randomColor(), "#000000")
});

function getDefaultPointStyle() {
    const style = { ...DEFAULT_STYLE };
    style.symbol = getDefaultSymbol(randomColor());
    style.label = getDefaultLabel();
    return style;
}

export default function DefaultStyle(type) {
    switch (type) {
        case "polygon":
            return getDefaultPolygonStyle();
        case "line":
            return getDefaultLineStyle();
        case "point":
            return getDefaultPointStyle();
        default:
            throw new Error(`Unknown type "${type}"`);
    }
}

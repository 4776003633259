import { useState, useContext } from "react";
import { AtlasContext } from "../../atlas-context";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { ObjectId } from "../../id-generation";
import { Typography, IconButton, Button, CircularProgress, Box } from "@mui/material";
import { WorkspaceContext } from "../../workspace/workspace-context";
import FUXFileUploadDialog from "foran-ux/dist/FUXFileUploadDialog";

function FileCellEditor({ value, attribute, layer, onChange }) {
    const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { dbManager } = useContext(AtlasContext);
    const { configuration } = useContext(WorkspaceContext);

    const handleFileUpload = async files => {
        setFileUploadDialogOpen(false);
        setLoading(true);
        if (files.length > 0) {
            const file = files[0];
            try {
                const idbFile = {
                    id: ObjectId().toString(),
                    localModified: Date.now(),
                    layerId: layer._id,
                    file
                };
                await dbManager.saveLocalFile(idbFile, configuration._id);
                onChange({ id: idbFile.id, name: file.name });
            } catch (e) {
                console.logError(e, "Error saving file");
            }
            setLoading(false);
        }
    };
    const handleDeleteFile = () => {
        onChange(null);
    };

    let filename;
    if (value) {
        filename = value.name;
    }
    return (
        <Box>
            {loading ? (
                <CircularProgress />
            ) : filename ? (
                <Box>
                    <IconButton onClick={handleDeleteFile} size="large">
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    <Typography variant="overline">{filename}</Typography>
                </Box>
            ) : (
                <Button color="primary" variant="outlined" onClick={() => setFileUploadDialogOpen(true)}>
                    Choose {attribute.type}
                </Button>
            )}
            <FUXFileUploadDialog
                title="Choose file to upload"
                open={fileUploadDialogOpen}
                onClose={() => setFileUploadDialogOpen(false)}
                onSubmit={handleFileUpload}
                accept={
                    attribute.type === "image"
                        ? {
                              "image/jpeg": [".jpeg", ".jpg"],
                              "image/jpg": [".jpeg", ".jpg"],
                              "image/png": [".png"]
                          }
                        : undefined
                }
                maxFiles={1}
                maxSize={1000000000} // 1GB
            />
        </Box>
    );
}

export default FileCellEditor;

import jsonLogic from "json-logic-js";
import lodash from "lodash";
import chroma from "chroma-js";

jsonLogic.add_operation("_", lodash);
jsonLogic.add_operation("Math", Math);
jsonLogic.add_operation("isnull", value => {
    return [null, undefined, ""].includes(value);
});
jsonLogic.add_operation("isnotnull", value => {
    return ![null, undefined, ""].includes(value);
});
jsonLogic.add_operation("interpolate", (colors, domain, value) => {
    const scale = chroma.scale(colors).domain(domain).mode("lrgb");
    return scale(value).hex();
});
jsonLogic.add_operation("startswith", (a, b) => {
    if (typeof a === "string" && typeof b === "string") {
        return a.startsWith(b);
    }
    return false;
});
jsonLogic.add_operation("endswith", (a, b) => {
    if (typeof a === "string" && typeof b === "string") {
        return a.endsWith(b);
    }
    return false;
});
jsonLogic.add_operation("contains", (a, b) => {
    if (typeof a === "string" && typeof b === "string") {
        return a.includes(b);
    }
    return false;
});
jsonLogic.add_operation("before", (a, b) => {
    if (a && b) {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        if (!Number.isNaN(aTime) && !Number.isNaN(bTime)) {
            return aTime < bTime;
        }
    }
    return false;
});
jsonLogic.add_operation("after", (a, b) => {
    if (a && b) {
        const aTime = new Date(a).getTime();
        const bTime = new Date(b).getTime();
        if (!Number.isNaN(aTime) && !Number.isNaN(bTime)) {
            return aTime > bTime;
        }
    }
    return false;
});
const nullish = [null, undefined];
const anyNullish = (...values) => values.some(value => nullish.includes(value));
jsonLogic.add_operation("<", (a, b) => {
    if (anyNullish(a, b)) {
        return false;
    }
    return a < b;
});
jsonLogic.add_operation(">", (a, b) => {
    if (anyNullish(a, b)) {
        return false;
    }
    return a > b;
});
jsonLogic.add_operation("<=", (a, b) => {
    if (anyNullish(a, b)) {
        return false;
    }
    return a <= b;
});
jsonLogic.add_operation(">=", (a, b) => {
    if (anyNullish(a, b)) {
        return false;
    }
    return a >= b;
});
// Guard against incomplete logic schemas
jsonLogic.add_operation(null, () => false);

jsonLogic.add_operation("strmatch", (str, regex) => {
    if (typeof str !== "string") {
        return null;
    }
    if (typeof regex === "string") {
        regex = new RegExp(regex);
    }
    return str.match(regex);
});
jsonLogic.add_operation("strmatchall", (str, regex) => {
    if (typeof str !== "string") {
        return null;
    }
    if (typeof regex === "string") {
        regex = new RegExp(regex, "g");
    }
    return [...str.matchAll(regex)];
});

jsonLogic.add_operation("index", function (array, index) {
    return Array.isArray(array) ? array[index] : null;
});

export default jsonLogic;

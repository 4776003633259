import React from "react";
import { useLayerVisible } from "../useLayerVisible";
import IconCheckbox from "./IconCheckbox";

import { VisibilityOff, Visibility } from "@mui/icons-material";

function LayerVisibleCheckbox({ layer, onChange }) {
    const visible = useLayerVisible(layer);

    return (
        <IconCheckbox
            tooltipTitle={visible ? "Visible" : "Not visible"}
            checked={visible}
            checkedIcon={<Visibility />}
            uncheckedIcon={<VisibilityOff />}
            onChange={onChange}
        />
    );
}

export default LayerVisibleCheckbox;

import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { activities as FORESTAND_ACTIVITIES } from "forestand-definitions";
import { usePrevious } from "../../../usePrevious";

function ForestandActivityAttribute({ activityId, name, isNew, defaultKey, children }) {
    const { resetField } = useFormContext();
    const value = useWatch({ name });
    const activity = FORESTAND_ACTIVITIES.find(activity => activity["activityId"] === activityId);
    const defaultValue = activity?.default[defaultKey];
    const previousActivityId = usePrevious(activityId);

    // Only render if there's a default value in the definition
    // but always render an old existing value
    const render = (!isNew && !!value) || defaultValue !== undefined;

    useEffect(() => {
        if (previousActivityId && previousActivityId !== activityId) {
            // activity id changed
            resetField(name, { defaultValue: defaultValue ?? null });
        }
    }, [activityId, previousActivityId, resetField, name, defaultValue]);

    if (!render) {
        return null;
    }

    return children;
}

export default ForestandActivityAttribute;

import React, { Component } from "react";
import { LoadingContext } from "../../../loading-context";

import OLImageLayer from "../openlayers/layers/ImageLayer";

import AsyncImageCanvasSource from "../../layers/AsyncImageCanvasSource";
import Api from "../../../../Api";
import { ErrorContext } from "../../../error-context";

export const DEFAULT_RESOLUTIONS = [4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125];
const OK_RESOLUTION_UNDERSAMPLING = 4;
export const DEFAULT_TILE_SIZE_PIXELS = 512;

class RasterLayer extends Component {
    constructor(props) {
        super(props);

        this.canvasContextFunction = this.canvasContextFunction.bind(this);

        const { configuration, dbManager } = this.props;

        if (configuration.source.type === "raster") {
            throw new Error("Only external part of RasterLayer is implemented");
        }
        this.projection = configuration.projection || "EPSG:3006";
        this.url = `?SERVICE=WMS&REQUEST=GetMap&VERSION=1.1.1&SRS=${this.projection}&STYLES=&LAYERS=${configuration.source.layerName}`;
        this.tileSizePixels = DEFAULT_TILE_SIZE_PIXELS;
        this.maxResolution = configuration.source.maxResolution || 1;
        this.resolutions = configuration.source.resolutions || DEFAULT_RESOLUTIONS;
        const maxResolutionIndex = this.resolutions.findIndex(r => r === this.maxResolution);
        this.resolutions =
            maxResolutionIndex !== -1 ? this.resolutions.slice(0, maxResolutionIndex + 1) : this.resolutions;
        this._source = new AsyncImageCanvasSource({
            projection: this.projection,
            ratio: 1,
            canvasFunction: this.canvasContextFunction
        });
        this._source.set("setPausedFunction", this._setPaused.bind(this));
        this._downloadManager = dbManager.downloadManagerCached;
        this._downloadingIds = new Set();
        this._currentDownloadId = 0;
        this._loadingIds = new Set();
    }

    componentWillUnmount() {
        this._loadingIds.forEach(id => {
            this.props.loadingContext.stopLoading(id);
            this._loadingIds.delete(id);
        });
    }

    async _createImage(sizeX, sizeY, blob) {
        return new Promise((resolve, reject) => {
            const url = URL.createObjectURL(blob);
            const image = new Image(sizeX, sizeY);
            image.onload = () => {
                URL.revokeObjectURL(url);
                resolve(image);
            };
            image.onerror = reject;
            image.src = url;
        });
    }

    async getTileFromDbOrApi(r, x, y) {
        const { configuration, dbManager, workspace } = this.props;
        const tileDb = await dbManager.getTileDB(configuration._id, workspace._id);
        const blob = await tileDb.getTile(x, y, r);

        if (!blob && navigator.onLine) {
            try {
                const url = Api.getTileBlobUrl(configuration._id, workspace._id, x, y, r);
                const id = this._downloadManager.getNewId();
                this._downloadingIds.add(id);
                const blob = await this._downloadManager.download(url, id);
                this._downloadingIds.delete(id);
                return blob;
            } catch (error) {
                if (error.name !== "CANCELLED") {
                    console.logError(error, "getTileFromDbOrApi");
                    if (!this._fetchError && error.type === "PROXY") {
                        this._fetchError = error;
                    }
                }
            }
        }
        return blob;
    }

    async getTile(resolution, extent) {
        let chosenResolution = resolution;
        let chosenX = extent[0];
        let chosenY = extent[1];
        let blob = await this.getTileFromDbOrApi(resolution, chosenX, chosenY);

        // Atlas probably working in offline mode, try to get a tile with worse resolution if it is in cache
        let cropX = 0;
        let cropY = 0;
        let cropSize = this.tileSizePixels;
        if (!blob) {
            const resolutionIndex = this.resolutions.findIndex(r => r === resolution);
            const okResolutions = this.resolutions.slice(
                Math.max(0, resolutionIndex - OK_RESOLUTION_UNDERSAMPLING),
                resolutionIndex
            );
            for (let i = okResolutions.length - 1; i >= 0; i--) {
                chosenResolution = okResolutions[i];
                const tileSizeMeters = chosenResolution * this.tileSizePixels;
                const chosenX = extent[0] - (extent[0] % tileSizeMeters);
                const chosenY = extent[1] - (extent[1] % tileSizeMeters);
                blob = await this.getTileFromDbOrApi(chosenResolution, chosenX, chosenY);
                if (blob) {
                    cropSize = (this.tileSizePixels * resolution) / chosenResolution;
                    cropX = (extent[0] - chosenX) / chosenResolution;
                    const pixelsFromBottom = (extent[1] - chosenY) / chosenResolution;
                    cropY = this.tileSizePixels - pixelsFromBottom - cropSize;
                    break;
                }
            }
        }

        const imageDescription = { image: null, cropX: cropX, cropY: cropY, cropSize: cropSize };
        if (!blob) {
            imageDescription.image = new Image(this.tileSizePixels, this.tileSizePixels);
            return imageDescription;
        }

        let image;
        try {
            image = await this._createImage(this.tileSizePixels, this.tileSizePixels, blob);
        } catch (err) {
            console.logError(
                err,
                `Error loading image source ${blob} (size: ${blob?.size}, type: ${blob?.type}) in layer ${this.props.configuration?._id}. tileSizePixels: ${this.tileSizePixels}`
            );
        }
        imageDescription.image = image;
        return imageDescription;
    }

    async renderTiles(ctx, tiles) {
        const removeIds = [];
        this._downloadingIds.forEach(id => removeIds.push(id));
        this._downloadingIds.clear();
        this._downloadManager.removeFromQueue(removeIds);
        return new Promise(resolve => {
            let tilesDone = 0;
            for (const tile of tiles) {
                this.getTile(tile.resolution, [
                    tile.gridX,
                    tile.gridY,
                    tile.gridX + tile.gridSizeMeters,
                    tile.gridY + tile.gridSizeMeters
                ])
                    // eslint-disable-next-line no-loop-func
                    .then(fetchedTile => {
                        ctx.drawImage(
                            fetchedTile.image,
                            fetchedTile.cropX,
                            fetchedTile.cropY,
                            fetchedTile.cropSize,
                            fetchedTile.cropSize,
                            tile.canvasX,
                            tile.canvasY,
                            tile.canvasSize,
                            tile.canvasSize
                        );
                        tilesDone++;
                        if (tilesDone === tiles.length) {
                            if (this._fetchError) {
                                this.props.errorContext.setError(this._fetchError, "warning");
                                delete this._fetchError;
                            }
                            resolve();
                        }
                    })
                    // eslint-disable-next-line no-loop-func
                    .catch(err => {
                        console.logError(err, "renderTiles");
                        tilesDone++;
                        if (tilesDone === tiles.length) resolve();
                    });
            }
        });
    }

    getTileDescriptions(canvasHeight, extent, resolution) {
        const tiles = [];
        let tileResolution = this.resolutions.find(r => resolution >= r);
        if (!tileResolution) tileResolution = this.resolutions[this.resolutions.length - 1];

        const tileSizeMeters = tileResolution * this.tileSizePixels;
        const canvasTileSizePixels = Math.round((this.tileSizePixels * tileResolution) / resolution);
        /*
        Loops over two different grids since the map coordinate system y axis grows northward/up and
        the canvas coordinate system grows southward/down.
         */
        let gridSquareX = extent[0] - (extent[0] % tileSizeMeters);
        let canvasDrawX = Math.round((gridSquareX - extent[0]) / resolution);
        for (; gridSquareX <= extent[2]; gridSquareX += tileSizeMeters) {
            let gridSquareY = extent[1] - (extent[1] % tileSizeMeters);
            let canvasDrawY = canvasHeight + Math.round((extent[1] - gridSquareY) / resolution) - canvasTileSizePixels;
            for (; gridSquareY <= extent[3]; gridSquareY += tileSizeMeters) {
                //console.log(`TILE: CANVAS ${canvasDrawX}, ${canvasDrawY} GRID ${gridExtent.join(", ")}`)
                tiles.push({
                    resolution: tileResolution,
                    gridX: gridSquareX,
                    gridY: gridSquareY,
                    gridSizeMeters: tileSizeMeters,
                    canvasX: canvasDrawX,
                    canvasY: canvasDrawY,
                    canvasSize: canvasTileSizePixels
                });
                canvasDrawY -= canvasTileSizePixels;
            }
            canvasDrawX += canvasTileSizePixels;
        }
        return tiles;
    }

    _setPaused(paused) {
        this._paused = paused;
        if (!paused && this._lastCanvasContextFunction) {
            this.canvasContextFunction(...this._lastCanvasContextFunction);
        }
    }

    canvasContextFunction(ctx, extent, resolution, pixelRatio, cb) {
        if (this._paused) {
            if (this._lastCanvasContextFunction) {
                this._lastCanvasContextFunction[4]();
            }
            this._lastCanvasContextFunction = [ctx, extent, resolution, pixelRatio, cb];
            return;
        }
        ctx.imageSmoothingEnabled = resolution > this.maxResolution;
        this._loadingIds.forEach(id => {
            this.props.loadingContext.stopLoading(id);
            this._loadingIds.delete(id);
        });
        const loadingId = this.props.loadingContext.startLoading(`Loading ${this.props.configuration.name}`);
        this._loadingIds.add(loadingId);
        this._lastCanvasContextFunction = null;
        resolution = resolution / pixelRatio;
        const tiles = this.getTileDescriptions(ctx.canvas.height, extent, resolution);
        this.renderTiles(ctx, tiles)
            .then(cb)
            .catch(err => {
                console.logError(err, "RasterLayer.canvasContextFunction");
                cb(err);
                this.props.errorContext.setError(err);
            })
            .finally(() => {
                this.props.loadingContext.stopLoading(loadingId);
                this._loadingIds.delete(loadingId);
            });
    }

    render() {
        const { configuration, opacity, visible, zIndex } = this.props;
        return (
            <OLImageLayer
                opacity={opacity / 100}
                visible={visible}
                source={this._source}
                zIndex={zIndex}
                properties={{ id: configuration._id }}
                // OpenLayers won't check for null values, only undefined
                maxResolution={configuration.maxVisibleResolution || undefined}
                minResolution={configuration.minVisibleResolution || undefined}
            />
        );
    }
}

const RasterLayerWrapper = props => {
    return (
        <LoadingContext.Consumer>
            {loadingContext => (
                <ErrorContext.Consumer>
                    {errorContext => (
                        <RasterLayer loadingContext={loadingContext} errorContext={errorContext} {...props} />
                    )}
                </ErrorContext.Consumer>
            )}
        </LoadingContext.Consumer>
    );
};

export default RasterLayerWrapper;

import chroma from "chroma-js";
import { io } from "jsts";
import { Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon } from "ol/geom";
import LinearRing from "ol/geom/LinearRing";
import ForanIcons from "foran-icons";

import CustomStyle from "./CustomStyle";
import { polygon as olPolygonStyle, line as olLineStyle, point as olPointStyle } from "./ol-default-styles";

const degreesToRadians = degrees => (degrees ? (degrees / 180) * Math.PI : 0);

const SVGS = Array.from(Object.values(ForanIcons)).flat();
export const SELECT_COLOR = "#ffbb00";

const parser = new io.OL3Parser();
parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);

const bufferGeometry = (geometry, amount) => {
    const jstsGeom = parser.read(geometry);
    const buffered = jstsGeom.buffer(amount);
    return parser.write(buffered);
};

export default class SelectStyle extends CustomStyle {
    getPolygonStyles(originalStyle, options) {
        return this._getPolygonStyles(originalStyle, options);
    }

    getLineStringStyles(originalStyle, options) {
        if (!originalStyle) {
            return [olLineStyle];
        }
        const [selectStyle, outlineStyle] = this._getPolygonStyles(originalStyle, options);
        // Find the widest stroke & buffer outside it
        const maxStroke = this._getMaxStroke(originalStyle);
        outlineStyle.geometryFunction = feature => {
            const geometry = feature.getGeometry();
            if (geometry.getType() === "MultiLineString") {
                return bufferGeometry(geometry, options.resolution * (3 + maxStroke / 2));
            }
        };
        return [selectStyle, outlineStyle];
    }

    _createOutlineFromSymbol(symbol) {
        return {
            symbol: {
                type: "circle",
                size: symbol.size + 3,
                fill: {
                    type: "solid",
                    color: ""
                },
                stroke: {
                    type: "solid",
                    color: SELECT_COLOR,
                    width: 3
                }
            }
        };
    }

    _createStyleFromEmptySymbol(style) {
        if (style?.label?.text) {
            return [
                {
                    ...style,
                    label: {
                        ...style.label,
                        backgroundStroke: {
                            type: "solid",
                            color: SELECT_COLOR,
                            width: 3
                        }
                    }
                }
            ];
        }
        return [
            style,
            {
                symbol: {
                    type: "circle",
                    size: 10,
                    fill: {
                        type: "solid",
                        color: SELECT_COLOR
                    },
                    stroke: {
                        type: "none"
                    }
                }
            }
        ];
    }

    _getIconOutlineGeometry(coordinates, size, resolution, type, offsetX = 0, offsetY = 0) {
        const minX = coordinates[0] - (size / 2 + offsetX) * resolution;
        const maxX = coordinates[0] + (size / 2 - offsetX) * resolution;
        const minY = coordinates[1] - (size / 2 + offsetY) * resolution;
        const maxY = coordinates[1] + (size / 2 - offsetY) * resolution;
        let polygonCoordinates;
        switch (type) {
            case "backgrounds_square":
            case "backgrounds_square_rounded":
                polygonCoordinates = [
                    [
                        [minX, minY],
                        [minX, maxY],
                        [maxX, maxY],
                        [maxX, minY],
                        [minX, minY]
                    ]
                ];
                break;
            case "backgrounds_triangle":
                polygonCoordinates = [
                    [
                        [minX, minY],
                        [(minX + maxX) / 2, maxY],
                        [maxX, minY],
                        [minX, minY]
                    ]
                ];
                break;
            case "backgrounds_tilted_square":
            case "backgrounds_tilted_square_rounded":
                polygonCoordinates = [
                    [
                        [minX, (minY + maxY) / 2],
                        [(minX + maxX) / 2, maxY],
                        [maxX, (minY + maxY) / 2],
                        [(minX + maxX) / 2, minY],
                        [minX, (minY + maxY) / 2]
                    ]
                ];
                break;
            default:
                return null;
        }
        const polygon = new Polygon(polygonCoordinates);
        return bufferGeometry(polygon, resolution * 4.5);
    }

    _createOutlineFromIcon(icon, options) {
        // * 2 to match that size is radius on circle styles
        const size = Math.max(icon.size ?? 0, icon.backgroundSize ?? 0) * 2;
        const style = {
            stroke: {
                type: "solid",
                color: SELECT_COLOR,
                width: 3
            }
        };
        let offsetX = 0;
        let offsetY = 0;
        const anchor = SVGS.find(_icon => _icon.id === icon.backgroundId)?.anchor;
        if (anchor) {
            offsetX = (0.5 - anchor[0]) * (icon.backgroundSize ?? 0) * 2;
            offsetY = -(0.5 - anchor[1]) * (icon.backgroundSize ?? 0) * 2;
        }
        switch (icon.backgroundId) {
            case "backgrounds_square":
            case "backgrounds_square_rounded":
            case "backgrounds_triangle":
            case "backgrounds_tilted_square":
            case "backgrounds_tilted_square_rounded":
                style.geometryFunction = feature => {
                    const multiPolygon = new MultiPolygon([]);
                    const coordinates = feature.getGeometry().getCoordinates();
                    for (const coordinate of coordinates) {
                        const geometry = this._getIconOutlineGeometry(
                            coordinate,
                            size,
                            options.resolution,
                            icon.backgroundId,
                            offsetX,
                            offsetY
                        );
                        if (icon.backgroundRotation) {
                            geometry.rotate(-degreesToRadians(icon.backgroundRotation), coordinate);
                        }
                        multiPolygon.appendPolygon(geometry);
                    }
                    return multiPolygon;
                };
                break;
            case "backgrounds_circle":
            default:
                style.symbol = {
                    type: "circle",
                    size: size / 2 + 3,
                    stroke: style.stroke
                };
                delete style.stroke;
                if (offsetX !== 0 || offsetY !== 0) {
                    style.geometryFunction = feature => {
                        const coordinates = feature.getGeometry().getCoordinates();
                        const multiPoint = new MultiPoint([]);
                        for (const coordinate of coordinates) {
                            const _coordinates = [
                                coordinate[0] + offsetX * options.resolution,
                                coordinate[1] - offsetY * options.resolution
                            ];
                            const point = new Point(_coordinates);
                            if (icon.backgroundRotation) {
                                point.rotate(-degreesToRadians(icon.backgroundRotation), coordinate);
                            }
                            multiPoint.appendPoint(point);
                        }
                        return multiPoint;
                    };
                }
                break;
        }
        return style;
    }

    getPointStyles(originalStyle, options) {
        if (!originalStyle) {
            return [olPointStyle, this._createOutlineFromSymbol(olPointStyle)];
        }
        switch (originalStyle.symbol.type) {
            case "circle":
                return [this._getPolygonStyles(originalStyle)[0], this._createOutlineFromSymbol(originalStyle.symbol)];
            case "icon":
                return [originalStyle, this._createOutlineFromIcon(originalStyle.symbol.icon, options)];
            case "none":
                return [...this._createStyleFromEmptySymbol(originalStyle)];
            default:
                throw new Error(`Unknown symbol type "${originalStyle.symbol.type}".`);
        }
    }

    _getPolygonStyles(originalStyle, options) {
        const outlineStyle = {
            stroke: {
                type: "solid",
                color: SELECT_COLOR,
                width: 3
            },
            geometryFunction: feature => {
                const geometry = feature.getGeometry();
                if (geometry.getType() === "MultiPolygon") {
                    return bufferGeometry(geometry, options.resolution * (3 + maxStroke / 2));
                }
            }
        };
        if (!originalStyle) {
            return [olPolygonStyle, outlineStyle];
        }

        const selectStyle = JSON.parse(JSON.stringify(originalStyle));
        // Find the widest stroke & buffer outside it
        const maxStroke = this._getMaxStroke(originalStyle);
        if (selectStyle.stroke) {
            for (const stroke of selectStyle.stroke) {
                if (stroke.color) {
                    stroke.color = chroma(stroke.color).brighten().hex();
                }
            }
        }
        if (selectStyle.fill) {
            for (const fill of selectStyle.fill) {
                if (fill.color) {
                    fill.color = chroma(fill.color).brighten().hex();
                }
            }
        }
        return [selectStyle, outlineStyle];
    }

    _getMaxStroke(style) {
        let maxStroke = 0;
        if (style.stroke) {
            for (const stroke of style.stroke) {
                maxStroke = Math.max(maxStroke, stroke.width || 0);
            }
        }
        return maxStroke;
    }
}

import { asArray } from "ol/color";
import { easeOut } from "ol/easing";
import { unByKey } from "ol/Observable";
import { Circle as CircleStyle, Stroke, Style } from "ol/style";

import { SELECT_COLOR } from "../../styles/SelectStyle";
import { getVectorContext } from "ol/render";

const [R, G, B] = asArray(SELECT_COLOR);
const DURATION = 2000;

// https://openlayers.org/en/v8.2.0/examples/feature-animation.html
function highlight(feature, layer, map) {
    const start = Date.now();
    const flashGeom = feature.getGeometry().clone();
    const listenerKey = layer.on("postrender", animate);
    map.render();

    function animate(event) {
        const frameState = event.frameState;
        const elapsed = frameState.time - start;
        if (elapsed >= DURATION) {
            unByKey(listenerKey);
            return;
        }
        const vectorContext = getVectorContext(event);
        const elapsedRatio = elapsed / DURATION;
        // radius will be 5 at start and 30 at end
        const radius = easeOut(elapsedRatio) * 25 + 5;
        const opacity = easeOut(1 - elapsedRatio);
        // width will be 3 at start and 5 at end
        const width = 5 - 2 * opacity;
        const color = `rgba(${R}, ${G}, ${B}, ${opacity})`;

        const style = new Style({
            image: new CircleStyle({
                radius,
                stroke: new Stroke({ color, width })
            })
        });

        vectorContext.setStyle(style);
        vectorContext.drawGeometry(flashGeom);
        // tell OpenLayers to continue postrender animation
        map.render();
    }
}

export const highlightPointFeature = (map, layerId, featureId) => {
    const layers = map.getLayers().getArray();
    const layer = layers.find(layer => layer.get("id") === layerId);
    const source = layer.getSource();
    const olFeature = source.getFeatureById(featureId);
    const type = olFeature.getGeometry().getType();
    if (!type.toLowerCase().includes("point")) {
        console.warn(`Can only highlight geometries of type Point, ignoring feature ${featureId} with type "${type}".`);
        return;
    }
    highlight(olFeature, layer, map);
};

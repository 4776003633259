export const getCookieByName = name => {
    // https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
    const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
};

export const getSessionName = () => {
    const suffix = getCookieByName("session-suffix");
    if (suffix) {
        return `session${suffix}`;
    }
    if (process.env.REACT_APP_SESSION_NAME) {
        return process.env.REACT_APP_SESSION_NAME;
    }
    return "session";
};

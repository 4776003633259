import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getCurrentLocation } from "../workspace/map/GeolocationWrapper";
import { getErrorTypes } from "./form-utils";
import DisabledCover from "../DisabledCover";
import FormGroup from "./FormGroup";
import FormWarningDialog from "../forms/FormWarningDialog";
import RenderCount from "../RenderCount";

import { Box, Button, CircularProgress, FormHelperText, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ErrorContext } from "../error-context";

export const FormContext = createContext({ submitWithWarnings: null, attributeDefinitions: [] });

// react-hook-form does not support flat arrays
const transformInitialArrayValues = (rootSchema, attributeDefinitions, initialValues) => {
    const values = JSON.parse(JSON.stringify(initialValues));
    const transform = schema => {
        const attribute = attributeDefinitions?.find(attribute => attribute._id === schema?.key);
        if (attribute?.type !== "custom" && attribute?.array && Array.isArray(values[schema.key])) {
            values[schema.key] = values[schema.key].map(value => ({ value }));
        }
        schema.children?.forEach(transform);
    };
    transform(rootSchema);
    return values;
};

function Form({ schema, initialValues, attributeDefinitions, onSubmit, onCancel, layer }) {
    const { setError } = useContext(ErrorContext);

    const defaultValues = useMemo(
        () => transformInitialArrayValues(schema, attributeDefinitions, initialValues),
        [schema, attributeDefinitions, initialValues]
    );
    const methods = useForm({
        defaultValues,
        criteriaMode: "all"
    });
    const {
        handleSubmit,
        getValues,
        formState: { isSubmitting, isValidating, errors },
        trigger
    } = methods;
    const [shouldSubmit, setShouldSubmit] = useState(false);
    const [submitWithWarningsDialogOpen, setSubmitWithWarningsDialogOpen] = useState(false);
    const submitWithWarnings = useRef(false);
    const disabled = isSubmitting || isValidating;
    const contextValue = useMemo(
        () => ({ submitWithWarnings, attributeDefinitions, initialValues: defaultValues, layer }),
        [submitWithWarnings, attributeDefinitions, defaultValues, layer]
    );

    /**
     * Removes "fake fields" from groups before submission,
     * see FormGroup file for details.
     */
    const transformValues = useCallback(
        async (values, metadata) => {
            const removeIfGroup = schema => {
                if (schema.type === "group") {
                    delete values[schema._id];
                }
                schema.children?.forEach(removeIfGroup);
            };

            // react-hook-form does not support flat arrays
            const transformArrayValues = schema => {
                const attribute = attributeDefinitions?.find(attribute => attribute._id === schema?.key);
                if (attribute?.type !== "custom" && attribute?.array && Array.isArray(values[schema.key])) {
                    values[schema.key] = values[schema.key].map(({ value }) => value);
                }
                schema.children?.forEach(transformArrayValues);
            };

            delete values.root; // Top level schema has no id, uses root instead
            removeIfGroup(schema);
            transformArrayValues(schema);
            await onSubmit(values, metadata);
        },
        [schema, onSubmit, attributeDefinitions]
    );

    const handleSubmitWithWarningsConfirmed = useCallback(async () => {
        setSubmitWithWarningsDialogOpen(false);
        // This ref will be read by fields in the form & warnings will be ignored
        submitWithWarnings.current = true;
        await trigger();
        setShouldSubmit(true);
    }, [trigger]);
    useEffect(() => {
        const submit = async () => {
            const errorTypes = getErrorTypes(errors);
            if (errorTypes.length > 0 && errorTypes.every(type => type.startsWith("warning"))) {
                // Form contains warnings. Prompt to submit anyway
                setSubmitWithWarningsDialogOpen(true);
            }
            // Make a submission attempt even if form contains errors,
            // it will make sure validation is triggered onChange
            await handleSubmit(async values => {
                let metadata;
                if (schema.recordLocation) {
                    try {
                        const location = await getCurrentLocation();
                        metadata = {
                            [`form_submission_${schema.title}`]: {
                                longitude: location.coords.longitude,
                                latitude: location.coords.latitude,
                                accuracy: location.coords.accuracy
                            }
                        };
                    } catch (error) {
                        setError(error);
                        return null;
                    }
                }
                return transformValues(values, metadata);
            })();
        };
        if (shouldSubmit) {
            setShouldSubmit(false);
            submit();
        }
    }, [handleSubmit, shouldSubmit, errors, transformValues, schema, setError]);
    const handleSubmitClick = useCallback(
        async event => {
            event.preventDefault();
            // Trigger validation before submission attempt so that it can be checked for warnigns
            await trigger();
            console.log("submit click");
            setShouldSubmit(true);
        },
        [trigger]
    );

    return (
        <FormProvider {...methods}>
            <FormContext.Provider value={contextValue}>
                <RenderCount label="form" />
                <form>
                    <Box position="relative">
                        <FormGroup getValues={getValues} schema={schema} attributeDefinitions={attributeDefinitions} />
                        <DisabledCover disabled={disabled}>
                            <Box
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <CircularProgress />
                            </Box>
                        </DisabledCover>
                    </Box>
                    <Stack gap={1} mt={3} mb={5}>
                        <Stack direction="row" gap={1} justifyContent="center">
                            <Button variant="contained" onClick={onCancel} disabled={disabled}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                loading={disabled}
                                onClick={handleSubmitClick}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                        {schema.recordLocation && (
                            <FormHelperText sx={{ textAlign: "center" }}>
                                This form automatically logs your location on submission. Location services must be
                                enabled.
                            </FormHelperText>
                        )}
                    </Stack>
                </form>
                <FormWarningDialog
                    open={submitWithWarningsDialogOpen}
                    onConfirm={handleSubmitWithWarningsConfirmed}
                    onCancel={() => setSubmitWithWarningsDialogOpen(false)}
                />
            </FormContext.Provider>
        </FormProvider>
    );
}

export default memo(Form);

import { createContext, useCallback, useMemo, useState } from "react";

export const AtlasFormContext = createContext({ errors: null, setError: null, clearError: null });

/**
 * Idea: Wrap context around inputs that require validation.
 * Inputs call setError/clearError as they are validating.
 * Context provider checks error object & displays an AtlasFormErrorDialog.
 * As of this writing, only NumberInput uses these context APIs.
 */
export const useAtlasFormContext = () => {
    const [errors, setErrors] = useState({});
    const setError = useCallback(
        (id, name, message) => setErrors(errors => ({ ...errors, [id]: { name, message } })),
        []
    );
    const clearError = useCallback(
        id =>
            setErrors(errors => {
                const newErrors = { ...errors };
                delete newErrors[id];
                return newErrors;
            }),
        []
    );
    return useMemo(() => ({ errors, setError, clearError }), [errors, setError, clearError]);
};

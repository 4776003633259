import React from "react";
import OutlinedContainer from "../OutlinedContainer";

import { IconButton, TextField, Slider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add, Remove } from "@mui/icons-material";

const useStyles = makeStyles(_ => ({
    textContainer: {
        display: "flex",
        alignItems: "center"
    },
    textField: {
        flexGrow: 1
    }
}));

function NumberInputWithSlider({ className, title, value, min = 0, max = 100, step = 1, onChange }) {
    const classes = useStyles();

    const handleChange = value => {
        let number = Number(value);
        if (Number.isInteger(number)) {
            number = Math.max(min, Math.min(number, max));
            onChange && onChange(number);
        }
    };

    return (
        <OutlinedContainer className={className} label={title}>
            <div className={classes.textContainer}>
                <IconButton onClick={() => handleChange(value - step)} size="large">
                    <Remove fontSize="small" />
                </IconButton>
                <TextField
                    className={classes.textField}
                    value={value}
                    onChange={event => handleChange(event.target.value)}
                    inputProps={{ min, max, step }}
                />
                <IconButton onClick={() => handleChange(value + step)} size="large">
                    <Add fontSize="small" />
                </IconButton>
            </div>
            <Slider value={value} onChange={(_, newValue) => handleChange(newValue)} />
        </OutlinedContainer>
    );
}

export default NumberInputWithSlider;

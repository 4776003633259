import { memo } from "react";
import { useFieldController } from "./useFieldController";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import OutlinedContainer from "../OutlinedContainer";
import RenderCount from "../RenderCount";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function BoolField({ schema, disabled, name, rules, defaultValue, className }) {
    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules, defaultValue });

    const _hasError = hasError(error);
    const _hasWarning = hasWarning(error);

    return (
        <>
            <RenderCount label="BoolField" />
            <OutlinedContainer
                className={className}
                label={schema.title}
                error={_hasError}
                warning={_hasWarning}
                helperText={getErrorMessages(error)?.join(", ")}
            >
                <RenderCount label="RadioGroup" />
                <RadioGroup
                    name={schema.key}
                    value={String(field.value)}
                    onClick={event => {
                        if (event.target.value === undefined) {
                            return;
                        }
                        const value = event.target.value === "true";
                        if (value === field.value) {
                            field.onChange(null);
                        } else {
                            field.onChange(value);
                        }
                    }}
                >
                    <FormControlLabel
                        disabled={disabled}
                        label="true"
                        value="true"
                        control={<Radio color="primary" />}
                    />
                    <FormControlLabel
                        disabled={disabled}
                        label="false"
                        value="false"
                        control={<Radio color="primary" />}
                    />
                </RadioGroup>
            </OutlinedContainer>
        </>
    );
}

export default memo(BoolField);

import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import jsonLogic from "../../../json-logic/json-logic";
import { getDerivedProperties } from "../../info/derived-properties";
import ForanIcons from "foran-icons";

import {
    Style as OLStyle,
    Fill as OLFill,
    Stroke as OLStroke,
    Circle as OLCircle,
    Text as OLText,
    Icon as OLIcon
} from "ol/style";
import { asArray as colorAsArray } from "ol/color";

const SVGS = Array.from(Object.values(ForanIcons)).flat();

const degreesToRadians = degrees => (degrees ? (degrees / 180) * Math.PI : 0);

function match(olFeature, map, condition) {
    if (condition) {
        const keys = Object.keys(condition);
        const operator = keys.length > 0 && keys[0];
        if (operator && operator !== "null") {
            const properties = olFeature.getProperties();
            const derivedProperties = getDerivedProperties(olFeature.getGeometry());
            const resolution = map.getView().getResolution();
            return jsonLogic.apply(condition, { ...properties, ...derivedProperties, resolution });
        }
    }
    return true;
}

/**
 * Add a transparent fill to make object clickable
 */
function fixOpenLayersClickableFill(olFeature, style) {
    const type = olFeature.getGeometry().getType().toLowerCase();
    switch (type) {
        case "multipolygon":
            if (!style.fill) {
                style.fill = [];
            }
            if (!Array.isArray(style.fill)) {
                style.fill = [style.fill];
            }
            if (!style.fill.some(fill => fill.type !== "none" && fill.color && fill.opacity > 0)) {
                style.fill.push({
                    type: "solid",
                    color: "#ffffff",
                    opacity: 0
                });
            }
            break;
        case "multipoint":
            if (style.symbol && style.symbol.type === "circle") {
                if (
                    !style.symbol.fill ||
                    style.symbol.fill.type === "none" ||
                    style.symbol.fill.opacity < 0 ||
                    !style.symbol.fill.color
                ) {
                    style.symbol.fill = {
                        type: "solid",
                        color: "#ffffff",
                        opacity: 0
                    };
                }
            }
            break;
        default:
            return;
    }
}

function executeJSONLogic(olFeature, style, keys) {
    const properties = olFeature.getProperties();
    const derivedProperties = getDerivedProperties(olFeature.getGeometry());
    delete properties.__atlas;
    delete properties.geometry;
    const result = {};
    keys.forEach(key => {
        if (typeof style[key] === "object") {
            result[key] = jsonLogic.apply(style[key], { ...properties, ...derivedProperties });
        }
    });
    return result;
}

const JSON_LOGIC_TYPES = {
    FILL: ["color", "opacity"],
    STROKE: ["color", "opacity", "width"],
    SYMBOL: ["size", "rotation", "opacity"],
    ICON: ["size", "rotation", "opacity", "backgroundSize", "backgroundRotation", "backgroundOpacity"],
    LABEL: ["text", "rotation", "opacity", "fontSize"]
};

function removeUndefined(object = {}) {
    const result = {};
    for (const key in object) {
        if (object[key] !== undefined) {
            result[key] = object[key];
        }
    }
    return result;
}

function combineFill(olFeature, styles) {
    const fills = [];
    styles.forEach(style => {
        if (Array.isArray(style.fill)) {
            style.fill.forEach((fill, index) => {
                fills[index] = {
                    ...fills[index],
                    ...removeUndefined(fill),
                    ...executeJSONLogic(olFeature, fill, JSON_LOGIC_TYPES.FILL)
                };
            });
        }
    });
    return fills.length > 0 ? fills : null;
}

function combineStroke(olFeature, styles) {
    const strokes = [];
    styles.forEach(style => {
        if (Array.isArray(style.stroke)) {
            style.stroke.forEach((stroke, index) => {
                strokes[index] = {
                    ...strokes[index],
                    ...removeUndefined(stroke),
                    ...executeJSONLogic(olFeature, stroke, JSON_LOGIC_TYPES.STROKE)
                };
            });
        }
    });
    return strokes.length > 0 ? strokes : null;
}

function combineSymbol(olFeature, styles) {
    const symbol = styles.reduce((acc, curr) => {
        const newSymbol = {
            ...acc,
            ...removeUndefined(curr.symbol),
            ...(curr.symbol && executeJSONLogic(olFeature, curr.symbol, JSON_LOGIC_TYPES.SYMBOL))
        };
        const currFill = curr.symbol && curr.symbol.fill;
        if (acc.fill || currFill) {
            newSymbol.fill = { ...acc.fill, ...currFill };
        }
        const currStroke = curr.symbol && curr.symbol.stroke;
        if (acc.stroke || currStroke) {
            newSymbol.stroke = { ...acc.stroke, ...currStroke };
        }
        const currIcon = curr.symbol?.icon && removeUndefined(curr.symbol.icon);
        if (acc.icon || currIcon) {
            newSymbol.icon = {
                ...acc.icon,
                ...currIcon,
                ...executeJSONLogic(olFeature, currIcon || {}, JSON_LOGIC_TYPES.ICON)
            };
        }
        return newSymbol;
    }, {});
    return Object.keys(symbol).length > 0 ? symbol : null;
}

function combineLabel(olFeature, styles) {
    const label = styles.reduce((acc, curr) => {
        const newLabel = {
            ...acc,
            ...removeUndefined(curr.label),
            ...(curr.label && executeJSONLogic(olFeature, curr.label, JSON_LOGIC_TYPES.LABEL))
        };
        const currFill = curr.label && curr.label.fill;
        if (acc.fill || currFill) {
            newLabel.fill = { ...acc.fill, ...currFill };
        }
        const currStroke = curr.label && curr.label.stroke;
        if (acc.stroke || currStroke) {
            newLabel.stroke = { ...acc.stroke, ...currStroke };
        }
        const currBackgroundFill = curr.label && curr.label.backgroundFill;
        if (acc.backgroundFill || currBackgroundFill) {
            newLabel.backgroundFill = { ...acc.backgroundFill, ...currBackgroundFill };
        }
        const currBackgroundStroke = curr.label && curr.label.backgroundStroke;
        if (acc.backgroundStroke || currBackgroundStroke) {
            newLabel.backgroundStroke = { ...acc.backgroundStroke, ...currBackgroundStroke };
        }
        return newLabel;
    }, {});
    return Object.keys(label).length > 0 ? label : null;
}

export function createCombinedStyle(olFeature, map, styles) {
    const stylesToApply = styles
        .filter(style => !style.disabled)
        .filter(style => match(olFeature, map, style.condition));
    if (stylesToApply.length === 0) {
        return null;
    }
    const fill = combineFill(olFeature, stylesToApply);
    const stroke = combineStroke(olFeature, stylesToApply);
    const symbol = combineSymbol(olFeature, stylesToApply);
    const label = combineLabel(olFeature, stylesToApply);
    const zIndex = stylesToApply.reduce((max, style) => {
        if (style.zIndex > max) {
            return style.zIndex;
        } else {
            return max;
        }
    }, 0);
    const combinedStyle = {
        ...(fill && { fill }),
        ...(stroke && { stroke }),
        ...(symbol && { symbol }),
        ...(label && { label }),
        zIndex
    };
    fixOpenLayersClickableFill(olFeature, combinedStyle);
    return combinedStyle;
}

function createColor(hex, opacity) {
    if (!hex) {
        return null;
    }
    try {
        const color = colorAsArray(hex).slice();
        if (opacity !== undefined) {
            color[3] = opacity;
        }
        return color;
    } catch (e) {
        console.logError(e, "Error converting color");
        return null;
    }
}

function getOLFill(fill) {
    switch (fill.type) {
        case "solid":
            const color = createColor(fill.color, fill.opacity);
            if (color) {
                return new OLFill({ color });
            } else {
                return null;
            }
        case "none":
            return null;
        default:
            throw new Error(`Unknown fill type "${fill.type}".`);
    }
}

function getOLStroke(stroke, resolution = 1) {
    const options = {
        width: stroke.width ? stroke.width / resolution : 0
    };
    switch (stroke.type) {
        case "dashed":
            if (Array.isArray(stroke.pattern) && stroke.pattern.every(n => typeof n === "number" && !Number.isNaN(n))) {
                options.lineDash = stroke.pattern.map(n => n / resolution);
            } else {
                const dash = 5 + options.width;
                options.lineDash = [dash, dash];
            }
        // fallthrough
        case "solid":
            const color = createColor(stroke.color, stroke.opacity);
            if (color) {
                options.color = color;
            }
            break;
        case "none":
            return null;
        default:
            throw new Error(`Unknown stroke type "${stroke.type}".`);
    }
    return new OLStroke(options);
}

function getFillStyles(style) {
    if (!style.fill) {
        return [];
    }
    const fills = Array.isArray(style.fill) ? style.fill : [style.fill];
    const styles = [];
    for (const fill of fills) {
        const olFill = getOLFill(fill);
        if (olFill) {
            styles.push(
                new OLStyle({
                    fill: olFill,
                    ...(style.geometryFunction && { geometry: style.geometryFunction })
                })
            );
        }
    }
    return styles;
}

function getStrokeStyles(style, resolution = 1) {
    if (!style.stroke) {
        return [];
    }
    const strokes = Array.isArray(style.stroke) ? style.stroke : [style.stroke];
    const styles = [];
    for (const stroke of strokes) {
        const olStroke = getOLStroke(stroke, resolution);
        if (olStroke) {
            styles.push(
                new OLStyle({
                    stroke: olStroke,
                    ...(style.geometryFunction && { geometry: style.geometryFunction })
                })
            );
        }
    }
    return styles;
}

function getOLIcon(id, size, color, opacity, rotation = 0, offset = [0, 0]) {
    if (!id) {
        return null;
    }
    const icon = SVGS.find(icon => icon.id === id);
    if (!icon) {
        console.error(`Styles: icon ${id} not found`);
        return null;
    }
    const Component = icon.component;
    const iconAnchor = [...(icon.anchor ?? [0.5, 0.5])];
    iconAnchor[0] *= size;
    iconAnchor[1] *= size;
    const anchor = [iconAnchor[0] + offset[0], iconAnchor[1] + offset[1]];
    const wh = `${size}px`;
    const svg = renderToStaticMarkup(<Component width={wh} height={wh} style={{ color, opacity }} />);
    const src = `data:image/svg+xml;base64,${btoa(svg)}`;
    if (rotation && (offset[0] !== 0 || offset[1] !== 0)) {
        const length = Math.sqrt(Math.pow(offset[0], 2) + Math.pow(offset[1], 2));
        const a = offset[0] === 0 ? Math.PI / 2 : Math.atan(offset[1] / offset[0]);
        const b = a + rotation / 2;
        const l = 2 * Math.sin(rotation / 2) * length;
        let x = l * Math.sin(b);
        let y = l * Math.cos(b);
        if (offset[0] > 0) {
            x *= -1;
            y *= -1;
        }
        anchor[0] += x;
        anchor[1] -= y;
    }
    return new OLStyle({
        image: new OLIcon({
            src,
            size: [size, size],
            anchor,
            anchorOrigin: "bottom-left",
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            rotation
        })
    });
}

function getSymbolStyles(style, resolution = 1) {
    if (!style.symbol) {
        return [];
    }
    const styles = [];
    switch (style.symbol.type) {
        case "circle":
            const circleOptions = {
                radius: style.symbol.size / resolution
            };
            if (style.symbol.stroke) {
                const olStroke = getOLStroke(style.symbol.stroke, resolution);
                if (olStroke) {
                    circleOptions.stroke = olStroke;
                }
            }
            if (style.symbol.fill) {
                const olFill = getOLFill(style.symbol.fill);
                if (olFill) {
                    circleOptions.fill = olFill;
                }
            }
            styles.push(
                new OLStyle({
                    image: new OLCircle(circleOptions),
                    ...(style.geometryFunction && { geometry: style.geometryFunction })
                })
            );
            break;
        case "icon":
            // * 2 to match that size is radius on circle styles
            let backgroundRotation = degreesToRadians(style.symbol.icon?.backgroundRotation);
            if (typeof style.symbol.icon?.backgroundRotationLogic === "object") {
            }
            const backgroundIconStyle = getOLIcon(
                style.symbol.icon?.backgroundId,
                (style.symbol.icon?.backgroundSize ?? 0) * 2,
                style.symbol.icon?.backgroundColor,
                style.symbol.icon?.backgroundOpacity,
                backgroundRotation
            );
            if (backgroundIconStyle) {
                styles.push(backgroundIconStyle);
            }
            const offset = [0, 0];
            const background = SVGS.find(icon => icon.id === style.symbol.icon?.backgroundId);
            if (background?.center) {
                offset[0] -= (0.5 - background.center[0]) * (style.symbol.icon?.backgroundSize ?? 0) * 2;
                offset[1] += (0.5 - background.center[1]) * (style.symbol.icon?.backgroundSize ?? 0) * 2;
            }
            if (background?.anchor) {
                offset[0] -= (0.5 - background.anchor[0]) * (style.symbol.icon?.backgroundSize ?? 0) * 2;
                offset[1] -= (0.5 - background.anchor[1]) * (style.symbol.icon?.backgroundSize ?? 0) * 2;
            }
            if (backgroundRotation) {
                const length = Math.sqrt(Math.pow(offset[0], 2) + Math.pow(offset[1], 2));
                const a = offset[1] === 0 ? Math.PI / 2 : Math.atan(offset[0] / offset[1]);
                const b = a + backgroundRotation / 2;
                const h = Math.sin(backgroundRotation / 2) * length * 2;
                const rotationOffsetX = Math.cos(b) * h;
                const rotationOffsetY = Math.sin(b) * h;
                offset[0] -= rotationOffsetX;
                offset[1] += rotationOffsetY;
            }
            const iconStyle = getOLIcon(
                style.symbol.icon?.id,
                (style.symbol.icon?.size ?? 0) * 2,
                style.symbol.icon?.color,
                style.symbol.icon?.opacity,
                degreesToRadians(style.symbol.icon?.rotation),
                offset
            );
            if (iconStyle) {
                styles.push(iconStyle);
            }
            break;
        case "none":
            break;
        default:
            throw new Error(`Unknown symbol type "${style.symbol.type}".`);
    }
    return styles;
}

function getLabelStyles(style) {
    if (!style.label) {
        return [];
    }
    const options = {};
    if (style.label.fill) {
        const olFill = getOLFill(style.label.fill);
        if (olFill) {
            options.fill = olFill;
        }
    }
    if (style.label.stroke) {
        const olStroke = getOLStroke(style.label.stroke);
        if (olStroke) {
            options.stroke = olStroke;
        }
    }
    if (style.label.backgroundFill) {
        const olFill = getOLFill(style.label.backgroundFill);
        if (olFill) {
            options.backgroundFill = olFill;
        }
    }
    if (style.label.backgroundStroke) {
        const olStroke = getOLStroke(style.label.backgroundStroke);
        if (olStroke) {
            options.backgroundStroke = olStroke;
        }
    }
    if (style.label.text) {
        options.text = style.label.text.replace(/([^\\])?\\n/g, "$1\n");
    }
    if (style.label.overflow !== undefined) {
        options.overflow = style.label.overflow;
    }
    if (style.label.padding) {
        options.padding = style.label.padding;
    }
    const rotation = Number(style.label.rotation);
    if (!Number.isNaN(rotation)) {
        options.rotation = degreesToRadians(rotation);
    }
    if (style.label.offsetX) {
        options.offsetX = style.label.offsetX;
    }
    if (style.label.offsetY) {
        options.offsetY = style.label.offsetY;
    }
    const fontFamily = style.label.font || "sans-serif";
    const fontStyle = style.label.style || "normal";
    const fontWeight = style.label.weight || "normal";
    let fontSize = 14;
    const fontSizeNumber = Number(style.label.size);
    if (!Number.isNaN(fontSizeNumber)) {
        fontSize = fontSizeNumber;
    }
    const fontSizeUnit = style.label.sizeUnit || "px";
    options.font = `${fontStyle} ${fontWeight} ${fontSize}${fontSizeUnit} ${fontFamily}`;
    return [
        new OLStyle({
            text: new OLText(options)
        })
    ];
}

export function createOLStyles(style = {}, resolution = 1) {
    let styles = [];

    if (style && style.fill) {
        styles = styles.concat(getFillStyles(style));
    }
    if (style && style.stroke) {
        styles = styles.concat(getStrokeStyles(style, resolution));
    }
    if (style && style.symbol) {
        styles = styles.concat(getSymbolStyles(style, resolution));
    }
    if (style && style.label) {
        styles = styles.concat(getLabelStyles(style));
    }
    if (style?.zIndex !== undefined && style?.zIndex !== null) {
        styles.forEach(s => s.setZIndex(style.zIndex));
    }

    return styles;
}

import { MenuItem } from "@mui/material";
import { useState } from "react";
import { useController } from "react-hook-form";
import WarnableTextField from "../../WarnableTextField";

const DATE_OPTIONS = [
    { key: "snarast", title: "Snarast" },
    { key: "2-5", title: "Inom 2-5 år" },
    { key: "6-10", title: "Inom 6-10 år" },
    { key: "11-20", title: "Inom 11-20 år" }
];

function ForestandDateSpanDropdown({ name, ...props }) {
    const [date, setDate] = useState(null);
    const {
        field: { onChange: onDateChange }
    } = useController({ name: `${name}.date` });
    const {
        field: { onChange: onSpanChange }
    } = useController({ name: `${name}.span` });

    const handleDateChange = event => {
        setDate(event.target.value);
        const thisYear = new Date().getFullYear();
        switch (event.target.value) {
            case "snarast":
                onDateChange(thisYear);
                onSpanChange("P2Y");
                break;
            case "2-5":
                onDateChange(thisYear + 2);
                onSpanChange("P4Y");
                break;
            case "6-10":
                onDateChange(thisYear + 6);
                onSpanChange("P5Y");
                break;
            case "11-20":
                onDateChange(thisYear + 11);
                onSpanChange("P10Y");
                break;
            default:
                console.warn(`Unknown ForestandActivityDate value ${event.target.value}`);
        }
    };

    return (
        <WarnableTextField {...props} value={date ?? ""} onChange={handleDateChange}>
            {DATE_OPTIONS.map(({ key, title }) => (
                <MenuItem key={key} value={key}>
                    {title}
                </MenuItem>
            ))}
        </WarnableTextField>
    );
}

export default ForestandDateSpanDropdown;

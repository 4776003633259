import { memo, useCallback } from "react";
import { useFieldController } from "./useFieldController";
import { useExtendedRules, getErrorMessages, hasError, hasWarning } from "./form-utils";
import SimpleNumericInput, { validateNumber } from "../inputs/SimpleNumericInput";
import RenderCount from "../RenderCount";
import Decimal from "decimal.js";

const getValue = value => {
    if (typeof value === "number" && !Number.isNaN(value)) {
        return new Decimal(value).times(100).toNumber();
    }
    if (typeof value === "string") {
        if (/[0-9]$/.test(value)) {
            const valueAsNumber = Number(value.replaceAll(",", "."));
            return getValue(valueAsNumber);
        }
        return value.replaceAll(",", ".");
    }
    return null;
};
const getEditedValue = value => {
    if (typeof value === "number" && !Number.isNaN(value)) {
        return new Decimal(value).dividedBy(100).toNumber();
    }
    if (typeof value === "string") {
        return value.replaceAll(",", ".");
    }
    return null;
};

function PercantageField({ schema, name, rules, defaultValue, ...other }) {
    const handleValidation = useCallback(value => {
        return validateNumber(getValue(value), { min: 0, max: 100 });
    }, []);
    const _rules = useExtendedRules(rules, "errorNumeric", handleValidation);
    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules: _rules, defaultValue });
    const value = getValue(field.value);

    return (
        <>
            <RenderCount label="NumberField" />
            <SimpleNumericInput
                variant="outlined"
                label={schema.title}
                error={hasError(error)}
                warning={hasWarning(error)}
                helperText={getErrorMessages(error)?.join(", ")}
                {...other}
                {...field}
                step={1}
                min={0}
                max={100}
                value={value}
                onChange={value => {
                    const edited = getEditedValue(value);
                    console.log("onChange", edited);
                    field.onChange(edited);
                }}
            />
        </>
    );
}

export default memo(PercantageField);

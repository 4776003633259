import { EventEmitter } from "events";
import { LocalDatabase } from "idb";
import { SpatialDatabase } from "spatial-idb";
import { difference, touches } from "./geojson-tools";
import { ObjectId } from "./id-generation";
import { initConsole } from "./console";
import { isRasterLayer, isVectorLayer } from "./layer-tools";
import Api from "../Api";
import DownloadManager from "./DownloadManager";
import TileDatabase from "./TileDatabase";

const BATCH_LIMIT = 1000;
const CONCURRENT_DOWNLOADS_LIMIT = 2;

class DBManager extends EventEmitter {
    constructor() {
        super();
        this._atlasDB = null;
        this._vectorDBs = new Map();
        this._tileDBs = new Map();

        this._syncChannelQueue = [];
        this._syncChannelQueueInProgress = false;

        this._downloadManagerLoadingId = null;
        this._downloadManager = new DownloadManager({ concurrentLimit: CONCURRENT_DOWNLOADS_LIMIT });
        this.downloadManagerCached = new DownloadManager({ concurrentLimit: CONCURRENT_DOWNLOADS_LIMIT, cache: true });

        this._sendLocalModifiedFeaturesQueue = [];
        this._downloadFeatureChangesQueue = [];
        this._initializeQueue = new Map();
        this._subscriptionQueue = new Map();

        window.__atlas.dbManager = this;
    }

    async initialize(syncChannel, startLoading, stopLoading) {
        this._startLoading = startLoading;
        this._stopLoading = stopLoading;
        this._downloadManager.on("downloading-change", ({ count }) => {
            if (!this._downloadManagerLoadingId && count > 0) {
                this._downloadManagerLoadingId = this._startLoading(`Downloading raster data (${count} tiles left)`);
            } else if (this._downloadManagerLoadingId && count === 0) {
                this._stopLoading(this._downloadManagerLoadingId);
                this._downloadManagerLoadingId = null;
            }
        });

        this._atlasDB = await new LocalDatabase({
            name: "atlas",
            version: 9,
            objectStores: [
                {
                    name: "workspaces",
                    keyPath: "_id"
                },
                {
                    name: "layers",
                    keyPath: "_id"
                },
                {
                    name: "files",
                    keyPath: "id",
                    indices: [{ keyPath: "localModified" }, { keyPath: "shouldDownload" }]
                },
                {
                    name: "failedUploads",
                    keyPath: "id"
                },
                {
                    name: "logs",
                    keyPath: "id",
                    indices: [{ keyPath: "timestamp", unique: false }]
                }
            ]
        }).initialize();
        await initConsole(this._atlasDB);
        this._syncChannel = syncChannel;
        this._syncChannel.on("change", event => this._handleChangeEvent(event));
        this._syncChannel.on("connect", this.handleConnect.bind(this));
        this._syncChannel.on("disconnect", this.handleDisconnect.bind(this));
    }

    async handleConnect() {
        this.initializeAllWorkspaces();
    }

    async handleDisconnect() {
        this._initializeQueue.clear();
        const _workspaces = await this._atlasDB.getAll("workspaces");
        const timestamp = Date.now();
        for (const workspace of _workspaces) {
            const configuration = await this._convertToWorkspaceConfiguration(workspace);
            for (const layer of configuration.layers.filter(isVectorLayer)) {
                const db = await this.getVectorDB(layer._id, workspace._id);
                if (await db.getMeta("upToDate")) {
                    await db.setMeta("lastUpToDate", timestamp);
                }
                await db.setMeta("upToDate", false);
            }
        }
        // Remove nudges from sync channel queue
        this._syncChannelQueue = this._syncChannelQueue.filter(e => e.type !== "feature");
    }

    async _restorePreviousVersionOfFeature(feature, spatialIDB) {
        console.log(`Attempting to restore previous version of feature "${feature.id}".`);
        await this._atlasDB.addObject(feature, "failedUploads", true);

        // Restore previous version
        try {
            await spatialIDB.remove(feature.id);
            if (feature.properties.__atlas.parent) {
                const previousVersion = await spatialIDB.get(feature.properties.__atlas.parent);
                if (previousVersion) {
                    previousVersion.properties.__atlas.child = 0;
                    await spatialIDB.update(previousVersion);
                    console.log(`Restored version "${previousVersion.id}".`);
                    return previousVersion;
                } else {
                    console.log("No previous version exists.");
                }
            } else {
                console.log("No previous version exists.");
            }
        } catch (error) {
            console.logError(error, "Feature restore failed");
        }
    }

    async _sendLocalFiles() {
        return new Promise(async (resolve, reject) => {
            if (this._sendingLocalFiles) {
                this._sendingLocalFilesQueue.push({ resolve, reject });
                return;
            }
            this._sendingLocalFiles = true;
            this._sendingLocalFilesQueue = [{ resolve, reject }];
            try {
                let files = await this._atlasDB.query("localModified", { $gt: 0 }, undefined, "files");
                while (files.length > 0) {
                    for (const fileObject of files) {
                        console.log(`Uploading file ${fileObject.id} "${fileObject.file.name}"`);
                        await this.uploadLocalFile(fileObject);
                    }
                    files = await this._atlasDB.query("localModified", { $gt: 0 }, undefined, "files");
                }
                this._sendingLocalFilesQueue.forEach(({ resolve }) => resolve());
            } catch (error) {
                this._sendingLocalFilesQueue.forEach(({ reject }) => reject(error));
            } finally {
                this._sendingLocalFiles = false;
                this._sendingLocalFilesQueue = [];
            }
        });
    }

    async downloadFeatureChanges(configuration) {
        return new Promise((resolve, reject) => {
            console.log(`Adding workspace "${configuration._id}" in the queue for feature downloads.`);
            this._downloadFeatureChangesQueue.push({ configuration, resolve, reject });
            this._downloadFeatureChanges();
        });
    }

    async _downloadFeatureChanges() {
        if (this._downloadFeatureChangesQueueInProgress) {
            return;
        }
        const first = this._downloadFeatureChangesQueue.shift();
        if (!first) {
            return;
        }
        const { configuration, resolve, reject } = first;
        this._downloadFeatureChangesQueueInProgress = true;

        try {
            console.log(`Downloading feature changes in workspace "${configuration._id}".`);
            for (const layer of configuration.layers.filter(isVectorLayer)) {
                const db = await this.getVectorDB(layer._id, configuration._id);
                let batch = 1;
                let featureChanges;
                do {
                    const lastFetchDate = await db.getMeta("lastFetchDate");
                    const lastFetchId = await db.getMeta("lastFetchId");
                    const hasBeenUpToDate = await db.getMeta("hasBeenUpToDate");
                    const workspaceAreaId = await db.getMeta("workspaceAreaId");
                    let initTimestamp = null;
                    if (!hasBeenUpToDate) {
                        initTimestamp = await db.getMeta("initTimestamp");
                    }
                    console.log(
                        `Downloading feature changes in "${configuration._id}-${layer._id}" (batch ${batch++})`
                    );
                    featureChanges = await Api.getFeatureChanges(
                        configuration._id,
                        layer._id,
                        lastFetchDate,
                        lastFetchId,
                        true,
                        initTimestamp,
                        workspaceAreaId
                    );
                    await this._handleFeatureChanges(featureChanges);
                } while (featureChanges && !featureChanges.lastInBatch);
            }
            console.log(`Downloading feature changes in workspace "${configuration._id}" COMPLETE.`);
            resolve();
        } catch (error) {
            console.logError(error, `Error downloading feature changes in "${configuration._id}"`);
            reject(error);
        } finally {
            this._downloadFeatureChangesQueueInProgress = false;
            this._downloadFeatureChanges();
        }
    }

    async sendAllLocalModifiedFeaturesInWorkspace(configuration) {
        for (const layer of configuration.layers.filter(isVectorLayer)) {
            await this.sendLocalModifiedFeatures(layer._id, configuration._id);
        }
    }

    async sendLocalModifiedFeatures(layerId, workspaceId) {
        return new Promise((resolve, reject) => {
            console.log(`Adding "${workspaceId}-${layerId}" to the queue for sending local modified features.`);
            this._sendLocalModifiedFeaturesQueue.push({ layerId, workspaceId, resolve, reject });
            this._sendLocalModifiedFeatures();
        });
    }

    async _sendLocalModifiedFeatures() {
        if (this._sendLocalModifiedFeaturesQueueInProgress) {
            // Already processing queue
            return;
        }
        const first = this._sendLocalModifiedFeaturesQueue.shift();
        if (!first) {
            // Queue is empty, processing done.
            return;
        }
        const { layerId, workspaceId, resolve, reject } = first;
        this._sendLocalModifiedFeaturesQueueInProgress = true;

        console.log(`Sending local modified features in "${workspaceId}-${layerId}.`);

        try {
            const spatialIDB = await this.getVectorDB(layerId, workspaceId);

            // Send files first
            await this._sendLocalFiles();

            while (true) {
                // This will fetch all local modified features *in order*
                const features = await spatialIDB.query({ "__atlas.localModified": { $gt: 0 } }, BATCH_LIMIT);
                if (features.length === 0) {
                    console.log(`No more features to send in "${workspaceId}-${layerId}"`);
                    // While loop ends here (or when error occurs further down)
                    return resolve();
                }

                console.log(`Uploading ${features.length} changes to "${workspaceId}-${layerId}"`);

                const { status, error, dateStamps, parentIds, errors } = await Api.bulkUpdateGeometries(
                    features,
                    layerId
                );
                if (status !== "ok") {
                    return reject(
                        new Error(
                            `Sending updates to server failed with status "${status}". Error:`,
                            (error && error.message) || "Unknown"
                        )
                    );
                }
                // All features in the array have been sent to the server successfully.
                console.log(`Done sending ${features.length} updates to the server.`);

                for (const feature of features) {
                    feature.properties = feature.properties || {};
                    feature.properties.__atlas = feature.properties.__atlas || {};

                    if (errors && errors[feature.id]) {
                        // Something went wrong with this feature on the server
                        const error = errors[feature.id];
                        feature.properties.__atlas.error = { ...error, layerId, workspaceId };
                        const message = `An error occurred when feature update "${feature.id}" (original id "${feature.properties.__atlas.originalId}") was sent to the server: ${error.message}`;
                        console.error(message);
                        this.emit("error", new Error(message));
                        const event = {
                            deleted: [feature.id],
                            features: []
                        };
                        const previousVersion = await this._restorePreviousVersionOfFeature(feature, spatialIDB);
                        if (previousVersion) {
                            event.features.push(previousVersion);
                        }
                        this.emit(`change-${layerId}-${workspaceId}`, event);
                        this.emit("uploadFailure", feature);
                        feature.ignore = true;
                        continue;
                    }

                    if (!dateStamps || !dateStamps[feature.id]) {
                        const error = new Error(`Date stamp missing for feature "${feature.id}".`);
                        feature.properties.__atlas.error = {
                            message: error.message,
                            layerId,
                            workspaceId
                        };
                        console.error(error.message);
                        this.emit("error", error);
                        const event = {
                            deleted: [feature.id],
                            features: []
                        };
                        const previousVersion = await this._restorePreviousVersionOfFeature(feature, spatialIDB);
                        if (previousVersion) {
                            event.features.push(previousVersion);
                        }
                        this.emit(`change-${layerId}-${workspaceId}`, event);
                        this.emit("uploadFailure", feature);
                        feature.ignore = true;
                        continue;
                    }

                    // Set date stamps
                    feature.properties.__atlas.modified = dateStamps[feature.id];
                    if (feature.properties.__atlas.createdInWorkspace) {
                        // This was a new feature, so its created date stamp
                        // is the same as its modified date stamp.
                        feature.properties.__atlas.created = dateStamps[feature.id];
                    } else {
                        // This update was a modification of an existing feature,
                        // so the parent must exist locally.
                        let parent = features.find(f => f.id === feature.properties.__atlas.parent);
                        if (!parent) {
                            parent = await spatialIDB.get(feature.properties.__atlas.parent);
                        }
                        if (parent) {
                            feature.properties.__atlas.created = parent.properties.__atlas.created;
                        }
                    }

                    const parentId = parentIds && parentIds[feature.id];
                    if (parentId && feature.properties.__atlas.parent !== parentId) {
                        // This update was sent to an old parent.
                        // The server placed it last in the feature chain.
                        // parentId is the new parent.
                        // We must delete the old feature chain locally.
                        console.log(
                            `Feature update "${feature.id}" was sent to an old parent ("${feature.properties.__atlas.parent}").`
                        );
                        console.groupCollapsed(`Deleting old local feature chain up until the old parent.`);
                        try {
                            const chain = [];
                            let currentFeature = feature;
                            while (currentFeature && currentFeature.properties.__atlas.parent) {
                                currentFeature = await spatialIDB.get(currentFeature.properties.__atlas.parent);
                                if (currentFeature) {
                                    chain.push(currentFeature.id);
                                }
                            }
                            // Delete chain backwards so we don't have a disconnected chain
                            // if the execution somehow is aborted.
                            for (let i = chain.length - 1; i >= 0; i--) {
                                await spatialIDB.remove(chain[i]);
                                console.log(`Deleted "${chain[i]}".`);
                                // If the feature also exist in the current update,
                                // make sure it's not inserted again further down in this function.
                                const featureInCurrentUpdate = features.find(f => f.id === chain[i]);
                                if (featureInCurrentUpdate) {
                                    featureInCurrentUpdate.ignore = true;
                                }
                            }
                        } finally {
                            console.groupEnd();
                        }
                        feature.properties.__atlas.parent = parentId;
                    }

                    // Remove local modified flags
                    delete feature.properties.__atlas.localModified;
                    delete feature.properties.__atlas.createdInWorkspace;
                    delete feature.properties.__atlas.modifiedInWorkspace;
                    delete feature.properties.__atlas.deletedInWorkspace;
                }
                const featuresToInsert = features.filter(feature => !feature.ignore);
                featuresToInsert.forEach(feature => delete feature.ignore);
                if (featuresToInsert.length > 0) {
                    await spatialIDB.performChanges({ updateProperties: featuresToInsert });
                }
            }
        } catch (error) {
            console.logError(error, "_sendLocalModifiedFeatures");
            reject(error);
        } finally {
            this._sendLocalModifiedFeaturesQueueInProgress = false;
            // Begin next item in queue
            this._sendLocalModifiedFeatures();
        }
    }

    /**
     * Initializes all workspaces marked for offline use.
     * These are the ones the user wants up to date.
     * Other workspaces that have been opened are not automatically
     * initialized, but will be initialized once opened again.
     *
     * This method will, however, send local modified features for
     * all workspaces, not just ones marked offline.
     */
    async initializeAllWorkspaces() {
        // TODO: Sort by most recently opened workspace
        const workspaces = await this._atlasDB.getAll("workspaces");
        const databases = await indexedDB.databases();
        for (const workspace of workspaces) {
            // Delete old raster databases
            for (const layerId of workspace.layers) {
                const oldRasterDatabase = databases.find(db => db.name === layerId);
                if (oldRasterDatabase) {
                    console.log(`Deleting old raster database ${oldRasterDatabase.name}`);
                    await indexedDB.deleteDatabase(oldRasterDatabase.name);
                }
            }
            if (workspace.__localData?.offline) {
                try {
                    await this.initializeWorkspace(workspace);
                } catch (error) {
                    console.logError(error, `initializeAllWorkspaces: Init failed for ${workspace._id}`);
                }
            } else {
                try {
                    const configuration = await this._convertToWorkspaceConfiguration(workspace);
                    await this.sendAllLocalModifiedFeaturesInWorkspace(configuration);
                } catch (error) {
                    console.logError(
                        error,
                        `initializeAllWorkspaces: Send local modified features failed for ${workspace._id}`
                    );
                }
            }
        }
        this._downloadFilesMarkedForDownload();
    }

    /**
     * When this method resolves, the workspace will be ready to open.
     * IndexedDB databases will have been created.
     * After resolving, it will also send local modified features, if any exists,
     * then subscribe for feature updates & queue feature changes for download.
     */
    async initializeWorkspace(workspace) {
        console.log(`Initializing workspace ${workspace._id}.`);
        return new Promise(async (resolve, reject) => {
            if (this._initializeQueue.has(workspace._id)) {
                const queue = this._initializeQueue.get(workspace._id);
                queue.push({ resolve, reject });
                return;
            } else {
                this._initializeQueue.set(workspace._id, [{ resolve, reject }]);
            }
            try {
                const configuration = await this._convertToWorkspaceConfiguration(workspace);
                await this._createDatabasesForWorkspaceConfiguration(configuration);
                if (configuration.__localData?.offline) {
                    // Start raster download in background
                    console.log(`Starting background raster download in workspace ${workspace._id}`);
                    for (const layer of configuration.layers.filter(isRasterLayer)) {
                        const tileDB = await this.getTileDB(layer._id, configuration._id);
                        tileDB
                            .download()
                            .then(() => {
                                console.log("_subscribe: tileDb download complete");
                            })
                            .catch(error => console.error("_subscribe: tileDb download error", error));
                    }
                }
                console.log(`Workspace ${workspace._id} initialized.`);
                const queue = this._initializeQueue.get(workspace._id);
                if (queue) {
                    queue.forEach(({ resolve }) => resolve());
                    this._subscribeToWorkpace(configuration);
                } else {
                    const message = `Workspace ${workspace._id} missing from initialization queue, assuming disconnect event during initialization.`;
                    console.log(message);
                    reject(new Error(message));
                }
            } catch (error) {
                console.logError(error, `Error initializing workspace ${workspace._id}`);
                const queue = this._initializeQueue.get(workspace._id);
                queue?.forEach(({ reject }) => reject(error));
            } finally {
                this._initializeQueue.delete(workspace._id);
            }
        });
    }

    async _subscribeToWorkpace(configuration) {
        console.log(`Subscribing to workspace ${configuration._id}.`);
        return new Promise(async (resolve, reject) => {
            if (this._subscriptionQueue.has(configuration._id)) {
                const queue = this._subscriptionQueue.get(configuration._id);
                queue.push({ resolve, reject });
                return;
            } else {
                this._subscriptionQueue.set(configuration._id, [{ resolve, reject }]);
            }

            try {
                const loadingId = this._startLoading("Synchronizing vector data");

                // Send local features
                try {
                    await this.sendAllLocalModifiedFeaturesInWorkspace(configuration);
                } catch (error) {
                    console.logError(
                        error,
                        `sendAllLocalModifiedFeaturesInWorkspace failed for workspace ${configuration._id}`
                    );
                }

                // Subscribe for vector feature changes
                try {
                    const subscription = {
                        id: configuration._id,
                        modifiedDate: configuration.modified.date,
                        layers: {}
                    };
                    for (const layer of configuration.layers) {
                        const layerSubscription = { id: layer._id, modifiedDate: layer.modified.date };
                        subscription.layers[layer._id] = layerSubscription;
                    }
                    await Api.subscribe(subscription);
                } catch (error) {
                    console.logError(error, `subscribe failed for workspace ${configuration._id}`);
                }

                // Download vector feature changes
                try {
                    await this.downloadFeatureChanges(configuration);
                } catch (error) {
                    console.logError(error, `downloadFeatureChanges failed for workspace ${configuration._id}`);
                }

                const queue = this._subscriptionQueue.get(configuration._id);
                queue.forEach(({ resolve }) => resolve());

                this._stopLoading(loadingId);
            } catch (error) {
                const queue = this._subscriptionQueue.get(configuration._id);
                queue.forEach(({ reject }) => reject(error));
            } finally {
                this._subscriptionQueue.delete(configuration._id);
            }
        });
    }

    async unsubscribeFromWorkspace(workspaceId) {
        this._initializeQueue.delete(workspaceId);
        await Api.unsubscribe({ id: workspaceId });
    }

    async _convertToWorkspaceConfiguration(workspaceOrConfiguration) {
        const configuration = { ...workspaceOrConfiguration };
        for (let i = 0; i < workspaceOrConfiguration.layers.length; i++) {
            let layer = workspaceOrConfiguration.layers[i];
            if (typeof layer === "string") {
                layer = await this.getLayer(layer, configuration._id);
                configuration.layers[i] = layer;
            }
        }
        return configuration;
    }

    async getOfflineWorkspaces() {
        const workspaces = await this._atlasDB.getAll("workspaces");
        return workspaces.filter(workspace => workspace?.__localData?.offline);
    }

    async addOfflineWorkspace(workspace) {
        const loadingId = this._startLoading(`Setting up ${workspace.name} for offline use.`);
        try {
            const configuration = await this.getWorkspaceConfiguration(workspace._id);
            configuration.__localData = configuration.__localData || {};
            configuration.__localData.offline = true;
            await this._createDatabasesForWorkspaceConfiguration(configuration);
            for (const layer of configuration.layers.filter(isVectorLayer)) {
                const fileAttributes = layer.attributes.filter(a => ["file", "image"].includes(a.type));
                if (fileAttributes.length > 0) {
                    await this._markAllFilesForDownload(layer._id, configuration._id, fileAttributes);
                }
            }
            await this.initializeWorkspace(configuration);
            this.emit("offline-workspace-change");
        } finally {
            this._stopLoading(loadingId);
        }
    }

    async removeOfflineWorkspace(workspaceId) {
        const workspace = await this._atlasDB.getObject(workspaceId, "workspaces");
        if (workspace) {
            const loaderId = this._startLoading(`Removing offline workspace ${workspace.name}`);
            console.log(`Removing offline mark of workspace ${workspaceId}. Unsubscribing.`);
            try {
                await Api.unsubscribe({ id: workspaceId });
                console.log(`Unsubscribed from ${workspaceId}.`);
            } catch (error) {
                console.logError(error, `Unsubscribe of workspace ${workspaceId} failed`);
            }
            workspace.__localData = workspace.__localData || {};
            workspace.__localData.offline = false;
            await this._atlasDB.updateObject(workspace, "workspaces");
            const configuration = await this.getWorkspaceConfiguration(workspaceId);
            for (const layer of configuration.layers) {
                if (isVectorLayer(layer)) {
                    await this._removeAllDownloadedFiles(layer, workspaceId);
                } else if (isRasterLayer(layer)) {
                    await this._deleteTileDB(layer._id, workspaceId);
                }
            }
            console.log(`Removed offline mark of workspace ${workspaceId}.`);
            this._stopLoading(loaderId);
            this.emit("offline-workspace-change");
        }
    }

    /**
     * Returns a locally stored layer definition if available, else undefined.
     * @param {string} id ID of the layer to fetch.
     */
    async getLocalLayer(id) {
        return this._atlasDB.getObject(id, "layers");
    }

    /**
     * Returns a locally stored workspace definition if available, else undefined.
     * @param {string} id ID of the workspace to fetch.
     */
    async getLocalWorkspace(id) {
        return this._atlasDB.getObject(id, "workspaces");
    }

    async _checkUserRightsForLayerInWorkspace(layer, workspace, type) {
        const userRights =
            (workspace.layerOverrides &&
                workspace.layerOverrides[layer._id] &&
                workspace.layerOverrides[layer._id].userRights) ||
            layer.userRights;
        if (!userRights.includes(type)) {
            const error = new Error(
                `You do not have "${type}" permissions for layer ${layer.name} in workspace ${workspace.name}.`
            );
            error.name = "ACCESSDENIED";
            throw error;
        }
        return true;
    }

    /**
     * Get a (locally stored) workspace configuration.
     * Layer configurations are from local storage if available, else from the server.
     * Does NOT affect local storage, only reads.
     * @param {string} id ID of the workspace to fetch.
     */
    async getLocalWorkspaceConfiguration(id) {
        const configuration = await this._atlasDB.getObject(id, "workspaces");
        if (configuration) {
            for (let i = 0; i < configuration.layers.length; i++) {
                configuration.layers[i] = await this.getLayer(configuration.layers[i], configuration._id);
            }
        }
        return configuration;
    }

    /**
     * Get a workspace configuration.
     * First checks if a local version exists. If not, it is fetched form the server.
     * Does NOT affect local storage, only reads.
     * @param {string} id ID of the workspace to fetch.
     */
    async getWorkspaceConfiguration(id) {
        let workspace = await this._atlasDB.getObject(id, "workspaces");
        if (!workspace) {
            workspace = await Api.getWorkspace(id);
        }
        const configuration = workspace;
        for (let i = 0; i < workspace.layers.length; i++) {
            configuration.layers[i] = await this.getLayer(workspace.layers[i], configuration._id);
        }
        return configuration;
    }

    async _createDatabaseForLayerConfiguration(layerConfiguration, workspaceId) {
        if (isVectorLayer(layerConfiguration)) {
            await this.getVectorDB(layerConfiguration._id, workspaceId);
        } else if (isRasterLayer(layerConfiguration)) {
            await this.getTileDB(layerConfiguration._id, workspaceId);
        } else {
            throw new Error("Unknown layer type", layerConfiguration?.source?.type);
        }
        layerConfiguration.__localData = layerConfiguration.__localData || {};
        await this._atlasDB.addObject(layerConfiguration, "layers", true);
    }

    async _createDatabasesForWorkspaceConfiguration(configuration) {
        for (const layer of configuration.layers) {
            await this._createDatabaseForLayerConfiguration(layer, configuration._id);
        }
        const workspace = { ...configuration };
        workspace.layers = workspace.layers.map(layer => layer._id);
        workspace.__localData = workspace.__localData || {};
        await this._atlasDB.addObject(workspace, "workspaces", true);
    }

    async getVectorDB(layerId, workspaceId) {
        if (!workspaceId) {
            throw new Error("Workspace id missing");
        }
        const id = `${workspaceId}:${layerId}`;
        if (!this._vectorDBs.has(id)) {
            const spatialIDB = await new SpatialDatabase({
                name: id,
                validation: false,
                maxEntries: 9,
                indices: [
                    { keyPath: "properties.__atlas.modified.date", name: "__atlas.modified.date" },
                    { keyPath: "properties.__atlas.localModified", name: "__atlas.localModified" },
                    { keyPath: "properties.__atlas.originalId", name: "__atlas.originalId" },
                    { keyPath: "properties.__atlas.child", name: "__atlas.child" }
                ],
                version: 3
            }).initialize();
            this._vectorDBs.set(id, spatialIDB);
        }
        return this._vectorDBs.get(id);
    }

    async getTileDB(layerId, workspaceId) {
        const id = TileDatabase.getDbName(workspaceId, layerId);
        if (!this._tileDBs.has(id)) {
            const queue = [];
            this._tileDBs.set(id, queue);
            const workspace = await this.getWorkspaceConfiguration(workspaceId);
            const layer = await this.getLayer(layerId, workspaceId);
            const tileDB = await new TileDatabase({
                workspace,
                layer,
                downloadManager: this._downloadManager
            }).initialize();
            this._tileDBs.set(id, tileDB);
            queue.forEach(resolve => resolve());
        }
        let tileDB = this._tileDBs.get(id);
        if (Array.isArray(tileDB)) {
            await new Promise(resolve => tileDB.push(resolve));
            tileDB = this._tileDBs.get(id);
        }
        return tileDB;
    }

    async _deleteVectorDB(layerId, workspaceId) {
        const id = `${workspaceId}:${layerId}`;
        const db = this._vectorDBs.get(id);
        if (db) {
            const localModified = await db.query({ "__atlas.localModified": { $gt: 0 } }, 1);
            if (localModified.length > 0) {
                console.log(`Local modified features found in ${id}, keeping.`);
                return;
            }
            await db.deleteDatabase();
            this._vectorDBs.delete(id);
        }
    }

    async _deleteTileDB(layerId, workspaceId) {
        const id = TileDatabase.getDbName(workspaceId, layerId);
        const tileDb = this._tileDBs.get(id);
        if (tileDb) {
            await tileDb.deleteDatabase();
            this._tileDBs.delete(id);
        }
    }

    async _removeAllDownloadedFiles(layer, workspaceId) {
        const fileAttributes = layer.attributes.filter(a => ["file", "image"].includes(a.type));
        if (fileAttributes.length > 0) {
            const db = await this.getVectorDB(layer._id, workspaceId);
            let filesToDelete = [];
            await db.forEach(null, null, null, feature => {
                if (!feature.properties.__atlas.child) {
                    filesToDelete = filesToDelete.concat(this._getFilesFromFeatures([feature], fileAttributes));
                }
            });
            if (filesToDelete.length > 0) {
                console.log(`Deleting ${filesToDelete.length} downloaded files in ${workspaceId}-${layer._id}.`);
                await this._atlasDB.performChanges({
                    files: {
                        delete: filesToDelete
                    }
                });
            }
        }
    }

    async _markAllFilesForDownload(layerId, workspaceId, fileAttributes) {
        const db = await this.getVectorDB(layerId, workspaceId);
        let files = [];
        await db.forEach(null, null, null, feature => {
            if (!feature.properties.__atlas.child) {
                files = files.concat(this._getFilesFromFeatures([feature], fileAttributes));
            }
        });
        await this._markFilesForDownload(files, workspaceId);
    }

    async _markFilesForDownload(files, workspaceId) {
        const filesToUpdate = [];
        for (const file of files) {
            if (!(await this._atlasDB.getObject(file.id, "files"))) {
                filesToUpdate.push({ ...file, shouldDownload: 1, workspaceId });
            }
        }
        if (filesToUpdate.length > 0) {
            console.log(`Marking ${filesToUpdate.length} files for download.`);
            await this._atlasDB.updateObjects(filesToUpdate, "files");
            this._downloadFilesMarkedForDownload();
        }
    }

    _getFilesFromFeatures(features, fileAttributes) {
        const files = [];
        for (const attribute of fileAttributes) {
            for (const feature of features) {
                const file = feature.properties[attribute._id];
                if (attribute.array) {
                    if (Array.isArray(file)) {
                        files.push(...file);
                    }
                } else if (file) {
                    files.push(file);
                }
            }
        }
        return files;
    }

    async _handleFeatureChanges({
        layerId,
        workspaceId,
        workspaceAreaId,
        features,
        deleted,
        lastId,
        timestamp,
        lastModifiedDate,
        lastInBatch
    }) {
        console.log(
            `Feature changes in layer "${layerId}", workspace "${workspaceId}": ` +
                `${features.length} new, ${deleted.length} deleted.` +
                `${lastInBatch && " Last batch."}`
        );
        const db = await this.getVectorDB(layerId, workspaceId);

        const changes = {
            update: features, // Use update so that duplicate calls won't throw
            removeFromRTree: [],
            updateProperties: [],
            meta: []
        };

        if (features.length > 0) {
            const configuration = await this._atlasDB.getObject(layerId, "layers");
            const fileAttributes = configuration.attributes.filter(
                attribute => attribute.type === "image" || attribute.type === "file"
            );
            const parentIds = features.filter(f => !!f.properties.__atlas.parent).map(f => f.properties.__atlas.parent);
            const parents = await db.get(parentIds, true);

            if (fileAttributes.length > 0) {
                // Remove outdated files
                if (parents.length > 0) {
                    for (const parent of parents) {
                        const newFeature = features.find(f => f.properties.__atlas.parent === parent.id);
                        for (const attribute of fileAttributes) {
                            const existingFile = parent.properties[attribute._id] || {};
                            const newFile = newFeature.properties[attribute._id] || {};
                            if (existingFile.id && existingFile.id !== newFile.id) {
                                console.log(
                                    `File "${existingFile.id}" of feature "${parent.id}" is outdated. Removing.`
                                );
                                await this.removeLocalFile(existingFile.id);
                            }
                        }
                    }
                }

                // Mark files for download
                const workspaces = await this._atlasDB.getAll("workspaces");
                const shouldDownloadFiles = workspaces.some(
                    workspace => workspace.layers.includes(layerId) && workspace?.__localData?.offline
                );
                if (shouldDownloadFiles) {
                    const currentFeatureVersions = features.filter(feature => !feature.properties.__atlas.child);
                    const files = this._getFilesFromFeatures(currentFeatureVersions, fileAttributes);
                    await this._markFilesForDownload(files, workspaceId);
                }
            }

            for (let i = features.length - 1; i >= 0; i--) {
                const feature = features[i];
                if (!feature.properties.__atlas.child) {
                    feature.properties.__atlas.child = 0;
                }
                if (feature.properties.__atlas.parent) {
                    const parent = parents.find(parent => parent.id === feature.properties.__atlas.parent);
                    if (parent) {
                        console.log(`Received newer version of "${parent.id}". Replacing with "${feature.id}".`);
                        parent.properties.__atlas.child = feature.id;
                        changes.updateProperties.push(parent);
                        changes.removeFromRTree.push(parent);
                    }
                }
            }
        }

        // Delete features
        if (deleted.length > 0) {
            const existingFeatures = await db.get(deleted, true);
            for (const feature of existingFeatures) {
                feature.properties.__atlas.deleted = true;
                changes.updateProperties.push(feature);
                changes.removeFromRTree.push(feature);
            }
        }

        // Update timestamp & lastFetchId
        const localLastFetchDate = await db.getMeta("lastFetchDate");
        const lastFetchDate = new Date(lastModifiedDate || timestamp).getTime();
        if (!localLastFetchDate || localLastFetchDate <= lastFetchDate) {
            changes.meta.push({ lastFetchDate });
        }
        if (timestamp) {
            changes.meta.push({ initTimestamp: timestamp });
        }
        if (lastId) {
            changes.meta.push({ lastFetchId: lastId });
        }
        if (lastInBatch) {
            changes.meta.push({ upToDate: true });
            changes.meta.push({ hasBeenUpToDate: true });
            if (workspaceAreaId) {
                changes.meta.push({ workspaceAreaId });
            }
        } else {
            changes.meta.push({ upToDate: false });
        }

        await db.performChanges(changes);

        this.emit(`change-${layerId}-${workspaceId}`, {
            layerId,
            features,
            deleted
        });
    }

    async _handleFeatureChangeNudge(event) {
        // Remove all nudges in the sync channel queue for this workspace-layer combination
        this._syncChannelQueue = this._syncChannelQueue.filter(e => {
            return !(e.type === "feature" && e.workspaceId === event.workspaceId && e.layerId === event.layerId);
        });
        try {
            const db = await this.getVectorDB(event.layerId, event.workspaceId);
            const lastFetchDate = await db.getMeta("lastFetchDate");
            const lastFetchId = await db.getMeta("lastFetchId");
            const workspaceAreaId = await db.getMeta("workspaceAreaId");
            const featureChanges = await Api.getFeatureChanges(
                event.workspaceId,
                event.layerId,
                lastFetchDate,
                lastFetchId,
                true,
                null,
                workspaceAreaId
            );
            await this._handleFeatureChanges(featureChanges);
        } catch (error) {
            console.logError(error, "Error handling feature change nudge");
        }
    }

    async _downloadFilesMarkedForDownload() {
        if (this._downloadingFiles) {
            return;
        }
        this._downloadingFiles = true;
        const filesToDownload = await this._atlasDB.query("shouldDownload", { $eq: 1 }, undefined, "files");
        if (filesToDownload.length === 0) {
            this._downloadingFiles = false;
            return;
        }
        for (const idbFile of filesToDownload) {
            console.log(`Downloading file "${idbFile.id}".`);
            idbFile.file = await Api.downloadFile(idbFile.id, idbFile.workspaceId);
            delete idbFile.shouldDownload;
            await this._atlasDB.updateObject(idbFile, "files");
        }
        this._downloadingFiles = false;
        this._downloadFilesMarkedForDownload();
    }

    async _removeLayerFromWorkspace(layerId, workspaceId) {
        console.log("removing layer", layerId);
        const layer = await this._atlasDB.getObject(layerId, "layers");
        if (isVectorLayer(layer)) {
            await this._deleteVectorDB(layerId, workspaceId);
        } else if (isRasterLayer(layer)) {
            await this._deleteTileDB(layerId, workspaceId);
        }
    }

    async _ensureLayerConfigurationExists(layerId, workspaceId) {
        let layer = await this._atlasDB.getObject(layerId, "layers");
        if (!layer) {
            layer = await Api.getLayer(layerId, workspaceId);
            await this._saveLayerConfiguration(layer);
        }
    }

    async _deleteFeaturesOutsideArea(workspace, newArea) {
        const configuration = await this._convertToWorkspaceConfiguration(workspace);
        for (const layer of configuration.layers.filter(isVectorLayer)) {
            const id = `${workspace._id}:${layer._id}`;
            const db = this._vectorDBs.get(id);
            if (!db) {
                continue;
            }
            // get bbox
            const bbox = await db.bbox();
            if (bbox.includes(Infinity)) {
                // There was no previous bbox (empty layer), so there can't be features to delete
                continue;
            }
            const bboxFeature = {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [
                        [
                            [bbox[0], bbox[1]],
                            [bbox[2], bbox[1]],
                            [bbox[2], bbox[3]],
                            [bbox[0], bbox[3]],
                            [bbox[0], bbox[1]]
                        ]
                    ]
                }
            };
            // subtract new area
            const oldArea = difference(bboxFeature, newArea);
            // get features covered by
            let featuresToDelete = await db.featuresCoveredByFeature(oldArea);
            // filter out features touching new area
            featuresToDelete = featuresToDelete.filter(feature => !touches(feature, newArea));
            // remove remaining features
            for (const feature of featuresToDelete) {
                await db.remove(feature.id);
            }
            const deleted = featuresToDelete.map(feature => feature.id);
            this.emit(`change-${layer._id}-${workspace._id}`, { deleted });
        }
    }

    async _handleWorkspaceChanges(event) {
        console.log(`_handleWorkspaceChanges, workspaceId: ${event?.workspace?._id}`);
        const previousWorkspace = await this._atlasDB.getObject(event.workspace._id, "workspaces");
        if (!previousWorkspace) {
            throw new Error(`Reveived workspace change event for unwanted workspace "${event.workspace._id}".`);
        }
        // See if layers were removed
        for (const layerId of previousWorkspace.layers) {
            if (!event.workspace.layers.find(id => id === layerId)) {
                console.log(`_handleWorkspaceChanges: layer ${layerId} was removed.`);
                await this._removeLayerFromWorkspace(layerId, event.workspace._id);
            }
        }
        // See if area changed
        const hasArea = event.workspace.area?.type === "Feature";
        const areaChanged = hasArea && JSON.stringify(event.workspace.area) !== JSON.stringify(previousWorkspace.area);
        if (areaChanged) {
            console.log("_handleWorkspaceChanges: Area changed, deleting features outside new area.");
            await this._deleteFeaturesOutsideArea(previousWorkspace, event.workspace.area);
        }
        // See if layers were added
        let anyLayerAdded = false;
        for (const layerId of event.workspace.layers) {
            let added = false;
            if (!previousWorkspace.layers.find(id => id === layerId)) {
                console.log(`_handleWorkspaceChanges: Layer ${layerId} was added.`);
                await this._ensureLayerConfigurationExists(layerId, event.workspace._id);
                added = true;
                anyLayerAdded = true;
            }
            const layer = await this.getLayer(layerId, previousWorkspace._id);
            if (added) {
                await this._createDatabaseForLayerConfiguration(layer, previousWorkspace._id);
            }
            if (isRasterLayer(layer) && (added || !hasArea || areaChanged)) {
                const tileDB = await this.getTileDB(layerId, previousWorkspace._id);
                tileDB.updateTileDescriptions(!previousWorkspace.__localData?.offline);
            }
        }
        // Keep local data
        event.workspace.__localData = previousWorkspace.__localData ?? {};
        // Save changes
        await this._atlasDB.updateObject(event.workspace, "workspaces");

        if (anyLayerAdded) {
            const configuration = await this._convertToWorkspaceConfiguration(event.workspace);
            this._subscribeToWorkpace(configuration);
        }

        this.emit(`change-${event.workspace._id}`);
        this.emit("workspaceChange", event.workspace);
    }

    async _saveLayerConfiguration(layer) {
        const previousLayer = await this._atlasDB.getObject(layer._id, "layers");
        // Keep local data
        layer.__localData = previousLayer?.__localData ?? {};
        await this._atlasDB.updateObject(layer, "layers");
    }

    async _handleLayerChanges(event) {
        await this._saveLayerConfiguration(event.layer);
        for (const [, tileDB] of this._tileDBs.entries()) {
            if (tileDB.layer === event.layer._id) {
                tileDB.updateTileDescriptions();
            }
        }
        this.emit("layerChange", event.layer);
    }

    _handleChangeEvent(event) {
        this._syncChannelQueue.push(event);
        let message = `Adding event ${event.sequence} to processing queue (length: ${this._syncChannelQueue.length})`;
        if (["point", "line", "polygon"].includes(event.type)) {
            message += ` (${(event.features || []).length} new/updated, ${(event.deleted || []).length} deleted)`;
        }
        console.log(message);
        this._startSyncChannelQueue();
    }

    async _startSyncChannelQueue() {
        if (this._syncChannelQueueInProgress) {
            return;
        }
        this._syncChannelQueueInProgress = true;
        const event = this._syncChannelQueue.shift();
        if (event) {
            console.group(`Processing sync channel event ${event.sequence} (type: ${event.type}).`);
            try {
                if (event.type === "feature") {
                    await this._handleFeatureChangeNudge(event);
                } else if (["point", "line", "polygon"].includes(event.type)) {
                    await this._handleFeatureChanges(event);
                } else if (event.type === "workspace") {
                    await this._handleWorkspaceChanges(event);
                } else if (event.type === "layer") {
                    await this._handleLayerChanges(event);
                } else {
                    console.warn(`Unhandled event of type "${event.type}"`, event);
                }
                console.log(`${event.sequence} done.`);
            } catch (err) {
                console.logError(err, "_startSyncChannelQueue error");
                console.error(event);
            } finally {
                console.groupEnd();
                this._syncChannelQueueInProgress = false;
                this._startSyncChannelQueue();
            }
            this.emit("sync-status-change");
        } else {
            this._syncChannelQueueInProgress = false;
        }
    }

    async addFeaturesFromGeoJSON(layer, workspace, features) {
        const db = await this.getVectorDB(layer._id, workspace._id);

        for (let i = features.length - 1; i >= 0; i--) {
            const feature = features[i];
            const now = Date.now();
            feature.properties.__atlas = {
                localModified: now,
                createdInWorkspace: workspace._id,
                created: {
                    date: now,
                    by: localStorage.getItem("hostname")
                },
                modified: {
                    date: now,
                    by: localStorage.getItem("hostname")
                }
            };
            const importedId = feature.id;
            feature.id = ObjectId().toString();
            feature.properties.__atlas.originalId = feature.id;
            feature.properties.__atlas.child = 0;
            if (importedId) {
                const existingFeatures = await db.query({
                    "__atlas.originalId": { $eq: importedId }
                });
                const lastExistingVersion = existingFeatures.find(feature => !feature.properties.__atlas.child);
                if (lastExistingVersion) {
                    feature.properties.__atlas.originalId = importedId;
                    feature.properties.__atlas.parent = lastExistingVersion.id;
                    feature.properties.__atlas.created = lastExistingVersion.properties.__atlas.created;
                    feature.properties.__atlas.modifiedInWorkspace = workspace._id;
                    delete feature.properties.__atlas.createdInWorkspace;

                    lastExistingVersion.properties.__atlas.child = feature.id;
                    features.push(lastExistingVersion);
                    console.log(`Imported newer version of "${importedId}".`);
                } else {
                    console.log(
                        `Feature "${importedId}" is not present in Atlas database, it was assigned a new id: "${feature.id}".`
                    );
                }
            }
        }
        await db.bulkInsert(features, true);
        this.sendLocalModifiedFeatures(layer._id, workspace._id);
        const event = { layerId: layer._id, features };
        this.emit(`change-${event.layerId}-${workspace._id}`, event);
    }

    async getLayer(layerId, workspaceId = null) {
        let layer = await this.getLocalLayer(layerId);
        if (!layer) {
            console.log(`Local db for layer "${layerId}" not found, fetching from server.`);
            layer = await Api.getLayer(layerId, workspaceId);
        }
        if (!layer) {
            throw new Error(`Could not fetch configuration for layer "${layerId}".`);
        }
        return layer;
    }

    async updateLayer(layer) {
        layer.__localData = layer.__localData || {};
        await this._atlasDB.updateObject(layer, "layers");
        for (const [, tileDB] of this._tileDBs.entries()) {
            if (tileDB.layer === layer._id) {
                tileDB.updateTileDescriptions();
            }
        }
    }

    async updateWorkspace(workspace) {
        const oldWorkspace = await this._atlasDB.getObject(workspace._id, "workspaces");
        await this._atlasDB.updateObject(workspace, "workspaces");
        if (workspace.__localData?.offline) {
            const configuration = await this.getWorkspaceConfiguration(workspace._id);
            for (const layer of configuration.layers) {
                // createDatabaseForLayerConfiguration already runs in the subscribe function,
                // but if the layer didn't exist at the time when the workspace was set to offline, then
                // the layer database entry does not exist - so let's create it here
                await this._createDatabaseForLayerConfiguration(layer, configuration._id);
            }
            const areaChanged = JSON.stringify(workspace.area ?? {}) !== JSON.stringify(oldWorkspace.area ?? {});
            for (const [, tileDB] of this._tileDBs.entries()) {
                if (tileDB.workspace._id === workspace._id) {
                    if (workspace.layers.includes(tileDB.layer)) {
                        tileDB.download(areaChanged);
                    } else {
                        // Layer was removed from workspace
                        await this._deleteTileDB(tileDB.layer._id, tileDB.workspace._id);
                    }
                }
            }
        }
    }

    async getLocalFile(id) {
        const object = await this._atlasDB.getObject(id, "files");
        if (object) {
            return object.file;
        } else {
            return null;
        }
    }

    async removeLocalFile(id) {
        return this._atlasDB.delete(id, "files");
    }

    async saveLocalFile(idbFile, workspaceId) {
        await this._atlasDB.updateObject(idbFile, "files");
        this.sendLocalModifiedFeatures(idbFile.layerId, workspaceId);
    }

    async uploadLocalFile(idbFile) {
        const { file, id, layerId } = idbFile;
        const result = await Api.uploadFile(file, id, layerId);
        if (!result || result.status !== "ok") {
            // TODO: Handle merge conflicts and other potential errors
            // If offline, fail silently. Files marked as local modified
            // in local database will be sent to server again before next
            // init call
            return;
        }

        const workspaces = await this._atlasDB.getAll("workspaces");
        const offline = workspaces.some(workspace => workspace.__localData?.offline);
        if (offline) {
            console.log(`Upload of file "${file.name}" complete.`);
            delete idbFile.localModified;
            await this._atlasDB.updateObject(idbFile, "files");
        } else {
            console.log(`Upload of file "${file.name}" complete, deleting local copy.`);
            await this.removeLocalFile(id);
        }
    }
}

export default DBManager;

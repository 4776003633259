import React, { useRef } from "react";

const ACTIVATE = false;

function RenderCount({ label }) {
    const count = useRef(0);

    if (!ACTIVATE) {
        return null;
    }

    return (
        <p style={{ color: "red", backgroundColor: "black" }}>
            {label && `${label}: `}
            {++count.current}
        </p>
    );
}

export default RenderCount;

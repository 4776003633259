import React, { forwardRef } from "react";
import classNames from "classnames";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    warning: {
        "& label, & fieldset, & .MuiFormHelperText-root": {
            color: theme.colors.warning,
            borderColor: theme.colors.warning
        }
    }
}));

/**
 * Material-UI TextField with an additional "warning" property,
 * similar to the regular "error" property, that's all.
 */
function WarnableTextField({ warning, className, children, ...props }, ref) {
    const classes = useStyles();
    return (
        <TextField inputRef={ref} className={classNames({ [classes.warning]: warning }, className)} {...props}>
            {children}
        </TextField>
    );
}

export default forwardRef(WarnableTextField);

import React from "react";
import classNames from "classnames";
import ForanIcons from "foran-icons";
import { getRandomIcon, getRandomBackgroundIcon } from "../workspace/map/styles/DefaultStyle";
import IconDisplay from "./IconDisplay";
import OutlinedContainer from "../OutlinedContainer";
import Color from "./Color";
import Integer from "./Integer";
import OverrideWrapper from "./OverrideWrapper";

import { List, ListSubheader, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const { backgrounds, ...icons } = ForanIcons;

const useStyles = makeStyles(theme => ({
    categoryContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    categoryHeader: {
        padding: 0,
        backgroundColor: "white"
    },
    iconContainer: {
        paddingTop: theme.spacing(1),
        display: "flex",
        flexWrap: "wrap"
    },
    icon: {
        boxSizing: "border-box",
        cursor: "pointer",
        border: "3px solid transparent",
        width: "50px",
        height: "50px",

        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.2)"
        },

        "&.selected": {
            borderColor: "black"
        },

        "&:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    none: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center"
    },
    disabledText: {
        color: theme.colors.disabledText
    }
}));

function IconPicker({ className, icon = {}, onChange }) {
    const classes = useStyles();

    const handleChange = key => value =>
        onChange(icon => ({ ...icon, [key]: typeof value === "function" ? value(icon[key]) : value }));

    return (
        <div className={className}>
            <Integer
                label="Background size"
                value={icon.backgroundSize}
                defaultValue={30}
                onChange={handleChange("backgroundSize")}
            />
            <Color
                label="Background color"
                color={icon.backgroundColor}
                opacity={icon.backgroundOpacity}
                onColorChange={handleChange("backgroundColor")}
                onOpacityChange={handleChange("backgroundOpacity")}
            />
            {typeof icon.backgroundRotation === "object" ? (
                <OutlinedContainer label="Background rotation (degrees)">
                    <Typography className={classes.disabledText} variant="overline">
                        Custom
                    </Typography>
                </OutlinedContainer>
            ) : (
                <Integer
                    label="Background rotation (degrees)"
                    value={icon.backgroundRotation}
                    defaultValue={0}
                    onChange={handleChange("backgroundRotation")}
                />
            )}
            <OutlinedContainer label="Background">
                <OverrideWrapper
                    inherited={icon.backgroundId === undefined}
                    onOverride={() => handleChange("backgroundId")(getRandomBackgroundIcon().id)}
                    onDelete={() => handleChange("backgroundId")(undefined)}
                >
                    <div className={classes.iconContainer}>
                        <div
                            className={classNames(classes.icon, classes.none, {
                                selected: icon.backgroundId === null
                            })}
                            onClick={() => handleChange("backgroundId")(null)}
                        >
                            <Typography className={classes.disabledText} variant="overline">
                                none
                            </Typography>
                        </div>
                        {backgrounds.map((iconOption, index) => (
                            <IconDisplay
                                key={index}
                                icon={iconOption}
                                className={classNames(classes.icon, { selected: iconOption.id === icon.backgroundId })}
                                color={icon.backgroundColor}
                                opacity={icon.backgroundOpacity}
                                onClick={() => handleChange("backgroundId")(iconOption.id)}
                            >
                                <Typography className={classes.disabledText} variant="overline">
                                    none
                                </Typography>
                            </IconDisplay>
                        ))}
                    </div>
                </OverrideWrapper>
            </OutlinedContainer>
            <Integer label="Icon size" value={icon.size} defaultValue={20} onChange={handleChange("size")} />
            <Color
                label="Icon color"
                color={icon.color}
                opacity={icon.opacity}
                onColorChange={handleChange("color")}
                onOpacityChange={handleChange("opacity")}
            />
            {typeof icon.rotation === "object" ? (
                <OutlinedContainer label="Icon rotation (degrees)">
                    <Typography className={classes.disabledText} variant="overline">
                        Custom
                    </Typography>
                </OutlinedContainer>
            ) : (
                <Integer
                    label="Icon rotation (degrees)"
                    value={icon.rotation}
                    defaultValue={0}
                    onChange={handleChange("rotation")}
                />
            )}
            <OutlinedContainer className={classes.iconContainer} label="Icon">
                <OverrideWrapper
                    inherited={icon.id === undefined}
                    onOverride={() => handleChange("id")(getRandomIcon().id)}
                    onDelete={() => handleChange("id")(undefined)}
                >
                    <List>
                        <div
                            className={classNames(classes.icon, classes.none, {
                                selected: icon.id === null
                            })}
                            onClick={() => handleChange("id")(null)}
                        >
                            <Typography className={classes.disabledText} variant="overline">
                                none
                            </Typography>
                        </div>
                        {Object.keys(icons).map(category => (
                            <div key={category} className={classes.categoryContainer}>
                                <ListSubheader className={classes.categoryHeader}>{category}</ListSubheader>
                                <div>
                                    {icons[category].map((iconOption, index) => (
                                        <IconDisplay
                                            key={index}
                                            icon={iconOption}
                                            className={classNames(classes.icon, {
                                                selected: iconOption.id === icon.id
                                            })}
                                            color={icon.color}
                                            opacity={icon.opacity}
                                            onClick={() => handleChange("id")(iconOption.id)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </List>
                </OverrideWrapper>
            </OutlinedContainer>
        </div>
    );
}

export default React.memo(IconPicker, (prevProps, nextProps) => prevProps.icon === nextProps.icon);

import { memo, useContext, useMemo, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { FormContext } from "./Form";
import _get from "lodash/get";
import Field from "./Field";
import OutlinedContainer from "../OutlinedContainer";
import RenderCount from "../RenderCount";

import { Button, IconButton, Typography } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    fieldContainer: {
        display: "flex",
        alignItems: "center",

        "& > *:first-child": {
            flexGrow: 1
        }
    },
    emptyLabel: {
        color: theme.colors.disabled,
        fontStyle: "italic",
        marginTop: theme.spacing(1)
    },
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

const getDefaultValue = (schema, initialValues, index) => {
    switch (schema.type) {
        case "custom":
            // react-hook-form will populate new array objects with the default values specified in useForm every time.
            // setting the entire object to null does not help, we must set each key to null individually
            const initialValue = _get(initialValues, `${schema.key}.${index}`) ?? {};
            const initialKeys = Object.keys(initialValue);
            return initialKeys.reduce((obj, key) => ({ ...obj, [key]: null }), {});
        default:
            // react-hook-form does not support flat arrays
            return { value: null };
    }
};

function ArrayField({ emptyLabel, addLabel, ...props }) {
    const { schema, disabled } = props;
    const classes = useStyles();
    const fieldArray = useMemo(() => ({ name: schema.key }), [schema.key]);
    const { fields, append, remove } = useFieldArray(fieldArray);
    const { initialValues } = useContext(FormContext);

    const [newIndices, setNewIndices] = useState(new Set());
    const handleAdd = () => {
        setNewIndices(indices => new Set([...indices, fields.length]));
        append(getDefaultValue(schema, initialValues, fields.length), { shouldFocus: false });
    };
    const handleRemove = index => {
        setNewIndices(indices => {
            const newIndices = new Set();
            for (const i of indices) {
                // Shift indices above
                if (i > index) {
                    newIndices.add(i - 1);
                }
                if (i < index) {
                    newIndices.add(i);
                }
            }
            return newIndices;
        });
        remove(index);
    };

    return (
        <OutlinedContainer label={schema.pluralTitle ?? schema.title}>
            <RenderCount label="ArrayField" />
            {fields.length === 0 && emptyLabel && (
                <Typography variant="caption" className={classes.emptyLabel} component="p">
                    {emptyLabel}
                </Typography>
            )}
            {fields.map((field, index) => {
                const name = `${schema.key}.${index}${schema.type !== "custom" ? ".value" : ""}`;
                return (
                    <div key={field.id} className={classes.fieldContainer}>
                        <Field
                            index={index}
                            name={name}
                            onRemoveFromArray={() => handleRemove(index)}
                            isNew={newIndices.has(index)}
                            {...props}
                        />
                        {schema.type !== "custom" && (
                            <IconButton disabled={disabled} onClick={() => handleRemove(index)} size="large">
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </div>
                );
            })}
            <Button
                className={classes.addButton}
                color="primary"
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={disabled}
                onClick={handleAdd}
            >
                {addLabel ?? "Add"}
            </Button>
        </OutlinedContainer>
    );
}

export default memo(ArrayField);

import React, { useContext, useState, useEffect, useCallback } from "react";
import { AtlasContext } from "./atlas-context";
import { ErrorContext } from "./error-context";
import useOnline from "./useOnline";
import Api from "../Api";
import FileList from "./FileList";
import Container from "./Container";
import CenteredCircularProgress from "./CenteredCircularProgress";

import { Typography, Link, Paper, Breadcrumbs, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        position: "relative"
    },
    upOneLevelButton: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1)
    },
    textDefault: {
        color: theme.palette.text.primary
    },
    textDisabled: {
        color: theme.palette.text.disabled,
        pointerEvents: "none"
    },
    breadcrumbsMargin: {
        marginLeft: "3px",
        marginRight: "3px"
    },
    dividerMargin: {
        marginTop: "0.5em"
    }
}));

function DirectoryContents({ className, directory, forceReload, types, onOpenContent, disableFile }) {
    const { setError } = useContext(ErrorContext);

    const classes = useStyles();

    const { dbManager } = useContext(AtlasContext);
    const online = useOnline();
    // Used to refresh current directory when FileList changes
    const [revisionCounter, setRevisionCounter] = useState(0);

    const [contents, setContents] = useState(null);
    const [crumbs, setBreadcrumbs] = useState(null);

    const setContentsWithLocalData = useCallback(
        async contents => {
            // Include local data if available
            for (let file of contents) {
                let localFile;
                switch (file.type) {
                    case "layer":
                        localFile = await dbManager.getLocalLayer(file._id);
                        break;
                    case "workspace":
                        localFile = await dbManager.getLocalWorkspace(file._id);
                        break;
                    default:
                        break;
                }
                if (localFile) {
                    file.__localData = localFile.__localData;
                }
            }
            setContents(contents);
        },
        [dbManager]
    );

    useEffect(() => {
        const setter = () => setContentsWithLocalData(contents);
        dbManager.on("offline-workspace-change", setter);
        return () => {
            dbManager.off("offline-workspace-change", setter);
        };
    }, [contents, dbManager, setContentsWithLocalData]);

    useEffect(() => {
        let cancelled = false;
        const fetchContents = () => {
            setContents(null);
            Api.getDirectoryContents(directory._id, types)
                .then(contents => {
                    if (!cancelled) {
                        setContentsWithLocalData(contents);
                    }
                })
                .catch(error => {
                    if (!cancelled) {
                        setError(new Error("Error fetching directory contents: " + error.message));
                    }
                });
            Api.getBreadcrumbs(directory._id)
                .then(breadcrumbs => {
                    if (!cancelled) {
                        breadcrumbs.reverse();
                        setBreadcrumbs(breadcrumbs);
                    }
                })
                .catch(error => {
                    if (!cancelled) {
                        setError(new Error("Error fetching breadcrumbs: " + error.message));
                    }
                });
        };
        setContents(null);
        setBreadcrumbs(null);
        directory && directory._id && fetchContents();
        return () => {
            cancelled = true;
        };
    }, [directory, forceReload, revisionCounter, setContentsWithLocalData, setError, types]);

    const onClickedBreadcrumb = async (dirId, event) => {
        event.preventDefault();
        const c = [];
        for (const dir of crumbs) {
            c.push(dir);
            if (dir.dirId === dirId) {
                break;
            }
        }
        onOpenContent({ type: "directory", _id: c[c.length - 1].dirId });
    };

    return (
        <Container className={className}>
            <Paper className={classes.container}>
                {crumbs && crumbs.length > 0 && (
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        classes={{ separator: classes.breadcrumbsMargin }}
                        separator={<NavigateNextIcon fontSize="small" />}
                    >
                        {crumbs.map((crumb, index) => {
                            return index === crumbs.length - 1 ? (
                                <Typography key={`${crumb.dirId}-${index}`}>{crumb.dirname}</Typography>
                            ) : (
                                <Link
                                    className={online ? classes.textDefault : classes.textDisabled}
                                    key={`${crumb.dirId}-${index}`}
                                    onClick={e => onClickedBreadcrumb(crumb.dirId, e)}
                                    underline="hover"
                                >
                                    {crumb.dirname}
                                </Link>
                            );
                        })}
                    </Breadcrumbs>
                )}
                <Divider className={classes.dividerMargin} />
                {directory && contents ? (
                    <React.Fragment>
                        <FileList
                            disabled={!online}
                            disableFile={disableFile}
                            files={contents}
                            fileItemProps={{ onClick: onOpenContent }}
                            disableMenu
                            onChange={() => setRevisionCounter(c => c + 1)}
                            containingDirectory={directory}
                        />
                    </React.Fragment>
                ) : (
                    <CenteredCircularProgress />
                )}
            </Paper>
        </Container>
    );
}

export default DirectoryContents;

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useController } from "react-hook-form";
import { FormContext } from "./Form";
import _get from "lodash/get";

export const useFieldController = props => {
    const { field: _field, ...controller } = useController({ shouldUnregister: true, ...props });
    const originalOnChange = _field.onChange;

    const { initialValues } = useContext(FormContext);
    const [userEdited, setUserEdited] = useState(false);

    useEffect(() => {
        // react-hook-form does not update the form values if the default value changes
        // Only update to the new default value if the initial value was empty and
        // the user hasn't made any changes manually
        const initialValue = _get(initialValues, props.name);
        if ([null, undefined].includes(initialValue) && !userEdited) {
            originalOnChange(props.defaultValue);
        }
    }, [props.name, initialValues, userEdited, props.defaultValue, originalOnChange]);
    const handleChange = useCallback(
        (...args) => {
            setUserEdited(true);
            originalOnChange(...args);
        },
        [originalOnChange]
    );

    const field = useMemo(() => ({ ..._field, onChange: handleChange }), [_field, handleChange]);

    return { field, ...controller };
};

import React, { useContext, useEffect, useRef, useState, memo } from "react";
import { LoadingContext } from "../../../loading-context";
import { WorkspaceContext } from "../../workspace-context";
import { AtlasContext } from "../../../atlas-context";
import { useLayerVisible } from "../../../useLayerVisible";
import { useLayerZIndex } from "../../../useLayerZIndex";
import { useLayerOpacity } from "../../../useLayerOpacity";

import OLVectorLayer from "../openlayers/layers/VectorLayer";
import { MapContext } from "../openlayers/map-context";
import VectorSource from "ol/source/Vector";
import { GeoJSON } from "ol/format";
import { unByKey } from "ol/Observable";

import { createCombinedStyle, createOLStyles } from "../styles/styles";
import { getFeatureLabelText } from "../styles/feature-label-text";

function VectorLayer({ configuration, ...props }) {
    const { startLoading, stopLoading } = useContext(LoadingContext);
    const { configuration: workspace } = useContext(WorkspaceContext);
    const { dbManager } = useContext(AtlasContext);
    const { map } = useContext(MapContext);
    const [resolution, setResolution] = useState(map.getView().getResolution());

    const styleCache = useRef({});
    const format = useRef(new GeoJSON());
    const source = useRef(new VectorSource({ strategy: extent => [extent] }));
    const [styleFunction, setStyleFunction] = useState(() => null);

    const visible = useLayerVisible(configuration);
    const zIndex = useLayerZIndex(configuration);
    const opacity = useLayerOpacity(configuration);

    useEffect(() => {
        const listener = map.getView().on("change:resolution", event => {
            setResolution(event.target.getResolution());
        });
        return () => {
            unByKey(listener);
        };
    }, [map]);

    useEffect(() => {
        source.current.setLoader(async (extent, resolution, projection, success, failure) => {
            const loadingId = startLoading();
            try {
                const db = await dbManager.getVectorDB(configuration._id, workspace._id);
                const features = (await db.featuresIntersecting(extent)).filter(
                    f => !f.properties.__atlas.deleted && !f.properties.__atlas.child
                );
                const olFeatures = format.current.readFeatures({
                    type: "FeatureCollection",
                    crs: { type: "name", properties: { name: `${projection.getCode()}` } },
                    features: features
                });
                olFeatures.forEach(olFeature => (olFeature.projection = projection));
                source.current.addFeatures(olFeatures);
            } catch (e) {
                console.logError(e, "vectorSourceLoader");
                failure();
            }
            success();
            stopLoading(loadingId);
        });

        const handleExistingFeature = (existingFeature, newOLFeature) => {
            if (existingFeature) {
                if (existingFeature.editing) {
                    // User is currently editing this feature
                    existingFeature.geometryChanged(newOLFeature);
                    return true;
                } else {
                    source.current.removeFeature(existingFeature);
                }
            }
        };

        const handleFeatureChanges = event => {
            if (event.features && event.features.length > 0) {
                for (const feature of event.features) {
                    const olFeature = format.current.readFeature(feature);
                    const existingFeature = source.current.getFeatureById(feature.id);
                    let changed = handleExistingFeature(existingFeature, olFeature);
                    if (feature.properties.__atlas.parent) {
                        const existingParent = source.current.getFeatureById(feature.properties.__atlas.parent);
                        changed = handleExistingFeature(existingParent, olFeature);
                    }
                    if (!feature.properties.__atlas.child && !changed) {
                        source.current.addFeature(olFeature);
                    }
                }
            }
            if (event.deleted && event.deleted.length > 0) {
                for (const id of event.deleted) {
                    const existingFeature = source.current.getFeatureById(id);
                    if (existingFeature) {
                        source.current.removeFeature(existingFeature);
                    }
                }
            }
        };
        dbManager.on(`change-${configuration._id}-${workspace._id}`, handleFeatureChanges);
        return () => {
            dbManager.removeListener(`change-${configuration._id}-${workspace._id}`, handleFeatureChanges);
        };
    }, [configuration._id, workspace._id, startLoading, stopLoading, dbManager]);

    useEffect(() => {
        styleCache.current = {};
        const getStyleObjectForFeature = olFeature => {
            const style = createCombinedStyle(
                olFeature,
                map,
                configuration.__localData?.styles ?? configuration.styles
            );
            if (style?.label?.text) {
                style.label.text = getFeatureLabelText(olFeature, style.label.text, configuration.attributes);
            }
            return style;
        };
        setStyleFunction(() => olFeature => {
            const cached = styleCache.current[olFeature.getId()];
            const parent = olFeature.getProperties()?.__atlas?.parent;
            if (parent) {
                delete styleCache.current[parent];
            }
            if (cached && cached.revision === olFeature.getRevision()) {
                return cached.styles;
            }
            delete styleCache.current[olFeature.getId()];
            const style = getStyleObjectForFeature(olFeature);
            olFeature.originalStyle = getStyleObjectForFeature(olFeature);
            olFeature.recalculateOriginalStyle = () => {
                olFeature.originalStyle = getStyleObjectForFeature(olFeature);
            };
            const styles = createOLStyles(style);
            styleCache.current[olFeature.getId()] = {
                revision: olFeature.getRevision(),
                styles
            };
            return styles;
        });
    }, [configuration.__localData?.styles, configuration.styles, configuration.attributes, map, resolution]);

    return (
        <OLVectorLayer
            source={source.current}
            style={styleFunction}
            properties={{ type: "VECTOR", id: configuration._id, name: configuration.name, hitPriority: configuration.hitPriority }}
            visible={visible}
            opacity={opacity / 100}
            zIndex={zIndex}
            // OpenLayers won't check for null values, only undefined
            maxResolution={configuration.maxVisibleResolution || undefined}
            minResolution={configuration.minVisibleResolution || undefined}
            {...props}
        />
    );
}

export default memo(VectorLayer);

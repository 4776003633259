import React, { useState } from "react";

import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";

export const DrawerContext = React.createContext({ setOverlay: null, setOverlayTitle: null });

const useStyles = makeStyles(theme => ({
    overlay: {
        backgroundColor: "white",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
        zIndex: 10
    },
    controls: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(1)
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    content: {
        flexGrow: 1,

        // This is to fix a bug in Safari causing the children to the content div
        // to not take up the whole height even though it's set to height: 100%
        // https://github.com/philipwalton/flexbugs/issues/197#issuecomment-378908438
        height: 0
    }
}));

function Drawer({ children }) {
    const [overlay, setOverlay] = useState(null);
    const [overlayTitle, setOverlayTitle] = useState(null);
    const classes = useStyles();

    const reset = () => {
        setOverlay(null);
        setOverlayTitle(null);
    };

    return (
        <DrawerContext.Provider value={{ setOverlay, setOverlayTitle }}>
            {children}
            {overlay && (
                <div className={classes.overlay}>
                    <div className={classes.controls}>
                        {overlayTitle && <Typography variant="h6">{overlayTitle}</Typography>}
                        <div className={classes.buttons}>
                            <IconButton onClick={reset} size="large">
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.content}>{overlay}</div>
                </div>
            )}
        </DrawerContext.Provider>
    );
}

export default Drawer;

import { useEffect, useContext } from "react";
import { MapContext } from "../map-context";
import { PinchRotate as OLPinchRotate } from "ol/interaction";
import { getOptions } from "../utility";

const OPTIONS = ["duration", "threshold"];

function PinchRotate(props) {
    const { map } = useContext(MapContext);
    const { duration, threshold } = getOptions(props, OPTIONS);

    useEffect(() => {
        const interaction = new OLPinchRotate({ duration, threshold });
        map.addInteraction(interaction);
        return () => {
            map.removeInteraction(interaction);
        };
    }, [map, duration, threshold]);

    return null;
}

export default PinchRotate;

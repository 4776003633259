import { EventEmitter } from "events";

const LOG_TIME_LIMIT = 1_000 * 86_400 * 14; // 14 days
window.windowId = Date.now();

export async function initConsole(db) {
    const originalConsole = { ...window.console };
    let logQueue = [];
    let logging = false;
    const saveLog = async (args, type) => {
        const timestamp = Date.now();
        const log = {
            windowId: window.windowId,
            timestamp,
            type,
            message: args
                .map(arg => {
                    if (typeof arg === "object") {
                        try {
                            return JSON.stringify(arg);
                        } catch (_) {
                            return arg;
                        }
                    } else {
                        return arg;
                    }
                })
                .join(" ")
        };
        logQueue.push(log);
        if (logging) {
            return;
        }
        logging = true;
        let logs;
        try {
            while (logQueue.length) {
                logs = [...logQueue];
                logQueue = [];
                await db.addObjects(logs, "logs");
            }
        } catch (error) {
            originalConsole.error("Error saving logs", error);
            originalConsole.log("logs", JSON.stringify(logs));
        } finally {
            logging = false;
        }
    };
    window.logger = new EventEmitter();
    const log = async (args, type) => {
        originalConsole[type](...args);
        window.logger.emit("log", { type, logs: args.join(" ") });
        return saveLog(args, type);
    };
    window.console.log = async (...args) => log(args, "log");
    window.console.warn = async (...args) => log(args, "warn");
    window.console.error = async (...args) => log(args, "error");
    window.console.group = async (...args) => log(args, "group");
    window.console.groupCollapsed = async (...args) => log(args, "groupCollapsed");
    window.console.logError = async (error, message) => {
        originalConsole.error(`${message}: ${error}`);
        let errorDescription;
        if (typeof error === "object") {
            try {
                errorDescription = JSON.stringify(error, ["message", "name", "type", "stack"]);
            } catch (_) {
                errorDescription = error?.toString();
            }
        } else {
            errorDescription = error?.toString?.() ?? `unknown error type "${typeof error}"`;
        }
        return saveLog([message, errorDescription], "error");
    };

    // Remove old logs
    const timeLimit = Date.now() - LOG_TIME_LIMIT;
    let count = 0;
    db.forEach("logs", { $lt: timeLimit }, null, "timestamp", async (_, cursor) => {
        if (count === 0) {
            console.log("Deleting old logs...");
        }
        ++count;
        await db.deletePointedAtByCursor(cursor);
    })
        .then(() => {
            if (count > 0) {
                console.log(`Deleted ${count} old logs.`);
            }
        })
        .catch(error => {
            console.error("Error deleting old logs:", error);
        });
}

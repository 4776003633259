import React from "react";
import classNames from "classnames";

import { DialogContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(theme => ({
    content: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1)
        }
    }
}));

function MobileFriendlyDialogContent({ children, className, ...props }) {
    const classes = useStyles();
    return (
        <DialogContent className={classNames(classes.content, className)} {...props}>
            {children}
        </DialogContent>
    );
}

export default MobileFriendlyDialogContent;

import React, { useContext } from "react";
import { AtlasContext } from "./atlas-context";
import { ErrorContext } from "./error-context";
import { removeAll } from "./Settings";
import DeleteButton from "./DeleteButton";

import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";

function RemoveAllDataDialog({ open, onClose }) {
    const { syncChannel } = useContext(AtlasContext);
    const { setError } = useContext(ErrorContext);

    const handleRemove = async () => {
        try {
            await syncChannel._disconnect();
            await removeAll(true);
        } catch (error) {
            console.logError(error, "Remove all data failed");
            setError(error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Really remove everything?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This will forcibly remove all data related to Atlas from this device WITHOUT checking if all changes
                    are synced or saved.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <DeleteButton onClick={handleRemove}>Delete all</DeleteButton>
            </DialogActions>
        </Dialog>
    );
}

export default RemoveAllDataDialog;

import React, { memo, useEffect, useState } from "react";
import { colors } from "../../resources/colors";
import { useWatch } from "react-hook-form";
import jsonLogic from "../json-logic/json-logic";

import CalculatedFieldUpdater from "./CalculatedFieldUpdater";
import OutlinedContainer from "../OutlinedContainer";

import { Typography } from "@mui/material";

function CalculatedField({ schema, attributeDefinitions, className, sx, onChange }) {
    const [result, setResult] = useState(null);
    const [error, setError] = useState(false);
    const values = useWatch();

    useEffect(() => {
        onChange?.(result);
    }, [result, onChange]);

    useEffect(() => {
        const valuesByAttributeName = Object.keys(values).reduce((formValues, key) => {
            const attribute = attributeDefinitions?.find(a => a._id === key);
            if (attribute) {
                formValues[attribute.name] = values[key];
            }
            return formValues;
        }, {});

        try {
            const rule = JSON.parse(schema.calculation);
            const data = { ...values, ...valuesByAttributeName };
            const result = jsonLogic.apply(rule, data);
            if (Number.isNaN(result)) {
                setResult(null);
            } else {
                setResult(result);
            }
            setError(false);
        } catch (e) {
            console.logError(e, `CalculatedField: Could not parse calculation schema "${schema.calculation}"`);
            setResult("Error: Could not perform calculation");
            setError(true);
        }
    }, [values, schema.calculation, attributeDefinitions]);

    const getColor = () => {
        if (!!error) {
            return colors.error;
        }
        if ([null, undefined, ""].includes(result)) {
            return "#e0e0e0";
        }
        return "text.disabled";
    };

    return (
        <OutlinedContainer label={schema.title} className={className} sx={sx}>
            <Typography sx={{ color: getColor(), my: 1 }}>
                {[null, undefined, ""].includes(result) ? "NULL" : String(result)}
            </Typography>
            {schema.key && <CalculatedFieldUpdater result={result} name={schema.key} />}
        </OutlinedContainer>
    );
}

export default memo(CalculatedField);

import React from "react";

function Compass(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <path
                        fill="#404040"
                        d="M16 30C8.268 30 2 23.732 2 16S8.268 2 16 2s14 6.268 14 14-6.268 14-14 14zm0-2c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12zm0 0"
                    ></path>
                    <path
                        fill="red"
                        d="M16 5s4 8.79 4 11a4 4 0 11-8 0c0-2.21 4-11 4-11zm0 13a2 2 0 100-4 2 2 0 000 4zm0 0"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default Compass;

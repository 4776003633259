import { forwardRef, useCallback } from "react";
import classNames from "classnames";
import WarnableTextField from "../uncontrolled-forms/WarnableTextField";

import { Box, IconButton } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    container: {
        position: "relative",

        "&.fullWidth": {
            width: "100%"
        }
    },
    textField: {
        width: "100%"
    },
    indent: {
        paddingLeft: "40px"
    },
    noIndent: {
        paddingLeft: "0",
        width: "unset"
    },
    button: {
        position: "absolute",
        top: "16px",
        transform: "translateY(-50%)",
        touchAction: "manipulation",

        "&.outlined": {
            top: "28px"
        },

        "&.left": {
            left: 0
        },
        "&.right": {
            right: 0
        }
    }
});

const isNumberInProgress = (val, integer) => {
    const regex = integer ? /^-?[0-9]*$/ : /^-?[0-9]*[,.]?[0-9]*$/;
    return typeof val === "string" && regex.test(val);
};

export const validateNumber = (value, { min, max } = {}) => {
    const valueAsNumber = Number(value);
    if (
        value?.endsWith?.(".") ||
        value?.endsWith?.(",") ||
        value?.endsWith?.("-") ||
        (value !== undefined && Number.isNaN(valueAsNumber))
    ) {
        return "Invalid number format";
    }
    if (typeof min === "number" && valueAsNumber < min) {
        return `Smaller than minimum allowed (${min})`;
    }
    if (typeof max === "number" && valueAsNumber > max) {
        return `Larger than maximum allowed (${max})`;
    }
};

function SimpleNumericInput({ className, value, onChange, step, min, max, inputRef, integer, sx, ...props }, ref) {
    const classes = useStyles();
    const { disabled, variant } = props;

    const handleChange = useCallback(
        event => {
            if (event.target.value === "") {
                onChange(null);
            } else if (isNumberInProgress(event.target.value, integer)) {
                const valueAsNumber = Number(event.target.value);
                if (`${valueAsNumber}` === event.target.value) {
                    onChange(valueAsNumber);
                } else {
                    onChange(event.target.value);
                }
            }
        },
        [onChange, integer]
    );
    const handleIncrement = useCallback(() => {
        const valueAsNumber = Number(value);
        if (Number.isNaN(valueAsNumber)) {
            onChange(typeof max === "number" ? Math.min(max, step) : step);
        } else {
            onChange(typeof max === "number" ? Math.min(max, valueAsNumber + step) : valueAsNumber + step);
        }
    }, [onChange, value, step, max]);
    const handleDecrement = useCallback(() => {
        const valueAsNumber = Number(value);
        if (Number.isNaN(valueAsNumber)) {
            onChange(typeof min === "number" ? Math.max(min, -step) : -step);
        } else {
            onChange(typeof min === "number" ? Math.max(min, valueAsNumber - step) : valueAsNumber - step);
        }
    }, [onChange, value, step, min]);

    return (
        <Box className={classNames(classes.container, className)} sx={sx}>
            <WarnableTextField
                className={classes.textField}
                InputLabelProps={{
                    classes: {
                        root: classNames({ [classes.indent]: !!step }),
                        shrink: classes.noIndent
                    }
                }}
                InputProps={{
                    className: classNames({ [classes.indent]: !!step })
                }}
                inputProps={{ inputMode: integer ? "numeric" : "decimal" }}
                inputRef={inputRef}
                ref={ref}
                value={[null, undefined].includes(value) ? "" : String(value)}
                onChange={handleChange}
                {...props}
            />
            {step && (
                <>
                    <IconButton
                        className={classNames(classes.button, "left", variant)}
                        color="primary"
                        disabled={disabled}
                        onClick={handleDecrement}
                        size="large"
                    >
                        <RemoveIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        className={classNames(classes.button, "right", variant)}
                        color="primary"
                        disabled={disabled}
                        onClick={handleIncrement}
                        size="large"
                    >
                        <AddIcon fontSize="small" />
                    </IconButton>
                </>
            )}
        </Box>
    );
}

export default forwardRef(SimpleNumericInput);

import React, { memo, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormContext } from "../../Form";
import ExpandingListItem from "../../../ExpandingListItem";
import FileField from "../../FileField";
import StringField from "../../StringField";

import { IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1.2)
    }
}));

function AkademiskaHusBuilding({ name, index, disabled, onRemoveFromArray }) {
    const classes = useStyles();

    const { attributeDefinitions } = useContext(FormContext);
    const floorPlanAttribute = attributeDefinitions.find(a => a.name === "floor_plans");
    if (!floorPlanAttribute || floorPlanAttribute.type !== "file" || !floorPlanAttribute.array) {
        throw new Error(
            `AkademiskaHusIndoorBuildingInfo: for this form component to function an attribute named "floor_plans" of type file (array) must exist in the layer`
        );
    }
    const floorPlans = useWatch({ name: `${floorPlanAttribute._id}` });

    const { setValue } = useFormContext();
    const handleRemoveFloor = () => {
        if (Array.isArray(floorPlans)) {
            // Move all floor plans to match array shift
            const newFloorPlans = [...floorPlans.slice(0, index), ...floorPlans.slice(index + 1)];
            setValue(`${floorPlanAttribute._id}`, newFloorPlans);
        }
        onRemoveFromArray();
    };

    const level = useWatch({ name: `${name}.level` });

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={level ?? "Level"}
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={handleRemoveFloor} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <StringField
                className={classes.input}
                name={`${name}.level`}
                schema={{ title: "Level" }}
                disabled={disabled}
            />
            <FileField
                className={classes.input}
                name={`${floorPlanAttribute._id}.${index}`}
                schema={{ title: "Floor plan", type: "file" }}
                disabled={disabled}
            />
            <StringField
                className={classes.input}
                name={`${name}.comments`}
                schema={{ title: "Comments" }}
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(AkademiskaHusBuilding);

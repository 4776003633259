import { convertFileToURL } from "./workspace/info/translate-properties";

export const convertFileAttributesToURLs = (featureCollection, attributeDefinitions, workspace) => {
    const fileAttributes = attributeDefinitions.filter(a => ["file", "image"].includes(a.type));
    if (fileAttributes.length > 0) {
        for (const feature of featureCollection.features) {
            for (const attribute of fileAttributes) {
                const value = feature.properties[attribute.name];
                if (Array.isArray(value)) {
                    feature.properties[attribute.name] = value.map(val => convertFileToURL(val, workspace));
                } else if (value) {
                    feature.properties[attribute.name] = convertFileToURL(value, workspace);
                }
            }
        }
    }
};

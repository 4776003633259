import React from "react";
import ErrorDialog from "../ErrorDialog";

function PasteStylesErrorDialog({ open, onClose }) {
    return (
        <ErrorDialog
            open={open}
            onClose={onClose}
            title="Incomplete paste"
            text="One or more attributes used in style conditions could not be found in this layer. Styling may be
        incomplete."
        />
    );
}

export default PasteStylesErrorDialog;

import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(theme => ({
    submitButton: {
        color: theme.colors.warning
    }
}));

function FormWarningDialog({ open, onConfirm, onCancel }) {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <DialogContentText>The form contains warnings. Submit anyway?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
                <Button className={classes.submitButton} onClick={onConfirm}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FormWarningDialog;

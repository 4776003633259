import React from "react";
import { ErrorMessage } from "../Error";

import { TextField, MenuItem } from "@mui/material";

const optionMap = option => {
    let o = option;
    if (typeof option === "string") {
        o = { value: option, title: option };
    }
    return (
        <MenuItem key={o.value} value={o.value}>
            {o.title || o.value}
        </MenuItem>
    );
};

function EnumFormInput(props) {
    const { schema, field, enabled, error, setError } = props;

    const handleChange = event => {
        setError(field.name, null);
        field.onChange(event);
    };

    const value = typeof field.value !== "undefined" ? field.value : "";

    return (
        <React.Fragment>
            <TextField
                variant="outlined"
                select
                fullWidth
                disabled={!enabled}
                label={schema.title}
                {...field}
                value={value}
                onChange={handleChange}
            >
                {schema.options.map(optionMap)}
            </TextField>
            <ErrorMessage error={error} />
        </React.Fragment>
    );
}

export default EnumFormInput;

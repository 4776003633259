import { memo, useCallback } from "react";
import { useFieldController } from "./useFieldController";
import { useExtendedRules, getErrorMessages, hasError, hasWarning } from "./form-utils";
import SimpleNumericInput, { validateNumber } from "../inputs/SimpleNumericInput";
import RenderCount from "../RenderCount";

function NumberField({ className, schema, name, rules, defaultValue, disabled }) {
    const handleValidation = useCallback(
        value => {
            return validateNumber(value, { min: schema.min, max: schema.max });
        },
        [schema.min, schema.max]
    );
    const _rules = useExtendedRules(rules, "errorNumeric", handleValidation);
    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules: _rules, defaultValue });

    return (
        <>
            <RenderCount label="NumberField" />
            <SimpleNumericInput
                className={className}
                variant="outlined"
                label={schema.title}
                step={schema.step}
                min={schema.min}
                max={schema.max}
                error={hasError(error)}
                warning={hasWarning(error)}
                helperText={getErrorMessages(error)?.join(", ")}
                disabled={disabled}
                integer={schema.type === "integer"}
                {...field}
            />
        </>
    );
}

export default memo(NumberField);

import { memo } from "react";
import { useFieldController } from "./useFieldController";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import RenderCount from "../RenderCount";
import WarnableTextField from "./WarnableTextField";

import { ListItemText, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {},
    menuItem: {
        whiteSpace: "normal"
    },
    selectMenu: {
        whiteSpace: "normal"
    },
    null: {
        color: theme.colors.disabled,
        fontStyle: "italic"
    }
}));

function EnumField({ className, schema, name, rules, defaultValue, disabled }) {
    const classes = useStyles();

    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules, defaultValue });

    return (
        <>
            <RenderCount label="EnumField" />
            <WarnableTextField
                className={className}
                SelectProps={{ classes: { select: classes.container, selectMenu: classes.selectMenu } }}
                select
                fullWidth
                variant="outlined"
                label={schema.title}
                multiline={!!schema.multiline}
                {...field}
                disabled={disabled}
                value={field.value ?? ""}
                error={hasError(error)}
                warning={hasWarning(error)}
                helperText={getErrorMessages(error)?.join(", ")}
            >
                {schema.includeNull && (
                    <MenuItem className={classes.menuItem} value={null}>
                        {schema.includeNullTitle ? (
                            <ListItemText
                                primary={schema.includeNullTitle}
                                secondary={schema.hideNullSubtitle ? undefined : "NULL"}
                                secondaryTypographyProps={{ className: classes.null }}
                            />
                        ) : (
                            <ListItemText primaryTypographyProps={{ className: classes.null }} primary="NULL" />
                        )}
                    </MenuItem>
                )}
                {schema.options.map((option, index) => {
                    let o = option;
                    if (typeof option === "string") {
                        o = { value: option, title: option };
                    }
                    const valueAsString = String(o.value);
                    return (
                        <MenuItem className={classes.menuItem} key={index} value={o.value}>
                            <ListItemText
                                primary={o.title ?? o.value}
                                secondary={
                                    !schema.hideValues && (o.subtitle ?? (o.title !== valueAsString && valueAsString))
                                }
                            />
                        </MenuItem>
                    );
                })}
            </WarnableTextField>
        </>
    );
}

export default memo(EnumField);

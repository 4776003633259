import { Point } from "ol/geom";

const LENGTH_COLOR = "#ff44aa";
const LENGTH_TEXT_STROKE_COLOR = "#ffffff";
const AREA_COLOR = "#44aaff";
const AREA_TEXT_STROKE_COLOR = "#ffffff";

export function createLineStringStyles() {
    return [
        {
            stroke: [
                {
                    type: "dashed",
                    color: LENGTH_COLOR,
                    width: 3,
                    pattern: [5, 5]
                }
            ],
            label: {
                fill: {
                    type: "solid",
                    color: LENGTH_COLOR
                },
                stroke: {
                    type: "solid",
                    color: LENGTH_TEXT_STROKE_COLOR,
                    width: 3
                },
                fontWeight: "bold"
            }
        }
    ];
}

export function createPolygonStyles(completed = false) {
    const styles = [
        {
            fill: [
                {
                    type: "solid",
                    color: AREA_COLOR,
                    opacity: 0.2
                }
            ],
            label: {
                fill: {
                    type: "solid",
                    color: AREA_COLOR
                },
                stroke: {
                    type: "solid",
                    color: AREA_TEXT_STROKE_COLOR,
                    width: 3
                },
                fontWeight: "bold",
                overflow: true,
                offsetY: -8
            }
        }
    ];
    if (completed) {
        styles[0].stroke = [
            {
                type: "dashed",
                color: LENGTH_COLOR,
                width: 3,
                pattern: [5, 5]
            }
        ];
        styles.push({
            label: {
                fill: {
                    type: "solid",
                    color: LENGTH_COLOR
                },
                stroke: {
                    type: "solid",
                    color: LENGTH_TEXT_STROKE_COLOR,
                    width: 3
                },
                fontWeight: "bold",
                overflow: true,
                offsetY: 8
            }
        });
    } else {
        styles.push(
            createPointStyle(AREA_COLOR, feature => {
                const vertices = feature
                    .getGeometry()
                    .getCoordinates()[0]
                    .slice();
                if (vertices.length > 4) {
                    return new Point(vertices[0]);
                }
            })
        );
        styles.push(
            createPointStyle(LENGTH_COLOR, feature => {
                const vertices = feature
                    .getGeometry()
                    .getCoordinates()[0]
                    .slice();
                if (vertices.length === 2) {
                    // Did just begin measurement
                    return new Point(vertices[0]);
                }
                if (vertices.length > 3) {
                    return new Point(vertices[vertices.length - 3]);
                }
            })
        );
    }
    return styles;
}

export function createPointStyle(color, geometryFunction) {
    return {
        symbol: {
            type: "circle",
            size: 10,
            stroke: {
                type: "solid",
                color,
                width: 3
            }
        },
        geometryFunction
    };
}

import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import projections from "proj4-definitions/Proj4Only";

export default function registerProjection(code) {
    const projection = projections[code];
    if (!projection) {
        throw new Error(`Code ${code} does not exist!`);
    }
    proj4.defs(code, projection.proj4);
    register(proj4);
}

import React, { useContext } from "react";
import { ErrorContext } from "./error-context";
import Snackbar from "./Snackbar";

function ErrorDisplay({ error }) {
    const { clearError } = useContext(ErrorContext);

    const handleClose = (_, reason) => {
        if (reason !== "clickaway") {
            clearError(error);
        }
    };

    return (
        <React.Fragment>
            {error && <Snackbar variant={error.type} message={error.message} onClose={handleClose} />}
        </React.Fragment>
    );
}

export default ErrorDisplay;

import React, { memo, useState } from "react";
import classNames from "classnames";
import { useController } from "react-hook-form";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import FUXFileUploadDialog from "foran-ux/dist/FUXFileUploadDialog";
import OutlinedContainer from "../OutlinedContainer";
import RenderCount from "../RenderCount";

import { Button, IconButton, Typography } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(_ => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

function FileField({ className, schema, name, rules, disabled }) {
    const classes = useStyles();
    const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
    const {
        field,
        fieldState: { error }
    } = useController({ name, rules });
    const handleFileUpload = files => {
        setFileUploadDialogOpen(false);
        if (files.length > 0) {
            const file = files[0];
            field.onChange({ file });
        }
    };

    const filename = field.value?.file?.name ?? field.value?.name;
    return (
        <>
            <OutlinedContainer
                className={classNames(classes.container, className)}
                label={schema.title}
                error={hasError(error)}
                warning={hasWarning(error)}
                helperText={getErrorMessages(error)?.join(", ")}
            >
                <RenderCount label="FileInput" />
                {filename ? (
                    <React.Fragment>
                        <Typography variant="overline">{filename}</Typography>
                        <IconButton disabled={disabled} onClick={() => field.onChange(null)} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </React.Fragment>
                ) : (
                    <Button
                        disabled={disabled}
                        color="primary"
                        variant="outlined"
                        onClick={() => setFileUploadDialogOpen(true)}
                    >
                        Choose {schema.type}
                    </Button>
                )}
            </OutlinedContainer>
            <FUXFileUploadDialog
                title="Choose file to upload"
                open={fileUploadDialogOpen}
                onClose={() => setFileUploadDialogOpen(false)}
                onSubmit={handleFileUpload}
                accept={
                    schema.type === "image"
                        ? {
                              "image/jpeg": [".jpeg", ".jpg"],
                              "image/jpg": [".jpeg", ".jpg"],
                              "image/png": [".png"]
                          }
                        : undefined
                }
                maxFiles={1}
                maxSize={1000000000} // 1GB
            />
        </>
    );
}

export default memo(FileField);

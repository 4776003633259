import React, { Component } from "react";
import RevolverButton from "./RevolverButton";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
    revolver: {
        position: "absolute",
        zIndex: 100,
        width: "200px",
        height: "200px",
        maxWidth: "50vmin",
        maxHeight: "50vmin",
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        userSelect: "none"
        // background: "linear-gradient(to bottom, red 50%, green 50%)"
    },
    circleDotted: {
        fill: "transparent",
        stroke: theme.colors.darkGreen,
        strokeWidth: "0.8px",
        strokeDasharray: ".43, 2.5",
        strokeLinecap: "round"
    },
    circlePlain: {
        fill: "transparent",
        stroke: "white",
        strokeWidth: "1.0px"
    }
});

class Revolver extends Component {
    constructor(props) {
        super(props);
        this._handleOnClick = this._handleOnClick.bind(this);
    }

    _getRevolverPosition(position) {
        if (position === null) return {};
        const [x, y] = position;
        return {
            left: `${x}px`,
            top: `${y}px`
        };
    }

    getRect(margin = 20) {
        const rect = this.revolverElement.getBoundingClientRect();
        return {
            left: rect.left - margin,
            bottom: rect.bottom + margin,
            right: rect.right + margin,
            top: rect.top - margin
        };
    }

    _handleOnClick(buttonId) {
        this.props.onClose(buttonId);
    }

    render() {
        const { classes, topButtons, bottomButtons, buttonsPerRevolution, position, onClose, ...other } = this.props;
        const buttonMap = (button, index, type) => {
            if (type === "bottom") {
                index = -(index + 1);
            }
            return (
                // disabled flag is only present on tools that needs special permission, like for adding/modifying things
                <RevolverButton
                    disabled={button.hasOwnProperty("disabled") ? button.disabled : false}
                    disabledExplanation={button.disabledExplanation}
                    key={index}
                    index={index}
                    onClick={this._handleOnClick}
                    button={button}
                    buttonsPerRevolution={buttonsPerRevolution}
                />
            );
        };
        return (
            <div
                className={classes.revolver}
                style={this._getRevolverPosition(position)}
                ref={el => (this.revolverElement = el)}
                {...other}
            >
                <svg viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r="8%" className={classes.circlePlain} />
                    <circle cx="50" cy="50" r="8%" className={classes.circleDotted} />
                </svg>
                {topButtons.map((button, index) => buttonMap(button, index, "top"))}
                {bottomButtons.map((button, index) => buttonMap(button, index, "bottom"))}
            </div>
        );
    }
}

Revolver.defaultProps = {
    topButtons: [],
    bottomButtons: [],
    buttonsPerRevolution: 10,
    position: null
};

export default withStyles(styles)(Revolver);

import React, { useCallback, useState } from "react";
import { Field as FormikField, FieldArray as FormikFieldArray } from "formik";
import jsonLogic from "../json-logic/json-logic";

import { getError } from "./Error";
import OutlinedContainer from "../OutlinedContainer";
import NumberFormInput from "./inputs/NumberFormInput";
import BooleanFormInput from "./inputs/BooleanFormInput";
import StringFormInput from "./inputs/StringFormInput";
import EnumFormInput from "./inputs/EnumFormInput";
import DateFormInput from "./inputs/DateFormInput";
import DateTimeFormInput from "./inputs/DateTimeFormInput";
import FileFormInput from "./inputs/FileFormInput";
import CalculatedField from "./CalculatedField";
import CustomInputs from "./custom-inputs";

import { Button, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add as AddIcon, Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    container: {
        margin: "20px 0",
        "&:last-child": {
            margin: "0"
        }
    },
    arrayContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1)
    },
    arrayField: {
        flexGrow: 1
    },
    arrayAddButton: {
        marginTop: theme.spacing(1)
    }
}));

function Field(props) {
    const { schema, formik, onAddWarning, onRemoveWarning, attribute } = props;
    const [errors, setErrors] = useState({});
    const classes = useStyles();

    const inputProps = {
        enabled: true
    };
    if (schema.hasOwnProperty("enabled")) {
        inputProps.enabled = schema.enabled;
        if (typeof inputProps.enabled === "object") {
            inputProps.enabled = jsonLogic.apply(inputProps.enabled, formik.values);
        }
    }
    if (schema.readonly) {
        inputProps.enabled = false;
    }

    const addError = useCallback((key, message, type = "error") => {
        if (key) {
            setErrors(errors => ({ ...errors, [key]: message ? { message, type } : null }));
        }
    }, []);

    function validateRule(validation, index) {
        if (validation.enabled && !jsonLogic.apply(validation.enabled, formik.values)) {
            return null;
        }
        let validationKey = schema.key;
        if (index !== undefined) {
            validationKey = `${validationKey}.${index}`;
        }
        const values = { ...formik.values };
        if (index !== undefined && Array.isArray(values[schema.key])) {
            values[schema.key] = values[schema.key][index];
        }
        if (!jsonLogic.apply(validation.rule, values)) {
            const error = { message: validation.message, type: validation.type };
            addError(validationKey, error.message, error.type);
            if (validation.type === "error") {
                return { [validationKey]: error };
            } else if (validation.type === "warning") {
                onAddWarning(validationKey);
            }
        }
    }

    function validate(value, index) {
        let validationKey = schema.key;
        if (index !== undefined) {
            validationKey = `${validationKey}.${index}`;
        }
        onRemoveWarning(validationKey);
        if (errors[validationKey]?.type === "error") {
            return { [validationKey]: errors[validationKey] };
        }
        if (schema.required && [null, undefined, ""].includes(value)) {
            return { [validationKey]: { message: "Required" } };
        }
        const validation = schema.validate;
        if (validation) {
            if (Array.isArray(validation)) {
                for (const rule of validation) {
                    const result = validateRule(rule, index);
                    if (result) {
                        return result;
                    }
                }
            } else {
                return validateRule(validation, index);
            }
        }
        return null;
    }

    let Component;
    if (schema.calculated) {
        Component = CalculatedField;
    } else {
        switch (schema.type) {
            case "integer":
                Component = NumberFormInput;
                inputProps.integer = true;
                break;
            case "decimal":
                Component = NumberFormInput;
                break;
            case "boolean":
                Component = BooleanFormInput;
                break;
            case "string":
                Component = StringFormInput;
                break;
            case "enum":
                Component = EnumFormInput;
                break;
            case "date":
                Component = DateFormInput;
                break;
            case "datetime":
                Component = DateTimeFormInput;
                break;
            case "image":
            case "file":
                Component = FileFormInput;
                break;
            case "custom":
                Component = CustomInputs[attribute?.custom?.type];
                if (!Component) {
                    console.error(`Could not find custom input "${attribute?.custom?.type}". Ignoring.`);
                    return null;
                }
                break;
            default:
                console.warn(`Unknown field type "${schema.type}". Skipping.`);
                return null;
        }
    }

    if (attribute?.array) {
        const values = formik.values[schema.key];
        return (
            <FormikFieldArray
                name={schema.key}
                render={({ push, remove }) => (
                    <div className={classes.container}>
                        <OutlinedContainer label={schema.title}>
                            {values?.map((_, index) => (
                                <div key={index} className={classes.arrayContainer}>
                                    <div className={classes.arrayField}>
                                        <FormikField
                                            component={Component}
                                            validate={val => validate(val, index)}
                                            {...inputProps}
                                            {...props}
                                            error={
                                                errors[`${schema.key}.${index}`] ??
                                                getError(`${schema.key}.${index}`, formik.errors, formik.touched)
                                            }
                                            setError={addError}
                                            schema={{ ...schema, title: `[${index + 1}]` }}
                                            name={`${schema.key}.${index}`}
                                        />
                                    </div>
                                    <IconButton onClick={() => remove(index)} disabled={!inputProps.enabled} size="large">
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            ))}
                            <Button
                                className={classes.arrayAddButton}
                                startIcon={<AddIcon />}
                                variant="outlined"
                                color="primary"
                                onClick={() => push(null)}
                                disabled={!inputProps.enabled}
                            >
                                Add
                            </Button>
                        </OutlinedContainer>
                    </div>
                )}
            />
        );
    }

    return (
        <div className={classes.container}>
            <FormikField
                component={Component}
                validate={validate}
                {...inputProps}
                {...props}
                error={errors[schema.key] ?? getError(schema.key, formik.errors, formik.touched)}
                setError={addError}
            />
        </div>
    );
}

export default Field;

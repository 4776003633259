import React from "react";
import { TextField, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const DERIVED_ATTRIBUTES = {
    point: [
        { type: "decimal", name: "Resolution", _id: "resolution", derived: true, section: "map" },
        { type: "integer", name: "Number of parts", _id: "parts", derived: true, section: "derived" }
    ],
    polygon: [
        { type: "decimal", name: "Resolution", _id: "resolution", derived: true, section: "map" },
        { type: "decimal", name: "Area", _id: "area", derived: true, section: "derived" },
        { type: "decimal", name: "Perimeter", _id: "perimeter", derived: true, section: "derived" },
        { type: "integer", name: "Number of parts", _id: "parts", derived: true, section: "derived" },
        { type: "integer", name: "Number of vertices", _id: "vertices", derived: true, section: "derived" }
    ],
    line: [
        { type: "decimal", name: "Resolution", _id: "resolution", derived: true, section: "map" },
        { type: "decimal", name: "Perimeter", _id: "perimeter", derived: true, section: "derived" },
        { type: "integer", name: "Number of vertices", _id: "vertices", derived: true, section: "derived" }
    ]
};

const useStyles = makeStyles(theme => ({
    menuItem: {
        paddingLeft: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    subtitle: {
        color: "#757575",
        lineHeight: 1,
        paddingBottom: "4px"
    },
    menuGroup: {
        minHeight: 0,
        "&:not(:first-child)": {
            borderTop: "1px solid #757575"
        },
        "&:hover": {
            backgroundColor: "transparent !important"
        }
    }
}));

function AttributePicker({ attributes = [], value, featureType, onChange, className }) {
    const classes = useStyles();

    return (
        <div className={className}>
            <TextField
                variant="outlined"
                label="Attribute"
                select
                fullWidth
                value={value || ""}
                onChange={event => onChange(event.target.value)}
                SelectProps={{
                    renderValue: attribute => <span>{attribute.name}</span>
                }}
            >
                <MenuItem disabled className={classes.menuGroup}>
                    <Typography variant="overline">Map</Typography>
                </MenuItem>
                {DERIVED_ATTRIBUTES[featureType]
                    .filter(da => da.section === "map")
                    .map(derivedAttribute => (
                        <MenuItem key={derivedAttribute._id} className={classes.menuItem} value={derivedAttribute}>
                            <Typography variant="subtitle1">{derivedAttribute.name}</Typography>
                            <Typography variant="caption" className={classes.subtitle}>
                                {derivedAttribute.type}
                            </Typography>
                        </MenuItem>
                    ))}
                <MenuItem disabled className={classes.menuGroup}>
                    <Typography variant="overline">Derived attributes</Typography>
                </MenuItem>
                {DERIVED_ATTRIBUTES[featureType]
                    .filter(da => da.section === "derived")
                    .map(derivedAttribute => (
                        <MenuItem key={derivedAttribute._id} className={classes.menuItem} value={derivedAttribute}>
                            <Typography variant="subtitle1">{derivedAttribute.name}</Typography>
                            <Typography variant="caption" className={classes.subtitle}>
                                {derivedAttribute.type}
                            </Typography>
                        </MenuItem>
                    ))}
                <MenuItem disabled className={classes.menuGroup}>
                    <Typography variant="overline">Attributes</Typography>
                </MenuItem>
                {attributes.map(attribute => (
                    <MenuItem key={attribute._id || attribute.name} className={classes.menuItem} value={attribute}>
                        <Typography variant="subtitle1">{attribute.name}</Typography>
                        <Typography variant="caption" className={classes.subtitle}>
                            {attribute.type}
                        </Typography>
                    </MenuItem>
                ))}
            </TextField>
        </div>
    );
}

export default AttributePicker;

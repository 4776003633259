import { useContext, useEffect, useState } from "react";
import { transform } from "ol/proj";
import { GeolocationContext } from "./GeolocationWrapper";
import TooltipIconButton from "../TooltipIconButton";

import { LocationOff, LocationOn } from "@mui/icons-material";

function GeolocationButton({ map, ...props }) {
    const { coordinates, tracking, setTracking } = useContext(GeolocationContext);

    // Only center map if tracking was toggled on by this button
    const [shouldCenterMap, setShouldCenterMap] = useState(false);

    const handleToggleTracking = () => {
        setShouldCenterMap(!tracking);
        setTracking(!tracking);
    };

    useEffect(() => {
        if (shouldCenterMap && tracking && coordinates) {
            setShouldCenterMap(false);
            const localCoordinates = transform(coordinates, "EPSG:4326", map.getView().getProjection());
            map.getView().animate({ center: localCoordinates, duration: 250 });
        }
    }, [map, tracking, shouldCenterMap, coordinates]);

    if (!("geolocation" in navigator)) {
        return null;
    }

    return (
        <TooltipIconButton
            label={tracking ? "Hide your location" : "Show your location"}
            icon={tracking ? <LocationOff /> : <LocationOn />}
            onClick={handleToggleTracking}
            {...props}
        />
    );
}

export default GeolocationButton;

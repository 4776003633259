import { useEffect, useState } from "react";
import { useController } from "react-hook-form";

export const useForestandDateSpanUpdate = ({ name }) => {
    const [fromYear, setFromYear] = useState(null);
    const [toYear, setToYear] = useState(null);
    const {
        field: { onChange: onDateChange, value: date }
    } = useController({ name: `${name}.date` });
    const {
        field: { onChange: onSpanChange, value: span }
    } = useController({ name: `${name}.span` });

    useEffect(() => {
        const fromYearNumber = Number(fromYear);
        const toYearNumber = Number(toYear);
        if (!Number.isNaN(fromYearNumber) && !Number.isNaN(toYearNumber)) {
            onDateChange(fromYearNumber);
            const diff = toYearNumber - fromYearNumber;
            onSpanChange(`P${diff}Y`);
        }
    }, [fromYear, toYear, onDateChange, onSpanChange]);

    useEffect(() => {
        if (fromYear === null && toYear === null && !!date && !!span) {
            const newFromYear = Number(date);
            if (Number.isNaN(newFromYear)) {
                return;
            }
            setFromYear(newFromYear);
            const newSpan = Number(span.match(/P(\d+)Y/)?.[1]);
            if (Number.isNaN(newSpan)) {
                return;
            }
            setToYear(newFromYear + newSpan);
        }
    }, [date, span, fromYear, toYear]);

    return { fromYear, toYear, setFromYear, setToYear };
};

import React from "react";
import { CustomPicker } from "react-color";
import { Hue, Alpha, Saturation, Checkboard } from "react-color/lib/components/common";

import makeStyles from "@mui/styles/makeStyles";
import ColorTextInput from "./ColorTextInput";

const useStyles = makeStyles({
    container: {
        width: "100%",
        minWidth: "180px"
    },
    preview: {
        width: "100%",
        height: "100px",
        marginBottom: "20px",
        position: "relative",
        borderRadius: "4px"
    },
    sliderContainer: {
        position: "relative",
        width: "100%",
        height: "16px",
        marginBottom: "20px",
        borderRadius: "4px",

        "&.twodimensions": {
            height: "150px",

            "& $pointer": {
                transform: "translate(-50%, -50%)"
            }
        }
    },
    pointer: {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        transform: "translate(-50%, -2px)",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.37)"
    }
});

const SliderPointer = () => {
    const classes = useStyles();
    return <div className={classes.pointer} />;
};

const PREVIEW_STYLE = {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: "inherit"
};

function ColorPicker(props) {
    const classes = useStyles();

    const { rgb } = props;
    const previewStyle = {
        ...PREVIEW_STYLE,
        backgroundColor: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
    };

    return (
        <div className={classes.container}>
            <div className={classes.preview}>
                <Checkboard borderRadius="inherit" />
                <div style={previewStyle} />
            </div>
            <div className={`${classes.sliderContainer} twodimensions`}>
                <Saturation {...props} radius="inherit" pointer={SliderPointer} />
            </div>
            <div className={classes.sliderContainer}>
                <Hue {...props} radius="inherit" pointer={SliderPointer} />
            </div>
            <div className={classes.sliderContainer}>
                <Alpha {...props} radius="inherit" pointer={SliderPointer} />
            </div>
            <ColorTextInput {...props} />
        </div>
    );
}

export default CustomPicker(ColorPicker);

import React from "react";
import { animated } from "@react-spring/web";

const AnimatedNumber = ({ value, integer, formatter }) => {
    return (
        <animated.span>
            {value.to(x => {
                let val = x;
                if (integer) {
                    val = Math.round(x);
                }
                if (formatter) {
                    val = formatter(val);
                }
                return val;
            })}
        </animated.span>
    );
};

export default AnimatedNumber;

import ImageCanvas from 'ol/ImageCanvas';
import ImageCanvasSource from "ol/source/ImageCanvas";

class AsyncImageCanvasSource extends ImageCanvasSource{
    constructor(opt_options){
        super(opt_options);
        this._cache = null;

    }

    _fetch(extent, resolution, pixelRatio, projection){
        if( !this._cache ) return null;
        if( resolution !== this._cache.resolution ) return null;
        if( pixelRatio !== this._cache.pixelRatio ) return null;
        if( projection !== this._cache.projection ) return null;
        if( extent[0] !== this._cache.extent[0] ) return null;
        if( extent[1] !== this._cache.extent[1] ) return null;
        if( extent[2] !== this._cache.extent[2] ) return null;
        if( extent[3] !== this._cache.extent[3] ) return null;
        return this._cache.imageCanvas;
    }

    getImageInternal(extent, resolution, pixelRatio, projection){
        pixelRatio = 1;
        resolution = this.findNearestResolution(resolution);

        let imageCanvas = this._fetch(extent, resolution, pixelRatio, projection);
        if( imageCanvas ) return imageCanvas;

        const width = (extent[2] - extent[0]) / resolution;
        const height = (extent[3] - extent[1]) / resolution;

        const canvasElement = document.createElement("canvas");
        canvasElement.width = width * pixelRatio;
        canvasElement.height = height * pixelRatio;
        const ctx = canvasElement.getContext("2d");
        const canvasFunction = this.canvasFunction_;
        imageCanvas = new ImageCanvas(extent, resolution, pixelRatio, canvasElement, done => {
            canvasFunction(ctx, extent, resolution, pixelRatio, done);
        });
        this._cache = {
            extent: extent,
            resolution: resolution,
            pixelRatio: pixelRatio,
            projection: projection,
            imageCanvas: imageCanvas
        };

        return imageCanvas;
    }
}

export default AsyncImageCanvasSource;
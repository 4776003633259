import React from "react";

function AtlasLogo(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="511.496"
            height="487.089"
            version="1.1"
            viewBox="0 0 511.496 487.089"
            xmlSpace="preserve"
            {...props}
        >
            <g transform="matrix(1.33333 0 0 1.33333 0 0)">
                <g>
                    <path
                        fill="#90b630"
                        fillOpacity="1"
                        fillRule="evenodd"
                        stroke="none"
                        d="M383.25 360.309L195.043 1.77C194.484.707 193.316 0 192.02 0c-.071 0-.137.008-.207.016-.071-.008-.137-.016-.208-.016-1.296 0-2.464.707-3.023 1.77L.375 360.309c-.246.464-.375 1-.375 1.566a3.439 3.439 0 003.441 3.441h79.012c2.465 0 4.645-1.418 5.715-3.453l103.645-197.414 103.64 197.414c1.07 2.035 3.25 3.453 5.715 3.453h79.016a3.438 3.438 0 003.437-3.441 3.38 3.38 0 00-.371-1.566z"
                    />
                </g>
            </g>
        </svg>
    );
}

export default AtlasLogo;

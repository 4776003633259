import { useEffect } from "react";
import { useController } from "react-hook-form";

function CalculatedFieldUpdater({ result, name }) {
    const {
        field: { onChange, value }
    } = useController({ name });

    useEffect(() => {
        if (!Number.isNaN(result) && result !== value) {
            onChange(result);
        }
    }, [result, value, onChange]);

    return null;
}

export default CalculatedFieldUpdater;

import { useContext, useEffect, useState } from "react";
import { AtlasContext } from "./atlas-context";
import { isRasterLayer, isVectorLayer } from "./layer-tools";
import useOnline from "./useOnline";

/**
 * @returns {Object} {
 *   vector: {
 *     status: "offline" || "analyzing" || "downloading" || "done",
 *     lastUpToDate: Date (timestamp) || "never",
 *     features: Integer (count),
 *     localChanges: Integer (count)
 *   }
 *   raster: {
 *     status: "offline" || "analyzing" || "downloading" || "done",
 *     analyzing: true || false, // if still analyzing (might be simultaneous with "downloading" status)
 *     tilesLeft: Integer (count),
 *     size: Integer (bytes)
 *   }
 * }
 */
export const useSyncStatus = (workspace, updateInterval) => {
    const { dbManager } = useContext(AtlasContext);
    const online = useOnline();
    const [status, setStatus] = useState({
        vector: { status: "analyzing", features: 0, localChanges: 0, lastUpToDate: null },
        raster: { status: "analyzing", analyzing: false, tilesLeft: 0, size: 0 }
    });

    useEffect(() => {
        let timeout;
        let cancelled = false;

        const getStatus = async () => {
            const status = {
                vector: { features: 0, localChanges: 0 },
                raster: { analyzing: false, tilesLeft: 0, size: 0, tilesFailed: 0 }
            };

            for (const layerId of workspace.layers) {
                const layer = await dbManager.getLayer(layerId);
                if (isVectorLayer(layer)) {
                    const db = await dbManager.getVectorDB(layerId, workspace._id);
                    status.vector.features += await db.count("__atlas.child", { $eq: 0 });
                    status.vector.localChanges += await db.count("__atlas.localModified");
                    const upToDate = await db.getMeta("upToDate");
                    if (!upToDate) {
                        status.vector.status = "downloading";
                    }
                    const lastUpToDate = await db.getMeta("lastUpToDate");
                    if (!lastUpToDate) {
                        status.vector.lastUpToDate = "never";
                    } else if (!status.vector.lastUpToDate || lastUpToDate < status.vector.lastUpToDate) {
                        status.vector.lastUpToDate = lastUpToDate;
                    }
                } else if (isRasterLayer(layer)) {
                    const db = await dbManager.getTileDB(layerId, workspace._id);
                    const layerStatus = db.getSyncStatus();
                    status.raster.size += layerStatus.size ?? 0;
                    status.raster.tilesLeft += layerStatus.tilesLeft ?? 0;
                    status.raster.tilesFailed += layerStatus.tilesFailed ?? 0;
                    if (status.raster.status === "error" || layerStatus.status === "error") {
                        status.raster.status = "error";
                        status.raster.error = status.raster.error ?? layerStatus.error;
                    }
                    if (
                        layerStatus.status === "downloading" ||
                        (layerStatus.status === "analyzing" && status.raster.status !== "downloading")
                    ) {
                        status.raster.status = layerStatus.status;
                    }
                    if (layerStatus.status === "analyzing") {
                        status.raster.analyzing = true;
                    }
                }
                if (cancelled) return;
            }

            if (online) {
                status.vector.status = status.vector.status ?? "done";
                status.raster.status = status.raster.status ?? "done";
            } else {
                status.vector.status = "offline";
                status.raster.status = status.raster.tilesLeft === 0 ? "done" : "offline";
            }
            setStatus(status);

            if (!cancelled && updateInterval) {
                timeout = setTimeout(getStatus, updateInterval);
            }
        };

        if (workspace) {
            getStatus();
        } else {
            setStatus({
                vector: { status: null, features: 0, localChanges: 0, lastUpToDate: null },
                raster: { status: null, analyzing: false, tilesLeft: 0, size: 0 }
            });
        }

        return () => {
            cancelled = true;
            clearTimeout(timeout);
        };
    }, [dbManager, online, workspace, updateInterval]);

    return status;
};

import NullableCellRenderer from "./NullableCellRenderer";

const getDisplayValue = (value, type) => {
    if (Array.isArray(value)) {
        return value.map(getDisplayValue);
    } else if (value) {
        const valueAsDate = new Date(value);
        if (!isNaN(valueAsDate.getTime())) {
            if (type === "date") {
                return valueAsDate.toLocaleDateString();
            }
            if (type === "datetime") {
                return valueAsDate.toLocaleString();
            }
        }
    }
    return value;
};

function DateCellRenderer({ value, type = "date", ...props }) {
    const displayValue = getDisplayValue(value, type);
    return <NullableCellRenderer value={displayValue} {...props} />;
}

export default DateCellRenderer;

import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useState } from "react";
import { Box, List } from "@mui/material";
import { DragHandle } from "@mui/icons-material";
import FUXELM from "foran-ux/dist/FUXExpandingListItem";
const { default: FUXExpandingListItem } = FUXELM;

const SortableItem = ({ item, children, expansion, setExpansion, disabled = false }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: item.id,
        disabled
    });

    const style = transform
        ? {
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
              transition,
              position: "relative",
              zIndex: isDragging ? 9999 : 0
          }
        : undefined;

    return (
        <div ref={setNodeRef} style={style}>
            <FUXExpandingListItem
                nested
                sx={[
                    isDragging && {
                        backgroundColor: "white",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)"
                    }
                ]}
                id={item.id}
                expanded={expansion === item.id}
                onClick={id => setExpansion(id === expansion ? null : id)}
                title={item.name}
                icon={item.icon}
                secondaryAction={
                    <Box sx={{ ml: 1, display: "flex", alignItems: "center" }} {...attributes} {...listeners}>
                        <DragHandle color={disabled ? "disabled" : undefined} />
                    </Box>
                }
            >
                {children}
            </FUXExpandingListItem>
        </div>
    );
};

/**
 * items: {
 *     id: string,
 *     name: string,
 *     icon: JSX
 * }
 */
const SortableExpandableList = ({ sx, items, onSortEnd, itemContent, disabled }) => {
    const [expansion, setExpansion] = useState(null);

    const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

    const handleDragEnd = event => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);

            const newItems = arrayMove(items, oldIndex, newIndex);
            onSortEnd(newItems);
        }
    };

    return (
        <List disablePadding sx={sx}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={items} strategy={verticalListSortingStrategy} disabled={disabled}>
                    {items.map((item, index) => (
                        <SortableItem
                            key={item.id}
                            item={item}
                            expansion={expansion}
                            setExpansion={setExpansion}
                            disabled={disabled}
                        >
                            {itemContent && itemContent(item, index)}
                        </SortableItem>
                    ))}
                </SortableContext>
            </DndContext>
        </List>
    );
};

export default SortableExpandableList;

import { LocalDatabase } from "idb";
import { NativeLocalDatabase } from "../NativeLocalDatabase";
import Api from "../Api";

const TILE_DESCRIPTION_FETCH_DELAY = 5000;
const TILE_DESCRIPTION_MAX_RETRIES = 5;

class TileDatabase {
    constructor({ workspace, layer, downloadManager }) {
        this._workspace = workspace;
        this._layer = layer;
        this._downloadManager = downloadManager;

        this.name = TileDatabase.getDbName(workspace._id, layer._id);

        this._supportsBlobs = false;
        this._tileDescriptions = null;
        this._updateError = null;
        this._downloadingIds = new Set();
        this._downloading = false;
        this._waitingForDownload = [];
        this._tileDescriptionUpdateId = 0;
        this._saving = false;
        this._saveQueue = [];
    }

    get workspace() {
        return this._workspace;
    }

    get layer() {
        return this._layer;
    }

    static getDbName(workspaceId, layerId) {
        return `${workspaceId}:${layerId}`;
    }

    async initialize() {
        await this._setupDb();
        await this._checkBlobSupport();

        this._tileDescriptions = await this.getMeta("tileDescriptions");
        this._offline = (await this.getMeta("offline")) === "true";
        const outdated = (await this.getMeta("outdatedTileDescriptions")) === "true";
        if (outdated) {
            // updateTileDescriptions was probably interrupted by a page reload
            this._log("Detected outdated state, updating tile descriptions.");
            this.updateTileDescriptions();
        }

        return this;
    }

    async setMeta(key, value) {
        if (value) {
            const object = {
                id: key,
                value
            };
            await this._db.addObject(object, "meta", true);
        } else {
            await this._db.delete(key, "meta");
        }
    }

    async getMeta(key) {
        const object = await this._db.getObject(key, "meta");
        return object?.value ?? null;
    }

    getSyncStatus() {
        if (this._updateError) {
            return { status: "error", error: this._updateError };
        }
        if (!this._tileDescriptions) {
            return { status: "analyzing" };
        }
        const downloadedTiles = this._tileDescriptions.filter(tile => tile.downloaded);
        const size = downloadedTiles.reduce((total, tile) => total + (tile.size ?? 0), 0);
        const tilesFailed = this._tileDescriptions.filter(tile => !!tile.error).length;
        const tilesLeft = this._tileDescriptions.length - downloadedTiles.length - tilesFailed;
        if (tilesLeft > 0) {
            return { status: "downloading", tilesLeft, tilesFailed, size };
        }
        return {
            status: "done",
            tilesLeft: 0,
            tilesFailed,
            size
        };
    }

    async download(updateTileDescriptions = false) {
        if (this._downloading) {
            this._log("already downloading");
            return new Promise((resolve, reject) => {
                this._waitingForDownload.push({ resolve, reject });
            });
        }
        this._downloading = true;
        this._log("download");
        try {
            if (!this._offline) {
                await this.setMeta("offline", "true");
                this._offline = true;
            }
            if (!this._tileDescriptions || updateTileDescriptions) {
                await this.updateTileDescriptions(true);
            }
            const tilesToDownload = this._tileDescriptions.filter(tile => !tile.downloaded);
            if (tilesToDownload.length > 0) {
                const promises = [];
                let complete = 0;
                let failed = 0;
                const incrementComplete = () => ++complete;
                const incrementFailed = () => ++failed;
                for (const tile of tilesToDownload) {
                    const promise = this._downloadTile(tile).then(incrementComplete).catch(incrementFailed);
                    promises.push(promise);
                }
                this._log(`Added ${tilesToDownload.length} tiles to download queue.`);
                await Promise.allSettled(promises);
                this._log(`Downloaded ${complete} tiles (${failed} failed)`);
            } else {
                this._log("No tiles to download.");
            }
            this._waitingForDownload.forEach(({ resolve }) => resolve());
        } catch (error) {
            this._waitingForDownload.forEach(({ reject }) => reject(error));
            throw error;
        } finally {
            this._downloading = false;
            this._waitingForDownload = [];
        }
    }

    async clear() {
        this._log("clear");
        this._clearDownloadQueue();
        this._tileDescriptions.forEach(tile => (tile.downloaded = false));
        await this.setMeta("tileDescriptions", this._tileDescriptions);
        await this.setMeta("offline", "false");
        await this._db.clear("tiles");
    }

    async deleteDatabase() {
        this._log("Deleting database");
        return this._db.deleteDatabase();
    }

    async updateTileDescriptions(skipDownload = false) {
        this._log("updateTileDescriptions");
        await this.setMeta("outdatedTileDescriptions", "true");
        this._clearDownloadQueue();
        const id = ++this._tileDescriptionUpdateId;
        this._updateError = null;
        let tileDescriptions = [];
        try {
            if (this._workspace.area) {
                tileDescriptions = await this._fetchTileDescriptions(0, id);
            }
        } catch (error) {
            if (this._tileDescriptionUpdateId !== id) {
                // Update was cancelled (replaced by newer request)
                this._log(
                    `Update tile description failed, but ignoring error since a newer request has been started. Error: ${error}`,
                    "warn"
                );
                return null;
            }
            this._updateError = error;
            throw error;
        }
        if (this._tileDescriptionUpdateId !== id) {
            // Update was cancelled (replaced by newer request)
            this._log("updateTileDescriptions cancelled (replaced by newer request)");
            return null;
        }
        await this._updateExistingTileDescriptions(tileDescriptions, id);
        await this.setMeta("tileDescriptions", tileDescriptions);
        await this.setMeta("outdatedTileDescriptions", "false");
        this._tileDescriptions = tileDescriptions;
        this._log("Tile descriptions updated.");
        if (this._offline && !skipDownload) {
            this.download();
        }
        return tileDescriptions;
    }

    async getTile(x, y, r) {
        const existingTile = this._tileDescriptions?.find(tile => tile.x === x && tile.y === y && tile.r === r);
        if (existingTile?.downloaded) {
            const tile = await this._db.getObject([x, y, r], "tiles");
            if (tile?.data) {
                if (typeof tile.data === "string") {
                    return readBinaryTextAsImageBlob(tile.data);
                }
                return tile.data;
            } else {
                this._log(`Tile ${x} ${y} ${r} stored without data (tile: ${tile}, data: ${tile?.data})`, "warn");
            }
        }
        return null;
    }

    _clearDownloadQueue() {
        const queueSize = this._downloadingIds.size;
        if (queueSize > 0) {
            this._log(`Clearing existing download queue (${queueSize} tiles in queue)`);
            this._downloadManager.removeFromQueue([...this._downloadingIds]);
            this._downloadingIds.clear();
        }
    }

    async _fetchTileDescriptions(retries = 0, id) {
        const tileDescriptions = await Api.getTileDescriptions(this._layer._id, this._workspace._id);
        if (this._tileDescriptionUpdateId !== id) {
            // Update was cancelled (replaced by newer request)
            this._log("_fetchTileDescriptions cancelled (replaced by newer request)");
            return null;
        }
        if (!tileDescriptions) {
            if (retries >= TILE_DESCRIPTION_MAX_RETRIES) {
                this._log(`_fetchTileDescriptions failed ${TILE_DESCRIPTION_MAX_RETRIES} times, giving up.`);
                throw new Error("Unable to fetch tile descriptions from server.");
            }
            this._log(
                `tileDescriptions came back ${tileDescriptions}, assuming not yet calculated. Retry number ${
                    retries + 1
                } in ${TILE_DESCRIPTION_FETCH_DELAY}ms`
            );
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this._fetchTileDescriptions(retries + 1, id)
                        .then(resolve)
                        .catch(reject);
                }, TILE_DESCRIPTION_FETCH_DELAY);
            });
        }
        return tileDescriptions;
    }

    async _downloadTile(tileDescription) {
        const { x, y, r } = tileDescription;
        tileDescription.error = null;
        // this._log(`tile ${x} ${y} ${r} download start`);
        const url = Api.getTileBlobUrl(this._layer._id, this._workspace._id, x, y, r);
        const id = this._downloadManager.getNewId();
        this._downloadingIds.add(id);
        let blob;
        try {
            blob = await this._downloadManager.download(url, id);
        } catch (error) {
            // Ignore error if download was cancelled
            if (error.name === "CANCELLED") {
                this._log(`tile ${x} ${y} ${r} download cancelled`);
                return null;
            }
            this._log(`tile ${x} ${y} ${r} download failed: ${error}`, "error");
            tileDescription.error = error;
            await this.setMeta("tileDescriptions", this._tileDescriptions);
            throw error;
        } finally {
            this._downloadingIds.delete(id);
        }
        const data = this._supportsBlobs ? blob : await readBlobDataAsBinaryText(blob);
        const tile = {
            x,
            y,
            r,
            data
        };
        tileDescription.downloaded = true;
        tileDescription.size = blob.size;
        return this._saveTile(tile);
    }

    async _saveTile(tile) {
        return new Promise((resolve, reject) => {
            this._saveQueue.push({ resolve, reject, tile });
            this._startSaveTileQueue();
        });
    }

    async _startSaveTileQueue() {
        if (this._saving) {
            return;
        }
        if (this._saveQueue.length === 0) {
            return;
        }
        this._saving = true;
        const queue = [...this._saveQueue];
        this._saveQueue = [];
        const tiles = queue.map(({ tile }) => tile);
        const updates = {
            tiles,
            meta: [
                {
                    id: "tileDescriptions",
                    value: this._tileDescriptions
                }
            ]
        };
        try {
            await this._db.performUpdates(updates);
            queue.forEach(({ resolve, tile }) => resolve(tile));
            this._log(`Saved batch with ${tiles.length} tile(s).`);
        } catch (error) {
            this._log(`Error saving tiles: ${error}`, "error");
            queue.forEach(({ reject }) => reject(error));
        } finally {
            this._saving = false;
            this._startSaveTileQueue();
        }
    }

    async _updateExistingTileDescriptions(newTileDescriptions, id) {
        this._log("_updateExistingTileDescriptions");
        if (!this._tileDescriptions) {
            return;
        }
        let keepCount = 0;
        let deleteCount = 0;
        let ignoredCount = 0;
        const idsToDelete = [];
        for (const tileDescription of this._tileDescriptions) {
            if (this._tileDescriptionUpdateId !== id) {
                // Update was cancelled (replaced by newer request)
                this._log("_updateExistingTileDescriptions cancelled");
                return;
            }
            const newTileDescription = newTileDescriptions.find(
                newTile =>
                    newTile.x === tileDescription.x &&
                    newTile.y === tileDescription.y &&
                    newTile.r === tileDescription.r
            );
            if (newTileDescription) {
                ++keepCount;
                newTileDescription.downloaded = tileDescription.downloaded;
                newTileDescription.size = tileDescription.size;
            } else if (tileDescription.downloaded) {
                ++deleteCount;
                idsToDelete.push([tileDescription.x, tileDescription.y, tileDescription.r]);
            } else {
                ++ignoredCount;
            }
        }
        await this._db.deleteMultiple(idsToDelete, "tiles");
        this._log(`Kept ${keepCount} tiles, deleted ${deleteCount} tiles and ignored ${ignoredCount} tiles.`);
    }

    async _setupDb() {
        const objectStores = [
            {
                name: "meta",
                keyPath: "id",
                autoIncrement: false
            },
            {
                name: "tiles",
                keyPath: ["x", "y", "r"],
                nativeKeys: ["data"]
            }
        ];
        const DBType = window.ATLAS_IS_NATIVE ? NativeLocalDatabase : LocalDatabase;
        this._db = new DBType({
            name: this.name,
            objectStores,
            version: 3
        });
        await this._db.initialize();
    }

    async _checkBlobSupport() {
        try {
            await this._db.addObject({ id: "testblob", blob: new Blob([""]) }, "meta");
            await this._db.deleteObject({ id: "testblob" }, "meta");
            this._supportsBlobs = true;
        } catch (error) {
            this._log(
                `Client does not support storing blobs in IndexedDB. Raster cache performance will be affected: ${error}`,
                "warn"
            );
        }
    }

    async _log(message, level = "log") {
        console[level](`TileDatabase (${this._workspace._id}-${this._layer._id}):`, message);
    }
}

const readBinaryTextAsImageBlob = async binaryText => {
    const dataArray = new Uint8Array(binaryText.length);
    for (let i = 0; i < dataArray.length; i++) {
        dataArray[i] = binaryText.charCodeAt(i);
    }
    return new Blob([dataArray], { type: "image/png" });
};

const readBlobDataAsBinaryText = async blob => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(blob);
    });
};

export default TileDatabase;

import { useContext } from "react";
import { useColumnDefs } from "./useColumnDefs";
import { useFeatureAttributeRows } from "./useFeatureAttributeRows";
import { useProcessRowUpdate } from "./useProcessRowUpdate";
import { WorkspaceContext } from "../workspace/workspace-context";

import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton
} from "@mui/x-data-grid-pro";

const GET_ROW_ID = row => row.__atlas_original_id;

const CustomToolbar = () => (
    <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
    </GridToolbarContainer>
);

function AttributeTable({ layer }) {
    const { configuration } = useContext(WorkspaceContext);

    const handleEditCommit = useProcessRowUpdate(layer._id);
    const columnDefs = useColumnDefs(layer);
    const rows = useFeatureAttributeRows(layer, configuration._id);

    return (
        <DataGridPro
            experimentalFeatures={{ newEditingApi: true }}
            columns={columnDefs}
            rows={rows}
            getRowId={GET_ROW_ID}
            processRowUpdate={handleEditCommit}
            components={{
                Toolbar: CustomToolbar
            }}
        />
    );
}

export default AttributeTable;

import React from "react";
import { getIn } from "formik";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    error: {
        marginTop: 0,
        color: "#ff2c2a"
    },
    warning: {
        marginTop: 0,
        color: "#ff981e"
    }
});

function getError(key, errors, touched) {
    let error = key && getIn(errors, key);
    if (error?.hasOwnProperty?.(key)) {
        error = error[key];
    }

    if (!error) {
        return null;
    }

    if (error.involved) {
        // All of the involved fields must have been touched for the error to show.
        if (!error.involved.every(k => !!touched[k])) {
            return null;
        }
    } else if (!getIn(touched, key)) {
        // The field itself must have been touched for the error to show.
        return null;
    }

    return error;
}

function ErrorMessage({ error }) {
    const classes = useStyles();

    if (!error) {
        return null;
    }

    return <p className={classes[error.type || "error"]}>{error.message}</p>;
}

export { getError, ErrorMessage };

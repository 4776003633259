import React from "react";
import { ErrorMessage } from "../Error";

import NumberInput from "../../inputs/NumberInput";

function NumberFormInput(props) {
    const { schema, form, field, enabled, integer, error, setError } = props;

    function handleChange(value) {
        setError(field.name, null);
        const event = {
            target: {
                name: field.name,
                value
            }
        };
        field.onChange(event);
        form.setFieldTouched(field.name, true, true);
    }
    function handleError(message) {
        setError(field.name, message);
    }

    function handleBlur(event) {
        event.target.name = field.name;
        field.onBlur(event);
    }

    const fieldProps = {
        ...field,
        onChange: handleChange,
        onBlur: handleBlur
    };

    return (
        <React.Fragment>
            <NumberInput
                label={schema.title}
                variant="outlined"
                fullWidth
                disabled={!enabled}
                step={schema.step || 1}
                integer={!!integer}
                min={schema.min}
                max={schema.max}
                onError={handleError}
                {...fieldProps}
            />
            <ErrorMessage error={error} />
        </React.Fragment>
    );
}

export default NumberFormInput;

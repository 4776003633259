import BooleanCellEditor from "./BooleanCellEditor";
import DateCellEditor from "./DateCellEditor";
import FileCellEditor from "./FileCellEditor";
import NumberCellEditor from "./NumberCellEditor";
import StringCellEditor from "./StringCellEditor";

import { useGridApiContext } from "@mui/x-data-grid-pro";

function AttributeCellEditor({ ...props }) {
    const apiRef = useGridApiContext();

    const { id, field, attribute } = props;
    props.onChange = value => {
        apiRef.current.setEditCellValue({ id, field, value });
    };

    let Component = null;
    switch (attribute.type) {
        case "integer":
            Component = NumberCellEditor;
            props.integer = true;
            break;
        case "decimal":
            Component = NumberCellEditor;
            break;
        case "date":
        case "datetime":
            Component = DateCellEditor;
            break;
        case "string":
            Component = StringCellEditor;
            break;
        case "boolean":
            Component = BooleanCellEditor;
            break;
        case "file":
        case "image":
            Component = FileCellEditor;
            break;
        default:
            return null;
    }

    return <Component {...props} />;
}

export default AttributeCellEditor;

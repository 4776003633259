import React, { useCallback, useState, useContext } from "react";
import { AtlasFormContext, useAtlasFormContext } from "../atlas-form/useAtlasFormContext";
import { CopyContext } from "../copy-context";
import { ErrorContext } from "../error-context";
import { getDefaultIcon } from "../workspace/map/styles/DefaultStyle";
import AtlasFormErrorDialog, { isEmptyObject } from "../atlas-form/AtlasFormErrorDialog";
import ConfirmationDialog from "../ConfirmationDialog";
import IconCheckbox from "../workspace/IconCheckbox";
import SortableExpandableList from "../SortableExpandableList";
import StyleForm from "./StyleForm";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    Tooltip
} from "@mui/material";
import {
    Add as AddIcon,
    Save as SaveIcon,
    Undo as UndoIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from "@mui/icons-material";

function LayerStyleEditor({
    className,
    layer,
    styles,
    onChange,
    onSave,
    onReset,
    onCopyStyles,
    onPasteStyles,
    featureType,
    ...other
}) {
    const { setError } = useContext(ErrorContext);
    const { type: copiedType } = useContext(CopyContext);
    const atlasFormContext = useAtlasFormContext();
    const canSaveStyles = layer?.userRights?.includes("write");
    const canPasteStyles = copiedType && copiedType.startsWith("styles");
    const wrongPasteStyleType = copiedType !== `styles-${featureType}`;

    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [pasteDialogOpen, setPasteDialogOpen] = useState(false);
    const [loadingType, setLoadingType] = useState(null);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const handleDelete = useCallback(
        index =>
            onChange(currentStyles => {
                const newStyles = [...currentStyles];
                newStyles.splice(index, 1);
                return newStyles;
            }),
        [onChange]
    );
    const handleChange = useCallback(
        (style, index) =>
            onChange(currentStyles => {
                const newStyles = [...currentStyles];
                newStyles[index] = typeof style === "function" ? style(currentStyles[index]) : style;
                if (newStyles[index].symbol?.type === "icon" && !newStyles[index].symbol?.icon) {
                    // Icon style was just added
                    if (!currentStyles.slice(0, index).some(s => s.symbol?.type === "icon")) {
                        // No other icon styles exist above this one, set default values
                        newStyles[index].symbol.icon = getDefaultIcon();
                    }
                }
                return newStyles;
            }),
        [onChange]
    );
    const handleSave = async type => {
        if (!loadingType) {
            if (!isEmptyObject(atlasFormContext.errors)) {
                setErrorDialogOpen(true);
                return;
            }
            setLoadingType(type);
            try {
                await onSave(type, layer._id);
                setSaveDialogOpen(false);
            } catch (error) {
                setError(error);
            }
            setLoadingType(null);
        }
    };
    const handleReset = async type => {
        if (!loadingType) {
            setLoadingType(type);
            try {
                await onReset(type, layer._id);
                setResetDialogOpen(false);
            } catch (error) {
                setError(error);
            }
            setLoadingType(null);
        }
    };
    const handlePasteStyles = () => {
        onPasteStyles();
        setPasteDialogOpen(false);
    };
    const getNewStyleName = () => {
        let count = 1;
        let name = "New style";
        while (true) {
            // eslint-disable-next-line no-loop-func
            if (!styles.find(style => style.name === name)) {
                return name;
            }
            name = `New style ${++count}`;
        }
    };
    const handleStyleEnabledChange = (index, checked) => {
        onChange(currentStyles => {
            const newStyles = [...currentStyles];
            newStyles[index].disabled = !checked;
            return newStyles;
        });
    };

    return (
        <AtlasFormContext.Provider value={atlasFormContext}>
            <Stack gap={1}>
                <SortableExpandableList
                    items={styles.map((style, index) => ({
                        id: index + 1,
                        name: style.name,
                        icon: (
                            <IconCheckbox
                                tooltipTitle={!style.disabled ? "Enabled" : "Disabled"}
                                checked={!style.disabled}
                                checkedIcon={<VisibilityIcon />}
                                uncheckedIcon={<VisibilityOffIcon />}
                                onChange={checked => handleStyleEnabledChange(index, checked)}
                            />
                        ),
                        style
                    }))}
                    onSortEnd={items => onChange(items.map(item => item.style))}
                    itemContent={(item, index) => (
                        <StyleForm
                            index={index}
                            style={item.style}
                            onDeleteStyle={handleDelete}
                            onChange={handleChange}
                            featureType={featureType}
                            {...other}
                        />
                    )}
                />
                <Stack direction="row" gap={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onChange(currentStyles => [...currentStyles, { name: getNewStyleName() }])}
                        startIcon={<AddIcon />}
                    >
                        Add style
                    </Button>
                    {onPasteStyles && canPasteStyles && (
                        <Tooltip
                            title={wrongPasteStyleType ? `You can only paste styles from a ${featureType} layer` : ""}
                        >
                            <span>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setPasteDialogOpen(true)}
                                    disabled={wrongPasteStyleType}
                                >
                                    Paste styles
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                    {onCopyStyles && (
                        <Button variant="contained" color="primary" onClick={onCopyStyles}>
                            Copy styles
                        </Button>
                    )}
                    {onReset && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setResetDialogOpen(true)}
                            startIcon={<UndoIcon />}
                        >
                            Reset to default style
                        </Button>
                    )}
                    {onSave && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setSaveDialogOpen(true)}
                            startIcon={<SaveIcon />}
                        >
                            Save as default style
                        </Button>
                    )}
                </Stack>
            </Stack>
            <ConfirmationDialog
                fullWidth
                open={pasteDialogOpen}
                onCancel={() => setPasteDialogOpen(false)}
                onConfirm={handlePasteStyles}
                title="Paste copied styles"
                text="The current styles will be overwritten."
                confirmText="Paste"
            />
            <Dialog open={resetDialogOpen} onClose={() => setResetDialogOpen(false)} fullWidth>
                <DialogTitle>Reset to default style from...</DialogTitle>
                <DialogContent>
                    <List dense>
                        <ListItemButton onClick={() => handleReset("local")}>
                            {loadingType === "local" ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ListItemText
                                    primary="Device"
                                    secondary="The style will be reset to your locally saved style, if available, else to the layer defaults."
                                />
                            )}
                        </ListItemButton>
                        <ListItemButton onClick={() => handleReset("layer")}>
                            {loadingType === "layer" ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ListItemText
                                    primary="Layer"
                                    secondary="The style will be reset to the layer defaults."
                                />
                            )}
                        </ListItemButton>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setResetDialogOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)} fullWidth>
                <DialogTitle>Save default style in...</DialogTitle>
                <DialogContent>
                    <List dense>
                        <ListItemButton onClick={() => handleSave("local")}>
                            {loadingType === "local" ? (
                                <CircularProgress size={22} />
                            ) : (
                                <ListItemText
                                    primary="Device"
                                    secondary="Only this device will use the style for this layer."
                                />
                            )}
                        </ListItemButton>
                        <Tooltip
                            placement="top"
                            title={canSaveStyles ? "" : "You don't have permissions to edit this layer"}
                        >
                            <span>
                                <ListItemButton disabled={!canSaveStyles} onClick={() => handleSave("layer")}>
                                    {loadingType === "layer" ? (
                                        <CircularProgress size={22} />
                                    ) : (
                                        <ListItemText
                                            primary="Layer"
                                            secondary="All devices will use the style for this layer."
                                        />
                                    )}
                                </ListItemButton>
                            </span>
                        </Tooltip>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setSaveDialogOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <AtlasFormErrorDialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)} />
        </AtlasFormContext.Provider>
    );
}

export default LayerStyleEditor;

import React, { Component } from "react";
import { ErrorContext } from "../../../error-context";
import { getFeaturesFromEvent, saveOLFeature, deleteOLFeature, simulateClick } from "./utility/utility";
import { validateGeometries } from "validate-geometries";
import { getVertexCount } from "../../info/derived-properties";
import { getRequiredForm } from "../../../layer-tools";

import { Collection as OLCollection } from "ol";
import { GeoJSON as OLGeoJSON } from "ol/format";
import OLModify from "../openlayers/interactions/Modify";
import OLDraw from "../openlayers/interactions/Draw";
import { Delete, Block, Done, Add, DeleteForever } from "@mui/icons-material";

import { Box, SvgIcon } from "@mui/material";
import { ReactComponent as AimIcon } from "../../../../icons/originals/aim.svg";

import EditStyle from "../styles/EditStyle";
import { createOLStyles } from "../styles/styles";

class Edit extends Component {
    constructor(props) {
        super(props);

        this._handleAddPartRevolverButtonClick = this._handleAddPartRevolverButtonClick.bind(this);
        this._handleDeletePartRevolverButtonClick = this._handleDeletePartRevolverButtonClick.bind(this);
        this._insertVertexCondition = this._insertVertexCondition.bind(this);
        this._deleteCondition = this._deleteCondition.bind(this);
        this._style = this._style.bind(this);
        this._onModifyStart = this._onModifyStart.bind(this);
        this._onModifyEnd = this._onModifyEnd.bind(this);
        this._updateFeatureChangeCount = this._updateFeatureChangeCount.bind(this);
        this._onDrawEnd = this._onDrawEnd.bind(this);
        this._handleMapMoved = this._handleMapMoved.bind(this);
        this._startPrecisionEditing = this._startPrecisionEditing.bind(this);
        this._updateStyle = this._updateStyle.bind(this);

        this.state = {
            mode: "editing"
        };

        this._didClickVertex = false;
        this._currentVertex = null;
        this._feature = props.tool.feature;
        this._collection = new OLCollection([this._feature]);
        this._geojsonFormat = new OLGeoJSON();
        this._editStyle = new EditStyle();

        window.editTool = this;
    }

    componentDidMount() {
        const originalId = this._feature.getProperties()?.__atlas?.originalId;
        const id = this._feature.getId();
        console.log(`EditTool mounted (${id ? `feature ${originalId}, version ${id}` : "new feature"})`);
        this._feature.editing = true;
        this._feature.originalGeometry = this._feature.getGeometry().clone();
        this._feature.geometryChanged = newOLFeature => {
            this._feature.setId(newOLFeature.getId());
            this._feature.originalGeometry = newOLFeature.getGeometry().clone();
            this._feature.setProperties(newOLFeature.getProperties());
            if (this._lastValidGeometry) {
                this._feature.setGeometry(this._lastValidGeometry);
            }
        };
        this._feature.on("change", this._updateFeatureChangeCount);

        this._validateGeometry();
        this._updateStyle();
        this.props.map.getView().on("change:resolution", this._updateStyle);

        this.props.onShowWorkspaceArea(true);
    }

    cleanup() {
        delete this._feature.editing;
        delete this._feature.originalGeometry;
        delete this._feature.geometryChanged;
        this._feature.setStyle(null);
        this._feature.un("change", this._updateFeatureChangeCount);
        this.props.map.getView().un("change:resolution", this._updateStyle);
    }

    componentWillUnmount() {
        this.cleanup();
        this.props.onShowWorkspaceArea(false);
        this.props.map.un("moveend", this._handleMapMoved);
    }

    _updateStyle() {
        const type = this._feature.getGeometry().getType();
        const styles = this._editStyle.createStyles(type, this._feature.originalStyle, {
            resolution: this.props.map.getView().getResolution(),
            highlight: this._currentVertex
        });
        const olStyles = styles.flatMap(style => createOLStyles(style));
        this._feature.setStyle(olStyles);
    }

    _matchVertex(vertex, coordinates) {
        const [x, y] = vertex;
        if (x === this._currentVertex[0] && y === this._currentVertex[1]) {
            vertex[0] = coordinates[0];
            vertex[1] = coordinates[1];
            return true;
        }
        return false;
    }

    _updateCurrentVertex(coordinates) {
        const geometryType = this._feature.getGeometry().getType();
        const featureCoordinates = this._feature.getGeometry().getCoordinates();
        let matched = false;
        switch (geometryType) {
            case "MultiPoint":
                for (let i = 0; i < featureCoordinates.length; i++) {
                    if (this._matchVertex(featureCoordinates[i], coordinates)) {
                        matched = true;
                        break; // Only allow one match so vertices don't end up on top of each other making them impossible to see
                    }
                }
                break;
            case "MultiPolygon":
                for (const polygon of featureCoordinates) {
                    for (const ring of polygon) {
                        for (let i = 0; i < ring.length; i++) {
                            if (this._matchVertex(ring[i], coordinates)) {
                                matched = true;
                                break;
                            }
                        }
                    }
                }
                break;
            case "MultiLineString":
                for (const line of featureCoordinates) {
                    for (let i = 0; i < line.length; i++) {
                        if (this._matchVertex(line[i], coordinates)) {
                            matched = true;
                            break;
                        }
                    }
                }
                break;
            default:
                console.log(`EditTool._updateCurrentVertex: Unhandled feature type ${geometryType}`);
                break;
        }
        if (!matched) {
            console.warn(
                `EditTool._updateCurrentVertex: unmatched coordinates. Feature coordinates: ${JSON.stringify(
                    featureCoordinates
                )}`
            );
            return;
        }
        this._feature.getGeometry().setCoordinates(featureCoordinates);
        if (!this._validateGeometry()) {
            console.log("EditTool._updateCurrentVertex: resulted in invalid geometry, ignoring.");
            return;
        }
        this._currentVertex = coordinates;
        this._updateStyle();
    }

    _startPrecisionEditing() {
        console.log("EditTool._startPrecisionEditing");
        this.setState({ mode: "precision" });
        this._updateCurrentVertex(this.props.map.getView().getCenter());
        this.props.map.on("moveend", this._handleMapMoved);
    }

    _handleMapMoved(event) {
        this._updateCurrentVertex(event.map.getView().getCenter());
    }

    _updateMapButtons() {
        let cancelButtonLabel = "Cancel edits";
        let doneButtonTitle = "Save edits";
        if (!this._feature.getId()) {
            // Feature is new
            cancelButtonLabel = "Discard new feature";
            doneButtonTitle = "Save new feature";
        }
        this.props.onMapButtonRequest([
            {
                label: doneButtonTitle,
                icon: <Done />,
                disabled: !!this._validationError,
                disabledExplanation: this._validationError?.message,
                onClick: this._handleDoneButtonClick.bind(this)
            },
            {
                label: cancelButtonLabel,
                icon: <Block />,
                onClick: this._handleCancelButtonClick.bind(this)
            }
        ]);
    }

    _insertVertexCondition() {
        const type = this._feature.getGeometry().getType();
        if (!type.includes("Point")) {
            // Adding new points are handled by draw tool
            this._addedVertex = true;
        }
        return true;
    }

    _deleteCondition(event) {
        if (event.type === "click") {
            this._didClickVertex = true;
            this._currentVertex = this._modify.vertexFeature_.getGeometry().getCoordinates().slice();
            this._updateStyle();
        }
        return false;
    }

    _style(feature) {
        // Style for the vertex that appears on hover when modifying lines / polygons
        const type = feature.getGeometry().getType().toLowerCase();
        if (type === "point") {
            if (this._currentVertex) {
                const [x, y] = feature.getGeometry().getCoordinates();
                if (x === this._currentVertex[0] && y === this._currentVertex[1]) {
                    return null; // Don't override highlighted vertex
                }
            }
            const style = this._editStyle.createModifyStyle(
                this._feature.getGeometry().getType(),
                this._feature.originalStyle
            );
            if (style) {
                return createOLStyles(style);
            }
        }
        return null;
    }

    _validateGeometry() {
        this._validationError = null;
        let valid = true;
        try {
            const geojson = this._geojsonFormat.writeFeatureObject(this._feature);
            validateGeometries([geojson]);
            this._lastValidGeometry = this._feature.getGeometry().clone();
        } catch (e) {
            console.logError(e, "Edit._validateGeometry: Validation error");
            valid = false;
            if (this.state.mode === "drawing" || !this._lastValidGeometry) {
                const error = new Error(
                    `${e.message}${e.message.endsWith(".") ? "" : "."} Please correct it before saving.`
                );
                this.context.setError(error);
                this._validationError = e;
            } else {
                this._feature.setGeometry(this._lastValidGeometry.clone());
            }
        }
        this._updateMapButtons();
        return valid;
    }

    _onModifyStart() {
        this._featureChangeCount = 0;
        this.props.onRevolverRequest(null);
    }

    _onModifyEnd() {
        // When moving or creating a vertex, it stays selected (on touch devices).
        // This resets the selection.
        this._modify.setActive(false);
        this._modify.setActive(true);
        if (this._featureChangeCount > 1) {
            // Multiple change events between modifystart end modifyend
            // means a vertex was dragged. If it was added and dragged
            // at the same time, the click event will not fire.
            this._addedVertex = false;
        }
        this._validateGeometry();
    }

    _onDrawEnd(event) {
        const geometry = event.feature.getGeometry();
        const type = geometry.getType();
        switch (type) {
            case "Point":
                this._feature.getGeometry().appendPoint(geometry);
                break;
            case "LineString":
                this._feature.getGeometry().appendLineString(geometry);
                break;
            case "Polygon":
                this._feature.getGeometry().appendPolygon(geometry);
                break;
            default:
                throw new Error(`Unsupported geometry type "${type}".`);
        }
        // If the user has drawn a lot, we don't want to restore the last
        // valid geometry (and remove everything they just drew) just because
        // they accidentally made a self intersection, so we clear the last
        // valid geometry here.
        this._lastValidGeometry = null;
        this._validateGeometry();
        this.setState({ mode: "editing" });
    }

    async _handleDoneButtonClick() {
        const { onRevolverRequest, onToolRequest, workspace, dbManager, layers } = this.props;
        if (!this._feature.getId()) {
            const layer = layers.find(layer => layer._id === this._feature.layer.get("id"));
            const form = getRequiredForm(layer);
            if (form) {
                onToolRequest("form", { feature: this._feature, schema: form });
                return;
            }
        }
        onRevolverRequest(null);
        try {
            delete this._feature.editing;
            const originalId = this._feature.getProperties()?.__atlas?.originalId;
            const id = this._feature.getId();
            console.log(`EditTool: Saving feature (${id ? `feature ${originalId}, version ${id}` : "new feature"})`);
            await saveOLFeature(this._feature, workspace, dbManager);
        } catch (error) {
            // Save failed
            console.logError(error, "Edit._handleDoneButtonClick: saveOLFeature failed");
            this.context.setError(error);
            if (["OUTSIDE_AREA", "QuotaExeededError"].includes(error.name)) {
                // Stay in edit mode
                this._feature.editing = true;
                return;
            }
            // Unknown error
            console.logError(error, "An unknown error occurred when trying to save feature from edit tool.");
        }
        onToolRequest("normal");
    }

    _handleCancelButtonClick() {
        if (this._feature.getId()) {
            console.log("EditTool: User cancelled edit.");
            this._feature.setGeometry(this._feature.originalGeometry);
        } else {
            // Feature was new, remove from map entirely
            console.log("EditTool: User discarded new feature.");
            this._feature.layer.getSource().removeFeature(this._feature);
        }
        this.props.onRevolverRequest(null);
        this.props.onToolRequest("normal");
    }

    _handleDeleteVertexRevolverButtonClick() {
        this._modify.removePoint();
        const { onRevolverRequest } = this.props;
        onRevolverRequest(null);
    }

    _calculateDistanceToPart(part, coordinates) {
        if (part.intersectsCoordinate(coordinates)) {
            return 0;
        }
        const closestPoint = part.getClosestPoint(coordinates);
        const p1 = this.props.map.getPixelFromCoordinate(coordinates);
        const p2 = this.props.map.getPixelFromCoordinate(closestPoint);
        const x = p1[0] - p2[0];
        const y = p1[1] - p2[1];
        return Math.sqrt(x * x + y * y);
    }

    _getIndexOfIntersectingPart(geometry, coordinates) {
        let parts = [];
        switch (geometry.getType()) {
            case "MultiPoint":
                parts = geometry.getPoints();
                break;
            case "MultiLineString":
                parts = geometry.getLineStrings();
                break;
            case "MultiPolygon":
                parts = geometry.getPolygons();
                break;
            default:
                break;
        }
        return parts.reduce(
            ({ index, minDistance }, part, i) => {
                const distance = this._calculateDistanceToPart(part, coordinates);
                // Less than or equal means that if multiple parts have the same distance,
                // the last one (i.e. the newest one) will be removed.
                if (distance <= minDistance) {
                    index = i;
                    minDistance = distance;
                }
                return { index, minDistance };
            },
            { index: 0, minDistance: Infinity }
        ).index;
    }

    _updateFeatureChangeCount() {
        this._featureChangeCount++;
    }

    _handleAddPartRevolverButtonClick(coordinates) {
        this.setState({ mode: "drawing" }, () => {
            simulateClick(coordinates, this.props.map);
        });
    }

    async _handleDeletePartRevolverButtonClick(index) {
        const coordinates = this._feature.getGeometry().getCoordinates();
        if (coordinates.length > 1) {
            coordinates.splice(index, 1);
            this._feature.getGeometry().setCoordinates(coordinates);
        } else {
            // Part was last part, button shouldn't have been visible
            console.warn("Delete part was called with only one part left. Ignoring.");
        }
    }

    async _handleDeleteFeatureRevolverButtonClick() {
        await deleteOLFeature(this._feature, this.props.workspace, this.props.dbManager);
        this.props.onToolRequest("normal");
    }

    handleMapClick(event) {
        if (this.state.mode === "precision") {
            return;
        }
        if (this._addedVertex) {
            // This click was to add a vertex. Ignore.
            this._addedVertex = false;
            return;
        }

        const topButtons = [];

        const geometryType = this._feature.getGeometry().getType();
        const features = getFeaturesFromEvent(event);
        const didClickInsidePolygon =
            !!features.find(f => f.getId() === this._feature.getId()) && geometryType === "MultiPolygon";

        if (this._didClickVertex) {
            // Click on vertex of feature
            if (geometryType !== "MultiPoint") {
                topButtons.push({
                    id: "deleteVertex",
                    icon: <Delete />,
                    label: "Delete vertex",
                    destructive: true,
                    disabled: getVertexCount(this._feature.getGeometry()) <= 3,
                    disabledExplanation: "Too few vertices to remove.",
                    onClick: this._handleDeleteVertexRevolverButtonClick.bind(this)
                });
            }
            let label = "Precision edit";
            switch (geometryType) {
                case "Point":
                case "MultiPoint":
                    label += " point";
                    break;
                default:
                    label += " vertex";
                    break;
            }
            topButtons.push({
                id: "precisionEdit",
                icon: (
                    <SvgIcon>
                        <AimIcon />
                    </SvgIcon>
                ),
                label,
                onClick: this._startPrecisionEditing.bind(this)
            });
        } else if (didClickInsidePolygon) {
            // Click inside feature
            // topButtons.push({
            //     id: "addHole",
            // icon: <AddCircleOutline />,
            //     label: "Add hole",
            //     onClick: () => console.error("Add hole not implemented.")
            // });
        } else {
            // Click outside feature
            topButtons.push({
                id: "addPart",
                icon: <Add />,
                label: "Add part",
                onClick: () => this._handleAddPartRevolverButtonClick(event.coordinate)
            });
        }
        if (this._didClickVertex || didClickInsidePolygon) {
            if (this._feature.getGeometry().getCoordinates().length > 1) {
                const partIndex = this._getIndexOfIntersectingPart(this._feature.getGeometry(), event.coordinate);
                topButtons.push({
                    id: "deletePart",
                    icon: <DeleteForever />,
                    label: "Delete part",
                    destructive: true,
                    onClick: () => this._handleDeletePartRevolverButtonClick(partIndex)
                });
            }
        }

        if (topButtons.length === 0) {
            return;
        }

        this.props.onRevolverRequest(event.coordinate, { topButtons }, clickedButton => {
            if (clickedButton !== "precisionEdit") {
                this._currentVertex = null;
            }
            if (this._didClickVertex) {
                this._didClickVertex = false;
                this._updateStyle();
            }
        });
    }

    render() {
        const { mode } = this.state;
        return (
            <React.Fragment>
                {mode === "editing" && (
                    <OLModify
                        features={this._collection}
                        insertVertexCondition={this._insertVertexCondition}
                        deleteCondition={this._deleteCondition}
                        style={this._style}
                        innerRef={ref => (this._modify = ref)}
                        onModifyStart={this._onModifyStart}
                        onModifyEnd={this._onModifyEnd}
                        pixelTolerance={20}
                    />
                )}
                {mode === "drawing" && (
                    <OLDraw
                        type={this._feature.getGeometry().getType().slice(5)} // "multi".length
                        onDrawEnd={this._onDrawEnd}
                        stopClick={true}
                        condition={() => true}
                        freehandCondition={() => false}
                        wrapX={false}
                    />
                )}
                {mode === "precision" && (
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        bottom={0}
                        right={0}
                        zIndex={9999}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ pointerEvents: "none" }}
                    >
                        <SvgIcon sx={{ fontSize: 40 }}>
                            <AimIcon />
                        </SvgIcon>
                    </Box>
                )}
            </React.Fragment>
        );
    }
}

Edit.contextType = ErrorContext;

export default Edit;

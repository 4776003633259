import { useMemo, useState } from "react";
import { useFeatureInfo } from "../../useFeatureInfo";
import AttributeCellRenderer from "../../attribute-table/renderers/AttributeCellRenderer";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { FormControlLabel, Switch, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginBottom: "30px"
    },
    container: {
        margin: 0
    },
    title: {
        margin: "20px 0 0"
    },
    metadata: {
        fontSize: "12px",
        color: "#aaaaaa",
        margin: 0
    },
    noAttributes: {
        fontStyle: "italic"
    },
    gridHeader: {
        margin: 0,
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    editSwitch: {
        // Editing not yet implemented
        display: "none"
    }
}));

const useFeatureInfoColumnDefs = layer => {
    return useMemo(
        () => [
            {
                headerName: "Name",
                field: "name",
                width: 120,
                disableClickEventBubbling: true
            },
            {
                headerName: "Value",
                field: "value",
                sortable: false,
                flex: 1,
                disableClickEventBubbling: true,
                renderCell: params => (
                    <AttributeCellRenderer
                        title={params.row.name}
                        attribute={params.row.attribute}
                        layer={layer}
                        {...params}
                    />
                )
            }
        ],
        [layer]
    );
};

function FeatureInfo(props) {
    const { olFeature, attributeDefinitions, layer } = props;

    const classes = useStyles();
    const [editable, setEditable] = useState(false);

    const colDefs = useFeatureInfoColumnDefs(layer);
    const { attributes, derivedAttributes, parts, metadata } = useFeatureInfo(olFeature, attributeDefinitions);

    let content;
    if (attributes === null) {
        content = <CircularProgress />;
    } else {
        content = (
            <>
                <div className={classes.container}>
                    <h3 className={classes.gridHeader}>Attributes</h3>
                    <FormControlLabel
                        className={classes.editSwitch}
                        label="Editable"
                        labelPlacement="start"
                        control={
                            <Switch
                                checked={editable}
                                onChange={event => setEditable(event.target.checked)}
                                color="primary"
                            />
                        }
                    />
                    {attributes.length === 0 ? (
                        <div className={classes.container}>
                            <p className={classes.noAttributes}>No attributes</p>
                        </div>
                    ) : (
                        <DataGridPro
                            autoHeight={true}
                            rows={attributes}
                            columns={colDefs}
                            disableColumnMenu
                            hideFooter
                        />
                    )}
                </div>
                {derivedAttributes.length > 0 && (
                    <div className={classes.container}>
                        <h3 className={classes.gridHeader}>Derived</h3>
                        <DataGridPro
                            autoHeight={true}
                            rows={derivedAttributes}
                            columns={colDefs}
                            disableColumnMenu
                            hideFooter
                        />
                    </div>
                )}
                {parts?.length > 1 &&
                    parts.map((part, i) => (
                        <div key={i} className={classes.container}>
                            <h3 className={classes.gridHeader}>Part {i + 1}</h3>
                            <DataGridPro autoHeight={true} rows={part} columns={colDefs} disableColumnMenu hideFooter />
                        </div>
                    ))}
                {metadata && (
                    <div className={classes.container}>
                        <h3 className={classes.gridHeader}>Metadata</h3>
                        <DataGridPro
                            autoHeight={true}
                            rows={Object.keys(metadata).map(key => ({ id: key, name: key, value: metadata[key] }))}
                            columns={colDefs}
                            disableColumnMenu
                            hideFooter
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <h2 className={classes.title}>Info</h2>
            </div>
            {content}
        </div>
    );
}

export default FeatureInfo;

import React from "react";
import Color from "./Color";
import Opacity from "./Opacity";
import Integer from "./Integer";
import OutlinedContainer from "../OutlinedContainer";

import { Typography, Button, Tabs, Tab } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between"
    },
    actionButtonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: "10px"
    },
    deleteButton: {
        color: theme.colors.destructive
    }
}));

function Stroke({ title, stroke, index, onChange, enableDelete, onDelete }) {
    const handleChange = key => value => onChange(currentStroke => ({ ...currentStroke, [key]: value }), index);

    const classes = useStyles();

    const showHeader = !!title;

    return (
        <React.Fragment>
            {showHeader && (
                <div className={classes.header}>{title && <Typography variant="h6">{title}</Typography>}</div>
            )}
            <OutlinedContainer label="Type">
                <Tabs
                    value={stroke.type}
                    onChange={(event, value) => handleChange("type")(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="none" value="none" />
                    <Tab label="solid" value="solid" />
                    <Tab label="dashed" value="dashed" />
                </Tabs>
            </OutlinedContainer>
            {["solid", "dashed"].includes(stroke.type) && (
                <React.Fragment>
                    <Color
                        color={stroke.color}
                        opacity={stroke.opacity}
                        onColorChange={handleChange("color")}
                        onOpacityChange={handleChange("opacity")}
                    />
                    <Opacity opacity={stroke.opacity} onChange={handleChange("opacity")} />
                    <Integer label="Width" value={stroke.width} defaultValue={1} onChange={handleChange("width")} />
                </React.Fragment>
            )}
            {enableDelete && (
                <div className={classes.actionButtonContainer}>
                    <Button className={classes.deleteButton} onClick={() => onDelete(index)}>
                        Delete stroke
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
}

export default React.memo(
    Stroke,
    (prevProps, nextProps) =>
        prevProps.title === nextProps.title &&
        prevProps.stroke === nextProps.stroke &&
        prevProps.index === nextProps.index &&
        prevProps.enableDelete === nextProps.enableDelete
);

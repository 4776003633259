const colors = {
    green: "#71bf44",
    "green-90": "#7fa953",
    "green-80": "#8ec964",
    "green-70": "#9bce77",
    "green-60": "#a9d38a",
    "green-50": "#b7db9c",
    "green-40": "#c5e1ae",
    "green-30": "#d2e8c1",
    "green-20": "#e0efd4",
    "green-10": "#eff7e9",

    "dark-green": "#346b20",
    "dark-green-80": "#56813e",
    "dark-green-60": "#7a9a64",
    "dark-green-40": "#9fb88f",
    "dark-green-20": "#c9d7c0",

    beige: "#d4d6be",
    "beige-80": "#dddfc8",
    "beige-60": "#e4e7d4",
    "beige-40": "#edeee2",
    "beige-20": "#f2f5ed",

    teal: "#008585",

    warning: "#ff981e",
    error: "#ff2c2a",
    errorDark: "#c20300"
};

function hexToRgb(hex, expand = true) {
    if (typeof hex !== "string") {
        return null;
    }
    if (expand) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });
    }

    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null;
}

function componentToHex(c) {
    let hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

function isComponentValid(c) {
    return typeof c === "number" && !Number.isNaN(c) && c >= 0 && c <= 255;
}

function rgbToHex(rgb) {
    if (!isComponentValid(rgb.r) || !isComponentValid(rgb.g) || !isComponentValid(rgb.b)) {
        return null;
    }
    return `#${componentToHex(rgb.r)}${componentToHex(rgb.g)}${componentToHex(rgb.b)}`;
}

function cmykToRgb(c, m, y, k) {
    return {
        r: Math.round(255.0 * (1.0 - (c / 100)) * (1.0 - (k / 100))), 
        g: Math.round(255.0 * (1.0 - (m / 100)) * (1.0 - (k / 100))), 
        b: Math.round(255.0 * (1.0 - (y / 100)) * (1.0 - (k / 100)))
    };
}

function cmykToHex(c, m, y, k) {
    return rgbToHex(cmykToRgb(c, m, y, k));
}

module.exports = {
    colors,
    cmykToHex,
    cmykToRgb,
    hexToRgb,
    rgbToHex
};

import React from "react";
import classNames from "classnames";
import { hexToRgb } from "../../resources/colors";

import { Checkbox, Tooltip } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => {
    const { r, g, b } = hexToRgb(theme.colors.primary);
    return {
        checkbox: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",

            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.35)"
            },

            "&.checked": {
                backgroundColor: theme.colors.primary,

                "&:hover": {
                    backgroundColor: `rgba(${r}, ${g}, ${b}, 0.7)`
                }
            },

            "&.disabled": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",

                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)"
                }
            }
        },
        icon: {
            color: "white"
        },
        uncheckedIcon: {
            color: "rgba(0, 0, 0, 0.54)",

            "&.disabled": {
                color: "rgba(0, 0, 0, 0.25)"
            }
        }
    };
});

function IconCheckbox({ checked, checkedIcon, uncheckedIcon, onChange, disabled, tooltipTitle }) {
    const classes = useStyles();
    return (
        <Tooltip title={tooltipTitle} enterDelay={0} enterTouchDelay={0} placement="top">
            <span>
                <Checkbox
                    size="small"
                    className={classNames(classes.checkbox, { checked, disabled })}
                    checked={checked}
                    checkedIcon={React.cloneElement(checkedIcon, { className: classes.icon })}
                    icon={React.cloneElement(uncheckedIcon, {
                        className: classNames(classes.uncheckedIcon, { disabled })
                    })}
                    onClick={event => event.stopPropagation()}
                    onFocus={event => event.stopPropagation()}
                    onChange={(_, checked) => onChange(checked)}
                    disabled={disabled}
                />
            </span>
        </Tooltip>
    );
}

export default IconCheckbox;

import { useEffect, useContext } from "react";
import { MapContext } from "../map-context";

import { unByKey } from "ol/Observable";
import { Pointer as PointerInteraction } from "ol/interaction";

// Time between two down events to count as a double click
const DOUBLE_CLICK_TIME_TOLERANCE = 200;
// Sensitivity for dragging to zoom. Larger number = less sensitive.
const ZOOM_SCALE_FACTOR = 100;
// Duration for double click zoom animation
const ZOOM_ANIMATION_DURATION = 250;

class ClickZoom extends PointerInteraction {
    constructor(options) {
        super(options);
        this._map = options.map;
        this._lastMapClick = 0;
        this._pixel = null;
        this._zooming = false;
        this.onZoom = (options.onZoom || (() => {})).bind(this);
        this.onDrag = (options.onDrag || (() => {})).bind(this);
        this.onClick = (options.onClick || (() => {})).bind(this);
        this.onDoubleClick = (options.onDoubleClick || (() => {})).bind(this);
        this.handleMapClick = this.handleMapClick.bind(this);
    }

    handleDownEvent(event) {
        clearTimeout(this._singleClickTimeout);
        if (Date.now() - this._lastMapClick < DOUBLE_CLICK_TIME_TOLERANCE) {
            this._map.getLayers().forEach(layer => {
                const setPaused = layer.getSource().get("setPausedFunction");
                if (setPaused) {
                    setPaused(true);
                }
            });
            this._zooming = true;
            this._pixel = event.pixel;
            this._dragged = false;
            return true;
        }
        return false;
    }

    handleDragEvent(event) {
        if (this._zooming) {
            const deltaY = event.pixel[1] - this._pixel[1];
            this.onZoom(deltaY, event.coordinate);
        }
        this._pixel[0] = event.pixel[0];
        this._pixel[1] = event.pixel[1];
        this._dragged = true;
    }

    handleUpEvent(event) {
        if (this._zooming) {
            this._zooming = false;
        }
        if (!this._dragged) {
            // Double click
            this.onDoubleClick(event);
            this._handledClick = true;
        }
        this._map.getLayers().forEach(layer => {
            const setPaused = layer.getSource().get("setPausedFunction");
            if (setPaused) {
                setPaused(false);
            }
        });
        return false;
    }

    handleMapClick(event) {
        this._lastMapClick = Date.now();
        if (!this._handledClick) {
            this._singleClickTimeout = setTimeout(() => {
                this.onClick(event);
            }, DOUBLE_CLICK_TIME_TOLERANCE);
        }
        this._handledClick = false;
    }
}

function ClickZoomInteraction(props) {
    const { onClick } = props;
    const { map } = useContext(MapContext);
    useEffect(() => {
        const interaction = new ClickZoom({
            onZoom: delta => {
                const view = map.getView();
                const newZoom = view.getZoom() + delta / ZOOM_SCALE_FACTOR;
                view.setZoom(newZoom);
            },
            onDoubleClick: event => {
                const view = map.getView();
                const center = view.getCenter();
                const newCenter = [(center[0] + event.coordinate[0]) / 2, (center[1] + event.coordinate[1]) / 2];
                view.animate({
                    zoom: view.getZoom() + 1,
                    center: newCenter,
                    duration: ZOOM_ANIMATION_DURATION
                });
            },
            onClick,
            map
        });
        const clickListener = map.on("click", interaction.handleMapClick);
        map.addInteraction(interaction);
        return () => {
            unByKey(clickListener);
            map.removeInteraction(interaction);
        };
    }, [map, onClick]);

    return null;
}

export default ClickZoomInteraction;

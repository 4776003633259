import React, { Component } from "react";
import { ErrorContext } from "./error-context";
import ErrorDisplay from "./ErrorDisplay";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.setError = this.setError.bind(this);
        this._clearError = this._clearError.bind(this);

        this.state = {
            errors: [],
            idCounter: 0
        };
        this.contextValue = {
            setError: this.setError,
            clearError: this._clearError
        };
    }

    componentDidCatch(error) {
        console.logError(error, "ErrorBoundary.componentDidCatch");
        this.setError(error);
    }

    setError(errorOrMessage, type = "error", callback) {
        this.setState(state => {
            const errors = state.errors.slice();
            let error = errorOrMessage;
            if (typeof error === "string") {
                error = { message: error };
            }
            error.type = type;
            error.id = state.idCounter + 1;
            error.callback = callback;
            errors.push(error);
            console.error(`ErrorBoundary.setError (type "${type}"): ${error.message}`);
            return { errors, idCounter: error.id };
        });
    }

    _clearError(error) {
        this.setState(state => {
            const errors = state.errors.slice();
            const index = errors.findIndex(e => e.id === error.id);
            if (index !== -1) {
                const [error] = errors.splice(index, 1);
                if (error?.callback) {
                    error.callback();
                }
            }
            return { ...state, errors };
        });
    }

    render() {
        const { errors } = this.state;
        return (
            <ErrorContext.Provider value={this.contextValue}>
                {errors.map((error, index) => (
                    <ErrorDisplay key={index} error={error} />
                ))}
                {this.props.children}
            </ErrorContext.Provider>
        );
    }
}

ErrorBoundary.contextType = ErrorContext;

export default ErrorBoundary;

import React, { useCallback, useMemo, useState } from "react";
import { LoadingContext } from "./loading-context";

import {
    Button,
    ClickAwayListener,
    Chip,
    CircularProgress,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    loadingBar: {
        position: "fixed",
        zIndex: 10000,
        bottom: 0,
        left: 0,
        right: 0
    },
    progressIndicator: {
        height: "5px",
        width: "100%"
    },
    text: {
        marginLeft: theme.spacing(1)
    },
    container: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1)
    },
    listContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(1 / 2)
    },
    listItem: {
        display: "flex",
        alignItems: "center",

        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    },
    hideButton: {
        padding: 0
    }
}));

function Loader({ children }) {
    const classes = useStyles();

    const [loaders, setLoaders] = useState([]);
    const [listOpen, setListOpen] = useState(false);

    // For debugging purposes
    window.__atlas.loaders = loaders;

    const handleStartLoading = useCallback((text = null, customId = null) => {
        const id = customId ?? `${Date.now()}${Math.random()}`;
        // console.log(`Start loading (id ${id}): ${text}`);
        setLoaders(loaders => {
            const newLoaders = [...loaders];
            const newLoader = { id, text, originalText: text };
            const existingIndex = newLoaders.findIndex(loader => loader.id === id);
            if (existingIndex !== -1) {
                newLoaders[existingIndex] = newLoader;
            } else {
                newLoaders.push(newLoader);
            }
            return newLoaders;
        });
        return id;
    }, []);
    const handleStopLoading = useCallback(id => {
        // console.log(`Stop loading (id ${id})`);
        setLoaders(loaders => [...loaders.filter(loader => loader.id !== id)]);
    }, []);
    const contextValue = useMemo(() => ({ startLoading: handleStartLoading, stopLoading: handleStopLoading }), [
        handleStartLoading,
        handleStopLoading
    ]);

    const hideLoader = useCallback(id => {
        setLoaders(loaders => loaders.map(loader => (loader.id === id ? { ...loader, text: null } : loader)));
    }, []);

    const loadersWithText = loaders.filter(loader => !!loader.text);

    return (
        <LoadingContext.Provider value={contextValue}>
            {children}
            {loaders.length > 0 && (
                <div className={classes.loadingBar}>
                    {listOpen && loadersWithText.length > 1 && (
                        <ClickAwayListener onClickAway={() => setListOpen(false)}>
                            <div className={classes.listContainer}>
                                <Paper>
                                    <List dense>
                                        {loadersWithText.slice(0, -1).map((loader, i) => (
                                            <ListItem
                                                key={loader.id}
                                                className={classes.listItem}
                                                divider={i < loadersWithText.length - 2}
                                            >
                                                <CircularProgress size={16} />
                                                <ListItemText primary={loader.text} />
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => hideLoader(loader.id)}
                                                >
                                                    Hide
                                                </Button>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            </div>
                        </ClickAwayListener>
                    )}
                    <LinearProgress className={classes.progressIndicator} />
                    {loadersWithText.length > 0 && (
                        <div className={classes.container}>
                            {loadersWithText.length > 1 && (
                                <Chip
                                    size="small"
                                    onClick={() => setListOpen(open => !open)}
                                    label={loadersWithText.length}
                                />
                            )}
                            <Typography className={classes.text} variant="body2">
                                {loadersWithText[loadersWithText.length - 1].text}
                            </Typography>
                            <Button
                                className={classes.hideButton}
                                size="small"
                                color="primary"
                                onClick={() => hideLoader(loadersWithText[loadersWithText.length - 1].id)}
                            >
                                Hide
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </LoadingContext.Provider>
    );
}

export default React.memo(Loader);

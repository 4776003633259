import React, { useState } from "react";
import Fills from "./Fills";
import Strokes from "./Strokes";
import Symbol from "./Symbol";
import Label from "./Label";
import ExpandingStyleListItem from "./ExpandingStyleListItem";
import NumberInput from "../inputs/NumberInput";
import FUXJSONLogic from "foran-ux/dist/FUXJSONLogic";
import { DERIVED_ATTRIBUTES } from "./conditions/AttributePicker";

import { List, Button, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    nameContainer: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    nameInput: { ...theme.typography.h6 },
    overrideButton: {
        marginBottom: theme.spacing(1)
    },
    footer: {
        display: "flex",
        justifyContent: "flex-end",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    deleteButton: {
        color: theme.colors.destructive
    },
    textField: {
        "&:not(:first-child)": {
            marginTop: theme.spacing(1)
        }
    }
}));

const createEmptyStyleObject = type => {
    switch (type) {
        case "fill":
        case "stroke":
            return [{ type: "none" }];
        case "symbol":
            return { type: "none" };
        default:
            return {};
    }
};

function StyleForm({ style, index, attributes, featureType, onChange, onDeleteStyle, className }) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState();

    const listItemProps = {
        onClick: id => setExpanded(id === expanded ? false : id),
        onOverride: id => onChange(currentStyle => ({ ...currentStyle, [id]: createEmptyStyleObject(id) }), index),
        onDelete: id =>
            onChange(currentStyle => {
                const newStyle = { ...currentStyle };
                delete newStyle[id];
                if (id === expanded) {
                    setExpanded(false);
                }
                return newStyle;
            }, index)
    };

    const handleChange = key => value =>
        onChange(
            currentStyle => ({
                ...currentStyle,
                [key]: typeof value === "function" ? value(currentStyle[key]) : value
            }),
            index
        );

    const variables = [
        ...DERIVED_ATTRIBUTES[featureType].map(attribute => ({
            id: attribute._id,
            name: attribute.name,
            type: attribute.type,
            category: attribute.section === "map" ? "Map" : "Derived attributes"
        })),
        ...attributes.map(attribute => ({
            id: attribute._id,
            name: attribute.name,
            type: attribute.type,
            category: "Attributes"
        }))
    ];

    return (
        <div className={className}>
            <TextField
                fullWidth
                className={classes.textField}
                variant="outlined"
                label="Name"
                value={style.name}
                onChange={event => handleChange("name")(event.target.value || "")}
            />
            <NumberInput
                fullWidth
                className={classes.textField}
                variant="outlined"
                label="Drawing priority"
                integer
                min={0}
                step={1}
                value={style.zIndex}
                onChange={zIndex => handleChange("zIndex")(zIndex)}
            />
            <List dense>
                {featureType === "polygon" && (
                    <ExpandingStyleListItem
                        title="Fill"
                        id="fill"
                        expanded={expanded === "fill"}
                        inherited={style.fill === undefined}
                        {...listItemProps}
                    >
                        <Fills fills={style.fill} onChange={handleChange("fill")} />
                    </ExpandingStyleListItem>
                )}
                {["polygon", "line"].includes(featureType) && (
                    <ExpandingStyleListItem
                        title="Stroke"
                        id="stroke"
                        expanded={expanded === "stroke"}
                        inherited={style.stroke === undefined}
                        {...listItemProps}
                    >
                        <Strokes strokes={style.stroke} onChange={handleChange("stroke")} />
                    </ExpandingStyleListItem>
                )}
                {featureType === "point" && (
                    <ExpandingStyleListItem
                        title="Symbol"
                        id="symbol"
                        expanded={expanded === "symbol"}
                        inherited={style.symbol === undefined}
                        {...listItemProps}
                    >
                        <Symbol symbol={style.symbol} onChange={handleChange("symbol")} />
                    </ExpandingStyleListItem>
                )}
                <ExpandingStyleListItem
                    title="Label"
                    id="label"
                    expanded={expanded === "label"}
                    inherited={style.label === undefined}
                    {...listItemProps}
                >
                    <Label label={style.label} onChange={handleChange("label")} />
                </ExpandingStyleListItem>
                <ExpandingStyleListItem
                    title="Condition"
                    id="condition"
                    expanded={expanded === "condition"}
                    disableInherit
                    {...listItemProps}
                >
                    <FUXJSONLogic
                        schema={style.condition}
                        onChange={handleChange("condition")}
                        variableTitle="Attribute"
                        variables={variables}
                    />
                </ExpandingStyleListItem>
            </List>
            <div className={classes.footer}>
                <Button className={classes.deleteButton} onClick={() => onDeleteStyle(index)}>
                    Delete style
                </Button>
            </div>
        </div>
    );
}

export default React.memo(
    StyleForm,
    (prevProps, nextProps) =>
        prevProps.style === nextProps.style &&
        prevProps.index === nextProps.index &&
        prevProps.featureType === nextProps.featureType &&
        prevProps.attributes === nextProps.attributes &&
        prevProps.className === nextProps.className
);

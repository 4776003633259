import React from "react";
import { addEnvironment, APPS } from "foran-ux/dist/apps";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import MainMenu from "./MainMenu";
import Workspace from "./workspace/Workspace";
import UserReports from "./UserReports";
import UserLogs from "./UserLogs";

function AtlasRouter() {
    const drive = addEnvironment(APPS.drive);

    return (
        <Router>
            <Route path="/" exact render={() => <Redirect to="/workspaces" />} />
            <Route path="/workspaces" exact component={MainMenu} />
            <Route
                path="/browse/:id?"
                exact
                render={({ match }) => {
                    window.location.href = `${drive.url}/directory/${match.params.id}`;
                    return null;
                }}
            />
            <Route
                path="/workspaces/:id"
                exact
                render={props => (
                    <Workspace workspaceId={props.match.params.id} onClose={() => props.history.goBack()} {...props} />
                )}
            />
            <Route path="/logs" exact component={UserReports} />
            <Route path="/logs/:report" exact component={UserLogs} />
        </Router>
    );
}

export default React.memo(AtlasRouter);

import { colors } from "../resources/colors";
import { grey } from "@mui/material/colors";
import { createTheme, adaptV4Theme } from "@mui/material/styles";

const v5theme = createTheme(
    adaptV4Theme({
        palette: {
            primary: {
                light: colors["green-50"],
                main: colors.green,
                dark: colors["dark-green"],
                contrastText: "#ffffff"
            },
            secondary: {
                light: colors["beige-60"],
                main: colors.beige
            },
            destructive: {
                main: colors.error
            }
        },
        variables: {
            navbarHeight: 70,
            maxWidth: "1400px"
        },
        typography: {
            useNextVariants: true
        },
        colors: {
            teal: colors.teal,
            primary: colors.green,
            darkGreen: colors["dark-green"],
            darkGreen80: colors["dark-green-80"],
            darkGreen60: colors["dark-green-60"],
            darkGreen40: colors["dark-green-40"],
            darkGreen20: colors["dark-green-20"],
            warning: colors.warning,
            error: colors.error,
            destructive: colors.error,
            destructiveDark: colors.errorDark,
            disabled: grey[300],
            disabledText: "#a0a0a0",
            nested: ["#e0efd4", "#e3eadf"]
        }
    })
);

v5theme.components = {
    MuiListItem: {
        styleOverrides: {
            root: {
                [v5theme.breakpoints.down("sm")]: {
                    paddingLeft: v5theme.spacing(1)
                }
            }
        }
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                [v5theme.breakpoints.down("sm")]: {
                    paddingLeft: v5theme.spacing(1)
                }
            }
        }
    },
    MuiListItemIcon: {
        styleOverrides: {
            root: {
                minWidth: 0,
                marginRight: v5theme.spacing(1)
            }
        }
    },
    MuiDataGrid: {
        styleOverrides: {
            panelWrapper: ({ theme }) => ({
                maxWidth: `calc(100vw)`
            })
        }
    }
};

export const theme = createTheme(v5theme);

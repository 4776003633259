import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ErrorContext } from "../../error-context";
import { postNativeMessage } from "../../../Native";

export const GeolocationContext = createContext({
    tracking: false,
    accuracy: null,
    setTracking: () => {},
    coordinates: []
});

export const getCurrentLocation = async () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: true });
    });
};

function GeolocationWrapper({ children }) {
    const { setError } = useContext(ErrorContext);
    const [tracking, setTracking] = useState(false);
    const [coordinates, setCoordinates] = useState(null);
    const [accuracy, setAccuracy] = useState(null);

    const handleSetTracking = useCallback(tracking => {
        if (!tracking) {
            setCoordinates(null);
            setAccuracy(null);
        }
        setTracking(tracking);
    }, []);

    const contextValue = useMemo(
        () => ({ tracking, setTracking: handleSetTracking, accuracy, coordinates }),
        [tracking, handleSetTracking, accuracy, coordinates]
    );

    const handleError = useCallback(
        error => {
            const _error = new Error(`An error occurred while getting your location: ${error.message}`);
            _error.name = "GEOLOCATION_ERROR";
            setError(_error);
            setTracking(false);
            setCoordinates(null);
            setAccuracy(null);
        },
        [setError]
    );

    useEffect(() => {
        if (!tracking) {
            return;
        }

        let watchId;
        let interval;

        const handlePositionError = error => {
            console.logError(error, "Geolocation wrapper error");
            interval && clearInterval(interval);
            watchId && navigator.geolocation.clearWatch(watchId);
            handleError(error);
        };
        const handlePositionUpdate = event => {
            setCoordinates([event.coords.longitude, event.coords.latitude]);
            setAccuracy(event.coords.accuracy);
        };

        if (window.ATLAS_IS_NATIVE) {
            window.ATLAS_NATIVE_GEOLOCATION_UPDATE = coords => {
                if (
                    typeof coords === "object" &&
                    coords.hasOwnProperty("latitude") &&
                    coords.hasOwnProperty("longitude") &&
                    coords.hasOwnProperty("accuracy")
                ) {
                    const event = { coords };
                    handlePositionUpdate(event);
                }
            };
            postNativeMessage("geolocation-start", { callback: "window.ATLAS_NATIVE_GEOLOCATION_UPDATE" })
                .then(() => {
                    console.log("Initiated native geolocation successfully.");
                })
                .catch(error => {
                    console.logError(error, "Error initiating native geolocation");
                    handleError({ message: error });
                });
        } else {
            watchId = navigator.geolocation.watchPosition(handlePositionUpdate, handlePositionError, {
                enableHighAccuracy: true
            });
            interval = setInterval(() => {
                watchId && navigator.geolocation.clearWatch(watchId);
                watchId = navigator.geolocation.watchPosition(handlePositionUpdate, handlePositionError, {
                    enableHighAccuracy: true
                });
            }, 5000);
        }

        return () => {
            interval && clearInterval(interval);
            watchId && navigator.geolocation.clearWatch(watchId);
            if (window.ATLAS_IS_NATIVE) {
                window.ATLAS_NATIVE_GEOLOCATION_UPDATE = () => {};
                postNativeMessage("geolocation-stop").catch(error => {
                    console.logError(error, "Error stopping native geolocation");
                    handleError({ message: error });
                });
            }
        };
    }, [tracking, handleError]);

    return <GeolocationContext.Provider value={contextValue}>{children}</GeolocationContext.Provider>;
}

export default GeolocationWrapper;

import React from "react";

import { CircularProgress, Dialog, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(_ => ({
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "#ffffff",
        opacity: 0.8,
        zIndex: 10000,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
}));

function LoadingDialog({ loading, progress, message, children, onClose, ...other }) {
    const classes = useStyles();

    const handleClose = (event, reason) => {
        if (loading && reason === "backdropClick") {
            return false;
        }

        if (typeof onClose === "function") {
            onClose(event);
        }
    };

    return (
        <Dialog onClose={handleClose} {...other}>
            {loading && (
                <div className={classes.container}>
                    <CircularProgress />
                    {progress !== null && (
                        <div className={classes.textContainer}>
                            <Typography variant="overline">{Math.round(progress * 100)}% complete</Typography>
                            {message && <Typography variant="overline">{message}</Typography>}
                        </div>
                    )}
                </div>
            )}
            {children}
        </Dialog>
    );
}

export default LoadingDialog;

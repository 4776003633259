import NullableCellRenderer from "./NullableCellRenderer";

const getDisplayValue = value => {
    if (Array.isArray(value)) {
        return value.map(getDisplayValue);
    } else if (value) {
        try {
            return JSON.stringify(value);
        } catch (_) {
            return null;
        }
    }
    return value;
};

function CustomCellRenderer({ value, ...other }) {
    const displayValue = getDisplayValue(value);
    return <NullableCellRenderer {...other} shortValue={displayValue && "CUSTOM"} value={displayValue ?? ""} />;
}

export default CustomCellRenderer;

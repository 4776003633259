import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UpdateContext } from "./update-context";
import { ErrorContext } from "./error-context";
import { postNativeMessage } from "../Native";

import { Snackbar, IconButton, Button } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

function UpdateChecker({ version, children }) {
    const { setError } = useContext(ErrorContext);
    const [nativeUpdateAvailable, setNativeUpdateAvailable] = useState(false);
    const [noUpdateAvailable, setNoUpdateAvailable] = useState(false);
    const [updateAvailable, setUpdateAvailable] = useState(false);

    const checkForNativeUpdate = useCallback((alwaysShowResult = false) => {
        if (window.ATLAS_IS_NATIVE) {
            console.log("Checking for native update...");
            postNativeMessage("check-for-update")
                .then(updateAvailable => {
                    setNativeUpdateAvailable(updateAvailable);
                    if (!updateAvailable && alwaysShowResult) {
                        setNoUpdateAvailable(true);
                    }
                })
                .catch(error => {
                    console.log("Could not check for native update:", error);
                });
        }
    }, []);
    const contextValue = useMemo(() => ({ checkForUpdate: checkForNativeUpdate }), [checkForNativeUpdate]);

    useEffect(() => {
        const onUpdateAvailable = () => {
            setUpdateAvailable(true);
        };
        version.on("update", onUpdateAvailable);
        return () => version.off("update", onUpdateAvailable);
    }, [version]);

    useEffect(() => {
        if (window.ATLAS_IS_NATIVE) {
            checkForNativeUpdate();
        }
    }, [checkForNativeUpdate]);

    const updateNow = async () => {
        try {
            await postNativeMessage("update");
        } catch (error) {
            setError(error);
        } finally {
            setNativeUpdateAvailable(false);
        }
    };

    let message = "";
    if (updateAvailable) {
        message =
            "A new version of Atlas is available. To get the update, first close all open Atlas tabs and apps, then reopen Atlas..";
    }
    if (nativeUpdateAvailable) {
        message =
            "A new version of Atlas is available. It will be automatically applied the next time Atlas is launched.";
    }
    return (
        <UpdateContext.Provider value={contextValue}>
            {children}
            <Snackbar
                open={updateAvailable || nativeUpdateAvailable}
                onClose={(_, reason) => {
                    if (reason !== "clickaway") {
                        setUpdateAvailable(false);
                        setNativeUpdateAvailable(false);
                    }
                }}
                message={message}
                action={
                    <React.Fragment>
                        {nativeUpdateAvailable && (
                            <Button color="primary" onClick={updateNow}>
                                Update now
                            </Button>
                        )}
                        <IconButton
                            onClick={() => {
                                setUpdateAvailable(false);
                                setNativeUpdateAvailable(false);
                            }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                }
            ></Snackbar>
            <Snackbar
                open={noUpdateAvailable}
                onClose={() => setNoUpdateAvailable(false)}
                message="You have the latest version of Atlas."
                action={
                    <IconButton onClick={() => setNoUpdateAvailable(false)} size="large">
                        <CloseIcon />
                    </IconButton>
                }
            />
        </UpdateContext.Provider>
    );
}

export default React.memo(UpdateChecker);

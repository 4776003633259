import React, { useState } from "react";
import FUXNavbar from "foran-ux/dist/FUXNavbar";
import Settings from "./Settings";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as AtlasLogo } from "../icons/originals/atlas-logo.svg";
import { Settings as SettingsIcon } from "@mui/icons-material";

function NavBar() {
    const [settingsOpen, setSettingsOpen] = useState(false);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex"
            }}
        >
            <FUXNavbar
                title="Atlas"
                svgLogo={AtlasLogo}
                endButtons={
                    <>
                        <IconButton color="primary" size="large" onClick={() => setSettingsOpen(true)}>
                            <SettingsIcon fontSize="inherit" />
                        </IconButton>
                    </>
                }
            />
            <Settings open={settingsOpen} handleClose={() => setSettingsOpen(false)} />
        </Box>
    );
}

export default NavBar;

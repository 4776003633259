import { EventEmitter } from "events";
import LRU from "lru-cache";

// Only supports BLOBS!!!!!!!!
const MAX_CACHE_SIZE = 40_000_000;
export default class DownloadManager extends EventEmitter {
    constructor(options = {}) {
        super();
        this._concurrentLimit = options.concurrentLimit || 1;
        if( options.cache ){
            const maxCacheSize = options.maxCacheSize || MAX_CACHE_SIZE;
            this._cache = new LRU({
                max: maxCacheSize,
                length: entry => entry.size
            });
        }

        this._downloading = 0;
        //this._queue = [];
        this._currentId = 0;

        /*
        Map {
            url: Map { id: {resolve, reject } }
        }
         */
        this._queue = new Map();
    }

    getNewId() {
        return this._currentId++;
    }

    async download(url, id) {
        //console.log(`Download manager ${url}`);
        const cached = this._getCached(url);
        return cached || this._addToQueue(url, id ?? this.getNewId());
    }


    removeFromQueue(ids){
        // Make sure it's an array and make a copy
        const _ids = (Array.isArray(ids) ? ids : [ids]).slice();
        while(_ids.length){
            const id = _ids.pop();
            for( const queueEntry of this._queue.entries() ){
                const url = queueEntry[0];
                const queuedIds = queueEntry[1];
                if( queuedIds.has(id) ){
                    const { reject } = queuedIds.get(id);
                    const error = new Error("The download was intentionally cancelled");
                    error.name = "CANCELLED";
                    reject(error);
                    queuedIds.delete(id);
                    if( queuedIds.size === 0 ){
                        this._queue.delete(url);
                    }
                    break;
                }
            }
        }
    }

    async _addToQueue(url, id) {
        return new Promise((resolve, reject) => {
            const handlerObject = {
                resolve,
                reject
            };

            if( this._queue.has(url) ){
                this._queue.get(url).set(id, handlerObject);
            } else {
                const urlQueueSpot = new Map();
                urlQueueSpot.set(id, handlerObject);
                this._queue.set(url, urlQueueSpot);
                this._startQueue();
            }
        });
    }

    _getCached(url){
        return this._cache && this._cache.get(url);
    }

    _addToCache(url, blob){
        this._cache && this._cache.set(url, blob);
    }

    async _startQueue() {
        if (this._downloading >= this._concurrentLimit  ) {
            return;
        }
        const queueEntry = this._queue.entries().next();
        if( queueEntry.done ){
            return;
        }
        const downloadUrl = queueEntry.value[0];
        this._queue.delete(downloadUrl);
        const downloadFinishedHandlers = queueEntry.value[1];
        this._downloading++;
        let response;
        let error;
        let blob;
        try {
            response = await fetch(downloadUrl);
        } catch (e) {
            console.logError(e, "DownloadManager: fetch rejected (most likely offline)");
            error = e;
        }
        if (response) {
            if( response.ok ) {
                try {
                    blob = await response.blob();
                    this._addToCache(downloadUrl, blob);
                } catch (err) {
                    error = err;
                }
            } else {
                try {
                    const body = await response.json();
                    error = body.error;
                } catch {
                    error = new Error("Invalid server response.");
                }
            }
        }
        this._downloading--;
        if( response && response.ok && blob ){
            for( const downloadFinishedHandler of downloadFinishedHandlers.values() ){
                downloadFinishedHandler.resolve(blob);
            }
        } else {
            for( const downloadFinishedHandler of downloadFinishedHandlers.values() ){
                downloadFinishedHandler.reject(error);
            }
        }
        this._startQueue();
    }
}

import React, { memo, useEffect, useMemo } from "react";
import { Controller, useWatch, useFormContext, useController } from "react-hook-form";
import { usePrevious } from "../../../usePrevious";
import { getErrorMessages, hasError, hasWarning } from "../../form-utils";
import { treeSpecies as FORESTAND_SPECIES } from "forestand-definitions";
import Decimal from "decimal.js";
import ExpandingListItem from "../../../ExpandingListItem";
import NumberField from "../../NumberField";
import SimpleNumericInput, { validateNumber } from "../../../inputs/SimpleNumericInput";
import WarnableTextField from "../../WarnableTextField";

import { IconButton, ListItemText, MenuItem } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    },
    // From EnumField:
    selectContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        minHeight: "37px" // Original padding was 18.5px
    },
    menuItem: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

const useAmountRules = (getValues, key) =>
    useMemo(
        () => ({
            validate: {
                error0: value => {
                    const error = validateNumber(value, { min: 0, max: 100 });
                    if (error) {
                        return error;
                    }
                    const allSpecies = getValues(key);
                    const total = allSpecies
                        .reduce((total, species) => total.plus(Number(species.amount)), new Decimal(0))
                        .toNumber();
                    if (total !== 1) {
                        return "Must sum to 100";
                    }
                }
            }
        }),
        [getValues, key]
    );
const useNumericRules = ({ min, max } = {}) =>
    useMemo(
        () => ({
            validate: {
                error0: value => {
                    const error = validateNumber(value, { min, max });
                    if (error) {
                        return error;
                    }
                }
            }
        }),
        [min, max]
    );

function ForestandSpecies({ name, schema, onRemoveFromArray, disabled }) {
    const classes = useStyles();
    const allSpecies = useWatch({ name: schema.key });
    const { trigger, getValues } = useFormContext();
    const species = useWatch({ name: `${name}.type` });

    const numericRules = useNumericRules({ min: 0 });

    const amountRules = useAmountRules(getValues, schema.key);
    const amountController = useController({ name: `${name}.amount`, rules: amountRules });
    // The ref will cause the amount to be displayed without being transformed 0-1 -> 0-100
    delete amountController.field.ref;
    let amount = null;
    if (typeof amountController.field.value === "number") {
        amount = new Decimal(amountController.field.value).times(100).toNumber();
    }
    const amountErrorType = amountController.fieldState.error?.type;
    const previousAmountErrorType = usePrevious(amountErrorType);
    useEffect(() => {
        // Trigger validation on other species amount fields if error state changes
        if (amountErrorType !== previousAmountErrorType) {
            const allSpecies = getValues(schema.key);
            const other = allSpecies
                .map((_, i) => `${schema.key}.${i}`)
                .filter(key => key !== name)
                .map(key => `${key}.amount`);
            trigger(other);
        }
    }, [name, schema.key, getValues, trigger, amountErrorType, previousAmountErrorType]);

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={FORESTAND_SPECIES.find(({ code }) => code === species)?.name ?? "Trädslag"}
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <Controller
                name={`${name}.type`}
                rules={{ required: "Required" }}
                render={({ field, fieldState: { error } }) => {
                    const otherSpecies = allSpecies?.filter(species => species?.type !== field.value);
                    const availableSpecies = FORESTAND_SPECIES.filter(
                        species => !otherSpecies?.find(otherSpecies => otherSpecies?.type === species.code)
                    );
                    delete field.ref;
                    return (
                        <WarnableTextField
                            SelectProps={{ classes: { select: classes.selectContainer } }}
                            fullWidth
                            select
                            variant="outlined"
                            label="Trädslag"
                            disabled={disabled}
                            {...field}
                            value={field.value ?? ""}
                            error={hasError(error)}
                            warning={hasWarning(error)}
                            helperText={getErrorMessages(error)?.join(", ")}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {availableSpecies.map(species => (
                                <MenuItem className={classes.menuItem} key={species.code} value={species.code}>
                                    <ListItemText primary={species.name} secondary={species.code} />
                                </MenuItem>
                            ))}
                        </WarnableTextField>
                    );
                }}
            />
            <SimpleNumericInput
                className={classes.input}
                variant="outlined"
                label="Andel"
                min={0}
                max={100}
                step={1}
                disabled={disabled}
                error={hasError(amountController.fieldState.error)}
                warning={hasWarning(amountController.fieldState.error)}
                helperText={getErrorMessages(amountController.fieldState.error)?.join(", ")}
                {...amountController.field}
                value={amount}
                onChange={val => {
                    let newValue = null;
                    if (typeof val === "number") {
                        newValue = new Decimal(val).dividedBy(100).toNumber();
                    }
                    amountController.field.onChange(newValue);
                }}
            />
            {schema.customType === "forestand-species-treewise" && (
                <>
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsWeightedHeight`}
                        rules={numericRules}
                        schema={{ title: "HGV" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsWeightedDiameter`}
                        rules={numericRules}
                        schema={{ title: "DGV" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsStandBasalArea`}
                        rules={numericRules}
                        schema={{ title: "Grundyta" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsAreaStandVolume`}
                        rules={numericRules}
                        schema={{ title: "Volym" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsAreaStemNumber`}
                        rules={numericRules}
                        schema={{ title: "Stamantal" }}
                        disabled={disabled}
                    />
                </>
            )}
        </ExpandingListItem>
    );
}

export default memo(ForestandSpecies);

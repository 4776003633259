import { useCallback, useEffect, useRef, useState } from "react";

import OLVectorLayer from "../map/openlayers/layers/VectorLayer";
import OLVectorSource from "ol/source/Vector";
import { GeoJSON } from "ol/format";
import { getVectorContext } from "ol/render";
import { Fill, Stroke, Style } from "ol/style";

import { io as jstsIO, geom as jstsGeom } from "jsts";

const LAYER_STYLE = new Style({
    fill: null,
    stroke: new Stroke({
        color: "#000000",
        width: 1
    })
});
const CONTEXT_STYLE = new Style({
    fill: new Fill({
        color: "rgba(0, 0, 0, 0.6)"
    })
});

const geomFactory = new jstsGeom.GeometryFactory();
const geojsonReader = new jstsIO.GeoJSONReader(geomFactory);
const geojsonWriter = new jstsIO.GeoJSONWriter(geomFactory);

function WorkspaceAreaHighlightLayer({ active, workspace, map }) {
    const source = useRef(new OLVectorSource());
    const [area, setArea] = useState(null);

    useEffect(() => {
        const workspaceArea = workspace?.area;
        if (!workspaceArea) {
            setArea(null);
            return;
        }
        let area = null;
        if (Array.isArray(workspaceArea) && workspaceArea.length > 0) {
            area = {
                type: "MultiPolygon",
                coordinates: workspaceArea
            };
        } else if (workspaceArea.geometry?.type === "MultiPolygon") {
            area = workspaceArea.geometry;
        }
        setArea(area);
    }, [workspace?.area]);

    useEffect(() => {
        if (area) {
            source.current.addFeatures(new GeoJSON().readFeatures(area));
        } else {
            source.current.clear();
        }
    }, [area]);

    const handlePostRender = useCallback(
        event => {
            if (!area) {
                return;
            }
            const context = getVectorContext(event);
            context.setStyle(CONTEXT_STYLE);
            const extent = map.getView().calculateExtent(map.getSize());
            const screenArea = geojsonReader.read({
                type: "Polygon",
                coordinates: [
                    [
                        [extent[0], extent[1]],
                        [extent[0], extent[3]],
                        [extent[2], extent[3]],
                        [extent[2], extent[1]],
                        [extent[0], extent[1]]
                    ]
                ]
            });
            const workspaceArea = geojsonReader.read(area);
            const difference = screenArea.difference(workspaceArea);
            if (difference.isEmpty()) {
                return;
            }
            const olDiff = new GeoJSON().readGeometry(geojsonWriter.write(difference));
            context.drawGeometry(olDiff);
        },
        [area, map]
    );

    if (!active || !map) {
        return null;
    }

    return <OLVectorLayer zIndex={9999} source={source.current} style={LAYER_STYLE} onPostRender={handlePostRender} />;
}

export default WorkspaceAreaHighlightLayer;

import React, { Component } from "react";
import { ErrorContext } from "../../error-context";

import { IconButton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Compass from "../../../icons/Compass";

import MapButtons from "./MapButtons";
import Tool from "./tools/Tool";
import VectorLayer from "./layers/VectorLayer";
import RasterLayerWrapper from "./layers/RasterLayerWrapper";
import WorkspaceAreaHighlightLayer from "./WorkspaceAreaHighlightLayer";

import AtlasMousePosition from "./AtlasMousePosition";
import ClickZoomInteraction from "./openlayers/interactions/ClickZoomInteraction";
import Feature from "ol/Feature";
import GeolocationIndicator from "./GeolocationIndicator";
import Map from "./openlayers/Map";
import PinchRotate from "./openlayers/interactions/PinchRotate";
import { MultiPolygon } from "ol/geom";
import { unByKey } from "ol/Observable";
import { defaults as defaultInteractions, DragPan as OLDragPan } from "ol/interaction";

import registerProjection from "../../registerProjection";

const TRANSPARENCY_GRID_SIZE = 30;

const styles = {
    map: {
        position: "relative",
        background: `
            #eeeeee
            url('data:image/svg+xml,\
                <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" fill-opacity=".10">\
                    <rect x="200" width="200" height="200" />\
                    <rect y="200" width="200" height="200" />\
                </svg>'
            )
        `,
        backgroundSize: `${TRANSPARENCY_GRID_SIZE}px ${TRANSPARENCY_GRID_SIZE}px`,
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    compass: {
        backgroundColor: "#ffffff",
        position: "absolute",
        top: "4rem",
        right: "1.45rem",
        zIndex: 1000,
        borderRadius: "50%",
        width: "2rem",
        height: "2rem",
        padding: 0
    },
    compassIcon: {
        width: "100%",
        height: "100%"
    },
    mapButtons: {
        position: "absolute",
        zIndex: 1000,
        bottom: 30,
        right: 10
    }
};

const isNumber = value => typeof value === "number" && !Number.isNaN(value);

class MapController extends Component {
    constructor(props) {
        super(props);

        this._setMap = this._setMap.bind(this);
        this._handleClick = this._handleClick.bind(this);
        this._handlePostRender = this._handlePostRender.bind(this);
        this._handleMoveEnd = this._handleMoveEnd.bind(this);
        this._handleToolRequest = this._handleToolRequest.bind(this);
        this._handleMapButtonRequest = this._handleMapButtonRequest.bind(this);
        this._handleRotation = this._handleRotation.bind(this);
        this._resetRotation = this._resetRotation.bind(this);

        // Fix drag interaction. By default, all mouse buttons are captured.
        // This makes sure that only the left mouse button is captured.
        // Simply removing the DragPan interaction from defaultInteractions,
        // and then adding a new one inside the map did not work when
        // the revolver is open for some reason. Probably something to do
        // with event propagation.
        this._interactions = defaultInteractions({
            doubleClickZoom: false,
            shiftDragZoom: false,
            pinchRotate: false
        });
        let dragPanIndex;
        this._interactions.forEach((interaction, index) => {
            if (interaction instanceof OLDragPan) {
                dragPanIndex = index;
            }
        });
        this._interactions.removeAt(dragPanIndex);
        this._interactions.insertAt(
            dragPanIndex,
            new OLDragPan({
                condition: event => {
                    if (event.originalEvent.button !== undefined) {
                        // Mouse events have a button property
                        return event.originalEvent.button === 0; // Left mouse button
                    } else {
                        // Touch events do not have a button property
                        return true;
                    }
                }
            })
        );

        let tool = {};
        if (props.editArea) {
            props.onStatusBarRequest(`Edit area for ${props.workspace.name}`);
            tool.onDone = area => {
                props.onEditArea(area.getGeometry().getCoordinates());
                props.onStatusBarRequest(null);
                this.setState({ toolType: "normal", tool: {} });
            };
            if (props.workspace.area) {
                if (Array.isArray(props.workspace.area)) {
                    tool.feature = new Feature(new MultiPolygon(props.workspace.area));
                } else if (props.workspace.area.type === "Feature") {
                    tool.feature = new Feature(new MultiPolygon(props.workspace.area.geometry.coordinates));
                }
            } else {
                tool.type = "MultiPolygon";
            }
        }

        this.state = {
            toolType: props.editArea ? "editWorkspaceArea" : "normal",
            tool: tool,
            rotation: 0,
            showWorkspaceArea: false,
            mapButtons: null,
            olMap: null
        };

        this.tool = null;
        this._lastExtent = [];

        this._registerProjections();
    }

    get olMap() {
        return this.state.olMap;
    }

    _registerProjections() {
        const { workspace } = this.props;
        if (workspace.projection) {
            registerProjection(workspace.projection);
        }
        for (const layer of workspace.layers) {
            if (layer.projection) {
                registerProjection(layer.projection);
            }
        }
    }

    componentDidMount() {
        this._setCenterAndZoom();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.identifier !== this.props.identifier || prevState.olMap !== this.state.olMap) {
            this._setCenterAndZoom();
        }
        this._registerProjections();
    }

    componentWillUnmount() {
        this._rotationListener && unByKey(this._rotationListener);
    }

    panToRect(rect) {
        const mapRect = this.state.olMap.getTarget().getBoundingClientRect();
        const center = this.state.olMap.getPixelFromCoordinate(this.state.olMap.getView().getCenter());
        center[0] += Math.min(rect.left - mapRect.left, 0);
        center[0] -= Math.min(mapRect.right - rect.right, 0);
        center[1] += Math.min(rect.top - mapRect.top, 0);
        center[1] -= Math.min(mapRect.bottom - rect.bottom, 0);
        this.panToCoordinate(this.state.olMap.getCoordinateFromPixel(center));
    }

    panToCoordinate(coordinate) {
        this.state.olMap.getView().animate({
            center: coordinate,
            duration: 200
        });
    }

    updateSize() {
        return this.state.olMap.updateSize();
    }

    getPixelFromCoordinate(coordinate) {
        return this.state.olMap.getPixelFromCoordinate(coordinate);
    }

    _handleClick(event) {
        if (this.props.revolverOpen) {
            this.props.onRevolverRequest(null);
        } else {
            this.tool.handleMapClick(event);
        }
    }

    _handlePostRender(event) {
        const { onMove } = this.props;
        const extent = event.map.getView().calculateExtent(event.map.getSize());
        if (
            onMove &&
            (this._lastExtent.length !== extent.length ||
                this._lastExtent.some((value, index) => value !== extent[index]))
        ) {
            onMove(extent);
        }
        this._lastExtent = extent;
    }

    _handleRotation(event) {
        this.setState({ rotation: event.target.getRotation() });
    }

    _resetRotation() {
        this.state.olMap?.getView().animate({ rotation: 0 });
    }

    _handleMoveEnd(event) {
        const { identifier } = this.props;
        // TODO: Put this in meta object store?
        const center = event.map.getView().getCenter();
        if (Array.isArray(center) && center.length === 2 && center.every(isNumber)) {
            localStorage.setItem(`${identifier}-mapCenter`, center.join(":"));
        }
        const zoom = event.map.getView().getZoom();
        if (isNumber(zoom)) {
            localStorage.setItem(`${identifier}-mapZoom`, zoom);
        }
    }

    async zoomToLayer(layer) {
        const { dbManager, workspace } = this.props;
        const db = await dbManager.getVectorDB(layer._id, workspace._id);
        const bbox = await db.bbox();
        if (Array.isArray(bbox) && bbox[0] !== Infinity) {
            this.state.olMap.getView().fit(bbox, {
                duration: 500
            });
        } else {
            this.context.setError(`No features found in "${layer.name}"`, "info");
        }
    }

    _handleToolRequest(type, tool = {}) {
        this.props.onStatusBarRequest(null, () => {
            this.setState({ toolType: type, tool, mapButtons: null });
        });
    }

    _handleMapButtonRequest(mapButtons) {
        this.setState({ mapButtons });
    }

    _setCenterAndZoom() {
        const { identifier } = this.props;
        const view = this.state.olMap?.getView();
        if (!view) {
            return;
        }
        const center = localStorage.getItem(`${identifier}-mapCenter`)?.split(":")?.map(parseFloat);
        if (Array.isArray(center) && center.length === 2 && center.every(isNumber)) {
            view.setCenter(center);
        }
        const zoom = parseFloat(localStorage.getItem(`${identifier}-mapZoom`) ?? "not a number");
        if (isNumber(zoom)) {
            view.setZoom(zoom);
        }
    }

    _setMap(element) {
        const olMap = element && element.olMap;
        if (olMap) {
            this._rotationListener && unByKey(this._rotationListener);
            this._rotationListener = olMap.getView().on("change:rotation", this._handleRotation);
            this.setState({ olMap });
        }
    }

    render() {
        const {
            classes,
            workspace,
            layers,
            dbManager,
            onRevolverRequest,
            onStatusBarRequest,
            onAddLayer,
            onDeleteFeature
        } = this.props;
        const { olMap, toolType, tool, rotation, mapButtons, showWorkspaceArea } = this.state;

        let center = Array.isArray(workspace.home) && workspace.home.length === 2 && workspace.home;
        const projection = workspace.projection || "EPSG:3006";
        if (!center) {
            if (projection === "EPSG:3006") {
                center = [532633, 6472593];
            } else {
                center = [0, 0];
            }
        }

        return (
            <Map
                ref={this._setMap}
                view={{
                    center,
                    zoom: 14,
                    projection
                }}
                className={classes.map}
                onMoveEnd={this._handleMoveEnd}
                onPostRender={this._handlePostRender}
                interactions={this._interactions}
            >
                <PinchRotate threshold={Math.PI / 4} />
                <ClickZoomInteraction onClick={this._handleClick} />
                <WorkspaceAreaHighlightLayer active={showWorkspaceArea} workspace={workspace} map={olMap} />
                {layers &&
                    layers.map(layer => {
                        switch (layer.source.type) {
                            case "polygon":
                            case "line":
                            case "point":
                                return <VectorLayer key={layer._id} configuration={layer} />;
                            case "raster":
                            case "external":
                                return (
                                    <RasterLayerWrapper
                                        key={layer._id}
                                        configuration={layer}
                                        workspace={workspace}
                                        dbManager={dbManager}
                                    />
                                );
                            default:
                                console.log(`Rendering of "${layer.source.type}" layers not yet implemented.`);
                                return null;
                        }
                    })}
                <GeolocationIndicator map={olMap} />
                <Tool
                    toolType={toolType}
                    tool={tool}
                    {...tool}
                    toolRef={el => (this.tool = el)}
                    map={olMap}
                    workspace={workspace}
                    layers={layers}
                    dbManager={dbManager}
                    onRevolverRequest={onRevolverRequest}
                    onToolRequest={this._handleToolRequest}
                    onMapButtonRequest={this._handleMapButtonRequest}
                    onStatusBarRequest={onStatusBarRequest}
                    onAddLayer={onAddLayer}
                    onShowWorkspaceArea={show => this.setState({ showWorkspaceArea: show })}
                    onDeleteFeature={onDeleteFeature}
                />
                <MapButtons className={classes.mapButtons} map={olMap} buttons={mapButtons} />
                <AtlasMousePosition />
                {rotation !== 0 && (
                    <IconButton
                        size="medium"
                        className={classes.compass}
                        onClick={this._resetRotation}
                        style={{ transform: `rotate(${rotation}rad)` }}
                    >
                        <Compass className={classes.compassIcon} />
                    </IconButton>
                )}
            </Map>
        );
    }
}

MapController.contextType = ErrorContext;

export default withStyles(styles)(MapController);

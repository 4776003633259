import { useEffect, useState } from "react";
import { getDerivedProperties } from "./workspace/info/derived-properties";
import { getAttributesByName } from "./workspace/info/translate-properties";
import Api from "../Api";

export const useFeatureInfo = (olFeature, attributeDefinitions) => {
    const [featureInfo, setFeatureInfo] = useState({ attributes: null, derivedAttributes: null, metadata: null });

    useEffect(() => {
        if (!olFeature || !attributeDefinitions) {
            return;
        }

        const properties = olFeature.getProperties();

        let cancelled = false;
        const getUsernames = async () => {
            let createdName;
            let modifiedName;
            try {
                let createdBy = await Api.getUser(properties.__atlas.created.by);
                if (!createdBy) {
                    // Legacy, used to be stored by hostname
                    createdBy = await Api.getUserByHostname(properties.__atlas.created.by);
                }
                createdName = createdBy.name;
                let modifiedBy = await Api.getUser(properties.__atlas.modified.by);
                if (!modifiedBy) {
                    // Legacy, used to be stored by hostname
                    modifiedBy = await Api.getUserByHostname(properties.__atlas.modified.by);
                }
                modifiedName = modifiedBy.name;
            } catch (error) {
                if (error.name === "OFFLINE") {
                    createdName = "Unavailable when Atlas is offline";
                    modifiedName = "Unavailable when Atlas is offline";
                } else {
                    console.logError(error, `Error fetching user ${properties?.__atlas?.created?.by}`);
                    createdName = "Could not fetch name";
                    modifiedName = "Could not fetch name";
                }
            }
            if (!cancelled) {
                setFeatureInfo(({ metadata, ...other }) => {
                    metadata["created by"] = createdName;
                    metadata["modified by"] = modifiedName;
                    return { metadata, ...other };
                });
            }
        };

        const layer = olFeature.layer.get("name");
        const attributesByName = getAttributesByName(olFeature, attributeDefinitions);
        const attributes = Object.keys(attributesByName).map(key => {
            const attribute = attributeDefinitions.find(a => a.name === key);
            return {
                id: key,
                name: key,
                value: attributesByName[key],
                attribute
            };
        });
        const derivedAttributesByName = getDerivedProperties(olFeature.getGeometry(), { string: true });
        const derivedAttributes = Object.keys(derivedAttributesByName).map(key => ({
            id: key,
            name: key[0].toUpperCase() + key.slice(1),
            value: derivedAttributesByName[key]
        }));

        let parts;
        switch (olFeature.getGeometry().getType()) {
            case "MultiPoint":
                parts = olFeature.getGeometry().getPoints();
                break;
            case "MultiLineString":
                parts = olFeature.getGeometry().getLineStrings();
                break;
            case "MultiPolygon":
                parts = olFeature.getGeometry().getPolygons();
                break;
            default:
                throw new Error(`Unknown geometry type "${olFeature.getGeometry().getType()}"`);
        }
        if (parts && parts.length > 0) {
            parts = parts.map(part => {
                const derivedByName = getDerivedProperties(part, { string: true });
                delete derivedByName.parts;
                return Object.keys(derivedByName).map(key => ({
                    id: key,
                    name: key[0].toUpperCase() + key.slice(1),
                    value: derivedByName[key]
                }));
            });
        }

        const id = properties.__atlas.originalId ?? olFeature.getId();
        const metadata = {
            id,
            layer,
            created: new Date(properties.__atlas.created.date).toLocaleString(),
            "created by": "Loading...",
            modified: new Date(properties.__atlas.modified.date).toLocaleString(),
            "modified by": "Loading..."
        };
        setFeatureInfo({ attributes, derivedAttributes, parts, metadata });
        getUsernames();

        return () => {
            cancelled = true;
        };
    }, [olFeature, attributeDefinitions]);

    return featureInfo;
};

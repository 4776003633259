import React, { useEffect, useState } from "react";
import classNames from "classnames";

import { IconButton, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    "@keyframes fadein": {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    container: {
        position: "relative",
        pointerEvents: "auto"
    },
    button: {
        "&, &:hover, &:focus": {
            width: "100%",
            height: "100%",
            border: `2px solid ${theme.colors.primary}`,
            borderRadius: "50%",
            backgroundColor: "#fff",
            color: theme.colors.primary,

            "&.destructive": {
                borderColor: theme.colors.destructive,
                color: theme.colors.destructive
            },

            "&.focused": {
                backgroundColor: theme.colors.primary,
                color: "#fff",

                "&.destructive": {
                    backgroundColor: theme.colors.destructive
                }
            },

            "&.disabled": {
                backgroundColor: "#fff",
                borderColor: theme.colors.disabled,
                color: theme.colors.disabled
            }
        },

        "& *": {
            pointerEvents: "none"
        }
    },
    label: {
        position: "absolute",
        top: 0,
        left: "50%",
        zIndex: 120,
        pointerEvents: "none",
        transform: "translate(-50%, -120%)",
        padding: "1px 8px",
        borderRadius: "5px",
        whiteSpace: "nowrap",
        backgroundColor: "#fff",
        boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.15)",
        color: "rgba(0, 0, 0, 0.87)",
        opacity: 0,
        animationName: "$fadein",
        animationDuration: "0.2s",
        animationFillMode: "forwards",

        "&.destructive": {
            color: theme.colors.destructive
        }
    }
}));

function TooltipIconButton({
    className,
    label,
    icon,
    disabled,
    disabledExplanation,
    destructive,
    labelClassName,
    onFocus,
    disableFocus,
    ...props
}) {
    const classes = useStyles();
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        onFocus?.(focused);
    }, [focused, onFocus]);

    const styleConditions = { focused, disabled, destructive };

    return (
        <div className={classNames(classes.container, className)}>
            {focused && (
                <Typography variant="body2" className={classNames(classes.label, styleConditions, labelClassName)}>
                    {label}
                </Typography>
            )}
            <Tooltip placement="top" title={disabled ? disabledExplanation : ""} enterTouchDelay={0} enterDelay={0}>
                <span>
                    <IconButton
                        className={classNames(classes.button, styleConditions)}
                        disabled={disabled}
                        onTouchStart={() => !disableFocus && setFocused(true)}
                        onTouchEnd={() => setFocused(false)}
                        onTouchCancel={() => setFocused(false)}
                        onMouseEnter={() => !disableFocus && setFocused(true)}
                        onMouseLeave={() => setFocused(false)}
                        {...props}
                        size="large">
                        {icon}
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    );
}

export default TooltipIconButton;

import LinkifyJS from "linkifyjs/react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.colors.primary
    }
}));

function Linkify({ children, ...other }) {
    const classes = useStyles();

    return (
        <LinkifyJS options={{ className: classes.link }} {...other}>
            {children}
        </LinkifyJS>
    );
}

export default Linkify;

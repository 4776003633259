import { Component } from "react";
import { MapContext } from "../map-context";
import { addEventListeners, removeEventListeners, getOptions } from "../utility";

import OLDraw from "ol/interaction/Draw";

class Draw extends Component {
    constructor(props) {
        super(props);

        this.options = {
            type: undefined,
            clickTolerance: undefined,
            features: undefined,
            source: undefined,
            dragVertexDelay: undefined,
            snapTolerance: undefined,
            stopClick: undefined,
            maxPoints: undefined,
            minPoints: undefined,
            finishCondition: undefined,
            style: undefined,
            geometryFunction: undefined,
            geometryName: undefined,
            condition: undefined,
            freehand: undefined,
            freehandCondition: undefined,
            wrapX: undefined
        };

        this.events = {};
        this.eventNames = {
            change: "change",
            changeactive: "change:active",
            propertychange: "propertychange",
            drawstart: "drawstart",
            drawend: "drawend"
        };
    }

    componentDidMount() {
        this.setup();
    }

    componentWillUnmount() {
        this.cleanup();
    }

    setup() {
        const options = getOptions(this.props, this.options);
        this.interaction = new OLDraw(options);
        this.context.map.addInteraction(this.interaction);
        addEventListeners(this.props, this.events, this.eventNames, this.interaction);
        if (this.props.innerRef) {
            this.props.innerRef(this.interaction);
        }
    }

    cleanup() {
        this.context.map.removeInteraction(this.interaction);
        removeEventListeners(this.events, this.interaction);
    }

    render() {
        return null;
    }
}

Draw.contextType = MapContext;

export default Draw;

import React, { memo, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { getErrorMessages, hasError, hasWarning } from "../../form-utils";
import { ObjectId } from "../../../id-generation";
import ArrayField from "../../ArrayField";
import CalculatedField from "../../CalculatedField";
import ExpandingListItem from "../../../ExpandingListItem";
import NumberField from "../../NumberField";
import WarnableTextField from "../../WarnableTextField";

import { IconButton, MenuItem } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

import dgvCalculationObject from "./calculations/dgv-calculation.json";
import grundytaCalculationObject from "./calculations/grundyta-calculation.json";
import hgvCalculationObject from "./calculations/hgv-calculation.json";
import stamantalCalculationObject from "./calculations/stamantal-calculation.json";
import volymCalculationObject from "./calculations/volym-calculation.json";
const volymCalculation = JSON.stringify(volymCalculationObject);
const stamantalCalculation = JSON.stringify(stamantalCalculationObject);
const hgvCalculation = JSON.stringify(hgvCalculationObject);
const grundytaCalculation = JSON.stringify(grundytaCalculationObject);
const dgvCalculation = JSON.stringify(dgvCalculationObject);

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

const ALL_TREELAYERS = [
    { code: "produktionsskikt", name: "produktionsskikt" },
    { code: "frotrad", name: "frotrad" },
    { code: "overstandare_ospecificerat", name: "overstandare_ospecificerat" },
    { code: "skarmstallning", name: "skarmstallning" },
    { code: "hogskarm", name: "hogskarm" },
    { code: "lagskarm", name: "lagskarm" },
    { code: "underskikt", name: "underskikt" }
];

function ForestandTreeLayer({ name, schema, onRemoveFromArray, disabled, ...props }) {
    const classes = useStyles();

    const { setValue } = useFormContext();
    const allTreeLayers = useWatch({ name: schema.key });
    const treeLayer = useWatch({ name: `${name}.treeLayer` });
    const id = useWatch({ name: `${name}.id` });

    const [shouldGenerateId, setShouldGenerateId] = useState(false);

    useEffect(() => {
        if (!id) {
            // For some reason, generating the id in the first render does not work.
            setShouldGenerateId(true);
        }
    }, [id]);
    useEffect(() => {
        if (shouldGenerateId) {
            const newId = ObjectId().toString();
            setValue(`${name}.id`, newId);
            setShouldGenerateId(false);
        }
    }, [shouldGenerateId, name, setValue]);

    const calcHGV = useMemo(
        () => ({
            calculated: true,
            title: "HGV",
            calculation: hgvCalculation.replace("<key>", `${name}.species`)
        }),
        [name]
    );
    const calcDGV = useMemo(
        () => ({
            calculated: true,
            title: "DGV",
            calculation: dgvCalculation.replace("<key>", `${name}.species`)
        }),
        [name]
    );
    const calcGrundyta = useMemo(
        () => ({
            calculated: true,
            title: "Grundyta",
            calculation: grundytaCalculation.replace("<key>", `${name}.species`)
        }),
        [name]
    );
    const calcVolym = useMemo(
        () => ({
            calculated: true,
            title: "Volym",
            calculation: volymCalculation.replace("<key>", `${name}.species`)
        }),
        [name]
    );
    const calcStamantal = useMemo(
        () => ({
            calculated: true,
            title: "Stamantal",
            calculation: stamantalCalculation.replace("<key>", `${name}.species`)
        }),
        [name]
    );

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={ALL_TREELAYERS.find(({ code }) => code === treeLayer)?.name ?? "Skikt"}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <Controller
                name={`${name}.treeLayer`}
                rules={{ required: "Required" }}
                render={({ field, fieldState: { error } }) => {
                    const otherTreeLayers = allTreeLayers?.filter(treeLayer => treeLayer?.treeLayer !== field.value);
                    const availableTreeLayers = ALL_TREELAYERS.filter(
                        treeLayer => !otherTreeLayers?.find(otherTreeLayer => otherTreeLayer?.treeLayer === treeLayer.code)
                    );
                    delete field.ref;
                    return (
                        <WarnableTextField
                            fullWidth
                            select
                            variant="outlined"
                            label="Typ"
                            {...field}
                            value={field.value ?? ""}
                            error={hasError(error)}
                            warning={hasWarning(error)}
                            helperText={getErrorMessages(error)?.join(", ")}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {availableTreeLayers.map(treeLayer => (
                                <MenuItem key={treeLayer.code} value={treeLayer.code}>
                                    {treeLayer.name}
                                </MenuItem>
                            ))}
                        </WarnableTextField>
                    );
                }}
            />
            <NumberField
                className={classes.input}
                name={`${name}.obsMeanAge`}
                schema={{ title: "Ålder" }}
                disabled={disabled}
            />
            {schema.customType === "forestand-treelayer-compartmentwise" && (
                <>
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsWeightedHeight`}
                        schema={{ title: "HGV" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsWeightedDiameter`}
                        schema={{ title: "DGV" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsStandBasalArea`}
                        schema={{ title: "Grundyta" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsAreaStandVolume`}
                        schema={{ title: "Volym" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.obsAreaStemNumber`}
                        schema={{ title: "Stamantal" }}
                        disabled={disabled}
                    />
                    <ArrayField
                        schema={{
                            key: `${name}.species`,
                            type: "custom",
                            title: "species",
                            customType: "forestand-species-compartmentwise"
                        }}
                        disabled={disabled}
                    />
                </>
            )}
            {schema.customType === "forestand-treelayer-treewise" && (
                <>
                    <ArrayField
                        schema={{
                            key: `${name}.species`,
                            type: "custom",
                            title: "species",
                            customType: "forestand-species-treewise"
                        }}
                    />
                    <CalculatedField schema={calcHGV} disabled={disabled} {...props} />
                    <CalculatedField schema={calcDGV} disabled={disabled} {...props} />
                    <CalculatedField schema={calcGrundyta} disabled={disabled} {...props} />
                    <CalculatedField schema={calcVolym} disabled={disabled} {...props} />
                    <CalculatedField schema={calcStamantal} disabled={disabled} {...props} />
                </>
            )}
        </ExpandingListItem>
    );
}

export default memo(ForestandTreeLayer);

// https://openlayers.org/en/latest/apidoc/module-ol_style_Style-Style.html

const white = [255, 255, 255, 1];
const blue = [0, 153, 255, 1];
const width = 3;

export const polygon = {
    fill: [
        {
            type: "solid",
            color: [255, 255, 255, 0.5]
        }
    ],
    stroke: [
        {
            type: "solid",
            color: white,
            width: width + 2
        },
        {
            type: "solid",
            color: blue,
            width
        }
    ]
};

export const line = {
    stroke: [
        {
            type: "solid",
            color: white,
            width: width + 2
        },
        {
            type: "solid",
            color: blue,
            width
        }
    ]
};

export const point = {
    symbol: {
        type: "circle",
        size: width * 2,
        fill: {
            type: "solid",
            color: blue
        },
        stroke: {
            type: "solid",
            color: white,
            width: width / 2
        }
    }
};

import React, { useState } from "react";
import randomColor from "randomcolor";
import OutlinedContainer from "../OutlinedContainer";
import ColorPicker from "./ColorPicker";
import OverrideWrapper from "./OverrideWrapper";
import { hexToRgb } from "../../resources/colors";

import { Dialog, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    button: {
        width: "100%",
        height: "32px",
        marginRight: theme.spacing(1)
    },
    dialog: {
        position: "relative",
        padding: theme.spacing(3)
    }
}));

function Color({ color, opacity, onColorChange, onOpacityChange, label = "Color" }) {
    const [pickerOpen, setPickerOpen] = useState(false);
    const classes = useStyles();

    const colorAreaStyle = {
        backgroundColor: color,
        opacity
    };

    return (
        <OutlinedContainer label={label}>
            <OverrideWrapper
                inherited={color === undefined}
                onOverride={() => onColorChange(randomColor())}
                onDelete={() => onColorChange(undefined)}
            >
                <Button className={classes.button} style={colorAreaStyle} onClick={() => setPickerOpen(true)}>
                    {" "}
                </Button>
                <Dialog
                    PaperProps={{ className: classes.dialog }}
                    open={pickerOpen}
                    onClose={() => setPickerOpen(false)}
                >
                    <ColorPicker
                        color={{ ...hexToRgb(color), a: opacity }}
                        onChange={newColor => {
                            if (newColor.hex !== color) {
                                onColorChange(newColor.hex);
                            }
                            if (newColor.rgb.a !== opacity) {
                                onOpacityChange(newColor.rgb.a);
                            }
                        }}
                    />
                </Dialog>
            </OverrideWrapper>
        </OutlinedContainer>
    );
}

export default React.memo(
    Color,
    (prevProps, nextProps) => prevProps.color === nextProps.color && prevProps.opacity === nextProps.opacity
);

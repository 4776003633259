import { validateAttributes } from "validate-attributes";
import { validateGeometries } from "validate-geometries";
import { ObjectId } from "../../../../id-generation";
import { GeoJSON } from "ol/format";
import MapBrowserEvent from "ol/MapBrowserEvent";

export function getFeaturesFromEvent(event) {
    const features = [];
    event.map.forEachFeatureAtPixel(
        event.pixel,
        (feature, layer) => {
            if (layer) {
                const layerProperties = layer.getProperties();
                feature.layer = layer;
                feature.hitPriority = layerProperties.hitPriority;
                features.push(feature);
            }
        },
        {
            hitTolerance: 22,
            layerFilter: candidate => candidate.get("type") === "VECTOR" && !candidate.get("ignoreclick")
        }
    );
    features.sort((a, b) => b.hitPriority - a.hitPriority);
    return features;
}

export async function deleteOLFeature(olFeature, workspace, dbManager) {
    // Remove feature from map
    const source = olFeature.layer.getSource();
    source.removeFeature(olFeature);

    // Make sure feature exists (it might not yet have been saved)
    if (!olFeature.getId()) {
        // Feature has not been saved and does not need further deletion work
        return;
    }

    const layerId = olFeature.layer.get("id");
    const feature = toGeoJSON(olFeature);
    try {
        const newId = await deleteFeature(feature, layerId, workspace, dbManager);
        olFeature.setId(newId);
    } catch (error) {
        console.logError(error, "deleteOLFeature: Error deleting feature:");
        source.addFeature(olFeature);
        throw error;
    }
}

export async function deleteFeature(feature, layerId, workspace, dbManager) {
    const originalId = feature?.properties?.__atlas?.originalId;
    const id = feature?.id;
    console.log(`uitility.deleteFeature (${originalId}, version ${id})`);

    // Mark feature as deleted in local database
    const db = await dbManager.getVectorDB(layerId, workspace._id);

    // Update id
    const currentFeature = await db.get(feature.id);
    const deletedFeature = { ...feature };
    deletedFeature.id = ObjectId().toString();
    currentFeature.properties.__atlas.child = deletedFeature.id;
    deletedFeature.properties.__atlas.parent = currentFeature.id;

    deletedFeature.properties.__atlas.localModified = Date.now();
    deletedFeature.properties.__atlas.deletedInWorkspace = workspace._id;
    deletedFeature.properties.__atlas.deleted = true;
    deletedFeature.geometry = null; // Won't be inserted into r-tree
    const changes = {
        insert: [deletedFeature],
        update: [currentFeature],
        removeFromRTree: [currentFeature]
    };
    await db.performChanges(changes);

    // Start sending changes to server
    dbManager.sendLocalModifiedFeatures(layerId, workspace._id);

    return deletedFeature.id;
}

function toGeoJSON(olFeature) {
    const feature = new GeoJSON().writeFeatureObject(olFeature);
    feature.properties = feature.properties || {};
    feature.properties.__atlas = feature.properties.__atlas || {};
    const metadata = feature.properties.__atlas.metadata;
    const originalId = feature.properties.__atlas.originalId;
    feature.properties.__atlas = { originalId, metadata };
    return feature;
}

export async function saveOLFeature(olFeature, workspace, dbManager) {
    const layerId = olFeature.layer.get("id");
    const feature = toGeoJSON(olFeature);
    const deletedId = await saveFeature(feature, layerId, workspace, dbManager, false);
    olFeature.setId(feature.id);
    const event = { layerId, features: [feature] };
    if (deletedId) {
        event.deleted = [deletedId];
    }
    dbManager.emit(`change-${layerId}-${workspace._id}`, event);
}

export async function saveFeature(feature, layerId, workspace, dbManager, emit = true) {
    console.log(`utility.saveFeature ${feature.id ?? "(new)"} (layer: ${layerId}, workspace: ${workspace._id})`);
    const logs = [];
    const start = Date.now();
    logs.push({ timestamp: start, log: `utility.saveFeature: ${JSON.stringify(feature)}` });

    const layer = await dbManager.getLayer(layerId, workspace._id);
    const db = await dbManager.getVectorDB(layerId, workspace._id);

    // Set default values
    for (const attribute of layer.attributes) {
        if (attribute.default !== undefined && feature.properties[attribute._id] === undefined) {
            feature.properties[attribute._id] = attribute.default;
        }
    }

    // Validate
    logs.push({ timestamp: Date.now(), log: "utility.saveFeature: validating attributes" });
    const attributes = { ...feature.properties };
    const metadata = attributes.__atlas.metadata;
    delete attributes.__atlas;
    validateAttributes(attributes, layer.attributes);
    let area;
    if (Array.isArray(workspace.area) && workspace.area.length > 0) {
        area = {
            type: "MultiPolygon",
            coordinates: workspace.area
        };
    } else if (workspace.area && workspace.area.type === "Feature") {
        area = workspace.area.geometry;
    }
    logs.push({ timestamp: Date.now(), log: "utility.saveFeature: validating geometry" });
    try {
        validateGeometries([feature], { allowedArea: area });
    } catch (error) {
        console.logError(error, "utility.saveFeature: validateGeometries failed");
        if (error.name === "OUTSIDE_AREA") {
            error.message = "The feature is outside of the specified workspace area.";
            throw error;
        } else {
            throw error;
        }
    }

    let lastId;
    if (feature.id) {
        // Feature is modified
        feature.properties.__atlas.modifiedInWorkspace = workspace._id;
        feature.properties.__atlas.parent = feature.id;
        lastId = feature.id;
    } else {
        // Feature is new
        feature.properties.__atlas.createdInWorkspace = workspace._id;
    }

    feature.id = ObjectId().toString();
    if (!feature.properties.__atlas.originalId) {
        feature.properties.__atlas.originalId = lastId || feature.id;
    }
    feature.properties.__atlas.localModified = Date.now();
    feature.properties.__atlas.child = 0;
    feature.properties.__atlas.modified = {
        date: feature.properties.__atlas.localModified,
        by: localStorage.getItem("hostname")
    };

    if (metadata) {
        feature.properties.__atlas.metadata = metadata;
    }

    // Save changes to local database
    try {
        const changes = {
            insert: [feature]
        };
        if (lastId) {
            // Mark the previous feature as deleted, so that it won't appear in the map
            // before it's been sent to the server
            logs.push({
                timestamp: Date.now(),
                log: `utility.saveFeature: checking if previous version exists (queue length: ${db._queue?.length})`
            });
            const previousFeature = await db.get(lastId);
            previousFeature.properties.__atlas.child = feature.id;
            changes.update = [previousFeature];
            changes.removeFromRTree = [previousFeature];
            feature.properties.__atlas.created = previousFeature.properties.__atlas.created;
        } else {
            feature.properties.__atlas.created = { ...feature.properties.__atlas.modified };
        }
        logs.push({
            timestamp: Date.now(),
            log: `utility.saveFeature: saving to idb (queue length: ${db._queue?.length})`
        });
        await db.performChanges(changes);
    } catch (error) {
        console.logError(error, "saveFeature error");
        if (error.message === "QuotaExceededError") {
            error.message =
                "Your device has run out of space. You need to free up some space in order to save features.";
            error.name = "QuotaExeededError";
            throw error;
        } else {
            throw error;
        }
    }

    // Start sending changes to server
    logs.push({
        timestamp: Date.now(),
        log: "utility.saveFeature: calling sendLocalModifiedFeatures"
    });
    dbManager.sendLocalModifiedFeatures(layerId, workspace._id);

    if (emit) {
        const event = { layerId, features: [feature] };
        if (lastId) {
            event.deleted = [lastId];
        }
        dbManager.emit(`change-${layerId}-${workspace._id}`, event);
    }

    logs.push({
        timestamp: Date.now(),
        log: "done"
    });
    const duration = Date.now() - start;
    if (duration > 1000) {
        console.warn(`Detected slow performance in utility.saveFeature (${duration}ms). Timestamps:`);
        logs.forEach(({ timestamp, log }) => console.warn(`${new Date(timestamp).toISOString()}: ${log}`));
    }

    return lastId;
}

export function createOLFeature(feature, olLayer) {
    const olFeature = new GeoJSON().readFeature(feature);
    olFeature.layer = olLayer;
    olLayer.getSource().addFeature(olFeature);
    olLayer.getStyle()(olFeature);
    return olFeature;
}

export function simulateEvent(type, coordinates, map) {
    const viewport = map.getViewport();
    const pixel = map.getPixelFromCoordinate(coordinates);
    const event = {
        type,
        target: viewport.firstChild,
        clientX: pixel[0],
        clientY: pixel[1],
        preventDefault: function () {},
        stopPropagation: function () {},
        pointerType: "mouse",
        pointerId: 0
    };
    const simulatedEvent = new MapBrowserEvent(type, map, event);
    map.handleMapBrowserEvent(simulatedEvent);
}

export function simulateClick(coordinates, map) {
    simulateEvent("pointerdown", coordinates, map);
    simulateEvent("pointerup", coordinates, map);
}

export const replaceVar = (condition, attributes = [], type) => {
    const operator = Object.keys(condition)[0];
    let error = false;
    if (operator === "var") {
        const attribute = attributes.find(
            a =>
                (a.derived && condition[operator] === `_derived_${a._id}`) ||
                a._id === condition[operator] ||
                a.name === condition[operator]
        );
        if (attribute) {
            if (attribute.derived) {
                condition[operator] = type === "id" ? attribute._id : `_derived_${attribute._id}`;
            } else {
                condition[operator] = type === "id" ? attribute._id : attribute.name;
            }
        } else {
            console.warn(`Could not find attribute "${condition[operator]}".`);
            condition[operator] = "";
            error = true;
        }
    } else if (operator) {
        const values = condition[operator];
        if (Array.isArray(values)) {
            for (const value of values) {
                let suberror = replaceVar(value, attributes, type);
                error = error || suberror;
            }
        }
    }
    return error;
};

const _replaceKeys = (object, keys, handler) => {
    if (Array.isArray(object)) {
        for (const sub of object) {
            _replaceKeys(sub, keys, handler);
        }
    } else if (typeof object === "object" && object !== null) {
        const objKeys = Object.keys(object);
        for (const objKey of objKeys) {
            if (keys.includes(objKey)) {
                object[objKey] = handler(objKey, object[objKey], object);
            } else {
                _replaceKeys(object[objKey], keys, handler);
            }
        }
    }
};

export const replaceKeys = (object, keys, handler) => {
    return _replaceKeys(object, keys, handler);
};

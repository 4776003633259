import { useEffect, useState, useContext } from "react";
import { ErrorContext } from "./error-context";
import Api from "../Api";

export const useUserLogs = reportId => {
    const [logs, setLogs] = useState(null);
    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        let cancelled = false;
        const fetchLogs = async () => {
            try {
                const logs = await Api.getLogs(reportId);
                if (!cancelled) {
                    setLogs(logs.map((log, index) => ({ ...log, id: index })));
                }
            } catch (error) {
                setError(error);
            }
        };
        fetchLogs();
        return () => {
            cancelled = true;
        };
    }, [reportId, setError]);

    return logs;
};

import Zip from "jszip";
import { GeoJSON } from "ol/format";
import Api from "../Api";
import { convertFileAttributesToURLs } from "./convertFileAttributesToURLs";
import { getDerivedProperties } from "./workspace/info/derived-properties";
import { getAttributesByName } from "./workspace/info/translate-properties";
import { transform } from "ol/proj";

const mapAttributeValue = async (value, fn) => {
    if (Array.isArray(value)) {
        const values = await Promise.all(value.map(fn));
        return values.filter(value => value !== null);
    } else if (![null, undefined].includes(value)) {
        return fn(value);
    } else {
        return null;
    }
};

export const generateLayerExport = async (layer, workspace, dbManager, options = {}) => {
    const db = await dbManager.getVectorDB(layer._id, workspace._id);
    let features = await db.getAll();
    const geojson = new GeoJSON();
    features = features.filter(feature => {
        if (feature.properties.__atlas.child || feature.properties.__atlas.deleted) {
            return false;
        }
        feature.id = feature.properties.__atlas.originalId;
        const properties = getAttributesByName(feature, layer.attributes);
        if (options.metadata.modifiedTimestamp) {
            properties[options.metadata.modifiedTimestamp] = new Date(
                feature.properties.__atlas.modified.date
            ).toISOString();
        }
        if (options.metadata.createdTimestamp) {
            properties[options.metadata.createdTimestamp] = new Date(
                feature.properties.__atlas.created.date
            ).toISOString();
        }
        if (options.metadata.workspaceName) {
            properties[options.metadata.workspaceName] = workspace.name;
        }
        const olFeature = geojson.readGeometry(feature.geometry);
        const derivedProperties = getDerivedProperties(olFeature);
        if (options.metadata.parts) {
            properties[options.metadata.parts] = derivedProperties.parts;
        }
        if (options.metadata.perimeter) {
            properties[options.metadata.perimeter] = derivedProperties.perimeter;
        }
        if (options.metadata.area) {
            properties[options.metadata.area] = derivedProperties.area;
        }
        if (options.metadata.vertices) {
            properties[options.metadata.vertices] = derivedProperties.vertices;
        }
        if (feature.properties.__atlas.metadata) {
            for (const key in feature.properties.__atlas.metadata) {
                properties[`__atlas_${key}`] = feature.properties.__atlas.metadata[key];
            }
        }
        feature.properties = properties;
        return true;
    });
    const featureCollection = {
        type: "FeatureCollection",
        features,
        properties: {
            name: layer.name
        },
        crs: {
            type: "name",
            properties: {
                name: layer.projection
            }
        }
    };

    let zip;
    let content;
    let extension;
    if (options.includeFileAttributes) {
        zip = new Zip();
        const fileAttribtues = layer.attributes.filter(a => ["file", "image"].includes(a.type));
        for (const attribute of fileAttribtues) {
            const folder = zip.folder(attribute.name);
            for (const feature of featureCollection.features) {
                const value = feature.properties[attribute.name];
                feature.properties[attribute.name] = await mapAttributeValue(value, async file => {
                    if (file && file.id) {
                        const fileExtension = /(?:\.([^.]+))?$/.exec(file.name || "")[1] || "";
                        const localFile = await dbManager.getLocalFile(file.id);
                        if (localFile) {
                            folder.file(`${file.id}.${fileExtension}`, localFile);
                        } else {
                            const response = await fetch(Api.getFileURL(file.id, true, workspace._id));
                            const remoteFile = await response.blob();
                            folder.file(`${file.id}.${fileExtension}`, remoteFile);
                        }
                        return `${attribute.name}/${file.id}.${fileExtension}`;
                    }
                    return null;
                });
            }
        }
    } else {
        convertFileAttributesToURLs(featureCollection, layer.attributes, workspace);
    }

    const dateAttributes = layer.attributes.filter(a => ["date"].includes(a.type));
    for (const dateAttribute of dateAttributes) {
        for (const feature of featureCollection.features) {
            const value = feature.properties[dateAttribute.name];
            feature.properties[dateAttribute.name] = await mapAttributeValue(value, date => {
                try {
                    return new Date(date).toISOString().slice(0, 10); // YYYY-MM-DD
                } catch (error) {
                    console.warn(
                        `Could not create Date object for attribute ${dateAttribute.name} in feature ${feature.id}, value: ${date}`
                    );
                }
            });
        }
    }
    const dateTimeAttributes = layer.attributes.filter(a => ["datetime"].includes(a.type));
    for (const dateTimeAttribute of dateTimeAttributes) {
        for (const feature of featureCollection.features) {
            const value = feature.properties[dateTimeAttribute.name];
            feature.properties[dateTimeAttribute.name] = await mapAttributeValue(value, date => {
                try {
                    return new Date(date).toISOString();
                } catch (error) {
                    console.warn(
                        `Could not create Date object for attribute ${dateTimeAttribute.name} in feature ${feature.id}, value: ${date}`
                    );
                }
            });
        }
    }
    const locationAttributes = layer.attributes.filter(a => a.type === "location");
    for (const locationAttribute of locationAttributes) {
        const name = locationAttribute.name;
        for (const feature of featureCollection.features) {
            const value = feature.properties[name];
            delete feature.properties[name];
            feature.properties[`${name}_x`] = await mapAttributeValue(value, location => {
                if (layer.projection && layer.projection !== "EPSG:4326") {
                    const [x] = transform([location.longitude, location.latitude], "EPSG:4326", layer.projection);
                    return x;
                }
                return location.latitude;
            });
            feature.properties[`${name}_y`] = await mapAttributeValue(value, location => {
                if (layer.projection && layer.projection !== "EPSG:4326") {
                    const [, y] = transform([location.longitude, location.latitude], "EPSG:4326", layer.projection);
                    return y;
                }
                return location.longitude;
            });
            feature.properties[`${name}_accuracy`] = await mapAttributeValue(value, location => location.accuracy);
        }
    }

    switch (options.format) {
        case "geojson":
            content = JSON.stringify(featureCollection, null, 2);
            extension = "geojson";
            break;
        case "csv":
            const separator = ";";
            const attributes = [...layer.attributes];
            Object.keys(options.metadata).forEach(metadataKey => {
                if (options.metadata[metadataKey]) {
                    attributes.push({ name: metadataKey });
                }
            });
            content = `id${separator}${attributes.map(attribute => attribute.name).join(separator)}`;
            for (const feature of featureCollection.features) {
                content += `\n${feature.id}${separator}${attributes
                    .map(attribute => {
                        let value = "";
                        const property = feature.properties[attribute.name];
                        if (![null, undefined, ""].includes(property)) {
                            if (attribute.type === "custom") {
                                value = JSON.stringify(property);
                            } else if (Array.isArray(property) && attribute.type === "string") {
                                value = property.map(prop => `"${prop}"`).join(",");
                            } else {
                                value = `${property}`;
                            }
                            value = `"${value.replace(/"/g, `""`)}"`;
                        }
                        return value;
                    })
                    .join(separator)}`;
            }
            extension = "csv";
            break;
        default:
            throw new Error(`Unknown export format "${options.format}"`);
    }

    if (zip) {
        zip.file(`${layer.name}.${extension}`, content);
        content = await zip.generateAsync({ type: "blob" });
        extension = "zip";
    }

    return { content, extension };
};

import Observable from "ol/Observable";

export function addEventListeners(props, events, eventNames, target) {
    for (const propName of Object.keys(props)) {
        if (propName.startsWith("on")) {
            const eventName = propName.slice(2).toLowerCase();
            if (eventNames.hasOwnProperty(eventName)) {
                const realEventName = eventNames[eventName];
                events[realEventName] = props[propName];
                target.on(realEventName, events[realEventName]);
            }
        }
    }
}

export function removeEventListeners(events, target) {
    for (const eventName of Object.keys(events)) {
        if (events[eventName]) {
            target.un(eventName, events[eventName]);
        }
    }
}

export function getOptions(props, matching) {
    const options = {};
    const keys = Array.isArray(matching) ? matching : Object.keys(matching);
    for (const optionName of keys) {
        if (props.hasOwnProperty(optionName)) {
            options[optionName] = props[optionName];
        }
    }
    return options;
}

export function addProperties(properties = {}, target) {
    for (const name of Object.keys(properties)) {
        target.set(name, properties[name]);
    }
}

export function hasChanged(props, prevProps, matching) {
    for (const propName of Object.keys(matching)) {
        if (props[propName] instanceof Observable && prevProps[propName] instanceof Observable) {
            return props[propName].getRevision() !== prevProps[propName].getRevision();
        }
        if (props[propName] !== prevProps[propName]) {
            return true;
        }
    }
    return false;
}

import { useMemo, useContext } from "react";
import { WorkspaceContext } from "./workspace/workspace-context";

export const useLayerOpacity = (layer, layerOverrides) => {
    const { configuration } = useContext(WorkspaceContext);
    let _layerOverrides = layerOverrides;
    if (!_layerOverrides) {
        _layerOverrides = configuration.layerOverrides;
    }
    return useMemo(() => {
        const layerOverride = _layerOverrides && _layerOverrides[layer._id];
        if (layerOverride && typeof layerOverride.opacity === "number") {
            return layerOverride.opacity;
        } else {
            return 100;
        }
    }, [layer._id, _layerOverrides]);
};

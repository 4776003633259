import React from "react";
import classNames from "classnames";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(_ => ({
    container: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center"
    },
    icon: {
        width: "80%",
        height: "80%"
    }
}));

function IconDisplay({ className, icon, color, opacity, ...props }) {
    const classes = useStyles();
    const Component = icon.component;
    return (
        <div className={classNames(className, classes.container)} {...props}>
            <Component className={classes.icon} style={{ color, opacity }} />
        </div>
    );
}

export default React.memo(IconDisplay);

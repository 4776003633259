import React, { useContext, useEffect, useMemo, useState } from "react";
import { AtlasContext } from "./atlas-context";
import { LoadingContext } from "./loading-context";
import { removeAll } from "./Settings";
import { ObjectId } from "./id-generation";
import Api from "../Api";
import { getCookieByName, getSessionName } from "../cookie";
import DBManager from "./DBManager";
import SyncChannel from "./SyncChannel";

import AtlasLoader from "./AtlasLoader";

function AtlasContextWrapper({ errorBoundary, children }) {
    const [error, setError] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [syncChannel, setSyncChannel] = useState();
    const [dbManager, setDbManager] = useState();
    const [user, setUser] = useState(null);
    const contextValue = useMemo(() => ({ syncChannel, dbManager, user }), [syncChannel, dbManager, user]);

    const { startLoading, stopLoading } = useContext(LoadingContext);

    const clearPreviousSessionIfNeeded = async () => {
        const lastAtlasUserId = localStorage.getItem("userId");
        try {
            // TODO: Replace (or fallback to) with session cookie decode so that it works offline
            const currentAtlasUser = await Api.getCurrentUser();
            if (lastAtlasUserId && lastAtlasUserId !== currentAtlasUser._id) {
                console.log("Current Atlas user differs from the previous; remove previous user's data");
                const removeCookies = false;
                await removeAll(removeCookies);
            }
            setUser(currentAtlasUser);
            localStorage.setItem("userId", currentAtlasUser._id);
        } catch (error) {
            (console.logError ?? console.error)(error, "Error fetching user id");
        }
    };

    useEffect(() => {
        const initialize = async () => {
            console.log("Initializing Atlas...");
            const start = Date.now();
            try {
                const sessionName = getSessionName();
                console.log("sessionName", sessionName);
                if (!getCookieByName(sessionName)) {
                    console.log(`No ${sessionName} cookie, redirecting to account.`);
                    if (window.ATLAS_IS_NATIVE) {
                        // Cookie should be set by native app.
                        throw new Error("No session cookie.");
                    } else {
                        window.location.href = "/login";
                        return;
                    }
                }
                window.id = new ObjectId().toString();
                const syncChannel = new SyncChannel();
                syncChannel.on("connect", () => {
                    console.log("SyncChannel connected.");
                });
                syncChannel.on("disconnect", () => {
                    console.log("SyncChannel disconnected");
                });
                await clearPreviousSessionIfNeeded();
                const dbManager = new DBManager();
                await dbManager.initialize(syncChannel, startLoading, stopLoading);
                dbManager.on("error", error => errorBoundary.current.setError(error));
                await syncChannel.register();
                if (navigator.storage && navigator.storage.persist) {
                    const persistent = await navigator.storage.persist();
                    console.log(`Storage persistance: ${persistent}`);
                }
                setSyncChannel(syncChannel);
                setDbManager(dbManager);
                console.log("Atlas initialization complete");
            } catch (e) {
                (console.logError ?? console.error)(e, "Error initializing Atlas");
                setError(e.message);
            }
            setTimeout(() => setInitialized(true), Math.max(0, start + 1000 - Date.now()));
        };
        initialize();
    }, [errorBoundary, startLoading, stopLoading]);

    if (error) {
        return <p>{error}</p>;
    }

    return initialized ? (
        <AtlasContext.Provider value={contextValue}>{children}</AtlasContext.Provider>
    ) : (
        <AtlasLoader />
    );
}

export default React.memo(AtlasContextWrapper);

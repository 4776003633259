import React, { Component } from "react";
import math from "../../../math";

import OLVectorSource from "ol/source/Vector";
import { LineString } from "ol/geom";
import { unByKey } from "ol/Observable";
import { getArea, getLength } from "ol/sphere";
import { Add, Done } from "@mui/icons-material";

import OLVectorLayer from "../openlayers/layers/VectorLayer";
import OLDraw from "../openlayers/interactions/Draw";

import { simulateClick } from "./utility/utility";
import { createOLStyles } from "../styles/styles";
import { createLineStringStyles, createPolygonStyles } from "../styles/measure-styles";

class Measure extends Component {
    constructor(props) {
        super(props);

        this._startNewMeasurement = this._startNewMeasurement.bind(this);
        this._endMeasurement = this._endMeasurement.bind(this);
        this._handleDrawStart = this._handleDrawStart.bind(this);
        this._handleDrawEnd = this._handleDrawEnd.bind(this);
        this._doMeasure = this._doMeasure.bind(this);
        this._exitTool = this._exitTool.bind(this);
        this._style = this._style.bind(this);

        this._source = new OLVectorSource({ wrapX: false });
        this._source.on("addfeature", event => {
            const feature = event.feature;
            const isLine = this._drawingType === "LineString";
            this._doMeasure(feature, isLine);
            if (isLine) {
                const coordinates = feature.getGeometry().getCoordinates()[0];
                coordinates.pop();
                const lineString = new LineString(coordinates);
                feature.setGeometry(lineString);
            }
        });

        this._styles = {
            LineString: createLineStringStyles(),
            Polygon: createPolygonStyles(false)
        };
        this._completedStyles = {
            LineString: createLineStringStyles(),
            Polygon: createPolygonStyles(true)
        };

        this.state = {
            drawing: false
        };
    }
    componentDidMount() {
        const { coordinates } = this.props.tool;
        if (coordinates) {
            this._startNewMeasurement(coordinates);
        }
    }

    _startNewMeasurement(coordinates) {
        this.setState({ drawing: true }, () => {
            simulateClick(coordinates, this.props.map);
        });
    }

    _endMeasurement() {
        const coordinates = this._currentFeature.getGeometry().getCoordinates()[0];
        const last = coordinates[coordinates.length - 1];
        const secondLast = coordinates[coordinates.length - 2];
        if (last[0] === secondLast[0] && last[1] === secondLast[1]) {
            this._drawingType = "Polygon";
        } else {
            this._drawingType = "LineString";
        }
        return true;
    }

    _exitTool() {
        this.props.onToolRequest("normal");
    }

    _handleDrawStart(event) {
        this._currentFeature = event.feature;
        this._listener = event.feature.getGeometry().on("change", () => this._doMeasure(this._currentFeature, true));
    }

    _handleDrawEnd() {
        unByKey(this._listener);
        this.setState({ drawing: false });
    }

    _doMeasure(feature, popLast) {
        const geometry = feature.getGeometry();

        const projection = this.props.workspace.projection || "EPSG:3006";

        const area = getArea(geometry, { projection });
        let areaText = "";
        // Area will sometimes return like 0.0000011234134 for a line
        if (area > 0.01) {
            let precision = 3;
            if (area > 10_000) {
                precision = 2;
            }
            if (area > 100_000) {
                precision = 1;
            }
            if (area > 1_000_000) {
                precision = 0;
            }
            areaText = math.unit(`${area} m2`).to("ha").format({ notation: "fixed", precision });
        }
        feature.set("area", areaText);

        const coordinates = geometry.getCoordinates()[0].slice();
        if (popLast) {
            coordinates.pop();
        }
        const lineString = new LineString(coordinates);
        const length = getLength(lineString, { projection });
        const lengthText = math.unit(`${length} m`).format({ precision: 3 });
        feature.set("length", lengthText);
    }

    handleMapClick(event) {
        const topButtons = [
            {
                id: "new",
                icon: <Add />,
                label: "New measurement",
                onClick: () => this._startNewMeasurement(event.coordinate)
            },
            {
                id: "done",
                icon: <Done />,
                label: "Done",
                onClick: this._exitTool
            }
        ];
        this.props.onRevolverRequest(event.coordinate, { topButtons });
    }

    _style(feature, completed) {
        const type = feature.getGeometry().getType();
        const styles = completed ? this._completedStyles[type] : this._styles[type];
        const area = feature.get("area");
        // Styling of the current feature is broken up into its parts,
        // the polygon itself (type Polygon), its vertices (type Point) and its edges (type LineString).
        // The polygon itself will have the properties set by this._doMeasure,
        // but the subcomponents won't. So we fall back to the whole polygon (this._currentFeature)
        // if the length is missing.
        const length = feature.get("length") || this._currentFeature.get("length");
        if (styles) {
            switch (type) {
                case "Polygon":
                    if (completed) {
                        styles[0].label.text = area;
                        styles[1].label.text = length;
                    } else {
                        styles[0].label.text = area;
                    }
                    break;
                case "LineString":
                    styles[0].label.text = length;
                    break;
                case "Point":
                    break;
                default:
                    break;
            }
            return styles.flatMap(s => createOLStyles(s));
        }
    }

    render() {
        const { drawing } = this.state;
        return (
            <React.Fragment>
                {drawing && (
                    <OLDraw
                        type="Polygon"
                        source={this._source}
                        stopClick={true}
                        condition={() => true}
                        finishCondition={this._endMeasurement}
                        freehandCondition={() => false}
                        onDrawStart={this._handleDrawStart}
                        onDrawEnd={this._handleDrawEnd}
                        style={feature => this._style(feature, false)}
                        wrapX={false}
                        minPoints={2}
                    />
                )}
                <OLVectorLayer source={this._source} style={feature => this._style(feature, true)} zIndex={9999} />
            </React.Fragment>
        );
    }
}

export default Measure;

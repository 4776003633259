import React from "react";
import classNames from "classnames";

import { Typography, Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    message: {
        marginBottom: theme.spacing(1)
    }
}));

function ErrorView({ className, title, message, onTryAgain, tryAgainTitle = "Try again" }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            {title && <Typography variant="h6">{title}</Typography>}
            {message && (
                <Typography className={classes.message} variant="subtitle1">
                    {message}
                </Typography>
            )}
            {onTryAgain && (
                <Button color="primary" variant="contained" onClick={() => onTryAgain()}>
                    {tryAgainTitle}
                </Button>
            )}
        </div>
    );
}

export default ErrorView;

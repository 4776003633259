import React from "react";

import { Snackbar as MUISnackbar, Slide } from "@mui/material";
import { Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    message: {
        wordBreak: "break-word"
    }
}));

const TransitionSlideUp = React.forwardRef((props, ref) => <Slide ref={ref} direction={"up"} {...props} />);

function Snackbar({ message, variant = "error", onClose, autoHideDuration = null }) {
    const classes = useStyles();

    return (
        <MUISnackbar
            open={!!message}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            onClose={onClose}
            TransitionComponent={TransitionSlideUp}
        >
            <Alert severity={variant} onClose={onClose} classes={classes}>
                {message}
            </Alert>
        </MUISnackbar>
    );
}

export default Snackbar;

import { memo } from "react";
import { useFieldController } from "./useFieldController";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import classNames from "classnames";
import HTMLEditor from "../HTMLEditor";
import OutlinedContainer from "../OutlinedContainer";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        padding: 0,

        "& .ck-toolbar": {
            borderLeft: "none",
            borderRight: "none",
            borderTop: "none",
            background: "none",
            paddingTop: theme.spacing(1)
        },
        "& .ck-content": {
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none"
        }
    }
}));

function HTMLField({ schema, disabled, name, rules, defaultValue, className }) {
    const classes = useStyles();

    const { field, fieldState: error } = useFieldController({ name, rules, defaultValue });

    return (
        <OutlinedContainer
            className={classNames(classes.container, className)}
            label={schema.title}
            error={hasError(error)}
            warning={hasWarning(error)}
            helperText={getErrorMessages(error)?.join(", ")}
        >
            <HTMLEditor markup={field.value} onChange={field.onChange} disabled={disabled} />
        </OutlinedContainer>
    );
}

export default memo(HTMLField);

window.ATLAS_NATIVE_MESSAGE_COUNTER = 0;
window.ATLAS_NATIVE_MESSAGE_RESPONSE_HANDLERS = {};
window.ATLAS_NATIVE_MESSAGE_RESPONSE = ({ id, result, error }) => {
    if (error) {
        console.error(`Native reponse (${id}) error: ${error}.`);
        window.ATLAS_NATIVE_MESSAGE_RESPONSE_HANDLERS[id].reject(error);
    } else {
        // console.log(`Native reponse (${id}) was successful.`);
        window.ATLAS_NATIVE_MESSAGE_RESPONSE_HANDLERS[id].resolve(result);
    }
    delete window.ATLAS_NATIVE_MESSAGE_RESPONSE_HANDLERS[id];
};

export const postNativeMessage = async (type, data) => {
    if (!window.ATLAS_IS_NATIVE) {
        return false;
    }
    return new Promise((resolve, reject) => {
        if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.native &&
            typeof window.webkit.messageHandlers.native.postMessage === "function"
        ) {
            const id = window.ATLAS_NATIVE_MESSAGE_COUNTER++;
            window.ATLAS_NATIVE_MESSAGE_RESPONSE_HANDLERS[id] = { resolve, reject };
            // console.log(`Sending native message (${id}): "${type}"`);
            window.webkit.messageHandlers.native.postMessage({ type, id, data });
        } else {
            reject("Native application is not available");
        }
    });
};

import CustomCellRenderer from "./CustomCellRenderer";
import DateCellRenderer from "./DateCellRenderer";
import FileCellRenderer from "./FileCellRenderer";
import FileArrayCellRenderer from "./FileArrayCellRenderer";
import LocationCellRenderer from "./LocationCellRenderer";
import NullableCellRenderer from "./NullableCellRenderer";

function AttributeCellRenderer({ ...props }) {
    const { attribute } = props;
    switch (attribute?.type) {
        case "date":
            return <DateCellRenderer {...props} />;
        case "datetime":
            return <DateCellRenderer type="datetime" {...props} />;
        case "image":
        case "file":
            if (attribute?.array) {
                return <FileArrayCellRenderer {...props} />;
            }
            return <FileCellRenderer {...props} />;
        case "location":
            return <LocationCellRenderer {...props} />;
        case "custom":
            return <CustomCellRenderer {...props} />;
        default:
            return <NullableCellRenderer {...props} />;
    }
}

export default AttributeCellRenderer;

import React, { memo, useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { useController } from "react-hook-form";
import { getValidationResult, getValidationsFromSchema } from "./form-utils";
import FieldWrapper from "./FieldWrapper";
import RenderCount from "../RenderCount";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormContext } from "./Form";
import { FUXExpandingListItem } from "foran-ux";
import { ListAlt } from "@mui/icons-material";
/**
 * Groups add a "fake field" with useController in order for validations
 * to be able to run on group level. They are never connected to any
 * inputs, but react-hook-form will add "undefined" values for them
 * which will have to be removed before submitting
 * (see implementation in Form file).
 */

const useStyles = makeStyles(theme => ({
    container: level => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(level),
        borderTop: level > 0 ? "3px solid #ccc" : "none",
        borderBottom: level > 0 ? "3px solid #ccc" : "none"
    }),
    error: {
        color: theme.colors.destructive
    },
    title: {
        fontWeight: "bold",
        fontSize: "1.1rem",

        "&.level1": {
            fontSize: "1.2rem"
        },
        "&.level0": {
            fontSize: "1.3rem"
        }
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    }
}));

function FormGroup({ schema, level = 0, ...props }) {
    const classes = useStyles(level);
    const { getValues } = props;
    const { submitWithWarnings } = useContext(FormContext);
    const [expanded, setExpanded] = useState(null);
    const controller = useMemo(() => {
        const validations = getValidationsFromSchema(schema) ?? [];
        let name = schema._id;
        if (!name) {
            if (level === 0) {
                name = "root";
            } else {
                throw new Error(`Group schema for "${schema.title}" is missing an id.`);
            }
        }
        return {
            name,
            rules: {
                validate: validations.reduce(
                    (validations, validation, index) => ({
                        ...validations,
                        [`${validation.type}${index}`]: () => {
                            if (submitWithWarnings.current && validation.type === "warning") {
                                return;
                            }
                            const values = getValues();
                            return getValidationResult(validation, schema, values);
                        }
                    }),
                    {}
                )
            }
        };
    }, [schema, getValues, level, submitWithWarnings]);
    const {
        fieldState: { error }
    } = useController(controller);

    return (
        <div className={classes.container}>
            <RenderCount label="group" />
            {level === 0 && (
                <Typography className={classNames(classes.title, `level${level}`)} variant="h5">
                    {schema.title}
                </Typography>
            )}
            {schema.subtitle && (
                <Typography className={classes.subtitle} variant="subtitle2">
                    {schema.subtitle}
                </Typography>
            )}
            {schema.children?.map((schema, index) => {
                if (schema.type === "group") {
                    return (
                        <FUXExpandingListItem
                            key={index}
                            nested
                            titleElement={
                                schema.title && (
                                    <>
                                        <Typography
                                            className={classNames(classes.title, `levels${level}`)}
                                            variant="h3"
                                        >
                                            {schema.title}
                                        </Typography>
                                    </>
                                )
                            }
                            icon={<ListAlt />}
                            expanded={expanded === index}
                            onClick={() => setExpanded(expanded => (expanded === index ? null : index))}
                            unmountOnExit={false}
                        >
                            <FormGroup schema={schema} level={level + 1} {...props} />
                        </FUXExpandingListItem>
                    );
                } else {
                    return <FieldWrapper key={index} schema={schema} {...props} />;
                }
            })}
            {error && <Typography className={classes.error}>{error.message}</Typography>}
        </div>
    );
}

export default memo(FormGroup);

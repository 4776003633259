import React, { useContext } from "react";
import { WorkspaceContext } from "./workspace-context";
import NumberInputWithSlider from "./NumberInputWithSlider";

import { List, ListItem } from "@mui/material";
import { useLayerOpacity } from "../useLayerOpacity";

function LayerListItemDetailsRaster({ className, layer }) {
    const { updateConfiguration } = useContext(WorkspaceContext);

    const opacity = useLayerOpacity(layer);

    return (
        <div className={className}>
            <List>
                <ListItem>
                    <NumberInputWithSlider
                        title="Opacity"
                        value={opacity}
                        min={0}
                        max={100}
                        step={1}
                        onChange={opacity =>
                            updateConfiguration(configuration => {
                                const layerOverrides = { ...configuration.layerOverrides };
                                layerOverrides[layer._id] = { ...layerOverrides[layer._id], opacity };
                                return { ...configuration, layerOverrides };
                            })
                        }
                    />
                </ListItem>
            </List>
        </div>
    );
}

export default LayerListItemDetailsRaster;

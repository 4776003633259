import { LocalDatabase } from "idb";
import { ObjectId } from "./classes/id-generation";
import { postNativeMessage } from "./Native";

export const blobToBase64String = blob => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

export const base64StringToBlob = async base64text => {
    const blob = await fetch(base64text).then(response => response.blob());
    return blob;
};

export class NativeLocalDatabase extends LocalDatabase {
    constructor(options) {
        super(options);
        for (const store in this._objectStores) {
            const storeOptions = options.objectStores.find(os => os.name === store);
            this._objectStores[store].nativeKeys = new Set(storeOptions.nativeKeys || []);
        }
    }

    // GET

    async getObject(id, store) {
        const obj = await super.getObject(id, store);
        if (!obj) {
            return undefined;
        }
        return this._getNative(obj, store);
    }

    async getObjects(ids, store, ignoreNonExisting) {
        const objects = [];
        for (const id of ids) {
            const object = await this.getObject(id, store);
            if (object) {
                ids.push(object);
            } else if (!ignoreNonExisting) {
                throw new Error(`Object "${id}" does not exist.`);
            }
        }
        return objects;
    }

    // ADD

    async addObject(obj, store, update) {
        if (update) {
            await this._deleteExistingNativeIfAvailable(obj, store);
        }
        const idbObj = await this._addNative(obj, store);
        try {
            return super.addObject(idbObj, store, update);
        } catch (error) {
            await this._deleteExistingNativeIfAvailable(obj, store);
            throw error;
        }
    }

    async addObjects(objects, store, update) {
        for (const object of objects) {
            await this.addObject(object, store, update);
        }
    }

    // UPDATE
    // handled by idb, just calls addObject with update = true

    // DELETE

    async delete(id, store) {
        const obj = await super.getObject(id, store);
        if (!obj) {
            return;
        }
        await this._deleteNative(obj, store);
        return super.delete(id, store);
    }

    async deleteObject(obj, store) {
        const id = this._getId(obj, store);
        return this.delete(id, store);
    }

    // OTHER

    async clear(store) {
        await postNativeMessage("db-clear", { store });
        return super.clear(store);
    }

    async forEach(store, query, direction, index, objectHandler) {
        const objectStore = this._objectStores[store];
        if (!objectStore) {
            throw new Error(`Store "${store}" does not exist.`);
        }
        return super.openCursor(store, query, direction, index, cursor => {
            const wrappedCursor = {
                delete: () => {
                    const request = {
                        onerror: () => {},
                        onsuccess: () => {}
                    };
                    const cursorRequest = cursor.delete();
                    cursorRequest.onerror = request.onerror;
                    cursorRequest.onsuccess = () => {
                        this._deleteNative(cursor.value, store)
                            .then(request.onsuccess)
                            .catch(request.onerror);
                    };
                    return request;
                }
            };
            objectHandler(cursor.value, wrappedCursor);
            cursor.continue();
        });
    }

    // INTERNAL

    _getId(obj, store) {
        const keyPath = this._objectStores[store].keyPath;
        if (Array.isArray(keyPath)) {
            return keyPath.map(key => obj[key]);
        } else {
            return obj[keyPath];
        }
    }

    async _deleteExistingNativeIfAvailable(obj, store) {
        const id = this._getId(obj, store);
        const existingObject = await super.getObject(id, store);
        if (existingObject) {
            await this._deleteNative(existingObject, store);
        }
    }

    async _getNative(obj, store) {
        const objectStore = this._objectStores[store];
        if (!objectStore) {
            throw new Error(`Store "${store}" does not exist.`);
        }
        const completeObject = { ...obj };
        for (const key in obj) {
            const nativeId = obj[key];
            if (objectStore.nativeKeys.has(key) && !!nativeId) {
                const string = await postNativeMessage("db-get", { id: nativeId, store });
                completeObject[key] = await base64StringToBlob(string);
            }
        }
        return completeObject;
    }

    async _addNative(obj, store) {
        const objectStore = this._objectStores[store];
        if (!objectStore) {
            throw new Error(`Store "${store}" does not exist.`);
        }
        const idbObj = { ...obj };
        for (const key in obj) {
            if (objectStore.nativeKeys.has(key) && !!obj[key]) {
                const nativeId = ObjectId().toString();
                const value = await blobToBase64String(obj[key]);
                await postNativeMessage("db-set", { id: nativeId, store, value });
                idbObj[key] = nativeId;
            }
        }
        return idbObj;
    }

    async _deleteNative(obj, store) {
        const objectStore = this._objectStores[store];
        if (!objectStore) {
            throw new Error(`Store "${store}" does not exist.`);
        }
        for (const key in obj) {
            const nativeId = obj[key];
            if (objectStore.nativeKeys.has(key) && !!nativeId) {
                await postNativeMessage("db-delete", { id: nativeId, store });
            }
        }
    }
}

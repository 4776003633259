import { useContext, useEffect, useState } from "react";
import { AtlasContext } from "../atlas-context";

export const useFeatureAttributeRows = (layer, workspaceId) => {
    const { dbManager } = useContext(AtlasContext);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let cancelled = false;
        const fetchRows = async () => {
            const db = await dbManager.getVectorDB(layer._id, workspaceId);
            const features = await db.query({ "__atlas.child": { $eq: 0 } });
            if (!cancelled) {
                setRows(
                    features
                        .filter(feature => !feature.properties?.__atlas?.deleted)
                        .map(feature => {
                            const row = layer.attributes.reduce((acc, attribute) => {
                                acc[attribute._id] = feature.properties[attribute._id];
                                return acc;
                            }, {});
                            row["__atlas_original_id"] = feature.properties.__atlas.originalId || feature.id;
                            return row;
                        })
                );
            }
        };
        fetchRows();
        return () => {
            cancelled = true;
        };
    }, [dbManager, workspaceId, layer]);

    return rows;
};

import React, { useState, useContext, useCallback } from "react";
import { checkLayerPermission } from "../layer-tools";
import { copyStyles, pasteStyles } from "../copy-style";
import { useLayerOpacity } from "../useLayerOpacity";
import { CopyContext } from "../copy-context";
import { DERIVED_ATTRIBUTES } from "../styles/conditions/AttributePicker";
import { DrawerContext } from "./Drawer";
import { WorkspaceContext } from "./workspace-context";
import AttributeTable from "../attribute-table/AttributeTable";
import ExpandingListItem from "../ExpandingListItem";
import ExportLayerDialog from "./ExportLayerDialog";
import GeoJSONImportDialog from "../GeoJSONImportDialog";
import LayerStyleEditor from "../styles/LayerStyleEditor";
import NumberInputWithSlider from "./NumberInputWithSlider";
import PasteStylesErrorDialog from "../styles/PasteStylesErrorDialog";

import { List, ListItem, ListItemText, ListItemIcon, Tooltip, Typography, ListItemButton } from "@mui/material";
import {
    ArrowDownward as ArrowDownwardIcon,
    ArrowUpward as ArrowUpwardIcon,
    ListAlt as ListAltIcon,
    MyLocation as MyLocationIcon,
    Style as StyleIcon
} from "@mui/icons-material";

function LayerListItemDetailsVector({ layer, onZoomToLayer }) {
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [exportDialogOpen, setExportDialogOpen] = useState(false);
    const [expansion, setExpansion] = useState(null);
    const { configuration, updateConfiguration, saveStyles, resetStyles, activeLayerIds } =
        useContext(WorkspaceContext);
    const { setOverlay, setOverlayTitle } = useContext(DrawerContext);
    const { copy, value: copiedValue } = useContext(CopyContext);

    const opacity = useLayerOpacity(layer);
    const createIsAllowed = checkLayerPermission("createfeatures", layer, configuration);

    const [pasteErrorDialogOpen, setPasteErrorDialogOpen] = useState(false);
    const handleCopyStyles = () => {
        const styles = layer.__localData.styles || layer.styles;
        const styleCopy = copyStyles(styles, [...layer.attributes, ...DERIVED_ATTRIBUTES[layer.source.type]]);
        copy(`styles-${layer.source.type}`, JSON.stringify(styleCopy));
    };
    const handlePasteStyles = () => {
        const { styles, error } = pasteStyles(JSON.parse(copiedValue), [
            ...layer.attributes,
            ...DERIVED_ATTRIBUTES[layer.source.type]
        ]);
        updateConfiguration(configuration => {
            const currentLayer = configuration.layers.find(l => l._id === layer._id);
            currentLayer.__localData.styles = styles;
            return configuration;
        });
        if (error) {
            setPasteErrorDialogOpen(true);
        }
    };
    const handleChangeStyles = useCallback(
        newStyles => {
            updateConfiguration(configuration => {
                const currentLayer = configuration.layers.find(l => l._id === layer._id);
                currentLayer.__localData.styles = currentLayer.__localData.styles || currentLayer.styles;
                if (typeof newStyles === "function") {
                    currentLayer.__localData.styles = newStyles(currentLayer.__localData.styles);
                } else {
                    currentLayer.__localData.styles = newStyles;
                }
                return configuration;
            });
        },
        [updateConfiguration, layer._id]
    );
    const handleDeleteStyle = useCallback(
        index => {
            updateConfiguration(configuration => {
                const currentLayer = configuration.layers.find(l => l._id === layer._id);
                currentLayer.__localData.styles = currentLayer.__localData.styles || currentLayer.styles;
                const newStyles = [...currentLayer.__localData.styles];
                newStyles.splice(index, 1);
                currentLayer.__localData.styles = newStyles;
                return configuration;
            });
        },
        [updateConfiguration, layer._id]
    );
    const handleAddStyle = useCallback(() => {
        updateConfiguration(configuration => {
            const currentLayer = configuration.layers.find(l => l._id === layer._id);
            currentLayer.__localData.styles = currentLayer.__localData.styles || currentLayer.styles;
            currentLayer.__localData.styles = [...currentLayer.__localData.styles, { name: "New style" }];
            return configuration;
        });
    }, [updateConfiguration, layer._id]);

    return (
        <>
            <List disablePadding>
                {createIsAllowed && (
                    <ListItem dense>
                        <Typography variant="body2">
                            Editing status: {activeLayerIds?.includes(layer._id) ? "Active" : "Inactive"}
                        </Typography>
                    </ListItem>
                )}
                <ListItem dense>
                    <Typography variant="body2">Name: {layer.name}</Typography>
                </ListItem>
                <ListItem dense>
                    <Typography variant="body2">
                        Type: {layer.source.type.charAt(0).toUpperCase() + layer.source.type.slice(1)}
                    </Typography>
                </ListItem>
                <ListItem dense>
                    <Typography variant="body2">id: {layer._id}</Typography>
                </ListItem>
                <ListItem>
                    <NumberInputWithSlider
                        title="Opacity"
                        value={opacity}
                        min={0}
                        max={100}
                        step={1}
                        onChange={opacity =>
                            updateConfiguration(configuration => {
                                const layerOverrides = { ...configuration.layerOverrides };
                                layerOverrides[layer._id] = { ...layerOverrides[layer._id], opacity };
                                return { ...configuration, layerOverrides };
                            })
                        }
                    />
                </ListItem>
                <ListItemButton onClick={() => onZoomToLayer(layer)}>
                    <ListItemIcon>
                        <MyLocationIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Zoom to layer"
                        primaryTypographyProps={{ variant: "subtitle2", fontWeight: "bold" }}
                    />
                </ListItemButton>
                <Tooltip
                    title={createIsAllowed ? "" : "You don't have permission to create features in this layer."}
                    placement="top-start"
                    enterTouchDelay={0}
                >
                    <span>
                        <ListItemButton onClick={() => setImportDialogOpen(true)} disabled={!createIsAllowed}>
                            <ListItemIcon>
                                <ArrowDownwardIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Import GeoJSON"
                                primaryTypographyProps={{ variant: "subtitle2", fontWeight: "bold" }}
                            />
                        </ListItemButton>
                    </span>
                </Tooltip>
                <ListItemButton onClick={() => setExportDialogOpen(true)}>
                    <ListItemIcon>
                        <ArrowUpwardIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Export layer"
                        primaryTypographyProps={{ variant: "subtitle2", fontWeight: "bold" }}
                    />
                </ListItemButton>
                <ListItemButton
                    onClick={() => {
                        setOverlay(<AttributeTable layer={layer} />);
                        setOverlayTitle(`Attribute table for ${layer.name}`);
                    }}
                >
                    <ListItemIcon>
                        <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary="Attribute table"
                        primaryTypographyProps={{ variant: "subtitle2", fontWeight: "bold" }}
                    />
                </ListItemButton>
                <ExpandingListItem
                    nested
                    id="styles"
                    title="Styles"
                    titleVariant={null}
                    icon={<StyleIcon />}
                    expanded={expansion === "styles"}
                    onClick={id => setExpansion(id === expansion ? null : id)}
                >
                    <LayerStyleEditor
                        layer={layer}
                        styles={layer.__localData.styles || layer.styles}
                        attributes={layer.attributes}
                        featureType={layer.source.type}
                        onAddStyle={handleAddStyle}
                        onDeleteStyle={handleDeleteStyle}
                        onChange={handleChangeStyles}
                        onSave={saveStyles}
                        onReset={resetStyles}
                        onCopyStyles={handleCopyStyles}
                        onPasteStyles={handlePasteStyles}
                    />
                </ExpandingListItem>
            </List>
            <GeoJSONImportDialog
                open={importDialogOpen}
                onCancel={() => setImportDialogOpen(false)}
                onComplete={() => setImportDialogOpen(false)}
                layer={layer}
            />
            <ExportLayerDialog
                open={exportDialogOpen}
                onClose={() => setExportDialogOpen(false)}
                layer={layer}
                workspace={configuration}
            />
            <PasteStylesErrorDialog open={pasteErrorDialogOpen} onClose={() => setPasteErrorDialogOpen(false)} />
        </>
    );
}

export default LayerListItemDetailsVector;

import React, { useContext } from "react";
import { WorkspaceContext } from "./workspace-context";
import { isVectorLayer, checkLayerPermission } from "../layer-tools";
import IconCheckbox from "./IconCheckbox";

import makeStyles from "@mui/styles/makeStyles";
import { Create } from "@mui/icons-material";

const useStyles = makeStyles(_ => ({
    invisibleButton: {
        width: "38px" // Width of icon button (which is only visible for vector layers)
    }
}));

function LayerActiveCheckbox({ layer, onChange }) {
    const classes = useStyles();
    const { configuration, activeLayerIds } = useContext(WorkspaceContext);

    if (isVectorLayer(layer)) {
        const disabled = !checkLayerPermission("createfeatures", layer, configuration);
        const checked = activeLayerIds && activeLayerIds.includes(layer._id);
        let tooltipTitle = checked ? "Active" : "Inactive";
        if (disabled) {
            tooltipTitle = "You don't have permission to create features in this layer";
        }
        return (
            <IconCheckbox
                tooltipTitle={tooltipTitle}
                checked={checked}
                checkedIcon={<Create />}
                uncheckedIcon={<Create />}
                onChange={onChange}
                disabled={disabled}
            />
        );
    } else {
        return <div className={classes.invisibleButton} />;
    }
}

export default LayerActiveCheckbox;

import React, { useContext } from "react";
import { UpdateContext } from "./update-context";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Update } from "@mui/icons-material";

function CheckForUpdatesSettingsButton() {
    const { checkForUpdate } = useContext(UpdateContext);
    return (
        <ListItem button onClick={() => checkForUpdate(true)}>
            <ListItemIcon>
                <Update />
            </ListItemIcon>
            <ListItemText
                primary="Check for updates"
                secondary="If you are using Atlas in a browser, reload the page to check for updates."
            />
        </ListItem>
    );
}

export default CheckForUpdatesSettingsButton;

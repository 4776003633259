import { useContext } from "react";
import { AtlasContext } from "../atlas-context";
import { ErrorContext } from "../error-context";
import { WorkspaceContext } from "../workspace/workspace-context";

import { ObjectId } from "../id-generation";
import { saveOLFeature } from "../workspace/map/tools/utility/utility";

export const useFeatureForm = (layer, olFeature) => {
    const { setError } = useContext(ErrorContext);
    const { dbManager } = useContext(AtlasContext);
    const { configuration } = useContext(WorkspaceContext);

    const handleFileUpload = async file => {
        const layerId = olFeature.layer.get("id");
        const idbFile = {
            id: ObjectId().toString(),
            localModified: Date.now(),
            layerId,
            file
        };
        await dbManager.saveLocalFile(idbFile, configuration._id);
        return { id: idbFile.id, name: file.name };
    };
    const saveFile = async file => {
        const _file = file?.file;
        if (_file) {
            console.log(`useForm.saveFile ${_file?.name}`, _file);
            try {
                return handleFileUpload(_file);
            } catch (error) {
                console.logError(error, `There was an error saving the file "${_file.name}"`);
                throw error;
            }
        }
        return file;
    };

    const handleSubmit = async (values, metadata) => {
        console.log("useForm.handleSubmit", JSON.stringify({ values, metadata }));
        const oldProperties = olFeature.getProperties();
        try {
            for (const def of layer.attributes) {
                // Make sure old references to attribute names are removed
                olFeature.unset(def.name);
            }
            const properties = { ...olFeature.getProperties(), ...values };

            // Save files
            const fileAttributes = layer.attributes.filter(a => ["image", "file"].includes(a.type));
            for (const fileAttribute of fileAttributes) {
                const value = values[fileAttribute._id];
                if (Array.isArray(value)) {
                    properties[fileAttribute._id] = await Promise.all(value.map(saveFile));
                } else if (value) {
                    properties[fileAttribute._id] = await saveFile(value);
                }
            }

            // set 0 length arrays to null
            const arrayAttributes = layer.attributes.filter(a => !!a.array);
            for (const arrayAttribute of arrayAttributes) {
                if (Array.isArray(properties[arrayAttribute._id]) && properties[arrayAttribute._id].length === 0) {
                    properties[arrayAttribute._id] = null;
                }
            }

            if (metadata) {
                properties.__atlas = { ...(properties.__atlas ?? {}), metadata };
            }

            olFeature.setProperties(properties);
            await saveOLFeature(olFeature, configuration, dbManager);
        } catch (error) {
            olFeature.setProperties(oldProperties);
            console.logError(error, "useForm.handleSubmit");
            setError(error);
        }
    };

    return { handleSubmit };
};

import * as React from "react";

import { SvgIcon } from "@mui/material";

function MouseClick(props) {
    return (
        <SvgIcon viewBox="0 0 24 24" {...props}>
            <path fill="none" d="M0 0h24v24H0z" />
            <path fill="none" d="M2 2h20v20H2z" />
            <path
                d="M5.406 10.753a.868.868 0 01-.986 1.428 5.581 5.581 0 117.76-7.761.868.868 0 11-1.428.986 3.952 3.952 0 00-.453-.54 3.831 3.831 0 00-2.717-1.125c-.983 0-1.966.375-2.716 1.125a3.831 3.831 0 00-1.125 2.717c0 1.26.627 2.457 1.665 3.17zm2.266-4.186l13.741 4.724a.87.87 0 01.034 1.632l-6.066 2.46-2.461 6.074a.872.872 0 01-1.629-.044L6.567 7.672a.87.87 0 011.105-1.105zm10.978 5.614l-9.859-3.39 3.39 9.859 1.726-4.259a.866.866 0 01.481-.482l4.262-1.728z"
                fillRule="nonzero"
            />
        </SvgIcon>
    );
}

export default MouseClick;

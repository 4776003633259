import { Component } from "react";
import { MapContext } from "../map-context";
import { addEventListeners, removeEventListeners, getOptions, addProperties } from "../utility";

import OLVectorLayer from "ol/layer/Vector";

class VectorLayer extends Component {
    constructor(props) {
        super(props);

        this.options = {
            opacity: undefined,
            visible: undefined,
            extent: undefined,
            zIndex: undefined,
            minResolution: undefined,
            maxResolution: undefined,
            renderOrder: undefined,
            renderBuffer: undefined,
            renderMode: undefined,
            source: undefined,
            map: undefined,
            declutter: undefined,
            style: undefined,
            updateWhileAnimating: undefined,
            updateWhileInteracting: undefined
        };

        this.events = {};
        this.eventNames = {
            change: "change",
            changeextent: "change:extent",
            changemaxresolution: "change:maxResolution",
            changeminresolution: "change:minResolution",
            changeopacity: "change:opacity",
            changesource: "change:source",
            changevisible: "change:visible",
            changezindex: "change:zIndex",
            propertychange: "propertychange",
            postrender: "postrender",
            rendercomplete: "rendercomplete"
        };
    }

    componentDidMount() {
        this.setup();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.opacity !== this.props.opacity) {
            this.layer.setOpacity(this.props.opacity);
        }
        if (prevProps.visible !== this.props.visible) {
            this.layer.setVisible(this.props.visible);
        }
        if (prevProps.zIndex !== this.props.zIndex) {
            this.layer.setZIndex(this.props.zIndex);
        }
        if (prevProps.style !== this.props.style) {
            this.layer.setStyle(this.props.style);
        }
        if (prevProps.maxResolution !== this.props.maxResolution) {
            this.layer.setMaxResolution(this.props.maxResolution);
        }
        if (prevProps.minResolution !== this.props.minResolution) {
            this.layer.setMinResolution(this.props.minResolution);
        }
        // TODO: More update checks
    }

    componentWillUnmount() {
        this.cleanup();
    }

    setup() {
        const options = getOptions(this.props, this.options);
        this.layer = new OLVectorLayer(options);
        this.context.map.addLayer(this.layer);
        addEventListeners(this.props, this.events, this.eventNames, this.layer);
        addProperties(this.props.properties, this.layer);
        if (this.props.innerRef) {
            this.props.innerRef(this.layer);
        }
    }

    cleanup() {
        this.context.map.removeLayer(this.layer);
        removeEventListeners(this.events, this.layer);
    }

    render() {
        return null;
    }
}

VectorLayer.contextType = MapContext;

export default VectorLayer;

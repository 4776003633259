import React from "react";
import { useUserByHostname } from "./useUserByHostname";
import AdapterLink from "./AdapterLink";
import Api from "../Api";

import { TableCell, TableRow, IconButton, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility as VisibilityIcon, GetApp as GetAppIcon } from "@mui/icons-material";

const useStyles = makeStyles({
    actions: {
        display: "flex"
    }
});

function UserReportTableRow({ report, hideNavigate = false }) {
    const user = useUserByHostname(report && report.hostname);
    const classes = useStyles();

    return (
        <TableRow>
            {user ? (
                <React.Fragment>
                    <TableCell>
                        <div className={classes.actions}>
                            {!hideNavigate && (
                                <IconButton component={AdapterLink} to={`/logs/${report._id}`} size="large">
                                    <VisibilityIcon />
                                </IconButton>
                            )}
                            <IconButton
                                onClick={() => window.open(Api.getLogsURL(report._id), "_blank")}
                                size="large">
                                <GetAppIcon />
                            </IconButton>
                        </div>
                    </TableCell>
                    <TableCell>{report._id}</TableCell>
                    <TableCell>{new Date(report.timestamp).toISOString()}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{report.message}</TableCell>
                </React.Fragment>
            ) : (
                <TableCell>
                    <CircularProgress />
                </TableCell>
            )}
        </TableRow>
    );
}

export default UserReportTableRow;

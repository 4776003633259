import { useCallback, useEffect, useState, useContext, useRef } from "react";

import Api from "../Api";
import { AtlasContext } from "./atlas-context";
import useOnline from "./useOnline";

function useWorkspaces() {
    const { dbManager } = useContext(AtlasContext);
    const online = useOnline();
    const mounted = useRef(true);

    const [offlineWorkspaces, setOfflineWorkspaces] = useState(null);
    const [availableWorkspaces, setAvailableWorkspaces] = useState(null);
    const [fetchError, setFetchError] = useState(null);
    const [fetchingOffline, setFetchingOffline] = useState(false);
    const [fetchingAvailable, setFetchingAvailable] = useState(false);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    const fetchOfflineWorkspaces = useCallback(async () => {
        setFetchingOffline(true);
        try {
            const newOfflineWorkspaces = (await dbManager.getOfflineWorkspaces()).map(w => ({
                ...w,
                type: "workspace"
            }));
            if (mounted.current) {
                setOfflineWorkspaces(newOfflineWorkspaces);
            }
            return newOfflineWorkspaces;
        } catch (error) {
            console.logError(error, "Error fetching offline workspaces");
            if (mounted.current) {
                setFetchError("Could not get offline workspaces.");
                setOfflineWorkspaces(null);
            }
        } finally {
            if (mounted.current) {
                setFetchingOffline(false);
            }
        }
    }, [dbManager]);

    const fetchWorkspaces = useCallback(async () => {
        const newOfflineWorkspaces = await fetchOfflineWorkspaces();
        if (!mounted.current) {
            return;
        }
        if (online) {
            setFetchingAvailable(true);
            try {
                let newAvailableWorkspaces = await Api.getAllWorkspaces();
                if (newOfflineWorkspaces) {
                    const offlineIDs = newOfflineWorkspaces.map(w => w._id);
                    newAvailableWorkspaces = newAvailableWorkspaces.filter(w => !offlineIDs.includes(w._id));
                }
                if (mounted.current) {
                    setAvailableWorkspaces(newAvailableWorkspaces);
                }
            } catch (error) {
                console.logError(error, "Error fetching workspaces from server");
                if (mounted.current) {
                    setFetchError("Could not get available workspaces from server.");
                    setAvailableWorkspaces(null);
                }
            } finally {
                if (mounted.current) {
                    setFetchingAvailable(false);
                }
            }
        } else {
            setOfflineWorkspaces(newOfflineWorkspaces);
            setAvailableWorkspaces([]);
        }
    }, [fetchOfflineWorkspaces, online]);

    useEffect(() => {
        fetchWorkspaces();
    }, [fetchWorkspaces]);

    useEffect(() => {
        dbManager.on("offline-workspace-change", fetchOfflineWorkspaces);
        return () => {
            dbManager.off("offline-workspace-change", fetchOfflineWorkspaces);
        };
    }, [dbManager, fetchOfflineWorkspaces]);

    return {
        offlineWorkspaces,
        availableWorkspaces,
        fetchError,
        fetchWorkspaces,
        fetchingOffline,
        fetchingAvailable
    };
}

export default useWorkspaces;

import { Box } from "@mui/material";

function DisabledCover({ disabled, children }) {
    if (!disabled) {
        return null;
    }

    return (
        <Box position="absolute" top={0} left={0} bottom={0} right={0} bgcolor="rgba(255, 255, 255, 0.7)">
            {children}
        </Box>
    );
}

export default DisabledCover;

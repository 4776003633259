import React, { useState } from "react";
import Fill from "./Fill";
import Stroke from "./Stroke";
import Switch from "./Switch";
import PlainText from "./PlainText";
import Integer from "./Integer";
import MultipleChoice from "./MultipleChoice";
import ExpandingStyleListItem from "./ExpandingStyleListItem";

import { List } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    list: {
        paddingTop: 0,
        paddingBottom: 0
    }
});

const FONTS = [
    { name: "Sans-serif", value: "sans-serif" },
    { name: "Serif", value: "serif" }
];
const FONT_WEIGHTS = [
    { name: "Regular", value: "normal" },
    { name: "Bold", value: "bold" }
];

function Label({ label, onChange }) {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(false);

    const handleChange = key => value =>
        onChange(currentLabel => ({
            ...currentLabel,
            [key]: typeof value === "function" ? value(currentLabel[key]) : value
        }));

    const listItemProps = {
        onClick: id => setExpanded(id === expanded ? false : id),
        onOverride: id => onChange(currentLabel => ({ ...currentLabel, [id]: { type: "none" } })),
        onDelete: id =>
            onChange(currentLabel => {
                const newLabel = { ...currentLabel };
                delete newLabel[id];
                return newLabel;
            })
    };

    return (
        <React.Fragment>
            <PlainText
                label="Text"
                text={label.text}
                helperText="Write {{attribute}} to include the value of an attribute anywhere in the label. Write \n to insert a new line."
                onChange={handleChange("text")}
            />
            <Integer
                label="Rotation (degrees)"
                value={label.rotation}
                defaultValue={0}
                onChange={handleChange("rotation")}
            />
            <MultipleChoice
                label="Font"
                value={label.font}
                defaultValue="sans-serif"
                choices={FONTS}
                onChange={handleChange("font")}
            />
            <MultipleChoice
                label="Weight"
                value={label.weight}
                defaultValue="normal"
                choices={FONT_WEIGHTS}
                onChange={handleChange("weight")}
            />
            <Integer label="Size" value={label.size} defaultValue={14} onChange={handleChange("size")} />
            <Switch
                label="Overflow"
                value={label.overflow}
                defaultValue={true}
                onChange={handleChange("overflow")}
                helperText="Allow label to exceed the width of polygons"
            />
            <Integer
                label="Horizontal offset"
                value={label.offsetX}
                defaultValue={0}
                onChange={handleChange("offsetX")}
            />
            <Integer
                label="Vertical offset"
                value={label.offsetY}
                defaultValue={0}
                onChange={handleChange("offsetY")}
            />
            <List className={classes.list}>
                <ExpandingStyleListItem
                    title="Text fill"
                    id="fill"
                    expanded={expanded === "fill"}
                    inherited={label.fill === undefined}
                    {...listItemProps}
                >
                    <Fill fill={label.fill} onChange={handleChange("fill")} />
                </ExpandingStyleListItem>
                <ExpandingStyleListItem
                    title="Text stroke"
                    id="stroke"
                    expanded={expanded === "stroke"}
                    inherited={label.stroke === undefined}
                    {...listItemProps}
                >
                    <Stroke stroke={label.stroke} onChange={handleChange("stroke")} />
                </ExpandingStyleListItem>
                <ExpandingStyleListItem
                    title="Background fill"
                    id="backgroundFill"
                    expanded={expanded === "backgroundFill"}
                    inherited={label.backgroundFill === undefined}
                    {...listItemProps}
                >
                    <Fill fill={label.backgroundFill} onChange={handleChange("backgroundFill")} />
                </ExpandingStyleListItem>
                <ExpandingStyleListItem
                    title="Background stroke"
                    id="backgroundStroke"
                    expanded={expanded === "backgroundStroke"}
                    inherited={label.backgroundStroke === undefined}
                    {...listItemProps}
                >
                    <Stroke stroke={label.backgroundStroke} onChange={handleChange("backgroundStroke")} />
                </ExpandingStyleListItem>
            </List>
        </React.Fragment>
    );
}

export default React.memo(Label);

import React from "react";

import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Delete as DeleteIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    outlined: {
        color: theme.colors.destructive
    },
    text: {
        color: theme.colors.destructive
    },
    contained: {
        backgroundColor: theme.colors.destructive,
        color: "#ffffff",
        "&:hover, &:focus, &:active": {
            backgroundColor: theme.colors.destructiveDark
        }
    }
}));

function DeleteButton({ className, children, ...props }) {
    const classes = useStyles();
    return (
        <Button
            className={className}
            classes={{ contained: classes.contained, outlined: classes.outlined, text: classes.text }}
            startIcon={<DeleteIcon />}
            {...props}
        >
            {children}
        </Button>
    );
}

export default DeleteButton;

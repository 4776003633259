import React from "react";
import classNames from "classnames";

import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: "100%"
    },
    container: {
        padding: theme.spacing(1),
        paddingLeft: "14px", // Align with label
        paddingRight: "14px", // Symmetry with left side
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        position: "relative",
        marginTop: "10px", // Give room for the label

        "&.noPadding": {
            padding: 0
        },
        "&.noMargin": {
            margin: 0
        },
        "&.error": {
            borderColor: theme.colors.destructive
        },
        "&.warning": {
            borderColor: theme.colors.warning
        }
    },
    label: {
        position: "absolute",
        top: "0",
        left: "8px",
        paddingLeft: "5px",
        paddingRight: "3px",
        color: "rgba(0, 0, 0, 0.54)",
        backgroundColor: "#ffffff",
        transform: "translateY(-50%)",

        "&.error": {
            color: theme.colors.destructive
        },
        "&.warning": {
            color: theme.colors.warning
        }
    },
    helperText: {
        display: "inline-block",
        marginLeft: "14px",
        color: "rgba(0, 0, 0, 0.54)",
        marginTop: "4px",

        "&.error": {
            color: theme.colors.destructive
        },
        "&.warning": {
            color: theme.colors.warning
        }
    }
}));

/**
 * Trying to match the appearance of a MUI TextField component
 */
function OutlinedContainer({
    label,
    helperText,
    children,
    fullWidth = false,
    className,
    style,
    error,
    warning,
    noPadding,
    noMargin,
    sx
}) {
    const classes = useStyles();

    return (
        <Box className={classNames({ [classes.fullWidth]: fullWidth })} style={style} sx={sx}>
            <div className={classNames(classes.container, className, { error, warning, noPadding, noMargin })}>
                {label && (
                    <Typography variant="caption" className={classNames(classes.label, { error, warning })}>
                        {label}
                    </Typography>
                )}
                {children}
            </div>
            {helperText && (
                <Typography variant="caption" className={classNames(classes.helperText, { error, warning })}>
                    {helperText}
                </Typography>
            )}
        </Box>
    );
}

export default OutlinedContainer;

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./HTMLEditor.scss";

/**
 * DEFAULT PLUGINS
 *
 * plugin                   description
 *
 * - used -
 * "Essentials"             essential editing features
 * "Autoformat"             markdown like editing
 * "Bold"                   bold
 * "Italic"                 italic
 * "BlockQuote"             block quotes
 * "Heading"                headings
 * "Image"                  images
 * "ImageCaption"           image captions
 * "ImageStyle"             image (css) styles
 * "ImageToolbar"           toolbar when image is selected
 * "Indent"                 indentation
 * "Link"                   links
 * "List"                   lists
 * "MediaEmbed"             media embeds (with URLs)
 * "Paragraph"              paragraphs
 * "Table"                  tables
 * "TableToolbar"           toolbar when table is selected
 * "TextTransformation"     common transformations, e.g. (c) -> ©: https://ckeditor.com/docs/ckeditor5/latest/api/module_typing_texttransformation-TextTransformationConfig.html
 *
 * - removed -
 * "CKFinderUploadAdapter"  file uploads
 * "CKFinder"               file uploads
 * "CloudServices"          CKEditor Cloud Services
 * "EasyImage"              image uploads
 * "ImageUpload"            image uploads
 * "PasteFromOffice"        paste from office, disabled since it may include images (?)
 *
 * DEFAULT TOOLBAR
 *
 * "heading"
 * "|"
 * "bold"
 * "italic"
 * "link"
 * "bulletedList"
 * "numberedList"
 * "|"
 * "outdent"
 * "indent"
 * "|"
 * "uploadImage" - removed
 * "blockQuote"
 * "insertTable"
 * "mediaEmbed"
 * "undo"
 * "redo"
 */

function HTMLEditor({ markup, onChange }) {
    return (
        <CKEditor
            config={{
                disablePlugins: ["CKFinderUploadAdapter", "CKFinder", "CloudServices", "EasyImage", "ImageUpload"],
                toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "mediaEmbed",
                    "|",
                    "undo",
                    "redo"
                ]
            }}
            onReady={editor => {
                window.editor = editor;
            }}
            editor={ClassicEditor}
            data={markup}
            onChange={(_, editor) => {
                onChange(editor.getData());
            }}
        />
    );
}

export default HTMLEditor;

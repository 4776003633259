import React from "react";
import LayerListItemDetailsVector from "./LayerListItemDetailsVector";
import LayerListItemDetailsRaster from "./LayerListItemDetailsRaster";

function LayerListItemDetails({ layer, ...props }) {
    switch (layer.source.type) {
        case "point":
        case "line":
        case "polygon":
            return <LayerListItemDetailsVector layer={layer} {...props} />;
        case "external":
            return <LayerListItemDetailsRaster layer={layer} {...props} />;
        default:
            return null;
    }
}

export default LayerListItemDetails;

import { memo } from "react";
import { statusValues } from "forestand-definitions";
import classNames from "classnames";
import ArrayField from "../../ArrayField";
import EnumField from "../../EnumField";
import ExpandingListItem from "../../../ExpandingListItem";
import ForestandOccasionDateSpan from "./ForestandOccasionDateSpan";
import StringField from "../../StringField";

import { Button, IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

function ForestandOccasion({ className, name, disabled, onRemoveFromArray, onUnlock, onLock, historical }) {
    const classes = useStyles();

    return (
        <ExpandingListItem
            className={classNames(classes.container, className)}
            nested
            id={name}
            title="Tillfälle"
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            {disabled && onUnlock && (
                <Button color="primary" variant="outlined" startIcon={<LockOpenIcon />} onClick={onUnlock}>
                    Unlock
                </Button>
            )}
            {!disabled && onLock && (
                <Button color="primary" variant="outlined" startIcon={<LockIcon />} onClick={onLock}>
                    Lock
                </Button>
            )}
            <EnumField
                className={classes.input}
                name={`${name}.status`}
                schema={{ title: "Status", options: statusValues }}
                disabled={disabled}
            />
            {historical ? (
                <StringField
                    className={classes.input}
                    name={`${name}.date`}
                    schema={{
                        title: "Datum"
                    }}
                    disabled={disabled}
                />
            ) : (
                <ForestandOccasionDateSpan name={name} disabled={disabled} />
            )}
            <ArrayField
                schema={{
                    key: `${name}.activities`,
                    type: "custom",
                    title: "Aktiviteter",
                    customType: "forestand-activity"
                }}
                historical={historical}
                disabled={disabled}
            />
            <ArrayField
                schema={{
                    key: `${name}.alternatives`,
                    type: "custom",
                    title: "Alternativ",
                    customType: "forestand-occasion" // DEVELOPER used RECURSION! It's super effective.
                }}
                historical={historical}
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(ForestandOccasion);

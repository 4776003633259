import React, { Component } from "react";

import { WorkspaceContext } from "../../workspace-context";
import { simulateClick, getFeaturesFromEvent } from "./utility/utility";
import { checkLayerPermission } from "../../../layer-tools";
import { copyToClipboard } from "../../../copyToClipboard";
import openInGoogleMaps from "./utility/open-in-google-maps";
import OLDraw from "../openlayers/interactions/Draw";

import { Menu, MenuItem } from "@mui/material";
import { Directions, Add, Straighten } from "@mui/icons-material";
import MouseClick from "../../../../icons/MouseClick";

const ATLAS_TYPES_MAP = {
    point: "MultiPoint",
    line: "MultiLineString",
    polygon: "MultiPolygon"
};

class Normal extends Component {
    constructor(props) {
        super(props);

        this._handleAddFeature = this._handleAddFeature.bind(this);
        this._showActiveLayer = this._showActiveLayer.bind(this);
        this._selectActiveLayerAndAddFeature = this._selectActiveLayerAndAddFeature.bind(this);
        this._handleDrawEnd = this._handleDrawEnd.bind(this);

        this.state = {
            drawing: false,
            selectActiveLayerMenuAnchor: null,
            selectActiveLayerLayers: null,
            selectActiveLayerCoordinates: null
        };

        if (props.tool.draw && props.tool.coordinates && props.tool.layerId) {
            this.state.drawingCoordinates = props.tool.coordinates;
            this.state.drawingLayerId = props.tool.layerId;
        }
    }

    componentDidMount() {
        if (this.state.drawingCoordinates) {
            this._handleAddFeature(this.state.drawingCoordinates, this.state.drawingLayerId);
        }
    }

    handleMapClick(event) {
        const { onToolRequest, onRevolverRequest, onStatusBarRequest, layers, map } = this.props;
        const { configuration, activeLayerIds } = this.context;

        const features = getFeaturesFromEvent(event);

        if (features.length > 0) {
            onRevolverRequest(null);
            onToolRequest("select", { features, coordinates: event.coordinate });
            return;
        }

        const topButtons = [
            {
                id: "googlemaps",
                icon: <Directions />,
                label: "Open in Google Maps",
                onClick: () =>
                    openInGoogleMaps(event.coordinate, map.getView().getProjection().getCode()).catch(error => {
                        console.logError(error, "Error opening coordinates in Google Maps");
                    })
            }
        ];
        if (activeLayerIds && activeLayerIds.length > 0) {
            if (activeLayerIds.length > 1) {
                topButtons.push({
                    id: "add",
                    icon: <Add />,
                    label: "Create feature in...",
                    onClick: ({ currentTarget }) =>
                        this._selectActiveLayerAndAddFeature(
                            { ...event, coordinate: event.coordinate, currentTarget },
                            activeLayerIds
                        ),
                    doNotCloseOnClick: true
                });
            } else {
                const activeLayer = layers.find(l => l._id === activeLayerIds[0]);
                topButtons.push({
                    id: "add",
                    icon: <Add />,
                    label: `Create feature in ${activeLayer.name}`,
                    disabled: !checkLayerPermission("createfeatures", activeLayer, configuration),
                    disabledExplanation: `You don't have permission to create features in ${activeLayer.name}.`,
                    onClick: () => this._handleAddFeature(event.coordinate, activeLayer._id)
                });
            }
        } else {
            topButtons.push({
                id: "add",
                icon: <Add />,
                disabled: true,
                disabledExplanation: "No active layer"
            });
        }
        topButtons.push({
            id: "copycoordinates",
            icon: <MouseClick />,
            label: "Copy coordinates",
            onClick: () => this._handleCopyCoordinates(event.coordinate)
        });
        const bottomButtons = [
            {
                id: "measure",
                icon: <Straighten />,
                label: "Measure",
                onClick: () => onToolRequest("measure", { coordinates: event.coordinate })
            }
        ];
        this._showActiveLayer();
        onRevolverRequest(event.coordinate, { topButtons, bottomButtons }, () => {
            onStatusBarRequest(null);
        });
    }

    async _handleCopyCoordinates(coordinates) {
        const text = coordinates.join(", ");
        await copyToClipboard(text);
    }

    _handleDrawEnd(event) {
        const { map, onToolRequest, onShowWorkspaceArea } = this.props;
        const { layer } = this.state.drawing;
        const olFeature = event.feature;
        const olLayer = map
            .getLayers()
            .getArray()
            .find(l => l.get("id") === layer._id);
        olFeature.layer = olLayer;
        olLayer.getSource().addFeature(olFeature);
        olLayer.getStyle()(olFeature);
        onShowWorkspaceArea(false);
        onToolRequest("edit", { feature: olFeature });
    }

    _showActiveLayer() {
        const { onStatusBarRequest, layers } = this.props;
        const { activeLayerIds } = this.context;
        if (activeLayerIds && activeLayerIds.length > 0) {
            if (activeLayerIds.length > 1) {
                onStatusBarRequest("Multiple active layers");
            } else {
                const layer = layers.find(l => l._id === activeLayerIds[0]);
                if (layer) {
                    onStatusBarRequest(`Active layer: ${layer.name}`);
                }
            }
        }
    }

    _selectActiveLayerAndAddFeature(event, activeLayerIds) {
        const { configuration } = this.context;
        const selectActiveLayerLayers = activeLayerIds.map(id => configuration.layers.find(l => l._id === id));
        this.setState({
            selectActiveLayerMenuAnchor: event.currentTarget,
            selectActiveLayerLayers,
            selectActiveLayerCoordinates: event.coordinate
        });
    }

    _handleAddFeature(coordinates, layerId) {
        const { layers, onShowWorkspaceArea } = this.props;
        const layer = layers.find(l => l._id === layerId);
        const layerType = layer.source.type;
        const type = ATLAS_TYPES_MAP[layerType];

        console.log(`NormalTool: Start drawing new ${layerType} feature in ${layerId}`);

        this.setState(
            {
                drawing: { type, layer },
                selectActiveLayerCoordinates: null,
                selectActiveLayerMenuAnchor: null,
                selectActiveLayerLayers: null
            },
            () => {
                onShowWorkspaceArea(true);
                simulateClick(coordinates, this.props.map);
            }
        );
    }

    render() {
        const { drawing, selectActiveLayerMenuAnchor, selectActiveLayerLayers, selectActiveLayerCoordinates } =
            this.state;
        return (
            <React.Fragment>
                {drawing && (
                    <OLDraw
                        type={drawing.type}
                        stopClick={true}
                        condition={() => true}
                        freehandCondition={() => false}
                        onDrawEnd={this._handleDrawEnd}
                    />
                )}
                <Menu
                    anchorEl={selectActiveLayerMenuAnchor}
                    open={!!selectActiveLayerMenuAnchor}
                    onClose={() =>
                        this.setState({
                            selectActiveLayerMenuAnchor: null,
                            selectActiveLayerLayers: null,
                            selectActiveLayerCoordinates: null
                        })
                    }
                >
                    {selectActiveLayerLayers &&
                        selectActiveLayerLayers.map(layer => (
                            <MenuItem
                                key={layer._id}
                                onClick={event => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    this.setState(
                                        {
                                            selectActiveLayerCoordinates: null,
                                            selectActiveLayerMenuAnchor: null,
                                            selectActiveLayerLayers: null
                                        },
                                        () => {
                                            this.props.onRevolverRequest(null);
                                            this._handleAddFeature(selectActiveLayerCoordinates, layer._id);
                                        }
                                    );
                                }}
                            >
                                {layer.name}
                            </MenuItem>
                        ))}
                </Menu>
            </React.Fragment>
        );
    }
}

Normal.contextType = WorkspaceContext;

export default Normal;

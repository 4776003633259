import React from "react";
import { useDocumentTitle } from "./useDocumentTitle";
import Navbar from "./Navbar";
import Workspaces from "./Workspaces";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    padTop: {
        padding: 0,
        boxSizing: "border-box"
    }
}));

function MainMenu(props) {
    const classes = useStyles();
    const openWorkspace = workspace => props.history.push(`/workspaces/${workspace._id}`);

    useDocumentTitle("Atlas");

    return (
        <React.Fragment>
            <Navbar />
            <Workspaces className={classes.padTop} onOpenWorkspace={openWorkspace} {...props} />
        </React.Fragment>
    );
}

export default MainMenu;

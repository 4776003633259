export default class CustomStyle {
    createStyles(type, originalStyle, options) {
        let styles;
        switch (type.toLowerCase()) {
            case "multipolygon":
                styles = this.getPolygonStyles(originalStyle, options);
                break;
            case "multilinestring":
                styles = this.getLineStringStyles(originalStyle, options);
                break;
            case "multipoint":
                styles = this.getPointStyles(originalStyle, options);
                break;
            default:
                throw new Error(`Unsupported type "${type}."`);
        }
        if (!Array.isArray(styles)) {
            styles = [styles];
        }
        if (originalStyle?.zIndex !== undefined && originalStyle?.zIndex !== null) {
            styles.forEach(s => (s.zIndex = originalStyle.zIndex));
        }
        return styles;
    }

    getPolygonStyle(originalStyle) {
        // Abstract
        throw new Error(`getPolygonStyle not implemented in "${this.constructor.name}".`);
    }

    getLineStringStyle(originalStyle) {
        // Abstract
        throw new Error(`getLineStringStyle not implemented in "${this.constructor.name}".`);
    }

    getPointStyle(originalStyle) {
        // Abstract
        throw new Error(`getPointStyle not implemented in "${this.constructor.name}".`);
    }
}

import React, { memo, useContext } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormContext } from "../../Form";
import ExpandingListItem from "../../../ExpandingListItem";
import EnumField from "../../EnumField";
import FileField from "../../FileField";
import StringField from "../../StringField";

import { IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1.2)
    }
}));

const levelOptions = [
    { value: "-2", title: "-2 (Basement)" },
    { value: "-1", title: "-1 (Basement)" },
    { value: "Street", title: "Street level" },
    { value: "2", title: "2nd floor" },
    { value: "3", title: "3rd floor" },
    { value: "4", title: "4th floor" },
    { value: "5", title: "5th floor" },
    { value: "6", title: "6th floor" },
    { value: "7", title: "7th floor" }
];
const statusOptions = ["In Progress", "Completed"];

function AkademiskaHusIndoorBuildingInfo({ name, index, disabled, onRemoveFromArray }) {
    const classes = useStyles();

    const { attributeDefinitions } = useContext(FormContext);
    const photo1Attribute = attributeDefinitions.find(a => a.name === "photo_1");
    if (!photo1Attribute || photo1Attribute.type !== "image" || !photo1Attribute.array) {
        throw new Error(
            `AkademiskaHusIndoorBuildingInfo: for this form component to function an attribute named "photo_1" of type image (array) must exist in the layer`
        );
    }
    const photo2Attribute = attributeDefinitions.find(a => a.name === "photo_2");
    if (!photo2Attribute || photo2Attribute.type !== "image" || !photo2Attribute.array) {
        throw new Error(
            `AkademiskaHusIndoorBuildingInfo: for this form component to function an attribute named "photo_2" of type image (array) must exist in the layer`
        );
    }
    const photo1s = useWatch({ name: `${photo1Attribute._id}` });
    const photo2s = useWatch({ name: `${photo2Attribute._id}` });

    const { setValue } = useFormContext();
    const handleRemoveFloor = () => {
        // Move all photos to match array shift
        if (Array.isArray(photo1s)) {
            const newPhoto1s = [...photo1s.slice(0, index), ...photo1s.slice(index + 1)];
            setValue(`${photo1Attribute._id}`, newPhoto1s);
        }
        if (Array.isArray(photo2s)) {
            const newPhoto2s = [...photo2s.slice(0, index), ...photo2s.slice(index + 1)];
            setValue(`${photo2Attribute._id}`, newPhoto2s);
        }
        onRemoveFromArray();
    };

    const level = useWatch({ name: `${name}.level` });

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={levelOptions.find(lo => lo.value === level)?.title ?? "Level"}
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={handleRemoveFloor} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <EnumField
                className={classes.input}
                name={`${name}.level`}
                schema={{ title: "Level", options: levelOptions }}
                disabled={disabled}
            />
            <StringField
                className={classes.input}
                name={`${name}.comments`}
                schema={{ title: "Comments" }}
                disabled={disabled}
            />
            <FileField
                className={classes.input}
                name={`${photo1Attribute._id}.${index}`}
                schema={{ title: "Photo 1", type: "image" }}
                disabled={disabled}
            />
            <FileField
                className={classes.input}
                name={`${photo2Attribute._id}.${index}`}
                schema={{ title: "Photo 2", type: "image" }}
                disabled={disabled}
            />
            <EnumField
                className={classes.input}
                name={`${name}.status`}
                schema={{ title: "Status", options: statusOptions, includeNull: true }}
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(AkademiskaHusIndoorBuildingInfo);

import React, { Component } from "react";

import withStyles from "@mui/styles/withStyles";

import classNames from "classnames";

const styles = theme => ({
    container: {
        width: "100%",
        maxWidth: theme.variables.maxWidth,
        margin: "auto",
        padding: theme.spacing(1),
        boxSizing: "border-box"
    }
});

class Container extends Component {
    render() {
        const { classes, children, className, ...other } = this.props;
        return (
            <div className={classNames(classes.container, className)} {...other}>
                {children}
            </div>
        );
    }
}

export default withStyles(styles)(Container);

import React, { memo, useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import ForestandOccasion from "./ForestandOccasion";
import OutlinedContainer from "../../../OutlinedContainer";

import { Button } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    occasion: {
        margin: 0
    },
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

function ForestandOccasions({ name, ...props }) {
    const classes = useStyles();

    const { fields, append, remove } = useFieldArray({ name });

    // initialFieldStatus will contain the status that the occasion initially had
    // to prevent it from moving between the two separate lists
    const [initialFieldStatus, setInitialFieldStatus] = useState(null);
    useEffect(() => {
        setInitialFieldStatus(status =>
            fields.reduce(
                (newStatus, field) => ({
                    ...newStatus,
                    [field.id]: status?.hasOwnProperty(field.id) ? status[field.id] : field.status
                }),
                {}
            )
        );
    }, [fields]);

    const [unlockedOccasion, setUnlockedOccasion] = useState(null);

    return (
        <div>
            <OutlinedContainer label="Tillfällen">
                {initialFieldStatus &&
                    fields.map((field, index) => {
                        if (initialFieldStatus[field.id] !== "done") {
                            return (
                                <ForestandOccasion
                                    key={field.id}
                                    className={classes.occasion}
                                    name={`${name}.${index}`}
                                    onRemoveFromArray={() => remove(index)}
                                    historical={false}
                                    {...props}
                                />
                            );
                        }
                        return null;
                    })}
                <Button
                    className={classes.addButton}
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => append(null, { shouldFocus: false })}
                >
                    Add
                </Button>
            </OutlinedContainer>
            <OutlinedContainer label="Historik">
                {initialFieldStatus &&
                    fields.map((field, index) => {
                        if (initialFieldStatus[field.id] === "done") {
                            return (
                                <ForestandOccasion
                                    key={field.id}
                                    className={classes.occasion}
                                    name={`${name}.${index}`}
                                    onRemoveFromArray={() => remove(index)}
                                    historical={true}
                                    {...props}
                                    disabled={field.id !== unlockedOccasion}
                                    onLock={() => setUnlockedOccasion(null)}
                                    onUnlock={() => setUnlockedOccasion(field.id)}
                                />
                            );
                        }
                        return null;
                    })}
            </OutlinedContainer>
        </div>
    );
}

export default memo(ForestandOccasions);

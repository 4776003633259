import React, { useState, useContext } from "react";
import { ErrorContext } from "../error-context";
import { WorkspaceContext } from "./workspace-context";
import SortableExpandableList from "../SortableExpandableList";
import AddLayer from "./AddLayer";
import LayerListItemDetails from "./LayerListItemDetails";
import LayerVisibleCheckbox from "./LayerVisibleCheckbox";
import LayerActiveCheckbox from "./LayerActiveCheckbox";

import { Typography, Dialog, Button, Stack, Box, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

function LayerList({ layers, onAddLayer, ...props }) {
    const { setError } = useContext(ErrorContext);
    const { configuration, updateConfiguration, addActiveLayer, removeActiveLayer } = useContext(WorkspaceContext);
    const workspace = { ...configuration };
    workspace.layers = workspace.layers.map(layer => layer._id);

    const [addLayerDialogOpen, setAddLayerDialogOpen] = useState(false);
    const addLayerButtonEnabled = configuration?.userRights?.includes("write");
    const sortedLayers = layers.slice().reverse();

    const handleSortEnd = items =>
        updateConfiguration(configuration => {
            const layers = items.map(item => item.layer).reverse();

            return { ...configuration, layers };
        });
    const handleAddLayer = async layer => {
        try {
            await onAddLayer(layer);
            setAddLayerDialogOpen(false);
        } catch (error) {
            setError(error);
        }
    };

    return (
        <Stack gap={1} height="100%" overflow="scroll" sx={{ backgroundColor: "white" }}>
            <Typography fontWeight="bold" variant="h5" pl={2} pt={2}>
                Layers
            </Typography>
            <SortableExpandableList
                items={sortedLayers.map(layer => ({
                    id: layer._id,
                    name: layer.name,
                    icon: (
                        <Stack direction="row" gap={1}>
                            <LayerVisibleCheckbox
                                layer={layer}
                                onChange={visible =>
                                    updateConfiguration(configuration => {
                                        const layerOverrides = { ...configuration.layerOverrides };
                                        layerOverrides[layer._id] = { ...layerOverrides[layer._id], visible };
                                        return { ...configuration, layerOverrides };
                                    })
                                }
                            />
                            <LayerActiveCheckbox
                                layer={layer}
                                onChange={active => (active ? addActiveLayer(layer._id) : removeActiveLayer(layer._id))}
                            />
                        </Stack>
                    ),
                    layer
                }))}
                onSortEnd={handleSortEnd}
                itemContent={item => {
                    return <LayerListItemDetails layer={item.layer} {...props} />;
                }}
            />

            <Box pl={1}>
                <Tooltip
                    placement="top"
                    title={addLayerButtonEnabled ? "" : "You don't have permission to add layers in this workspace"}
                >
                    <span>
                        <Button
                            disabled={!addLayerButtonEnabled}
                            variant="contained"
                            color="primary"
                            onClick={() => setAddLayerDialogOpen(true)}
                            startIcon={<Add />}
                        >
                            Add layer
                        </Button>
                    </span>
                </Tooltip>
            </Box>

            <Dialog fullWidth open={addLayerDialogOpen} onClose={() => setAddLayerDialogOpen(false)}>
                <AddLayer workspace={workspace} onAddLayer={handleAddLayer} />
            </Dialog>
        </Stack>
    );
}

export default LayerList;

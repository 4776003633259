import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers-pro";
import { TextField } from "@mui/material";
import { useState } from "react";

const FORMATS = {
    date: "YYYY-MM-DD",
    datetime: "YYYY-MM-DD HH:mm:ss"
};
const COMPONENTS = {
    date: MobileDatePicker,
    datetime: MobileDateTimePicker
};

function DateCellEditor({ attribute, value, onChange }) {
    // Default to open state
    const [open, setOpen] = useState(true);

    const format = FORMATS[attribute.type];
    const Component = COMPONENTS[attribute.type];
    if (!Component) {
        throw new Error(`DateCellEditor: Unknown type "${attribute.type}"`);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Component
                inputFormat={format}
                inputVariant="outlined"
                value={value ?? null}
                onChange={momentDate => {
                    if (momentDate === null) {
                        onChange(null);
                    } else if (momentDate.isValid()) {
                        onChange(momentDate.valueOf());
                    }
                }}
                renderInput={params => <TextField fullWidth placeholder={format} {...params} />}
                componentsProps={{
                    actionBar: {
                        actions: ["clear", "today", "cancel", "accept"]
                    }
                }}
                ampm={false}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            />
        </LocalizationProvider>
    );
}

export default DateCellEditor;

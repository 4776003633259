import React, { useState } from "react";
import { ErrorMessage } from "../Error";
import FUXFileUploadDialog from "foran-ux/dist/FUXFileUploadDialog";
import OutlinedContainer from "../../OutlinedContainer";

import { Button, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Delete as DeleteIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    }
}));

function FileFormInput(props) {
    const { schema, field, enabled, error, setError } = props;
    const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
    const classes = useStyles();

    const handleFileUpload = files => {
        setError(field.name, null);
        setFileUploadDialogOpen(false);
        if (files.length > 0) {
            const file = files[0];
            field.onChange({ target: { name: field.name, value: { file } } });
        }
    };

    let filename;
    if (field.value) {
        if (field.value.file) {
            filename = field.value.file.name;
        } else {
            filename = field.value.name;
        }
    }

    return (
        <React.Fragment>
            <OutlinedContainer className={classes.container} label={schema.title}>
                {filename ? (
                    <React.Fragment>
                        <Typography variant="overline">{filename}</Typography>
                        <IconButton
                            disabled={!enabled}
                            onClick={() => field.onChange({ target: { name: field.name, value: null } })}
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </React.Fragment>
                ) : (
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!enabled}
                        onClick={() => setFileUploadDialogOpen(true)}
                    >
                        Choose {schema.type}
                    </Button>
                )}
            </OutlinedContainer>
            <FUXFileUploadDialog
                title="Choose file to upload"
                open={fileUploadDialogOpen}
                onClose={() => setFileUploadDialogOpen(false)}
                onSubmit={handleFileUpload}
                accept={
                    schema.type === "image"
                        ? {
                              "image/jpeg": [".jpeg", ".jpg"],
                              "image/jpg": [".jpeg", ".jpg"],
                              "image/png": [".png"]
                          }
                        : undefined
                }
                maxFiles={1}
                maxSize={1000000000} // 1GB
            />
            <ErrorMessage error={error} />
        </React.Fragment>
    );
}

export default FileFormInput;

import React from "react";
import NumberInput from "../inputs/NumberInput";
import OutlinedContainer from "../OutlinedContainer";
import OverrideWrapper from "./OverrideWrapper";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        paddingRight: theme.spacing(1)
    }
}));

function Integer({ label, value, defaultValue, min, max, onChange }) {
    const classes = useStyles();
    const inherited = value === undefined;
    return (
        <OutlinedContainer label={label} className={inherited ? undefined : classes.container}>
            <OverrideWrapper
                inherited={inherited}
                onOverride={() => onChange(defaultValue)}
                onDelete={() => onChange(undefined)}
            >
                <NumberInput
                    integer={true}
                    value={value}
                    min={min}
                    max={max}
                    fullWidth
                    step={1}
                    onChange={onChange}
                    variant="standard"
                />
            </OverrideWrapper>
        </OutlinedContainer>
    );
}

export default React.memo(
    Integer,
    (prevProps, nextProps) =>
        prevProps.label === nextProps.label &&
        prevProps.value === nextProps.value &&
        prevProps.defaultValue === nextProps.defaultValue
);

import React from "react";
import classNames from "classnames";
import OutlinedContainer from "../OutlinedContainer";
import OverrideWrapper from "./OverrideWrapper";

import { Switch as MUISwitch } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    padFix: {
        paddingRight: theme.spacing(1)
    }
}));

function Switch({ label, helperText, value, defaultValue = false, onChange }) {
    const classes = useStyles();
    const inherited = value === undefined;
    return (
        <OutlinedContainer
            className={classNames(classes.container, { [classes.padFix]: !inherited })}
            label={label}
            helperText={helperText}
        >
            <OverrideWrapper
                inherited={inherited}
                onOverride={() => onChange(defaultValue)}
                onDelete={() => onChange(undefined)}
            >
                <MUISwitch color="primary" checked={value} onChange={event => onChange(event.target.checked)} />
            </OverrideWrapper>
        </OutlinedContainer>
    );
}

export default React.memo(
    Switch,
    (prevProps, nextProps) =>
        prevProps.label === nextProps.label &&
        prevProps.helperText === nextProps.helperText &&
        prevProps.value === nextProps.value &&
        prevProps.defaultValue === nextProps.defaultValue
);

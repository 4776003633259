import { useCallback, useContext, useEffect, useState } from "react";
import { AtlasContext } from "../atlas-context";
import { saveFeature } from "../workspace/map/tools/utility/utility";
import { WorkspaceContext } from "../workspace/workspace-context";

export const useProcessRowUpdate = layerId => {
    const { dbManager } = useContext(AtlasContext);
    const { configuration } = useContext(WorkspaceContext);
    const [db, setDb] = useState(null);

    useEffect(() => {
        let cancelled = false;
        const fetchDb = async () => {
            const db = await dbManager.getVectorDB(layerId, configuration._id);
            if (!cancelled) {
                setDb(db);
            }
        };
        fetchDb();
        return () => {
            cancelled = true;
        };
    }, [layerId, dbManager, configuration]);

    return useCallback(
        async newRow => {
            const { __atlas_original_id, ...attributes } = newRow;
            const features = await db.query({
                "__atlas.originalId": { $eq: __atlas_original_id }
            });
            const feature = features.find(feature => !feature.properties.__atlas.child);
            for (const id in attributes) {
                feature.properties[id] = attributes[id];
            }
            await saveFeature(feature, layerId, configuration, dbManager);
            return newRow;
        },
        [db, layerId, configuration, dbManager]
    );
};

import React, { forwardRef, useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { AtlasFormContext } from "../atlas-form/useAtlasFormContext";

import { TextField, IconButton } from "@mui/material";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const isNumber = val => {
    return typeof val === "number" && !Number.isNaN(val);
};

const isValidNumber = (number, integer, min, max) => {
    let valid = isNumber(number) && (!integer || Number.isInteger(number));
    if (valid && isNumber(min)) {
        valid = number >= min;
    }
    if (valid && isNumber(max)) {
        valid = number <= max;
    }
    return valid;
};

const isNumberInProgress = (val, integer) => {
    const regex = integer ? /^-?[0-9]*$/ : /^-?[0-9]*[,.]?[0-9]*$/;
    return typeof val === "string" && regex.test(val);
};

const isEmpty = string => {
    return [null, undefined, "", " "].includes(string);
};

const toNumber = string => {
    return Number(string.replace(/,/g, "."));
};

const useStyles = makeStyles({
    container: {
        position: "relative",

        "&.fullWidth": {
            width: "100%"
        }
    },
    textField: {
        width: "100%"
    },
    indent: {
        paddingLeft: "40px"
    },
    noIndent: {
        paddingLeft: "0",
        width: "unset"
    },
    button: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        touchAction: "manipulation",

        "&.outlined": {
            top: "28px"
        },

        "&.left": {
            left: 0
        },
        "&.right": {
            right: 0
        }
    }
});

function NumberInput(
    {
        value,
        className,
        name,
        label,
        fullWidth,
        disabled,
        variant,
        placeholder,
        inputRef,
        helperText,
        integer,
        min,
        max,
        step,
        onChange,
        onError,
        onBlur,
        error
    },
    ref
) {
    const { errors, setError, clearError } = useContext(AtlasFormContext);

    const classes = useStyles();
    const _id = useState(Math.random());
    const [innerState, setInnerState] = useState("");
    const [touched, setTouched] = useState(false);
    const enableStep = integer && isNumber(step);

    let isValid = true;
    if (![null, undefined, ""].includes(value) || ![null, undefined, ""].includes(innerState)) {
        const numValue = Number(value);
        const currentNumValue = Number(innerState);
        isValid = numValue === currentNumValue;
    }

    const _setError = useCallback(
        message => {
            if (typeof onError === "function") {
                onError(message);
            } else {
                setError?.(_id, name ?? label, message);
            }
        },
        [onError, setError, _id, name, label]
    );
    const _clearError = useCallback(() => {
        if (typeof onError === "function") {
            onError(null);
        } else {
            clearError?.(_id);
        }
    }, [onError, clearError, _id]);

    useEffect(() => {
        setInnerState(innerState => {
            if ([null, undefined, ""].includes(value)) {
                // Make sure to not set a null/undefined value as this will
                // make the component switch between controlled/uncontrolled
                return "";
            }
            const valueAsNumber = Number(value);
            const currentValueAsNumber = Number(innerState);
            if (isNumber(valueAsNumber)) {
                if (integer && !Number.isInteger(valueAsNumber)) {
                    console.warn(`NumberInput: Expected integer, received ${value}`);
                }
                if ((isNumber(min) && valueAsNumber < min) || (isNumber(max) && valueAsNumber > max)) {
                    console.warn(`NumberInput: Value ${value} is outside of the defined range [${min}, ${max}]`);
                }
                // Only update the state if numeric value has changed,
                // otherwise cursor will jump to the end
                if (valueAsNumber !== currentValueAsNumber || [null, undefined, ""].includes(innerState)) {
                    return `${valueAsNumber}`;
                } else {
                    return innerState;
                }
            } else {
                console.warn(`NumberInput: Received invalid value ${value}. Expected number or null/undefined.`);
                return "";
            }
        });
    }, [value, min, max, integer]);

    const handleChange = useCallback(
        event => {
            // Only allow input of valid symbols
            if (isNumberInProgress(event.target.value, integer)) {
                // Always update state with event.target.value directly in this callback,
                // otherwise the cursor will jump to the end
                setInnerState(event.target.value);
                _clearError();
                if (isEmpty(event.target.value)) {
                    // empty
                    onChange(null);
                } else {
                    const number = toNumber(event.target.value);
                    if (isValidNumber(number, integer, min, max)) {
                        // valid
                        onChange(number);
                    } else {
                        // Number isn't valid, but is either:
                        if (isNumber(min) && number < min) {
                            // - lower than min
                            _setError(`Value is smaller than the minimum allowed (${min})`);
                        } else if (isNumber(max) && number > max) {
                            // - higher than max
                            _setError(`Value is larger than the maximum allowed (${max})`);
                        } else {
                            // - in progress, e.g. "-" or "."
                            _setError("Value isn't a valid number");
                        }
                    }
                }
            }
        },
        [onChange, min, max, integer, _setError, _clearError]
    );

    const handleStep = useCallback(
        increment => {
            let current = Number(value);
            if ([null, undefined, ""].includes(value)) {
                current = 0;
            }
            if (isNumber(current)) {
                current += increment ? step : -step;
                // Don't increment past min/max values
                if (isValidNumber(current, integer, min, max)) {
                    onChange(current);
                    setTouched(true);
                }
            }
        },
        [value, onChange, step, min, max, integer]
    );

    const handleBlur = useCallback(
        event => {
            setTouched(true);
            onBlur?.(event);
        },
        [onBlur]
    );

    return (
        <div className={classNames(classes.container, className, { fullWidth })}>
            <TextField
                className={classNames(classes.textField, "nospinner")}
                disabled={disabled}
                // Only show error if field is touched
                error={!!error || (touched && !isValid)}
                fullWidth={fullWidth}
                helperText={(touched && errors?.[_id]?.message) ?? helperText}
                InputLabelProps={{
                    classes: {
                        root: classNames({ [classes.indent]: enableStep }),
                        shrink: classes.noIndent
                    }
                }}
                InputProps={{
                    className: classNames({ [classes.indent]: enableStep })
                }}
                // No, ESLint, this is not a duplicate, InputProps !== inputProps.
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ inputMode: integer ? "numeric" : "decimal" }}
                inputRef={inputRef}
                label={label}
                name={name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                value={innerState}
                variant={variant}
                ref={ref}
            />
            {enableStep && (
                <React.Fragment>
                    <IconButton
                        className={classNames(classes.button, "left", variant)}
                        color="primary"
                        disabled={disabled}
                        onClick={() => handleStep(false)}
                        size="large"
                    >
                        <RemoveIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        className={classNames(classes.button, "right", variant)}
                        color="primary"
                        disabled={disabled}
                        onClick={() => handleStep(true)}
                        size="large"
                    >
                        <AddIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            )}
        </div>
    );
}

export default forwardRef(NumberInput);

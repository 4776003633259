import React, { Component } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import "../../scss/react-reflex.css";

import { AtlasContext } from "../atlas-context";
import { WorkspaceContext } from "./workspace-context";
import { ErrorContext } from "../error-context";

import Api from "../../Api";
import AtlasLoader from "../AtlasLoader";
import Drawer from "./Drawer";
import ErrorView from "../ErrorView";
import GeolocationWrapper from "./map/GeolocationWrapper";
import LayerList from "./LayerList";
import MapController from "./map/MapController";
import Revolver from "./revolver/Revolver";
import StatusBar from "./StatusBar";
import { deleteOLFeature, saveOLFeature } from "./map/tools/utility/utility";
import { isVectorLayer, checkLayerPermission } from "../layer-tools";

import { Button, IconButton, Snackbar, Typography, Paper, Container } from "@mui/material";
import { Close, DragHandle } from "@mui/icons-material";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
    container: {
        height: "100%",
        overflow: "hidden"
    },
    splitter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "0 !important",
        border: "none !important",
        opacity: "1 !important",
        margin: "0 !important"
    },
    splitterIcon: {
        padding: theme.spacing(1),
        color: "white",
        backgroundColor: theme.colors.primary,
        borderRadius: "50%"
    },
    "@keyframes fadein": {
        from: { opacity: 0 },
        to: { opacity: 1 }
    },
    resizeOverlay: {
        opacity: 0,
        animationName: "$fadein",
        animationDuration: "0.1s",
        animationFillMode: "forwards",
        backgroundColor: "#333",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10
    },
    resizeOverlayText: {
        color: "white",
        fontWeight: "bold"
    },
    errorContainer: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    errorView: {
        padding: theme.spacing(1)
    }
});

class Workspace extends Component {
    constructor(...props) {
        super(...props);
        this._handleMapMove = this._handleMapMove.bind(this);
        this._handleRevolverRequest = this._handleRevolverRequest.bind(this);
        this._handleRevolverClosed = this._handleRevolverClosed.bind(this);
        this._handleStatusBarRequest = this._handleStatusBarRequest.bind(this);
        this._handleAddLayer = this._handleAddLayer.bind(this);
        this._handleEditArea = this._handleEditArea.bind(this);
        this._updateConfiguration = this._updateConfiguration.bind(this);
        this._handleSaveStyles = this._handleSaveStyles.bind(this);
        this._handleResetStyles = this._handleResetStyles.bind(this);
        this._handleDeleteFeature = this._handleDeleteFeature.bind(this);
        this._undoDeleteFeature = this._undoDeleteFeature.bind(this);
        this._handleUploadFailure = this._handleUploadFailure.bind(this);
        this._handleUndoSnackbarClose = this._handleUndoSnackbarClose.bind(this);
        this._handleZoomToLayer = this._handleZoomToLayer.bind(this);
        this._addActiveLayer = this._addActiveLayer.bind(this);
        this._removeActiveLayer = this._removeActiveLayer.bind(this);
        this._handleSyncChannelConnect = this._handleSyncChannelConnect.bind(this);
        this._handleConfigurationChange = this._handleConfigurationChange.bind(this);
        this._handleLayerChange = this._handleLayerChange.bind(this);
        this._handleWindowResize = this._handleWindowResize.bind(this);
        this.getMap = this.getMap.bind(this);

        this.state = {
            configuration: null,
            fetchError: null,
            revolver: {
                coordinates: null,
                configuration: {},
                closeCallback: null
            },
            statusBar: {
                open: false,
                text: null
            },
            editArea: this.props.editArea,
            undoFeature: null,
            drawerSize: localStorage.getItem("drawerSize") || 50 / window.innerHeight,
            resizingDrawer: false,
            activeLayerIds: null,
            windowHeight: window.innerHeight
        };
        this.revolver = null;
    }

    _getInitialActiveLayer(configuration) {
        const layerOverrides = configuration.layerOverrides || {};
        const isVisible = layer => {
            const layerOverride = layerOverrides[layer._id];
            if (layerOverride && typeof layerOverride.visible === "boolean") {
                return layerOverride.visible;
            }
            return true;
        };
        const createIsAllowed = layer => checkLayerPermission("createfeatures", layer, configuration);
        const firstValidLayerId = configuration.layers
            .filter(isVectorLayer)
            .filter(isVisible)
            .filter(createIsAllowed)
            .map(layer => layer._id)
            .reverse()
            .shift();

        return firstValidLayerId || null;
    }

    async _handleSyncChannelConnect() {
        if (this.state.configuration) {
            await this.context.dbManager.initializeWorkspace(this.state.configuration);
        }
    }

    async _handleConfigurationChange(workspace) {
        if (workspace._id === this.state.configuration?._id) {
            const configuration = await this.context.dbManager.getWorkspaceConfiguration(this.state.configuration._id);
            let activeLayerIds = this.state.activeLayerIds;
            if (activeLayerIds) {
                activeLayerIds = activeLayerIds.filter(id => configuration.layers.some(layer => layer._id === id));
            }
            document.title = `${configuration.name} - Atlas`;
            this.setState({ configuration, activeLayerIds });
        }
    }

    async _handleLayerChange(layer) {
        if (this.state.configuration?.layers.some(l => l._id === layer._id)) {
            const configuration = await this.context.dbManager.getWorkspaceConfiguration(this.state.configuration._id);
            this.setState({ configuration });
        }
    }

    _handleWindowResize() {
        this.setState({ windowHeight: window.innerHeight });
    }

    componentDidMount() {
        this._fetchConfiguration();
        this.context.dbManager.on("uploadFailure", this._handleUploadFailure);
        this.context.dbManager.on("workspaceChange", this._handleConfigurationChange);
        this.context.dbManager.on("layerChange", this._handleLayerChange);
        this.context.syncChannel.on("connect", this._handleSyncChannelConnect);
        window.addEventListener("resize", this._handleWindowResize);
    }

    componentWillUnmount() {
        const { configuration } = this.state;
        if (configuration) {
            if (configuration.__localData && configuration.__localData.offline) {
                console.log(`Leaving workspace ${configuration._id}. Keeping subscription since it's marked offline.`);
            } else {
                console.log(`Leaving workspace ${configuration._id}. Unsubscribing.`);
                this.context.dbManager.unsubscribeFromWorkspace(this.state.configuration._id);
            }
        }
        this.context.dbManager.off("uploadFailure", this._handleUploadFailure);
        this.context.dbManager.off("workspaceChange", this._handleConfigurationChange);
        this.context.dbManager.off("layerChange", this._handleLayerChange);
        this.context.syncChannel.off("connect", this._handleSyncChannelConnect);
        window.removeEventListener("resize", this._handleWindowResize);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workspaceId !== this.props.workspaceId) {
            this._fetchConfiguration();
        }
    }

    _handleUploadFailure(feature) {
        if (this.state.undoFeature && this.state.undoFeature.getId() === feature.id) {
            this.setState({ undoFeature: null });
        }
    }

    _addActiveLayer(layerId, callback) {
        const { configuration } = this.state;
        const activeLayerIds = [...this.state.activeLayerIds];
        if (!activeLayerIds.includes(layerId)) {
            activeLayerIds.push(layerId);
            window.localStorage.setItem(
                `${configuration._id}-activeLayers`,
                activeLayerIds ? activeLayerIds.join("-") : "null"
            );
        }
        this.setState({ activeLayerIds }, callback);
    }

    _removeActiveLayer(layerId, callback) {
        const { configuration } = this.state;
        const activeLayerIds = [...this.state.activeLayerIds].filter(id => id !== layerId);
        window.localStorage.setItem(
            `${configuration._id}-activeLayers`,
            activeLayerIds ? activeLayerIds.join("-") : "null"
        );
        this.setState({ activeLayerIds }, callback);
    }

    _addLayerPriority(configuration) {
        if (configuration.layers.length === 0 || !configuration.layers) return;
        let i = 0;
        for (const layer of configuration.layers) {
            layer.hitPriority = i;
            i++;
        }
    }

    async _fetchConfiguration() {
        try {
            this.setState({ fetchError: null });
            const configuration = await this.context.dbManager.getWorkspaceConfiguration(this.props.workspaceId);
            this._addLayerPriority(configuration);
            await this.context.dbManager.initializeWorkspace(configuration);
            let activeLayerIds = window.localStorage.getItem(`${configuration._id}-activeLayers`);
            // Yes, that's the string null, since localStorage stores the value null as a string &
            // returns the value null if the item doesn't exist.
            if (activeLayerIds === "null") {
                activeLayerIds = null;
            } else if (activeLayerIds) {
                const ids = activeLayerIds.split("-");
                activeLayerIds = ids.filter(id => configuration.layers.map(layer => layer._id).includes(id));
            } else {
                const initialActiveLayer = this._getInitialActiveLayer(configuration);
                if (initialActiveLayer) {
                    activeLayerIds = [initialActiveLayer];
                } else {
                    activeLayerIds = [];
                }
            }
            document.title = `${configuration.name} - Atlas`;
            this.setState({ configuration, activeLayerIds });
        } catch (error) {
            console.logError(error, "Error fetching workspace configuration");
            this.setState({ fetchError: error });
        }
    }

    _handleMapMove() {
        const revolver = { ...this.state.revolver };
        if (revolver.coordinates) {
            this.setState({ revolver });
        }
    }

    _handleRevolverRequest(coordinates, configuration, closeCallback) {
        const revolver = { ...this.state.revolver };
        revolver.coordinates = coordinates;
        if (configuration !== undefined) {
            revolver.configuration = configuration;
        }
        if (closeCallback !== undefined) {
            revolver.closeCallback = closeCallback;
        }

        this.setState({ revolver }, () => {
            if (this.state.revolver.coordinates) {
                // Make sure revolver is visible
                this.map.panToRect(this.revolver.getRect());
            } else {
                this.state.revolver.closeCallback && this.state.revolver.closeCallback();
            }
        });
    }

    _handleRevolverClosed(closeButtonId) {
        const { closeCallback } = this.state.revolver;
        closeCallback && closeCallback(closeButtonId);
        this.setState({ revolver: { coordinates: null } });
    }

    _handleStatusBarRequest(content, callback) {
        const state = { statusBar: { open: !!content } };
        if (typeof content === "string") {
            state.statusBar.text = content;
        } else if (content) {
            state.statusBar.content = content;
        }
        this.setState(state, callback);
    }

    async _handleAddLayer(layer) {
        const configuration = { ...this.state.configuration };
        const layers = configuration.layers.map(layer => layer._id);
        if (layers.includes(layer._id)) {
            this.setState(() => {
                throw new Error(`Layer "${layer._id}" already exists in workspace "${configuration._id}".`);
            });
            return;
        }
        layers.push(layer._id);
        await Api.updateWorkspace(configuration._id, { layers });
        const workspace = { ...configuration };
        workspace.layers = layers;
        await this.context.dbManager.updateWorkspace(workspace);
        await this.context.dbManager.updateLayer(layer);
        configuration.layers = [...configuration.layers, layer];
        await this.context.dbManager.initializeWorkspace(configuration);
        this.setState({ configuration });
    }

    async _handleEditArea(area) {
        await Api.updateWorkspace(this.state.configuration._id, { area });
        const configuration = { ...this.state.configuration };
        configuration.area = area;
        const workspace = { ...configuration };
        workspace.layers = workspace.layers.map(layer => layer._id);
        await this.context.dbManager.updateWorkspace(workspace);
        this.setState({ configuration });
    }

    _updateConfiguration(updater = () => {}) {
        const currentConfiguration = { ...this.state.configuration };
        const newConfiguration = updater(currentConfiguration);
        this.setState({ configuration: newConfiguration });
        // TODO: Save to IndexedDB / Send to server
    }

    async _handleResetStyles(type, layerId) {
        const configuration = { ...this.state.configuration };
        const layer = configuration.layers.find(layer => layer._id === layerId);
        switch (type) {
            case "local":
                const savedLayer = await this.context.dbManager.getLayer(layerId);
                if (savedLayer.__localData.styles) {
                    layer.__localData.styles = savedLayer.__localData.styles;
                } else {
                    delete layer.__localData.styles;
                }
                break;
            case "layer":
                delete layer.__localData.styles;
                break;
            case "workspace":
                console.warn("Resetting styles on workspace is not yet implemented.");
                break;
            default:
                console.error(`Workspace._handleResetStyles: Unknown type "${type}".`);
        }
        await this.context.dbManager.updateLayer(layer);
        this.setState({ configuration });
    }

    async _handleSaveStyles(type, layerId) {
        const layer = this.state.configuration.layers.find(layer => layer._id === layerId);
        switch (type) {
            case "local":
                await this.context.dbManager.updateLayer(layer);
                break;
            case "layer":
                layer.styles = layer.__localData.styles || [];
                delete layer.__localData.styles;
                await Api.updateLayer(layerId, { styles: layer.styles });
                await this.context.dbManager.updateLayer(layer);
                break;
            case "workspace":
                console.warn("Saving styles on workspace is not yet implemented.");
                break;
            default:
                console.error(`Workspace._handleSaveStyles: Unknown type "${type}".`);
        }
    }

    async _handleDeleteFeature(olFeature) {
        await deleteOLFeature(olFeature, this.state.configuration, this.context.dbManager);
        // Show undo snackbar
        this.setState({ undoFeature: olFeature });
    }

    async _undoDeleteFeature() {
        const id = this.state.undoFeature?.getProperties()?.__atlas?.originalId;
        console.log(`Undo deleted feature ${id} (version ${this.state.undoFeature.getId()}).`);
        await saveOLFeature(this.state.undoFeature, this.state.configuration, this.context.dbManager);
        console.log(`Undo deleted feature ${id} (version ${this.state.undoFeature.getId()}) completed.`);
        this.setState({ undoFeature: null });
    }

    _handleUndoSnackbarClose(_, reason) {
        if (reason !== "clickaway") {
            this.setState({ undoFeature: null });
        }
    }

    _handleZoomToLayer(layer) {
        this.map.zoomToLayer(layer);
    }

    getMap() {
        return this.map && this.map.olMap;
    }

    render() {
        const { dbManager } = this.context;
        const { revolver, configuration, statusBar, editArea, activeLayerIds, fetchError } = this.state;
        const { classes } = this.props;

        if (fetchError) {
            return (
                <Container className={classes.errorContainer}>
                    <Paper>
                        <ErrorView
                            className={classes.errorView}
                            title="Could not open workspace"
                            message={fetchError.message}
                            onTryAgain={() => this._fetchConfiguration()}
                        />
                    </Paper>
                </Container>
            );
        }

        if (!configuration) {
            return <AtlasLoader />;
        }
        let revolverPosition = null;
        if (this.map && revolver.coordinates) {
            revolverPosition = this.map.getPixelFromCoordinate(revolver.coordinates);
        }
        // react-reflex doesn't like when numbers don't add upp
        // it logs an error sometimes when using fractions
        const drawerSize = Math.max(0, Math.round(this.state.drawerSize * this.state.windowHeight));
        return (
            <WorkspaceContext.Provider
                value={{
                    configuration,
                    updateConfiguration: this._updateConfiguration,
                    saveStyles: this._handleSaveStyles,
                    resetStyles: this._handleResetStyles,
                    getMap: this.getMap,
                    deleteFeature: this._handleDeleteFeature,
                    activeLayerIds,
                    addActiveLayer: this._addActiveLayer,
                    removeActiveLayer: this._removeActiveLayer
                }}
            >
                <GeolocationWrapper>
                    <div className={classes.container}>
                        <ReflexContainer orientation="horizontal" windowResizeAware={true}>
                            <ReflexElement size={this.state.windowHeight - drawerSize}>
                                {this.state.resizingDrawer && (
                                    <div className={classes.resizeOverlay}>
                                        <Typography className={classes.resizeOverlayText} variant="overline">
                                            Resizing...
                                        </Typography>
                                    </div>
                                )}
                                <MapController
                                    identifier={configuration._id}
                                    workspace={configuration}
                                    layers={configuration.layers}
                                    dbManager={dbManager}
                                    ref={el => (this.map = el)}
                                    onMove={this._handleMapMove}
                                    revolverOpen={!!revolver.coordinates}
                                    onRevolverRequest={this._handleRevolverRequest}
                                    onStatusBarRequest={this._handleStatusBarRequest}
                                    onAddLayer={this._handleAddLayer}
                                    editArea={editArea}
                                    onEditArea={this._handleEditArea}
                                    onDeleteFeature={this._handleDeleteFeature}
                                />
                            </ReflexElement>
                            <ReflexSplitter className={classes.splitter}>
                                <DragHandle className={classes.splitterIcon} />
                            </ReflexSplitter>
                            <ReflexElement
                                size={drawerSize}
                                minSize={0}
                                direction={-1}
                                onStartResize={() => this.setState({ resizingDrawer: true })}
                                onStopResize={({ domElement }) => {
                                    const drawerSize = domElement.clientHeight / this.state.windowHeight;
                                    this.setState({ resizingDrawer: false, drawerSize }, () => {
                                        localStorage.setItem("drawerSize", this.state.drawerSize);
                                        this.map && this.map.updateSize();
                                    });
                                }}
                            >
                                <Drawer>
                                    <LayerList
                                        layers={configuration.layers}
                                        onAddLayer={this._handleAddLayer}
                                        onZoomToLayer={this._handleZoomToLayer}
                                    />
                                </Drawer>
                            </ReflexElement>
                        </ReflexContainer>
                        {revolverPosition && (
                            <Revolver
                                position={revolverPosition}
                                onClose={this._handleRevolverClosed}
                                ref={el => (this.revolver = el)}
                                {...revolver.configuration}
                            />
                        )}
                        {statusBar.open && <StatusBar text={statusBar.text}>{statusBar.content}</StatusBar>}
                        <Snackbar
                            open={!!this.state.undoFeature}
                            autoHideDuration={7000}
                            message={<Typography>Deleted feature</Typography>}
                            action={[
                                <Button key="undo" color="primary" onClick={this._undoDeleteFeature}>
                                    Undo
                                </Button>,
                                <IconButton
                                    key="close"
                                    onClick={() => this.setState({ undoFeature: null })}
                                    size="large"
                                >
                                    <Close />
                                </IconButton>
                            ]}
                            onClose={this._handleUndoSnackbarClose}
                        />
                    </div>
                </GeolocationWrapper>
            </WorkspaceContext.Provider>
        );
    }
}

Workspace.contextType = AtlasContext;

const WorkspaceWrapper = props => (
    <ErrorContext.Consumer>
        {errorContext => <Workspace {...props} errorContext={errorContext} />}
    </ErrorContext.Consumer>
);

export default withStyles(styles)(WorkspaceWrapper);

import React, { Component } from "react";
import Normal from "./Normal";
import Select from "./Select";
import Edit from "./Edit";
import Measure from "./Measure";
import EditWorkspaceArea from "./EditWorkspaceArea";
import FormTool from "./FormTool";

class Tool extends Component {
    render() {
        const { toolType, tool, toolRef, ...other } = this.props;
        switch (toolType) {
            case "normal":
                return <Normal tool={tool} ref={toolRef} {...other} />;
            case "select":
                return <Select tool={tool} ref={toolRef} {...other} />;
            case "edit":
                return <Edit tool={tool} ref={toolRef} {...other} />;
            case "measure":
                return <Measure tool={tool} ref={toolRef} {...other} />;
            case "editWorkspaceArea":
                return <EditWorkspaceArea tool={tool} ref={toolRef} {...other} />;
            case "form":
                return <FormTool tool={tool} ref={toolRef} {...other} />;
            default:
                throw new Error(`Unknown tool type "${toolType}".`);
        }
    }
}

export default Tool;

import { getCurrentLocation } from "../workspace/map/GeolocationWrapper";
import { getErrorMessages, hasError, hasWarning } from "./form-utils";
import { memo, useContext, useEffect, useState } from "react";
import { transform } from "ol/proj";
import { useFieldController } from "./useFieldController";

import { ErrorContext } from "../error-context";
import { FormContext } from "./Form";

import { Button, Stack, Typography } from "@mui/material";
import { FUXOutlinedContainer } from "foran-ux";
import { LoadingButton } from "@mui/lab";

function LocationField({ schema, disabled, name, rules, defaultValue }) {
    const { setError } = useContext(ErrorContext);
    const { layer } = useContext(FormContext);
    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules, defaultValue });

    const _hasError = hasError(error);
    const _hasWarning = hasWarning(error);

    const [loading, setLoading] = useState(false);
    const handleSetToCurrentLocation = async () => {
        setLoading(true);
        try {
            const location = await getCurrentLocation();
            field.onChange({
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                accuracy: location.coords.accuracy
            });
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    const handleClear = () => {
        field?.onChange(null);
    };

    const [x, setX] = useState(null);
    const [y, setY] = useState(null);
    useEffect(() => {
        if (!field.value?.longitude || !field.value?.latitude || !layer?.projection) {
            setX(null);
            setY(null);
            return;
        }
        const [x, y] = transform([field.value.longitude, field.value.latitude], "EPSG:4326", layer.projection);
        setX(x);
        setY(y);
    }, [field.value, layer?.projection]);

    return (
        <FUXOutlinedContainer
            label={schema.title}
            error={_hasError}
            warning={_hasWarning}
            helperText={getErrorMessages(error)?.join(", ")}
        >
            <Stack gap={1}>
                {field?.value && (
                    <Stack display="grid" gridTemplateColumns="auto 1fr" gap={1}>
                        <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>Coordinates:</Typography>
                        <Typography>
                            {field.value.latitude}, {field.value.longitude}
                        </Typography>
                        {x && y && (
                            <>
                                <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>X, Y:</Typography>
                                <Typography>
                                    {x}, {y}
                                </Typography>
                            </>
                        )}
                        <Typography sx={{ textAlign: "right", fontWeight: "bold" }}>Accuracy:</Typography>
                        <Typography>{Math.round(field.value.accuracy)}m</Typography>
                    </Stack>
                )}
                <Stack direction="row" gap={1}>
                    <LoadingButton
                        variant="outlined"
                        loading={loading}
                        disabled={disabled}
                        onClick={handleSetToCurrentLocation}
                    >
                        Set to current location
                    </LoadingButton>
                    {field?.value && !loading && (
                        <Button variant="outlined" disabled={disabled} onClick={handleClear}>
                            Clear
                        </Button>
                    )}
                </Stack>
            </Stack>
        </FUXOutlinedContainer>
    );
}

export default memo(LocationField);

import React, { Component } from "react";
import MousePosition from "./openlayers/MousePosition";

import { createStringXY } from "ol/coordinate";

import withStyles from "@mui/styles/withStyles";

const styles = {
    mousePosition: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 100,
        color: "black",
        backgroundColor: "#ffffff",
        margin: 0,
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none"
    }
};

class AtlasMousePosition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            target: null
        };
        this.mousePositionElement = null;
    }

    componentDidMount() {
        this.setState({ target: this.mousePositionElement });
    }

    render() {
        const { classes } = this.props;
        const { target } = this.state;
        return (
            <React.Fragment>
                <p className={classes.mousePosition} ref={el => (this.mousePositionElement = el)} />
                <MousePosition coordinateFormat={createStringXY(4)} target={target} undefinedHTML="&nbsp;" />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(AtlasMousePosition);

import React from "react";
import Fill from "./Fill";

import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";

function Fills({ fills = [], onChange }) {
    if (fills.length > 1) {
        // TODO: Enable multiple fills
        console.warn("Multiple fills not implemented yet");
    }

    return (
        <React.Fragment>
            {fills.map((fill, index) => (
                <Fill
                    fill={fill}
                    key={index}
                    index={index}
                    onChange={(value, index) =>
                        onChange(currentFills => {
                            const newFills = [...currentFills];
                            newFills[index] = typeof value === "function" ? value(currentFills[index]) : value;
                            return newFills;
                        })
                    }
                    enableDelete={false}
                    onDelete={index =>
                        onChange(currentFills => {
                            const newFills = [...currentFills];
                            newFills.splice(index, 1);
                            return newFills;
                        })
                    }
                />
            ))}

            {false && (
                <Button
                    color="primary"
                    onClick={() => {
                        const newFills = [...fills, { type: "none", color: "#000000", opacity: 1 }];
                        onChange(newFills);
                    }}
                >
                    <Add />
                    Add fill
                </Button>
            )}
        </React.Fragment>
    );
}

export default Fills;

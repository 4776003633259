import React, { useState, useContext, useEffect } from "react";
import { ErrorContext } from "../error-context";
import Api from "../../Api";
import DirectoryContents from "../DirectoryContents";

import { CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(_ => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px"
    }
}));

function AddLayer({ workspace, onAddLayer }) {
    const { setError } = useContext(ErrorContext);
    const classes = useStyles();

    const [directory, setDirectory] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleOpenContent = async content => {
        setLoading(true);
        switch (content.type) {
            case "directory":
                setDirectory(content);
                break;
            case "layer":
                await onAddLayer(content);
                break;
            default:
                break;
        }
        setLoading(false);
    };

    useEffect(() => {
        const fetchRoot = async () => {
            setLoading(true);
            setDirectory(null);
            try {
                let id;
                if (workspace && workspace._id) {
                    id = await Api.getParentId(workspace._id);
                } else {
                    id = await Api.getRoot();
                }
                const directory = await Api.getDirectory(id);
                setDirectory(directory);
            } catch (error) {
                setError(error);
                return;
            } finally {
                setLoading(false);
            }
        };
        fetchRoot();
    }, [workspace, setError]);

    return (
        <React.Fragment>
            {loading ? (
                <div className={classes.container}>
                    <CircularProgress />
                </div>
            ) : (
                <DirectoryContents
                    directory={directory}
                    onOpenContent={handleOpenContent}
                    types={["layer", "directory"]}
                    tabIndex={-1}
                    disableFile={file => (workspace ? workspace.layers.includes(file._id) : false)}
                />
            )}
        </React.Fragment>
    );
}

export default AddLayer;

import React, { memo } from "react";
import ExpandingListItem from "../../../ExpandingListItem";
import EnumField from "../../EnumField";
import NumberField from "../../NumberField";

import { IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

const damageOptions = [
    { value: "GSUS", title: "Gammal viltskada utan exponerad splintved" },
    { value: "GSMS", title: "Gammal skada med exponerad splintved" },
    { value: "FSUS", title: "Färsk skada utan exponerad splintved" },
    { value: "FSMS", title: "Färsk skada med exponerad splintved" }
];
const shapeOptions = [
    { value: "Triangel", title: "Triangel" },
    { value: "Rektangel", title: "Rektangel" },
    { value: "Romb", title: "Romb" }
];

function BarkbinDamage({ name, index, disabled, onRemoveFromArray }) {
    const classes = useStyles();

    const damage = useWatch({ name: `${name}.type` });

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={`Skada ${index + 1}`}
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
        >
            <EnumField
                className={classes.input}
                name={`${name}.type`}
                schema={{ title: "Skada", options: damageOptions }}
                disabled={disabled}
            />
            {damage === "FSMS" && (
                <>
                    <NumberField
                        className={classes.input}
                        name={`${name}.ÖH`}
                        schema={{ title: "Övre höjd av skada [cm]" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.NH`}
                        schema={{ title: "Nedre höjd av skada [cm]" }}
                        disabled={disabled}
                    />
                    <NumberField
                        className={classes.input}
                        name={`${name}.Bredd`}
                        schema={{ title: "Skadans bredd på bredaste stället [cm]" }}
                        disabled={disabled}
                    />
                    <EnumField
                        className={classes.input}
                        name={`${name}.Form`}
                        schema={{ title: "Skadans form", options: shapeOptions }}
                        disabled={disabled}
                    />
                </>
            )}
        </ExpandingListItem>
    );
}

export default memo(BarkbinDamage);

import React from "react";

export const WorkspaceContext = React.createContext({
    configuration: null,
    updateConfiguration: () => {},
    saveStyles: () => {},
    resetStyles: () => {},
    getMap: () => {},
    deleteFeature: () => {},
    activeLayerIds: null,
    addActiveLayer: () => {},
    removeActiveLayer: () => {}
});

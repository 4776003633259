import React, { useState } from "react";
import { useUserReport } from "./useUserReport";
import { useUserLogs } from "./useUserLogs";
import { useDocumentTitle } from "./useDocumentTitle";
import UserLogMetadata from "./UserLogMetadata";

import { Button, CircularProgress, Container, Paper } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import makeStyles from "@mui/styles/makeStyles";

const DEFAULT_COL_DEF = {
    disableClickEventBubbling: true,
    sortable: false
};
const COLUMN_DEF = [
    {
        ...DEFAULT_COL_DEF,
        headerName: "Timestamp",
        field: "timestamp",
        width: 200
    },
    {
        ...DEFAULT_COL_DEF,
        headerName: "Window",
        field: "windowId",
        width: 150
    },
    {
        ...DEFAULT_COL_DEF,
        headerName: "Type",
        field: "type",
        width: 85
    },
    {
        ...DEFAULT_COL_DEF,
        headerName: "Message",
        field: "message",
        flex: 1
    }
];

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    buttonContainer: {
        padding: 10,
        display: "flex",
        justifyContent: "center"
    },
    log: {
        wordBreak: "break-all"
    },
    warn: {
        wordBreak: "break-all",
        color: "orange"
    },
    error: {
        wordBreak: "break-all",
        color: "red"
    }
}));

function UserLogs({ match }) {
    const classes = useStyles();
    const report = useUserReport(match.params.report);
    const logs = useUserLogs(match.params.report);
    const [expanded, setExpanded] = useState(false);

    useDocumentTitle(report ? `User report ${report._id} - Atlas` : "User report - Atlas");

    if (!logs) {
        return <CircularProgress />;
    }
    return (
        <Container className={classes.container}>
            <Paper className={classes.container}>
                <UserLogMetadata report={report} />
            </Paper>
            <Paper className={classes.container}>
                {expanded ? (
                    <DataGridPro
                        autoHeight
                        hideFooter
                        rows={logs}
                        columns={COLUMN_DEF}
                        getRowClassName={params => classes[params.row.type]}
                    />
                ) : (
                    <div className={classes.buttonContainer}>
                        <Button variant="contained" color="primary" onClick={() => setExpanded(true)}>
                            Expand logs
                        </Button>
                    </div>
                )}
            </Paper>
        </Container>
    );
}

export default UserLogs;

import React, { useContext, useEffect, useState } from "react";
import Api from "../Api";
import { ErrorContext } from "./error-context";
import { useQuery } from "./useQuery";
import { useDocumentTitle } from "./useDocumentTitle";
import UserReportTableRow from "./UserReportTableRow";

import {
    CircularProgress,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Container,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    },
    timestamp: {
        minWidth: "200px"
    }
}));

function UserReports() {
    const query = useQuery();
    const limit = query.get("limit");
    const { setError } = useContext(ErrorContext);
    const [reports, setReports] = useState(null);
    const classes = useStyles();

    useDocumentTitle("User reports - Atlas");

    useEffect(() => {
        let cancelled = false;
        const fetchReports = async () => {
            try {
                const reports = await Api.getReports(limit);
                if (!cancelled) {
                    setReports(reports);
                }
            } catch (error) {
                setError(error);
            }
        };
        fetchReports();
        return () => {
            cancelled = true;
        };
    }, [setError, limit]);

    if (reports === null) {
        return <CircularProgress />;
    }
    return (
        <Container className={classes.container}>
            <Paper>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell>id</TableCell>
                            <TableCell className={classes.timestamp}>Timestamp</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports.map(report => (
                            <UserReportTableRow key={report._id} report={report} />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
}

export default UserReports;

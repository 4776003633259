import React, { useContext, useEffect, useMemo, useRef } from "react";

import OLVectorLayer from "./openlayers/layers/VectorLayer";
import { Vector as VectorSource } from "ol/source";
import { transform } from "ol/proj";
import { Feature } from "ol";
import { Point } from "ol/geom";

import { createOLStyles } from "./styles/styles";
import { colors } from "../../../resources/colors";
import { GeolocationContext } from "./GeolocationWrapper";

const POSITION_RADIUS = 10;

function GeolocationIndicator({ map }) {
    const { tracking, accuracy, coordinates } = useContext(GeolocationContext);

    const positionFeature = useRef(new Feature());
    const accuracyFeature = useRef(new Feature());

    const source = useMemo(() => {
        const positionStyle = {
            symbol: {
                type: "circle",
                size: POSITION_RADIUS,
                fill: {
                    type: "solid",
                    color: colors.green
                },
                stroke: {
                    type: "solid",
                    color: "#ffffff",
                    width: 3
                }
            }
        };
        positionFeature.current.setStyle(createOLStyles(positionStyle)[0]);
        const accuracyStyle = { ...positionStyle };
        accuracyStyle.symbol.fill.opacity = 0.2;
        accuracyStyle.symbol.stroke.color = accuracyStyle.symbol.fill.color;
        accuracyStyle.symbol.size = 0;
        accuracyFeature.current.setStyle(createOLStyles(accuracyStyle)[0]);
        const source = new VectorSource({
            features: [accuracyFeature.current, positionFeature.current]
        });
        return source;
    }, []);

    useEffect(() => {
        if (!map || !Array.isArray(coordinates)) {
            return;
        }
        const setAccuracyRadius = accuracy => {
            const resolution = map.getView().getResolution();
            let radius = accuracy / resolution;
            if (radius < POSITION_RADIUS * 1.5) {
                // Only show accuracy ring if it's significantly larger than position point
                radius = 0;
            }
            const style = accuracyFeature.current.getStyle().getImage();
            style.setRadius(radius);
        };

        const localCoordinates = transform(coordinates, "EPSG:4326", map.getView().getProjection());
        const point = new Point(localCoordinates);
        positionFeature.current.setGeometry(point);
        accuracyFeature.current.setGeometry(point);
        setAccuracyRadius(accuracy);

        map?.getView().on("change:resolution", setAccuracyRadius);
        return () => {
            map?.getView().un("change:resolution", setAccuracyRadius);
        };
    }, [map, coordinates, accuracy]);

    return (
        <OLVectorLayer
            visible={tracking}
            renderMode="image"
            source={source}
            properties={{ ignoreclick: true }}
            zIndex={1000}
        />
    );
}

export default GeolocationIndicator;

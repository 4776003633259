import React from "react";

import { CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    spinner: {
        position: "absolute",
        top: "50%",
        left: "50%"
    }
});

function CenteredCircularProgress({ size = 24 }) {
    const classes = useStyles();

    return (
        <div style={{ position: "relative", minHeight: size }}>
            <CircularProgress
                className={classes.spinner}
                size={size}
                style={{ marginLeft: -size / 2, marginTop: -size / 2 }}
            />
        </div>
    );
}

export default CenteredCircularProgress;

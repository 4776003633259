import React from "react";
import { theme } from "./atlas-theme";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
    "977462aba3c4f68a52ae98adb3c96246Tz02NDYzNixFPTE3MTMzNjc3NDcwNDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function MaterialUIWrapper({ children }) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
}

export default MaterialUIWrapper;

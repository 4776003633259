import React, { Component } from "react";
import { AtlasContext } from "./atlas-context";
import { getSessionName } from "../cookie";
import { postNativeMessage } from "../Native";
import CheckForUpdatesSettingsButton from "./CheckForUpdatesSettingsButton.js";
import FileExport from "./FileExport.js";
import RemoveAllDataDialog from "./RemoveAllDataDialog";
import UploadLogsDialog from "./UploadLogsDialog.js";
import packageInfo from "../../package.json";

import withStyles from "@mui/styles/withStyles";
import {
    AppBar,
    Dialog,
    Slide,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {
    Close,
    DeleteSweep,
    Block,
    CheckCircle,
    HelpOutline,
    Storage,
    Info,
    Warning,
    Description
} from "@mui/icons-material";

const styles = theme => ({
    appBar: {
        position: "relative"
    },
    flex: {
        flex: 1
    },
    error: {
        color: theme.colors.error
    }
});

const TransitionSlideUp = React.forwardRef((props, ref) => <Slide ref={ref} direction={"up"} {...props} />);

async function _deleteDatabase(name) {
    console.log(`deleting ${name}`);
    return new Promise((resolve, reject) => {
        const request = indexedDB.deleteDatabase(name);
        request.onerror = reject;
        request.onsuccess = resolve;
        request.onblocked = reject;
    });
}

async function _deleteDatabases(names) {
    for (const name of names) {
        await _deleteDatabase(name);
    }
}

async function _deleteAllDbsViaAtlasDb() {
    return new Promise((resolve, reject) => {
        const openDbRequest = indexedDB.open("atlas");
        openDbRequest.onerror = reject;
        openDbRequest.onupgradeneeded = reject;
        openDbRequest.onsuccess = () => {
            const atlasDb = openDbRequest.result;
            atlasDb.onversionchange = () => {
                console.log("Atlas IDB onversionchange triggered.");
                atlasDb.close();
            };
            const tx = atlasDb.transaction("workspaces", "readonly");
            const workspaces = tx.objectStore("workspaces");
            const getAllRequest = workspaces.getAll();
            getAllRequest.onerror = reject;
            getAllRequest.onsuccess = async () => {
                const workspaces = getAllRequest.result;
                console.log(`workspaces ${workspaces}`);
                let dbNames = [];
                for (const workspace of workspaces) {
                    dbNames = [
                        ...dbNames,
                        ...workspace.layers,
                        ...workspace.layers.map(layer => `${workspace._id}:${layer}`)
                    ];
                }
                await _deleteDatabases(dbNames);
                await _deleteDatabase("atlas");
                resolve();
            };
        };
    });
}

export const removeAllCookies = () => {
    // Remove session cookie
    let cookie_domain = document.domain;
    if (document.domain.split(".").length > 2) {
        const split_domain = document.domain.split(".");
        cookie_domain = split_domain[split_domain.length - 2] + "." + split_domain[split_domain.length - 1];
    }
    const sessionName = getSessionName();
    document.cookie = `${sessionName}=; path=/; domain=.${cookie_domain};expires=Thu, 1 Jan 1970 00:00:01 UTC;`;

    // Remove other cookies
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
        const name = cookie.split("=").shift();
        document.cookie = `${name}=; expires = Thu, 01 Jan 1970 00:00:00 UTC`;
    }
};

export async function removeAll(removeCookies = true) {
    if (indexedDB.databases) {
        // Chrome, use built in functionality to remove everything
        const dbs = await indexedDB.databases();
        for (const db of dbs) {
            await _deleteDatabase(db.name);
        }
    } else {
        await _deleteAllDbsViaAtlasDb();
    }
    if (window.ATLAS_IS_NATIVE) {
        await postNativeMessage("remove-all");
    }

    localStorage.clear();
    if (removeCookies) {
        removeAllCookies();
    }

    window.location.reload();
}

class Settings extends Component {
    constructor(...props) {
        super(...props);
        this.state = {
            persisted: null,
            estimate: null,
            mainOpen: false,
            confirmCleanupOpen: false,
            failedUploads: null,
            fileToExport: null,
            sendLogsDialogOpen: false
        };
    }

    componentDidMount() {
        const couldNotCalcStorageString = "Could not calculate storage usage.";
        if (navigator.storage) {
            if (navigator.storage.persisted) {
                navigator.storage.persisted().then(persisted => {
                    if (!this._unmounted) {
                        this.setState({
                            persisted: persisted
                        });
                    }
                });
            } else {
                this.setState({ persisted: false });
            }

            if (navigator.storage.estimate) {
                navigator.storage.estimate().then(estimate => {
                    if (!this._unmounted) {
                        this.setState({ estimate });
                    }
                });
            } else {
                this.setState({ estimate: couldNotCalcStorageString });
            }
        } else {
            this.setState({
                persisted: false,
                estimate: couldNotCalcStorageString
            });
        }
        this._fetchFailedUploads();
    }

    componentWillUnmount() {
        this._unmounted = true;
    }

    async _fetchFailedUploads() {
        const features = await this.context.dbManager._atlasDB.getAll("failedUploads");
        if (!this._unmounted) {
            this.setState({ failedUploads: features });
        }
    }

    renderPersisted() {
        let icon;
        let text;
        if (this.state.persisted === null) {
            icon = <HelpOutline />;
            text = "Checking storage";
        } else if (this.state.persisted) {
            icon = <CheckCircle />;
            text = "Storage is safe";
        } else {
            icon = <Block />;
            text = "Storage will be wiped when it reaches maximum";
        }
        return (
            <ListItem>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        );
    }

    renderStorageQuota() {
        let text;
        if (this.state.estimate && typeof this.state.estimate !== "string") {
            const used = Math.round(this.state.estimate.usage / 1000000);
            const quota = Math.round(this.state.estimate.quota / 1000000);
            text = `${used}MB used out of ${quota}MB`;
        } else {
            text = this.state.estimate !== null ? this.state.estimate : "Checking storage";
        }
        return (
            <ListItem>
                <ListItemIcon>
                    <Storage />
                </ListItemIcon>
                <ListItemText primary={text} />
            </ListItem>
        );
    }

    async _exportFailedUploads() {
        const features = this.state.failedUploads || [];
        for (const feature of features) {
            const error = {
                name: "UNKNOWN",
                message: "An unknown error occurred",
                workspaceId: null,
                layerId: null,
                ...feature.properties.__atlas.error
            };
            feature.id = feature.properties.__atlas.originalId || feature.id;
            feature.properties["atlas-error-name"] = error.name;
            feature.properties["atlas-error-message"] = error.message;
            feature.properties["atlas-workspace"] = "Unknown";
            feature.properties["atlas-layer"] = "Unknown";
            feature.properties["atlas-timestamp"] = new Date(feature.properties.__atlas.localModified).toISOString();
            if (error.workspaceId) {
                feature.properties["atlas-workspace"] = error.workspaceId;
            }
            if (error.layerId) {
                feature.properties["atlas-layer"] = error.layerId;
            }
            delete feature.properties.__atlas;
        }
        const collection = {
            type: "FeatureCollection",
            features
        };
        this.setState({ fileToExport: JSON.stringify(collection, null, 2) });
    }

    render() {
        const { classes, handleClose } = this.props;
        return (
            <Dialog fullScreen open={this.props.open} TransitionComponent={TransitionSlideUp}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClose} aria-label="Close" size="large">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                            Settings
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText primary={`Version: ${packageInfo?.version}`} />
                    </ListItem>
                    {this.renderPersisted()}
                    {this.renderStorageQuota()}
                    <ListItem button onClick={() => this.setState({ sendLogsDialogOpen: true })}>
                        <ListItemIcon>
                            <Description />
                        </ListItemIcon>
                        <ListItemText
                            primary="Upload device logs"
                            secondary="If you have encountered a problem with Atlas you can send us your device logs so that we can investigate it."
                        />
                    </ListItem>
                    <ListItem button onClick={() => this.setState({ confirmCleanupOpen: true })}>
                        <ListItemIcon>
                            <DeleteSweep />
                        </ListItemIcon>
                        <ListItemText
                            primary="Clear saved data"
                            secondary="Remove all data related to Atlas stored on this device."
                        />
                    </ListItem>
                    {this.state.failedUploads && this.state.failedUploads.length > 0 && (
                        <ListItem button onClick={() => this._exportFailedUploads()}>
                            <ListItemIcon>
                                <Warning />
                            </ListItemIcon>
                            <ListItemText
                                primary="Export failed uploads"
                                secondary={`Atlas has failed to upload ${this.state.failedUploads.length} feature update(s) to the server.`}
                            />
                        </ListItem>
                    )}
                    {window.ATLAS_IS_NATIVE && <CheckForUpdatesSettingsButton />}
                </List>
                {this.state.fileToExport && (
                    <FileExport
                        open={true}
                        onClose={() => this.setState({ fileToExport: null })}
                        fileString={this.state.fileToExport}
                        filename={"atlas-failed-uploads.geojson"}
                    />
                )}
                <RemoveAllDataDialog
                    open={this.state.confirmCleanupOpen}
                    onClose={() => this.setState({ confirmCleanupOpen: false })}
                />
                <UploadLogsDialog
                    open={this.state.sendLogsDialogOpen}
                    onClose={() => this.setState({ sendLogsDialogOpen: false })}
                />
            </Dialog>
        );
    }
}

Settings.contextType = AtlasContext;

export default withStyles(styles)(Settings);

import { Component } from "react";
import { MapContext } from "./map-context";

import OLMousePosition from "ol/control/MousePosition";

import { addEventListeners, removeEventListeners, getOptions } from "./utility";

class MousePosition extends Component {
    constructor(props) {
        super(props);

        this.options = {
            className: undefined,
            coordinateFormat: undefined,
            projection: undefined,
            render: undefined,
            target: undefined,
            undefinedHTML: undefined
        };

        this.events = {};
        this.eventNames = {
            change: "change",
            changecoordinateFormat: "change:coordinateFormat",
            changeprojection: "change:projection",
            propertychange: "propertychange"
        };
    }

    componentDidMount() {
        const options = getOptions(this.props, this.options);
        this.mousePosition = new OLMousePosition(options);
        this.mousePosition.setMap(this.context.map);
        addEventListeners(this.props, this.events, this.eventNames, this.mousePosition);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.target !== this.props.target) {
            this.mousePosition.setTarget(this.props.target);
            this.mousePosition.setMap(this.context.map);
        }
    }

    componentWillUnmount() {
        this.mousePosition.setMap(null);
        removeEventListeners(this.events, this.target);
    }

    render() {
        return null;
    }
}

MousePosition.contextType = MapContext;

export default MousePosition;

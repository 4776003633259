import React, { useContext, useState } from "react";
import classNames from "classnames";

import {
    Collapse,
    ListItem,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Divider,
    Tooltip
} from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const NestedContext = React.createContext({
    level: 0
});

const useStyles = makeStyles(theme => ({
    listItem: {
        listStyle: "none"
    },
    container: {
        "&.nested": {
            borderColor: "transparent",
            borderLeftStyle: "solid",
            borderBottomStyle: "solid",
            borderLeftWidth: theme.spacing(1),
            borderBottomWidth: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),

            [theme.breakpoints.down("sm")]: {
                borderLeftWidth: theme.spacing(1 / 2),
                borderBottomWidth: theme.spacing(1 / 2)
            }
        }
    },
    icon: {
        minWidth: 0,
        marginRight: theme.spacing(1)
    },
    titleElementContainer: {
        width: "100%"
    },
    disabled: {
        color: "#aaa"
    },
    actions: {
        display: "flex"
    },
    expandIcon: {
        pointerEvents: "none"
    },
    primaryText: {
        fontWeight: "bold",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }
}));

function ExpandingListItem({
    className,
    id,
    title,
    titleElement,
    tooltipText,
    icon,
    listItemProps,
    expanded,
    disabled,
    onClick,
    secondaryAction,
    children,
    nested,
    topDivider,
    bottomDivider,
    dividerProps = {},
    mountOnEnter = true,
    unmountOnExit = true
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { level } = useContext(NestedContext);
    const [selfExpanded, setSelfExpanded] = useState(false);
    const _expanded = expanded ?? selfExpanded;
    const _onClick = onClick ?? (() => setSelfExpanded(selfExpanded => !selfExpanded));

    const handleClick = () => {
        if (!disabled) {
            _onClick(id);
        }
    };

    let Container = React.Fragment;
    let ContainerProps = {};
    const listItemStyle = {};
    const containerStyle = {};
    if (nested) {
        if (_expanded) {
            const nestedColor = theme.colors.nested[level % 2];
            listItemStyle.backgroundColor = nestedColor;
            containerStyle.borderColor = nestedColor;
        }
        Container = NestedContext.Provider;
        ContainerProps.value = {
            level: level + 1
        };
    }

    return (
        <Container {...ContainerProps}>
            <div className={classNames(className, classes.listItem, { nested: nested && level > 0 })}>
                {(topDivider || (!nested && _expanded)) && <Divider {...dividerProps} />}
                <ListItem
                    className={classNames(classes.listItem, { nested })}
                    button={!disabled}
                    onClick={handleClick}
                    style={listItemStyle}
                    {...listItemProps}
                >
                    {icon &&
                        (Array.isArray(icon) ? icon : [icon]).map((icon, index) => (
                            <ListItemIcon
                                key={index}
                                className={classNames(classes.icon, { [classes.disabled]: disabled })}
                            >
                                {icon}
                            </ListItemIcon>
                        ))}
                    <Tooltip title={tooltipText || ""} placement="top">
                        {titleElement ? (
                            <div className={classes.titleElementContainer}>{titleElement}</div>
                        ) : (
                            <ListItemText
                                className={classNames({ [classes.disabled]: disabled })}
                                classes={{ primary: classes.primaryText }}
                                primaryTypographyProps={{ variant: "subtitle2" }}
                                primary={title || ""}
                            />
                        )}
                    </Tooltip>
                    {_expanded ? (
                        <ExpandLess className={classNames({ [classes.disabled]: disabled })} />
                    ) : (
                        <ExpandMore className={classNames({ [classes.disabled]: disabled })} />
                    )}
                    {secondaryAction && (
                        <ListItemSecondaryAction>
                            <div className={classes.actions}>{secondaryAction}</div>
                        </ListItemSecondaryAction>
                    )}
                </ListItem>
                <Collapse in={_expanded} mountOnEnter={mountOnEnter} unmountOnExit={unmountOnExit}>
                    <div className={classNames(classes.container, { nested })} style={containerStyle}>
                        {children}
                    </div>
                </Collapse>
                {(bottomDivider || (!nested && _expanded)) && <Divider {...dividerProps} />}
            </div>
        </Container>
    );
}

export default ExpandingListItem;

import React, { useState } from "react";
import classNames from "classnames";
import { useSpring, animated } from "@react-spring/web";
import TooltipIconButton from "../TooltipIconButton";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    buttonContainer: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: 100,
        left: "50%",
        top: "50%",
        width: "20%",
        height: "20%",
        boxSizing: "border-box",

        "&.focused": {
            zIndex: 110
        }
    },
    button: {
        width: "100%",
        height: "100%"
    }
});

const useButtonAnimation = (index, buttonsPerRevolution, completion) => {
    const width = 0.2;
    const height = 0.2;
    const angle = (2 * Math.PI) / buttonsPerRevolution;
    const alpha = index * angle;
    const x = Math.cos(alpha) * (1 - width);
    const y = -Math.sin(alpha) * (1 - height);
    return useSpring({
        from: {
            left: "50%",
            top: "50%"
        },
        to: {
            left: `${50 + x * 50}%`,
            top: `${50 + y * 50}%`
        },
        config: {
            mass: 1,
            tension: 1500,
            friction: 50
        },
        onRest: completion
    });
};

function RevolverButton({ disabled, disabledExplanation = "", button, index, buttonsPerRevolution, onClick }) {
    const classes = useStyles();
    const [focused, setFocused] = useState(false);
    const [mounted, setMounted] = useState(false);

    const animationProps = useButtonAnimation(index, buttonsPerRevolution, () => setMounted(true));
    const styleConditions = disabled ? { focused, disabled: true } : { focused, destructive: button.destructive };

    return (
        <animated.div className={classNames(classes.buttonContainer, styleConditions)} style={animationProps}>
            <TooltipIconButton
                className={classes.button}
                label={button.label}
                destructive={button.destructive}
                icon={button.icon}
                disabled={disabled}
                disabledExplanation={disabledExplanation}
                onClick={event => {
                    if (mounted) {
                        console.log(`RevolverButton: click ${button.id}`);
                        if (!button.doNotCloseOnClick) {
                            onClick(button.id);
                        }
                        button.onClick({ ...button, currentTarget: event.currentTarget });
                    }
                }}
                onFocus={setFocused}
                disableFocus={!mounted}
            />
        </animated.div>
    );
}

export default RevolverButton;

import React, { useContext, useState } from "react";
import { fixGeometry } from "fix-geometry";
import { readGeoJSONFile, validateFeatures } from "./geojson-tools";
import { removeInvalidAttributes } from "validate-attributes";
import { AtlasContext } from "./atlas-context";
import { ErrorContext } from "./error-context";
import { WorkspaceContext } from "./workspace/workspace-context";
import FUXFileUpload from "foran-ux/dist/FUXFileUpload";
import GeoJSONImportResults from "./GeoJSONImportResults";

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Tooltip,
    Stack
} from "@mui/material";

function GeoJSONImportDialog({ open, onCancel, onComplete, layer }) {
    const { dbManager } = useContext(AtlasContext);
    const { configuration } = useContext(WorkspaceContext);
    const { setError } = useContext(ErrorContext);

    const [files, setFiles] = useState([]);
    const [updateExisting, setUpdateExisting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imported, setImported] = useState(false);
    const [importedFeatures, setImportedFeatures] = useState(null);
    const [importedValid, setImportedValid] = useState(null);
    const [importedValidReplacing, setImportedValidReplacing] = useState(null);
    const [importedInvalidGeometry, setImportedInvalidGeometry] = useState(null);
    const [importedInvalidAttributes, setImportedInvalidAttributes] = useState(null);
    const [importedOutsideArea, setImportedOutsideArea] = useState(null);
    const [importSkippedFeatures, setImportSkippedFeatures] = useState([]);

    const handleImportSubmit = async () => {
        setLoading(true);
        try {
            const file = files[0];
            const geojson = await readGeoJSONFile(file);
            // Check that file is geojson
            if (!Array.isArray(geojson) && !Array.isArray(geojson.features)) {
                throw new Error("Invalid file.");
            }
            const features = geojson.features || geojson;

            const { valid, validReplacing, invalidGeometry, invalidAttributes, outsideArea } = await validateFeatures({
                features,
                layer,
                workspace: configuration,
                updateExisting,
                dbManager
            });
            setImportedFeatures(features);
            setImportedValid(valid);
            setImportedValidReplacing(validReplacing);
            setImportedInvalidGeometry(invalidGeometry);
            setImportedInvalidAttributes(invalidAttributes);
            setImportedOutsideArea(outsideArea);
            setImported(true);
        } catch (error) {
            setError(error);
            return;
        } finally {
            setLoading(false);
        }
    };
    const handleSkipInvalidGeometries = () => {
        setImportSkippedFeatures(skipped => [...skipped, ...importedInvalidGeometry]);
        setImportedInvalidGeometry([]);
    };
    const handleFixInvalidGeometries = async () => {
        const features = [...importedInvalidGeometry].map(({ feature }) => feature);
        for (const feature of features) {
            feature.geometry = fixGeometry(feature.geometry);
        }
        const { valid, validReplacing, invalidGeometry, invalidAttributes, outsideArea } = await validateFeatures({
            features,
            layer,
            workspace: configuration,
            updateExisting,
            dbManager
        });
        setImportedValid(oldValid => [...oldValid, ...valid]);
        setImportedValidReplacing(oldReplacing => [...oldReplacing, ...validReplacing]);
        setImportedInvalidGeometry(invalidGeometry);
        setImportedInvalidAttributes(oldInvalidAttributes => [...oldInvalidAttributes, ...invalidAttributes]);
        setImportedOutsideArea(oldOutsideArea => [...oldOutsideArea, ...outsideArea]);
    };
    const handleSkipInvalidAttributes = () => {
        setImportSkippedFeatures(skipped => [...skipped, ...importedInvalidAttributes]);
        setImportedInvalidAttributes([]);
    };
    const handleSkipOutsideArea = () => {
        setImportSkippedFeatures(skipped => [...skipped, ...importedOutsideArea]);
        setImportedOutsideArea([]);
    };
    const handleRemoveInvalidAttributes = async () => {
        const features = [...importedInvalidAttributes].map(({ feature }) => feature);
        for (const invalidFeature of features) {
            invalidFeature.properties = removeInvalidAttributes(invalidFeature.properties, layer.attributes);
        }
        const { valid, validReplacing, invalidGeometry, invalidAttributes, outsideArea } = await validateFeatures({
            features,
            layer,
            workspace: configuration,
            updateExisting,
            dbManager
        });
        setImportedValid(oldValid => [...oldValid, ...valid]);
        setImportedValidReplacing(oldReplacing => [...oldReplacing, ...validReplacing]);
        setImportedInvalidGeometry(oldInvalidGeometry => [...oldInvalidGeometry, ...invalidGeometry]);
        setImportedInvalidAttributes(invalidAttributes);
        setImportedOutsideArea(oldOutsideArea => [...oldOutsideArea, ...outsideArea]);
    };
    const resetImport = () => {
        setFiles([]);
        setImported(false);
        setImportedFeatures(null);
        setImportedValid(null);
        setImportedValidReplacing(null);
        setImportedInvalidGeometry(null);
        setImportedInvalidAttributes(null);
        setImportedOutsideArea(null);
        setImportSkippedFeatures([]);
    };
    const submitResult = async () => {
        setLoading(true);
        try {
            await dbManager.addFeaturesFromGeoJSON(
                layer,
                configuration,
                [...importedValid, ...importedValidReplacing].map(({ feature }) => feature)
            );
            onComplete();
        } catch (error) {
            setError(error);
        } finally {
            resetImport();
            setLoading(false);
        }
    };

    const canContinue =
        (!importedInvalidGeometry || importedInvalidGeometry.length === 0) &&
        (!importedInvalidAttributes || importedInvalidAttributes.length === 0) &&
        (!importedOutsideArea || importedOutsideArea.length === 0);

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>Upload GeoJSON</DialogTitle>
            {imported ? (
                <>
                    <DialogContent>
                        <GeoJSONImportResults
                            imported={importedFeatures}
                            updateExisting={updateExisting}
                            valid={importedValid}
                            validReplacing={importedValidReplacing}
                            invalidGeometry={importedInvalidGeometry}
                            invalidAttributes={importedInvalidAttributes}
                            outsideArea={importedOutsideArea}
                            skipped={importSkippedFeatures}
                            onSkipInvalidGeometries={handleSkipInvalidGeometries}
                            onFixInvalidGeometries={handleFixInvalidGeometries}
                            onSkipInvalidAttributes={handleSkipInvalidAttributes}
                            onRemoveInvalidAttributes={handleRemoveInvalidAttributes}
                            onSkipOutsideArea={handleSkipOutsideArea}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Stack spacing={1} direction="row">
                            <Button disabled={loading} variant="outlined" color="primary" onClick={resetImport}>
                                Cancel
                            </Button>
                            <Tooltip
                                title={canContinue ? "" : "Fix the problems above before continuing"}
                                placement="top"
                            >
                                <span>
                                    <Button
                                        disabled={!canContinue || loading}
                                        variant="contained"
                                        color="primary"
                                        onClick={submitResult}
                                    >
                                        Continue
                                    </Button>
                                </span>
                            </Tooltip>
                        </Stack>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <FUXFileUpload
                            files={files}
                            setFiles={setFiles}
                            maxFiles={1}
                            maxSize={1000000000} // 1GB
                            accept={{
                                "application/json": [".json", ".geojson"],
                                "text/json": [".json", ".geojson"],
                                "application/geo+json": [".json", ".geojson"]
                            }}
                            supportedFiles={["GeoJSON"]}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={updateExisting}
                                        onChange={event => setUpdateExisting(event.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Update existing features"
                            />
                            <FormHelperText>
                                Atlas will try to match the identifiers of imported features to existing features in
                                Atlas, and replace them.
                            </FormHelperText>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={loading} variant="outlined" color="primary" onClick={onCancel}>
                            Cancel
                        </Button>
                        <Button disabled={loading} variant="contained" color="primary" onClick={handleImportSubmit}>
                            Submit
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

export default GeoJSONImportDialog;

import { useState } from "react";
import FileViewer from "../../FileViewer";
import NullableCellRenderer from "./NullableCellRenderer";

import { Box, Dialog, IconButton } from "@mui/material";
import { Image as ImageIcon, Attachment as AttachmentIcon } from "@mui/icons-material";

function FileCellRenderer({ value, ...props }) {
    const [fileToView, setFileToView] = useState(null);

    const { attribute } = props;
    const filename = value?.name;
    return (
        <Box display="flex" alignItems="center">
            {filename && (
                <IconButton onClick={() => setFileToView({ ...value, type: attribute.type })} size="large">
                    {attribute.type === "image" ? <ImageIcon fontSize="small" /> : <AttachmentIcon fontSize="small" />}
                </IconButton>
            )}
            <NullableCellRenderer value={filename} disableClick {...props} />
            <Dialog open={!!fileToView} onClose={() => setFileToView(null)} fullWidth>
                <FileViewer file={fileToView} />
            </Dialog>
        </Box>
    );
}

export default FileCellRenderer;

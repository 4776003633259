import { Box } from "@mui/material";
import { useForestandDateSpanUpdate } from "./useForestandDateSpanUpdate";
import CalculatedField from "../../CalculatedField";

import fromDateCalcObject from "./calculations/occasion-from-date-calculation.json";
import toDateCalcObject from "./calculations/occasion-to-date-calculation.json";
const fromDateCalculation = JSON.stringify(fromDateCalcObject);
const toDateCalculation = JSON.stringify(toDateCalcObject);

function ForestandOccasionDateSpan({ name, attributeDefinitions, disabled }) {
    const { setFromYear, setToYear } = useForestandDateSpanUpdate({ name });

    return (
        <Box sx={{ display: "flex", gap: 1 }}>
            <CalculatedField
                sx={{ flexGrow: 1 }}
                attributeDefinitions={attributeDefinitions}
                schema={{
                    title: "Från",
                    calculation: fromDateCalculation.replace("<activities_key>", `${name}.activities`)
                }}
                onChange={setFromYear}
                disabled={disabled}
            />
            <CalculatedField
                sx={{ flexGrow: 1 }}
                attributeDefinitions={attributeDefinitions}
                schema={{
                    title: "Till",
                    calculation: toDateCalculation.replace("<activities_key>", `${name}.activities`)
                }}
                onChange={setToYear}
                disabled={disabled}
            />
        </Box>
    );
}

export default ForestandOccasionDateSpan;

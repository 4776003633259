import { useEffect, useState, useContext } from "react";
import { ErrorContext } from "./error-context";
import Api from "../Api";

export const useUserReport = reportId => {
    const [report, setReport] = useState(null);
    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        let cancelled = false;
        const fetchReport = async () => {
            try {
                const report = await Api.getReport(reportId);
                if (!cancelled) {
                    setReport(report);
                }
            } catch (error) {
                setError(error);
            }
        };
        fetchReport();
        return () => {
            cancelled = true;
        };
    }, [reportId, setError]);

    return report;
};

import React, { useState } from "react";
import Stroke from "./Stroke";
import ExpandingStyleListItem from "./ExpandingStyleListItem";

import { Button, List } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Add } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    addButton: {
        marginTop: theme.spacing(1)
    }
}));

function Strokes({ strokes = [], onChange }) {
    const classes = useStyles();

    const [expanded, setExpanded] = useState(strokes.length === 1 && 0);

    const getSingleStroke = (stroke, index) => (
        <Stroke
            stroke={stroke}
            index={index}
            onChange={(value, index) =>
                onChange(currentStrokes => {
                    const newStrokes = [...currentStrokes];
                    newStrokes[index] = typeof value === "function" ? value(currentStrokes[index]) : value;
                    return newStrokes;
                })
            }
            enableDelete={strokes.length > 1}
            onDelete={index =>
                onChange(currentStrokes => {
                    const newStrokes = [...currentStrokes];
                    newStrokes.splice(index, 1);
                    return newStrokes;
                })
            }
        />
    );

    return (
        <React.Fragment>
            {strokes.length === 1 ? (
                getSingleStroke(strokes[0], 0)
            ) : (
                <List dense>
                    {strokes.map((stroke, index) => (
                        <ExpandingStyleListItem
                            key={index}
                            title={`Stroke ${index + 1}`}
                            id={index}
                            inherited={stroke === null}
                            expanded={expanded === index}
                            onClick={index => setExpanded(index === expanded ? false : index)}
                            onOverride={index =>
                                onChange(currentStrokes => {
                                    const newStrokes = [...currentStrokes];
                                    newStrokes[index] = { type: "none" };
                                    return newStrokes;
                                })
                            }
                            onDelete={index =>
                                onChange(currentStrokes => {
                                    const newStrokes = [...currentStrokes];
                                    newStrokes[index] = null;
                                    return newStrokes;
                                })
                            }
                        >
                            {getSingleStroke(stroke, index)}
                        </ExpandingStyleListItem>
                    ))}
                </List>
            )}
            <Button
                variant="contained"
                className={classes.addButton}
                startIcon={<Add />}
                color="primary"
                onClick={() => onChange(currentStrokes => [...currentStrokes, { type: "none" }])}
            >
                Add stroke
            </Button>
        </React.Fragment>
    );
}

export default Strokes;

import React, { memo } from "react";
import ArrayField from "../../ArrayField";
import EnumField from "../../EnumField";
import ExpandingListItem from "../../../ExpandingListItem";
import NumberField from "../../NumberField";

import { IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

const speciesOptions = [
    { title: "Tall", value: "Tall" },
    { title: "Gran", value: "Gran" },
    { title: "ÖvrigtBarr", value: "ÖvrigtBarr" },
    { title: "Björk", value: "Björk" },
    { title: "Asp", value: "Asp" },
    { title: "ÖvrigtLöv", value: "ÖvrigtLöv" },
    { title: "Ädellöv", value: "Ädellöv" }
];

function BarkbinTree({ name, index, disabled, onRemoveFromArray }) {
    const classes = useStyles();

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            title={`Träd ${index + 1}`}
            icon={disabled && <LockIcon />}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
        >
            <EnumField
                className={classes.input}
                name={`${name}.Tradslag`}
                schema={{ title: "Trädslag", options: speciesOptions }}
                disabled={disabled}
            />
            <NumberField
                className={classes.input}
                name={`${name}.BRHD`}
                schema={{ title: "Diameter vid brösthöjd (1,3 meter) [cm]" }}
                disabled={disabled}
            />
            <ArrayField
                schema={{ key: `${name}.skador`, type: "custom", title: "Skador", customType: "barkbin-damage" }}
                emptyLabel="Inga skador"
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(BarkbinTree);

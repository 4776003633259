import { memo, useCallback } from "react";
import { useFieldController } from "./useFieldController";
import { getErrorMessages, hasError, hasWarning, useExtendedRules } from "./form-utils";
import RenderCount from "../RenderCount";
import WarnableTextField from "./WarnableTextField";

import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";

function DateField({ className, schema, name, rules, defaultValue, disabled }) {
    const handleValidate = useCallback(value => {
        if (Number.isNaN(value)) {
            return "Invalid date format";
        }
    }, []);
    const _rules = useExtendedRules(rules, "errorInvalidDateFormat", handleValidate);
    const {
        field,
        fieldState: { error }
    } = useFieldController({ name, rules: _rules, defaultValue });

    return (
        <>
            <RenderCount label="DateField" />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RenderCount label="DatePicker" />
                <MobileDatePicker
                    {...field}
                    className={className}
                    disabled={disabled}
                    label={schema.title}
                    inputFormat="YYYY-MM-DD"
                    value={field.value ?? null}
                    onChange={date => field.onChange(date?.valueOf() ?? null)}
                    renderInput={params => (
                        <WarnableTextField
                            fullWidth
                            variant="outlined"
                            placeholder="YYYY-MM-DD"
                            {...params}
                            error={hasError(error)}
                            warning={hasWarning(error)}
                            helperText={getErrorMessages(error)?.join(", ")}
                        />
                    )}
                    componentsProps={{
                        actionBar: {
                            actions: ["clear", "cancel", "accept"]
                        }
                    }}
                />
            </LocalizationProvider>
        </>
    );
}

export default memo(DateField);

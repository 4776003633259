import React, { Component } from "react";
import { MapContext } from "./map-context";

import OLMap from "ol/Map";
import OLView from "ol/View";

import { addEventListeners, removeEventListeners, getOptions } from "./utility";

class Map extends Component {
    constructor(props) {
        super(props);

        this.options = {
            controls: undefined,
            pixelRatio: undefined,
            interactions: undefined,
            keyboardEventTarget: undefined,
            layers: undefined,
            maxTilesLoading: undefined,
            loadTilesWhileAnimating: undefined,
            loadTilesWhileInteracting: undefined,
            moveTolerance: undefined,
            overlays: undefined,
            target: undefined,
            view: undefined
        };

        this.events = {};
        this.eventNames = {
            change: "change",
            changelayergroup: "change:layerGroup",
            changesize: "change:size",
            changetarget: "change:target",
            changeview: "change:view",
            click: "click",
            dblclick: "dblclick",
            moveend: "moveend",
            movestart: "movestart",
            pointerdrag: "pointerdrag",
            pointermove: "pointermove",
            postcompose: "postcompose",
            postrender: "postrender",
            precompose: "precompose",
            propertychange: "propertychange",
            rendercomplete: "rendercomplete",
            singleclick: "singleclick"
        };

        const options = getOptions(this.props, this.options);
        options.view = new OLView(options.view);
        options.controls = [];
        this.map = new OLMap(options);
        addEventListeners(this.props, this.events, this.eventNames, this.map);
    }

    get olMap() {
        return this.map;
    }

    componentDidMount() {
        this.map.setTarget(this.mapElement);
    }

    componentWillUnmount() {
        removeEventListeners(this.events, this.map);
        this.map.setTarget(null);
    }

    render() {
        const { className, children } = this.props;
        return (
            <MapContext.Provider value={{ map: this.map }}>
                <div className={className} ref={el => (this.mapElement = el)}>
                    {children}
                </div>
            </MapContext.Provider>
        );
    }
}

export default Map;

import Api from "../../../Api";

export function getAttributes(feature) {
    let attributes;
    if (typeof feature.getProperties === "function") {
        // Feature is OpenLayers-feature
        attributes = feature.getProperties();
    } else if (feature.type === "Feature" && typeof feature.properties === "object") {
        // Feature is GeoJSON
        attributes = { ...feature.properties };
    } else {
        // Unknown feature type
        console.error("Cannot read properties from feature:", feature);
        return {};
    }
    delete attributes.__atlas;
    delete attributes.geometry;
    return attributes;
}

export function convertFileToURL(file, workspace) {
    return file && file.id && Api.getFileURL(file.id, undefined, workspace?._id);
}

export function getAttributesByName(feature, attributeDefinitions = []) {
    const attributes = getAttributes(feature);
    return attributeDefinitions.reduce((attributesByName, attribute) => {
        if (attributes.hasOwnProperty(attribute._id)) {
            attributesByName[attribute.name] = attributes[attribute._id];
        } else if (attributes.hasOwnProperty(attribute.name)) {
            attributesByName[attribute.name] = attributes[attribute.name];
        } else {
            attributesByName[attribute.name] = null;
        }
        return attributesByName;
    }, {});
}

export function getAttributesById(feature, attributeDefinitions = []) {
    const attributes = getAttributes(feature);
    return attributeDefinitions.reduce((attributesById, attribute) => {
        if (attributes.hasOwnProperty(attribute._id)) {
            attributesById[attribute._id] = attributes[attribute._id];
        } else if (attributes.hasOwnProperty(attribute.name)) {
            attributesById[attribute._id] = attributes[attribute.name];
        } else {
            attributesById[attribute._id] = null;
        }
        return attributesById;
    }, {});
}

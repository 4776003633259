import React from "react";
import prettyBytes from "pretty-bytes";
import { saveAs } from "file-saver";
import { useUserByHostname } from "./useUserByHostname";
import Api from "../Api";
import ExpandingListItem from "./ExpandingListItem";

import { Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";

const formatBytes = bytes => {
    if (!bytes) {
        return null;
    }
    return prettyBytes(bytes);
};

const useStyles = makeStyles({
    pre: {
        whiteSpace: "pre-wrap",
        wordBreak: "break-all"
    },
    buttons: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: 10,
        padding: 10
    }
});

function UserLogMetadata({ report }) {
    const classes = useStyles();
    const user = useUserByHostname(report && report.hostname);

    const downloadReport = () => {
        const content = JSON.stringify(report, null, 2);
        saveAs(new Blob([content]), `Report ${report?._id}.json`);
    };
    const downloadLogs = () => {
        window.open(Api.getLogsURL(report._id), "_blank");
    };

    return (
        <div>
            <div className={classes.buttons}>
                <Button variant="contained" color="primary" onClick={downloadReport}>
                    Download report
                </Button>
                <Button variant="contained" color="primary" onClick={downloadLogs}>
                    Download logs
                </Button>
            </div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{report?._id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Timestamp</TableCell>
                        <TableCell>{new Date(report?.timestamp).toLocaleString()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Window</TableCell>
                        <TableCell>{report?.metadata?.windowId}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Hostname</TableCell>
                        <TableCell>{report?.hostname}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell>{user?.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Message</TableCell>
                        <TableCell>{report?.message}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Atlas version</TableCell>
                        <TableCell>{report?.metadata?.version}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Persisted</TableCell>
                        <TableCell>{report?.metadata?.persisted === true ? "true" : "false"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Quota</TableCell>
                        <TableCell>{formatBytes(report?.metadata?.estimate?.quota)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Usage</TableCell>
                        <TableCell>{formatBytes(report?.metadata?.estimate?.usage)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <ExpandingListItem nested title="Usage details">
                <pre className={classes.pre}>
                    {JSON.stringify(report?.metadata?.estimate?.usageDetails ?? {}, null, 2)}
                </pre>
            </ExpandingListItem>
            <ExpandingListItem nested title={`Workspaces (count ${report?.metadata?.workspaces?.length})`}>
                <pre className={classes.pre}>{JSON.stringify(report?.metadata?.workspaces ?? {}, null, 2)}</pre>
            </ExpandingListItem>
            <ExpandingListItem nested title={`Layers (count ${report?.metadata?.layers?.length})`}>
                <pre className={classes.pre}>{JSON.stringify(report?.metadata?.layers ?? {}, null, 2)}</pre>
            </ExpandingListItem>
            <ExpandingListItem nested title={`Failed uploads (count ${report?.metadata?.failedUploads?.length})`}>
                <pre className={classes.pre}>{JSON.stringify(report?.metadata?.failedUploads ?? {}, null, 2)}</pre>
            </ExpandingListItem>
            <ExpandingListItem nested title="Local storage">
                <pre className={classes.pre}>
                    {JSON.stringify(JSON.parse(report?.metadata?.localStorage ?? {}), null, 2)}
                </pre>
            </ExpandingListItem>
            <ExpandingListItem nested title="Device detect">
                <pre className={classes.pre}>{JSON.stringify(report?.metadata?.deviceDetect ?? {}, null, 2)}</pre>
            </ExpandingListItem>
        </div>
    );
}

export default UserLogMetadata;

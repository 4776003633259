import classNames from "classnames";
import GeolocationButton from "./GeolocationButton";

import { makeStyles } from "@mui/styles";
import TooltipIconButton from "../TooltipIconButton";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    },
    button: {
        width: 40,
        height: 40
    },
    label: {
        transform: "translate(0, -120%)",
        left: "unset",
        right: 0
    }
}));

function MapButtons({ map, className, buttons }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            {buttons?.map((button, index) => (
                <TooltipIconButton key={index} labelClassName={classes.label} className={classes.button} {...button} />
            ))}
            <GeolocationButton map={map} labelClassName={classes.label} className={classes.button} />
        </div>
    );
}

export default MapButtons;

import chroma from "chroma-js";

import { MultiPoint, Point } from "ol/geom";

import SelectStyle, { SELECT_COLOR } from "./SelectStyle";

class EditStyle extends SelectStyle {
    createModifyStyle(type, originalStyle) {
        if (["multipolygon", "multilinestring"].includes(type.toLowerCase())) {
            const style = this.getPolygonStyles(originalStyle)[1];
            delete style.geometryFunction;
            return style;
        }
        return null;
    }

    getPolygonStyles(originalStyle, options) {
        const selectStyle = super.getPolygonStyles(originalStyle)[0];
        const outlineStyle = this._getNodeStyle(selectStyle, function (feature) {
            const polygons = feature.getGeometry().getCoordinates();
            const coordinates = [];
            polygons.forEach(polygon => {
                polygon.forEach(ring => {
                    ring.forEach(coordinate => {
                        coordinates.push(coordinate);
                    });
                });
            });
            return new MultiPoint(coordinates);
        });
        const styles = [selectStyle, outlineStyle];
        if (options?.highlight) {
            const highlightStyle = this._getHighlightStyle(outlineStyle);
            highlightStyle.geometryFunction = feature => {
                const polygons = feature.getGeometry().getCoordinates();
                for (const polygon of polygons) {
                    for (const ring of polygon) {
                        for (const [x, y] of ring) {
                            if (x === options.highlight[0] && y === options.highlight[1]) {
                                return new Point([x, y]);
                            }
                        }
                    }
                }
                return null;
            };
            styles.push(highlightStyle);
        }
        return styles;
    }

    getLineStringStyles(originalStyle, options) {
        const selectStyle = super.getLineStringStyles(originalStyle)[0];
        const outlineStyle = this._getNodeStyle(selectStyle, function (feature) {
            const lines = feature.getGeometry().getCoordinates();
            const coordinates = [];
            lines.forEach(line => {
                line.forEach(coordinate => {
                    coordinates.push(coordinate);
                });
            });
            return new MultiPoint(coordinates);
        });
        const styles = [selectStyle, outlineStyle];
        if (options?.highlight) {
            const highlightStyle = this._getHighlightStyle(outlineStyle);
            highlightStyle.geometryFunction = feature => {
                const lines = feature.getGeometry().getCoordinates();
                for (const line of lines) {
                    for (const [x, y] of line) {
                        if (x === options.highlight[0] && y === options.highlight[1]) {
                            return new Point([x, y]);
                        }
                    }
                }
                return null;
            };
            styles.push(highlightStyle);
        }
        return styles;
    }

    getPointStyles(originalStyle, options) {
        const selectStyles = super.getPointStyles(originalStyle, options);
        let guideColor = "#000000";
        if (selectStyles[0].symbol?.type === "icon") {
            const stroke = selectStyles[1].stroke ?? selectStyles[1].symbol.stroke;
            stroke.color =
                originalStyle?.symbol.icon?.backgroundColor ?? originalStyle?.symbol.icon?.color ?? guideColor;
            return selectStyles;
        }
        let strokeWidth = 0;
        let targetSize = 0;
        if (selectStyles[0]?.symbol.type === "circle") {
            if (selectStyles[0].symbol.stroke.type !== "none") {
                guideColor = selectStyles[0].symbol.stroke.color;
                if (selectStyles[0].symbol.stroke.width) {
                    strokeWidth = selectStyles[0].symbol.stroke.width;
                }
            } else if (selectStyles[0].symbol.fill.type !== "none") {
                guideColor = selectStyles[0].symbol.fill.color;
            }
            targetSize = selectStyles[0].symbol.size + strokeWidth / 2;
        }
        const outlineStyle = this._createOutlineStyle(targetSize, guideColor);
        const styles = [selectStyles[0], outlineStyle];
        if (options.highlight) {
            const highlightStyle = this._getHighlightStyle(outlineStyle);
            highlightStyle.geometryFunction = feature => {
                const points = feature.getGeometry().getCoordinates();
                for (const [x, y] of points) {
                    if (x === options.highlight[0] && y === options.highlight[1]) {
                        return new Point([x, y]);
                    }
                }
                return null;
            };
            styles.push(highlightStyle);
        }
        return styles;
    }

    _getHighlightStyle(outlineStyle) {
        const highlightStyle = JSON.parse(JSON.stringify(outlineStyle));
        highlightStyle.symbol.stroke.color = SELECT_COLOR;
        highlightStyle.zIndex = 99999;
        return highlightStyle;
    }

    _getNodeStyle(selectStyle, geometryFunction) {
        let nodeColor;
        const strokes = selectStyle.stroke || [];
        for (const stroke of strokes) {
            if (["solid", "dashed"].includes(stroke.type)) {
                nodeColor = stroke.color;
            }
        }
        if (!nodeColor) {
            const fills = selectStyle.fill || [];
            for (const fill of fills) {
                if (fill.type === "solid") {
                    nodeColor = fill.color;
                }
            }
        }
        if (!nodeColor) {
            nodeColor = "#000000";
        }
        const nodeStyle = this._createOutlineStyle(6, nodeColor);
        nodeStyle.symbol.fill = {
            type: "solid",
            color: nodeColor
        };
        if (geometryFunction) {
            nodeStyle.geometryFunction = geometryFunction;
        }
        return nodeStyle;
    }

    _createOutlineStyle(targetSize, guideColor) {
        return {
            symbol: {
                type: "circle",
                size: targetSize + 1,
                stroke: {
                    type: "solid",
                    width: 4,
                    opacity: 0.7,
                    color: chroma(guideColor).brighten().hex()
                }
            }
        };
    }
}

export default EditStyle;

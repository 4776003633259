import React, { memo } from "react";
import ArrayField from "./ArrayField";
import CustomInputs from "./custom-inputs";
import Field from "./Field";

function FieldWrapper(props) {
    const { schema, attributeDefinitions } = props;
    const attribute = attributeDefinitions?.find(attribute => attribute._id === schema?.key);
    const customArray = CustomInputs[schema.customType]?.array; // Custom component will handle array stuff itself
    if (attribute?.array && !customArray) {
        return <ArrayField attribute={attribute} {...props} />;
    } else {
        return <Field name={schema.key} attribute={attribute} {...props} />;
    }
}

export default memo(FieldWrapper);

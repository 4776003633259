import { TextField } from "@mui/material";

function StringCellEditor({ value, onChange }) {
    const handleChange = event => {
        if (event.target.value === "") {
            onChange(null);
        } else {
            onChange(event.target.value);
        }
    };
    return <TextField fullWidth value={value ?? ""} onChange={handleChange} />;
}

export default StringCellEditor;

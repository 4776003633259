import { useState, useEffect, useContext } from "react";
import { WorkspaceContext } from "./workspace/workspace-context";

import Api from "../Api";

const useFileURL = ({ id, dbManager }) => {
    const [url, setUrl] = useState(null);
    const [isLocal, setIsLocal] = useState(false);
    const { configuration } = useContext(WorkspaceContext);

    useEffect(() => {
        let url;
        const fetchFile = async () => {
            const file = await dbManager.getLocalFile(id);
            if (file) {
                url = URL.createObjectURL(file);
                setUrl(url);
                setIsLocal(true);
            } else {
                setUrl(Api.getFileURL(id, true, configuration?._id));
                setIsLocal(false);
            }
        };
        id && fetchFile();
        return () => {
            URL.revokeObjectURL(url);
        };
    }, [id, dbManager, configuration]);

    return { url, isLocal };
};

export default useFileURL;

import React, { memo } from "react";
import { useWatch } from "react-hook-form";
import ExpandingListItem from "../../../ExpandingListItem";
import EnumField from "../../EnumField";
import NumberField from "../../NumberField";

import { IconButton } from "@mui/material";
import { Delete as DeleteIcon, Lock as LockIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1)
    },
    input: {
        marginTop: theme.spacing(1)
    }
}));

const codeTradOptions = [
    { value: "E1", title: "Produktiv skogsmark" },
    { value: "E2_0", title: "Impediment – ospecificerat" },
    { value: "E2_1", title: "Impediment – myr" },
    { value: "E2_2", title: "Impediment – berg" },
    { value: "E2_3", title: "Impediment – fjällbarrskog" },
    { value: "E2_4", title: "Impediment – fjäll" },
    { value: "E3_0", title: "Vatten – ospecificerat" },
    { value: "E3_1", title: "Vatten – sötvatten" },
    { value: "E3_2", title: "Vatten – saltvatten" },
    { value: "E4_0", title: "Jordbruksmark – ospecificerat" },
    { value: "E4_1", title: "Jordbruksmark – åkermark" },
    { value: "E4_2", title: "Jordbruksmark – betesmark" },
    { value: "E5_0", title: "Annan mark – ospecificerat" },
    { value: "E5_1", title: "Annan mark – bebyggd mark" },
    { value: "E5_2", title: "Annan mark – väg, järnväg" },
    { value: "E5_3", title: "Annan mark – täkt" },
    { value: "E5_4", title: "Annan mark – kraftledning" }
];

function ForestandLandUse({ name, onRemoveFromArray, disabled }) {
    const classes = useStyles();

    const codeTrad = useWatch({ name: `${name}.codeTrad` });

    return (
        <ExpandingListItem
            className={classes.container}
            nested
            id={name}
            icon={disabled && <LockIcon />}
            title={codeTrad ?? "Ägoslag"}
            secondaryAction={
                onRemoveFromArray && (
                    <IconButton edge="end" disabled={disabled} onClick={onRemoveFromArray} size="large">
                        <DeleteIcon />
                    </IconButton>
                )
            }
            mountOnEnter={false}
            unmountOnExit={false}
        >
            <EnumField
                className={classes.input}
                name={`${name}.codeTrad`}
                schema={{ title: "Ägoslag", options: codeTradOptions }}
                disabled={disabled}
            />
            <NumberField
                className={classes.input}
                name={`${name}.share`}
                schema={{ title: "Ägoslagsandel" }}
                disabled={disabled}
            />
        </ExpandingListItem>
    );
}

export default memo(ForestandLandUse);

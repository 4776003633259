import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { useContext } from "react";
import { AtlasFormContext } from "./useAtlasFormContext";

export const isEmptyObject = object => {
    for (let _ in object) {
        return false;
    }
    return true;
};

function AtlasFormErrorDialog({ open, onClose }) {
    const { errors } = useContext(AtlasFormContext);
    return (
        <Dialog open={open} keepMounted={false} onClose={onClose}>
            <DialogTitle>Can't submit form with errors</DialogTitle>
            <DialogContent>
                {Object.keys(errors).map(id => (
                    <Typography key={id}>
                        {errors[id].name}: {errors[id].message}
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={onClose}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AtlasFormErrorDialog;

import React, { useEffect } from "react";
import Decimal from "decimal.js";
import OutlinedContainer from "../../OutlinedContainer";
import NumberInput from "../../inputs/NumberInput";
import { ErrorMessage } from "../Error";

import { MenuItem, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    input: {
        "&:not(:first-child)": {
            marginTop: theme.spacing(1)
        }
    }
}));

export const ALL_SPECIES = [
    { code: "E1_1_1", name: "tall (Pinus sylvestris)" },
    { code: "E1_1_2", name: "contortatall (Pinus contorta)" },
    { code: "E1_1_3", name: "cembratall (Pinus cembra)" },
    { code: "E1_1_4", name: "svarttall (Pinus nigra)" },
    { code: "E1_1_5", name: "bergtall (Pinus mugo)" },
    { code: "E1_2_1", name: "gran (Picea abies)" },
    { code: "E1_2_2", name: "sitkagran (Picea sitchensis)" },
    { code: "E1_2_3", name: "svartgran (Picea mariana)" },
    { code: "E1_3_1", name: "sibirisk lärk (Larix Sibirica)" },
    { code: "E1_3_2", name: "europeisk lärk (Larix decidua)" },
    { code: "E1_3_3", name: "hybridlärk (Larix x Marschlinsii)" },
    { code: "E1_3_4", name: "japansk lärk (Larix kaempferi)" },
    { code: "E1_4_1", name: "douglasgranar (Pseudotsuga)" },
    { code: "E1_5_1", name: "silvergran (Abies alba)" },
    { code: "E1_6_1", name: "en (Juniperus Communis)" },
    { code: "E1_6_2", name: "idegran (Taxus Baccata)" },
    { code: "E2_1_1", name: "skogsalm (Ulmus glabra)" },
    { code: "E2_1_2", name: "vresalm (Ulmus laevis)" },
    { code: "E2_1_3", name: "lundalm (Ulmus minor)" },
    { code: "E2_2_1", name: "ask (Fraxinus excelsior)" },
    { code: "E2_3_1", name: "avenbok (Carpinus betulus)" },
    { code: "E2_4_1", name: "bok (Fagus sylvatica)" },
    { code: "E2_5_1", name: "skogsek (Quercius robur)" },
    { code: "E2_5_2", name: "bergek (Quercius petraea)" },
    { code: "E2_6_1", name: "sötkörsbär, fågelbär (Prunus avium)" },
    { code: "E2_6_2", name: "hägg (Prunus Padus)" },
    { code: "E2_7_1", name: "skogslind (Tilia cordata)" },
    { code: "E2_7_2", name: "bohuslind (Tilia platyphyllos)" },
    { code: "E2_7_3", name: "parklind (Tilia Europea)" },
    { code: "E2_8_1", name: "lönn (Acer platanoides)" },
    { code: "E2_8_2", name: "näverlönn (Acer Campestre)" },
    { code: "E2_8_3", name: "sykomorlönn (Acer Pseudoplatanus)" },
    { code: "E2_9_1", name: "vårtbjörk (Betula pendula)" },
    { code: "E2_9_2", name: "glasbjörk (Betula pubescens)" },
    { code: "E2_10_1", name: "asp (Populus tremula)" },
    { code: "E2_10_2", name: "poppel (Poulus spp)" },
    { code: "E2_10_3", name: "hybridasp (P_ tremula x P_ tremuloides)" },
    { code: "E2_11_1", name: "gråal (Alnus incana)" },
    { code: "E2_11_2", name: "klibbal (Alnus glutinosa)" },
    { code: "E2_12_1", name: "sälg (Salix caprea)" },
    { code: "E2_12_2", name: "oxel (Sorbus intermedia)" },
    { code: "E2_12_3", name: "rönn (Sorbus aucuparia)" },
    { code: "E2_12_4", name: "äkta kastanj (Castanea sativa)" },
    { code: "ExotiskGran", name: "ExotiskGran" },
    { code: "ExotiskLark", name: "ExotiskLark" },
    { code: "ExotiskPopulus", name: "ExotiskPopulus" },
    { code: "ExotiskTall", name: "ExotiskTall" },
    { code: "InhemskAl", name: "InhemskAl" },
    { code: "InhemskAlm", name: "InhemskAlm" },
    { code: "InhemskBjork", name: "InhemskBjork" },
    { code: "InhemskEk", name: "InhemskEk" },
    { code: "InhemskLind", name: "InhemskLind" },
    { code: "InhemskLonn", name: "InhemskLonn" },
    { code: "InhemsktAdelLovExklEkBok", name: "InhemsktAdelLovExklEkBok" },
    { code: "InhemsktTrivialLov", name: "InhemsktTrivialLov" },
    { code: "InhemsktTrivialLovExklBjork", name: "InhemsktTrivialLovExklBjork" }
];

function ForestandSpecies({ field, form, attribute, error, setError }) {
    const classes = useStyles();

    const { validateField, setFieldTouched, values } = form;

    const value = field.value;
    const allSpecies = values[attribute._id];
    const otherSpecies = allSpecies?.filter(species => species?.type !== value?.type);
    const availableSpecies = ALL_SPECIES.filter(
        species => !otherSpecies?.find(otherSpecies => otherSpecies?.type === species.code)
    );

    const handleChange = newValue => {
        setError(field.name, null);
        const event = {
            target: {
                name: field.name,
                value: {
                    ...value,
                    ...newValue
                }
            }
        };
        field.onChange(event);
        setFieldTouched(field.name, true, true);
    };

    const totalAmount = allSpecies
        ?.reduce((total, species) => total.plus(species?.amount ?? 0), new Decimal(0))
        .toNumber();

    useEffect(() => {
        if (totalAmount !== 1) {
            setError(field.name, "Must sum to 100");
        } else {
            setError(field.name, null);
        }
    }, [totalAmount, setError, field.name]);

    useEffect(() => {
        // Revalidate when custom error changes
        validateField(field.name);
    }, [error, validateField, field.name]);

    let amount = null;
    if (typeof value?.amount === "number") {
        amount = new Decimal(value.amount).times(100).toNumber();
    }

    return (
        <OutlinedContainer>
            <TextField
                fullWidth
                select
                className={classes.input}
                variant="outlined"
                label="Trädslag"
                value={value?.type ?? ""}
                onChange={event => {
                    const newValue = { type: event.target.value };
                    if (newValue.type) {
                        newValue.ref = null;
                    }
                    handleChange(newValue);
                }}
            >
                <MenuItem value={null}>None</MenuItem>
                {availableSpecies.map(species => (
                    <MenuItem key={species.code} value={species.code}>
                        {species.name}
                    </MenuItem>
                ))}
            </TextField>
            <NumberInput
                fullWidth
                className={classes.input}
                variant="outlined"
                label="Andel"
                min={0}
                max={100}
                step={1}
                value={amount}
                onChange={val => {
                    const newValue = { amount: null };
                    if (typeof val === "number") {
                        newValue.amount = new Decimal(val).dividedBy(100).toNumber();
                    }
                    handleChange(newValue);
                }}
                onError={error => setError(field.name, error)}
            />
            <ErrorMessage error={error} />
            {attribute?.custom?.type === "forestand-species-tree" && (
                <>
                    <NumberInput
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        label="HGV"
                        value={value?.obsWeightedHeight ?? ""}
                        onChange={obsWeightedHeight => handleChange({ obsWeightedHeight })}
                        onError={error => setError(field.name, error)}
                    />
                    <NumberInput
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        label="DGV"
                        value={value?.obsWeightedDiameter ?? ""}
                        onChange={obsWeightedDiameter => handleChange({ obsWeightedDiameter })}
                        onError={error => setError(field.name, error)}
                    />
                    <NumberInput
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        label="Grundyta"
                        value={value?.obsStandBasalArea ?? ""}
                        onChange={obsStandBasalArea => handleChange({ obsStandBasalArea })}
                        onError={error => setError(field.name, error)}
                    />
                    <NumberInput
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        label="Volym"
                        value={value?.obsAreaStandVolume ?? ""}
                        onChange={obsAreaStandVolume => handleChange({ obsAreaStandVolume })}
                        onError={error => setError(field.name, error)}
                    />
                    <NumberInput
                        fullWidth
                        className={classes.input}
                        variant="outlined"
                        label="Stamantal"
                        value={value?.obsAreaStemNumber ?? ""}
                        onChange={obsAreaStemNumber => handleChange({ obsAreaStemNumber })}
                        onError={error => setError(field.name, error)}
                    />
                </>
            )}
        </OutlinedContainer>
    );
}

export default ForestandSpecies;

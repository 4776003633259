import React, { Component } from "react";
import { getFeaturesFromEvent, simulateClick } from "./utility/utility";

import Feature from "ol/Feature";
import OLCollection from "ol/Collection";
import OLVectorSource from "ol/source/Vector";
import OLDraw from "../openlayers/interactions/Draw";
import OLModify from "../openlayers/interactions/Modify";
import OLVectorLayer from "../openlayers/layers/VectorLayer";
import { unByKey } from "ol/Observable";
import { Polygon, MultiPolygon, LineString, MultiLineString, Point, MultiPoint } from "ol/geom";
import { Delete, Done, Add, DeleteForever } from "@mui/icons-material";

class EditWorkspaceArea extends Component {
    constructor(props) {
        super(props);

        this._insertVertexCondition = this._insertVertexCondition.bind(this);
        this._deleteCondition = this._deleteCondition.bind(this);
        this._handleModifyStart = this._handleModifyStart.bind(this);
        this._handleModifyEnd = this._handleModifyEnd.bind(this);
        this._handleDrawEnd = this._handleDrawEnd.bind(this);
        this._deleteVertex = this._deleteVertex.bind(this);
        this._addPart = this._addPart.bind(this);
        this._deletePart = this._deletePart.bind(this);
        this._done = this._done.bind(this);

        this._olModify = null;

        this.state = {
            mode: "editing"
        };

        if (props.feature) {
            this._feature = props.feature.clone();
        } else if (props.type) {
            let geometry;
            switch (props.type.toLowerCase()) {
                case "polygon":
                    geometry = new Polygon([]);
                    break;
                case "multipolygon":
                    geometry = new MultiPolygon([]);
                    break;
                case "linestring":
                    geometry = new LineString([]);
                    break;
                case "multilinestring":
                    geometry = new MultiLineString([]);
                    break;
                case "point":
                    geometry = new Point([]);
                    break;
                case "multipoint":
                    geometry = new MultiPoint([]);
                    break;
                default:
                    throw new Error(`Invalid geometry type "${props.type}".`);
            }
            this._feature = new Feature(geometry);
        } else {
            throw new Error(`A feature or type must be provided.`);
        }

        this._source = new OLVectorSource({ wrapX: false, features: [this._feature] });
    }

    componentDidMount() {
        this._changeListener = this._feature.on("change", () => this._featureChangeCount++);
    }

    componentWillUnmount() {
        unByKey(this._changeListener);
    }

    /// Modify props

    _insertVertexCondition() {
        const type = this._feature.getGeometry().getType();
        if (!type.includes("Point")) {
            // Adding new points are handled by draw tool
            this._addedVertex = true;
        }
        return true;
    }

    _deleteCondition(event) {
        if (event.type === "pointerdrag") {
            this._isDragging = true;
        }
        if (!this._addedVertex && !this._isDragging && event.type === "pointerup") {
            // A click was made on an existing vertex.
            this._didClickVertex = true;
        }
        if (event.type === "pointerup") {
            this._isDragging = false;
        }
        return false;
    }

    _handleModifyStart() {
        this._featureChangeCount = 0;
    }

    _handleModifyEnd() {
        // When moving or creating a vertex, it stays selected (on touch devices).
        // This resets the selection.
        this._olModify.setActive(false);
        this._olModify.setActive(true);
        if (this._featureChangeCount > 1) {
            // Multiple change events between modifystart end modifyend
            // means a vertex was dragged. If it was added and dragged
            // at the same time, the click event will not fire.
            this._addedVertex = false;
        }
        if (this.props.validationFunction) {
            try {
                const geojson = this._geojsonFormat.writeGeometry(this._feature.getGeometry());
                this.props.validationFunction(geojson);
                this._lastValidGeometry = this._feature.getGeometry().clone();
            } catch (e) {
                console.logError(e, "EditWorkspaceArea._handleModifyEnd: Validation error");
                this._feature.setGeometry(this._lastValidGeometry.clone());
            }
        }
    }

    /// Draw props

    _handleDrawEnd(event) {
        const geometry = event.feature.getGeometry();
        const type = geometry.getType();
        switch (type) {
            case "Point":
                this._feature.getGeometry().appendPoint(geometry);
                break;
            case "LineString":
                this._feature.getGeometry().appendLineString(geometry);
                break;
            case "Polygon":
                this._feature.getGeometry().appendPolygon(geometry);
                break;
            default:
                throw new Error(`Unsupported geometry type "${type}".`);
        }
        this.setState({ mode: "editing" });
    }

    /// Revolver buttons

    _done() {
        this.props.onDone(this._feature);
    }

    _deleteVertex() {
        this._olModify.removePoint();
    }

    _addPart(coordinates) {
        this.setState({ mode: "drawing" }, () => {
            simulateClick(coordinates, this.props.map);
        });
    }

    _deletePart(index) {
        const coordinates = this._feature.getGeometry().getCoordinates();
        coordinates.splice(index, 1);
        this._feature.getGeometry().setCoordinates(coordinates);
    }

    /// TODO: Move these out to a helper class

    _calculateDistanceToPart(part, coordinates) {
        if (part.intersectsCoordinate(coordinates)) {
            return 0;
        }
        const closestPoint = part.getClosestPoint(coordinates);
        const p1 = this.props.map.getPixelFromCoordinate(coordinates);
        const p2 = this.props.map.getPixelFromCoordinate(closestPoint);
        const x = p1[0] - p2[0];
        const y = p1[1] - p2[1];
        return Math.sqrt(x * x + y * y);
    }

    _getIndexOfIntersectingPart(geometry, coordinates) {
        let parts = [];
        switch (geometry.getType()) {
            case "MultiPoint":
                parts = geometry.getPoints();
                break;
            case "MultiLineString":
                parts = geometry.getLineStrings();
                break;
            case "MultiPolygon":
                parts = geometry.getPolygons();
                break;
            default:
                break;
        }
        return parts.reduce(
            ({ index, minDistance }, part, i) => {
                const distance = this._calculateDistanceToPart(part, coordinates);
                // Less than or equal means that if multiple parts have the same distance,
                // the last one (i.e. the newest one) will be removed.
                if (distance <= minDistance) {
                    index = i;
                    minDistance = distance;
                }
                return { index, minDistance };
            },
            { index: 0, minDistance: Infinity }
        ).index;
    }

    /// Tool methods

    handleMapClick(event) {
        if (this._addedVertex) {
            // This click was to add a vertex. Ignore.
            this._addedVertex = false;
            return;
        }

        const { onRevolverRequest } = this.props;

        const topButtons = [
            {
                id: "done",
                icon: <Done />,
                label: "Done",
                onClick: this._done
            }
        ];

        const features = getFeaturesFromEvent(event);
        const didClickInsidePolygon =
            !!features.find(f => f.getId() === this._feature.getId()) &&
            this._feature.getGeometry().getType() === "MultiPolygon";

        if (this._didClickVertex) {
            // Click on vertex of feature
            if (this._feature.getGeometry().getType() !== "MultiPoint") {
                topButtons.push({
                    id: "deleteVertex",
                    icon: <Delete />,
                    label: "Delete vertex",
                    onClick: this._deleteVertex
                });
            }
        } else if (didClickInsidePolygon) {
            // Click inside feature
            // topButtons.push({
            //     id: "addHole",
            //     icon: <AddCircleOutline />,
            //     label: "Add hole",
            //     onClick: () => console.error("Add hole not implemented.")
            // });
        } else {
            // Click outside feature
            topButtons.push({
                id: "addPart",
                icon: <Add />,
                label: "Add part",
                onClick: () => this._addPart(event.coordinate)
            });
        }
        if (this._didClickVertex || didClickInsidePolygon) {
            const partIndex = this._getIndexOfIntersectingPart(this._feature.getGeometry(), event.coordinate);
            topButtons.push({
                id: "deletePart",
                icon: <DeleteForever />,
                label: "Delete part",
                onClick: () => this._deletePart(partIndex)
            });
        }

        this._didClickVertex = false;

        onRevolverRequest(event.coordinate, { topButtons });
    }

    render() {
        const { mode } = this.state;
        let type = this._feature.getGeometry().getType();
        if (type.toLowerCase().startsWith("multi")) {
            type = type.slice(5);
        }
        return (
            <React.Fragment>
                {mode === "editing" && (
                    <OLModify
                        innerRef={ref => (this._olModify = ref)}
                        features={new OLCollection([this._feature])}
                        insertVertexCondition={this._insertVertexCondition}
                        deleteCondition={this._deleteCondition}
                        onModifyStart={this._handleModifyStart}
                        onModifyEnd={this._handleModifyEnd}
                        pixelTolerance={20}
                    />
                )}
                {mode === "drawing" && (
                    <OLDraw
                        type={type}
                        stopClick={true}
                        condition={() => true}
                        freehandCondition={() => false}
                        wrapX={false}
                        onDrawEnd={this._handleDrawEnd}
                    />
                )}
                <OLVectorLayer source={this._source} zIndex={1000} />
            </React.Fragment>
        );
    }
}

export default EditWorkspaceArea;

import { io } from "jsts";
import { Point, LineString, Polygon, MultiPoint, MultiLineString, MultiPolygon } from "ol/geom";
import LinearRing from "ol/geom/LinearRing";
import { getCenter } from "ol/extent";

const parser = new io.OL3Parser();
parser.inject(Point, LineString, LinearRing, Polygon, MultiPoint, MultiLineString, MultiPolygon);

export function getArea(olGeometry) {
    const type = olGeometry.getType().toLowerCase();
    if (["polygon", "multipolygon"].includes(type)) {
        const jstsGeom = parser.read(olGeometry);
        return jstsGeom.getArea();
    }
    return undefined;
}

export function getAreaString(olGeometry, fractionDigits) {
    const area = getArea(olGeometry);
    return area && `${area.toFixed(fractionDigits)} m²`;
}

export function getPerimeter(olGeometry) {
    const type = olGeometry.getType().toLowerCase();
    if (["polygon", "multipolygon", "linestring", "multilinestring"].includes(type)) {
        const jstsGeom = parser.read(olGeometry);
        return jstsGeom.getLength();
    }
    return undefined;
}

export function getPerimeterString(olGeometry, fractionDigits) {
    const perimeter = getPerimeter(olGeometry, fractionDigits);
    return perimeter && `${perimeter.toFixed(fractionDigits)} m`;
}

export function getPartsCount(olGeometry) {
    const coordinates = olGeometry.getCoordinates();
    const type = olGeometry.getType().toLowerCase();
    switch (type) {
        case "point":
        case "linestring":
        case "polygon":
            return 1;
        case "multipoint":
        case "multilinestring":
        case "multipolygon":
            return coordinates.length;
        default:
            return undefined;
    }
}

export function getVertexCount(olGeometry) {
    const jstsGeom = parser.read(olGeometry);
    const numPoints = jstsGeom.getNumPoints();
    if (olGeometry.getType().toLowerCase().includes("polygon")) {
        return numPoints - getPartsCount(olGeometry);
    } else {
        return numPoints;
    }
}

export function getDerivedProperties(olGeometry, { string, fractionDigits } = {}) {
    const properties = {};

    const area = string ? getAreaString(olGeometry, fractionDigits) : getArea(olGeometry);
    if (area !== undefined) {
        properties["area"] = area;
    }

    const perimeter = string ? getPerimeterString(olGeometry, fractionDigits) : getPerimeter(olGeometry);
    if (perimeter !== undefined) {
        properties["perimeter"] = perimeter;
    }

    const partsCount = getPartsCount(olGeometry);
    if (partsCount !== undefined) {
        properties["parts"] = string ? `${partsCount}` : partsCount;
    }

    const vertexCount = getVertexCount(olGeometry);
    if (vertexCount !== undefined && !olGeometry.getType().toLowerCase().includes("point")) {
        properties["vertices"] = string ? `${vertexCount}` : vertexCount;
    }

    const extent = olGeometry.getExtent();
    if (Array.isArray(extent) && extent.length === 4) {
        properties.extent = string ? `[${extent.join(", ")}]` : extent;
        properties.minX = string ? `${extent[0]}` : extent[0];
        properties.minY = string ? `${extent[1]}` : extent[1];
        properties.maxX = string ? `${extent[2]}` : extent[2];
        properties.maxY = string ? `${extent[3]}` : extent[3];
        const center = getCenter(extent);
        properties.centerX = center[0];
        properties.centerY = center[1];
    }

    return properties;
}

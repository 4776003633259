import React from "react";
import classNames from "classnames";
import InheritIcon from "./InheritIcon";

import { Button, Typography, IconButton } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    label: {
        color: "#bbbbbb"
    },
    children: {
        flexGrow: 2
    },
    deleteButton: {
        marginLeft: theme.spacing(1)
    }
}));

function OverrideWrapper({ className, label, inherited, children, onOverride, onDelete, deleteButtonSize = "small" }) {
    const classes = useStyles();

    return (
        <div className={classNames(classes.container, className)}>
            {inherited ? (
                <React.Fragment>
                    <Typography disabled variant="overline" className={classes.label}>
                        {label && `${label} `}inherited
                    </Typography>
                    <Button color="primary" onClick={() => onOverride()}>
                        Override
                    </Button>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={classes.children}>{children}</div>
                    {onDelete && (
                        <IconButton className={classes.deleteButton} onClick={onDelete} size="large">
                            <InheritIcon fontSize={deleteButtonSize} />
                        </IconButton>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

export default OverrideWrapper;

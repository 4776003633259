import React, { useState } from "react";
import {
    Button,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Typography,
    List,
    ListItem,
    Divider,
    FormHelperText,
    Dialog,
    IconButton
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Visibility as VisibilityIcon } from "@mui/icons-material";

// { default as FUXExpandingListItem } not working for some reason
import FUXExpanding from "foran-ux/dist/FUXExpandingListItem";
const FUXExpandingListItem = FUXExpanding.default;

const useStyles = makeStyles(theme => ({
    header: {
        fontWeight: "bold"
    },
    invalid: {
        color: theme.colors.destructive
    },
    flexCell: {
        display: "flex",
        alignItems: "center",

        "& span": {
            whiteSpace: "nowrap"
        }
    },
    actions: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridGap: theme.spacing(1),
        justifyItems: "start",

        "& > *": {
            width: "100%"
        }
    }
}));
const useListResultStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1)
    },
    list: {
        maxHeight: "60vh",
        overflowY: "scroll"
    },
    listItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: theme.spacing(1)
    }
}));

function ListResults({ title, features, onClose }) {
    const classes = useListResultStyles();
    return (
        <div className={classes.container}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="subtitle2">{features?.length ?? 0} feature(s)</Typography>
            <List className={classes.list}>
                {features?.map(({ feature, error }, index) => {
                    const { geometry, ...featureOnly } = feature;
                    return (
                        <React.Fragment key={index}>
                            <Divider />
                            <ListItem className={classes.listItem}>
                                <pre>{JSON.stringify(featureOnly, null, 2)}</pre>
                                {error && <FormHelperText error>{error.message}</FormHelperText>}
                            </ListItem>
                            <FUXExpandingListItem nested label="Expand geometry">
                                <pre>{JSON.stringify(geometry, null, 2)}</pre>
                            </FUXExpandingListItem>
                            {index === features.length - 1 && <Divider />}
                        </React.Fragment>
                    );
                })}
            </List>
            <div className={classes.actions}>
                <Button variant="contained" color="primary" onClick={onClose}>
                    Close
                </Button>
            </div>
        </div>
    );
}

function GeoJSONImportResults({
    imported,
    updateExisting,
    valid,
    validReplacing,
    invalidGeometry,
    invalidAttributes,
    outsideArea,
    skipped,
    onSkipInvalidGeometries,
    onFixInvalidGeometries,
    onSkipInvalidAttributes,
    onRemoveInvalidAttributes,
    onSkipOutsideArea
}) {
    const [featureDetails, setFeatureDetails] = useState(null);
    const [featureDetailsTitle, setFeatureDetailsTitle] = useState(null);
    const classes = useStyles();

    const closeDialog = () => {
        setFeatureDetails(null);
        setFeatureDetailsTitle(null);
    };
    const handleViewFeatures = type => () => {
        switch (type) {
            case "valid":
                setFeatureDetails(valid);
                setFeatureDetailsTitle("Valid");
                break;
            case "validReplacing":
                setFeatureDetails(validReplacing);
                setFeatureDetailsTitle("Replacing existing");
                break;
            case "invalidGeometry":
                setFeatureDetails(invalidGeometry);
                setFeatureDetailsTitle("Invalid geometry");
                break;
            case "invalidAttributes":
                setFeatureDetails(invalidAttributes);
                setFeatureDetailsTitle("Invalid attributes");
                break;
            case "outsideArea":
                setFeatureDetails(outsideArea);
                setFeatureDetailsTitle("Outside workspace area");
                break;
            case "skipped":
                setFeatureDetails(skipped);
                setFeatureDetailsTitle("Skipped");
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <Typography>Importing {imported?.length} feature(s)</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.header}>Type</TableCell>
                        <TableCell className={classes.header}>Count</TableCell>
                        <TableCell className={classes.header}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Valid</TableCell>
                        <TableCell>
                            <div className={classes.flexCell}>
                                <span>{valid.length} feature(s)</span>
                                <IconButton onClick={handleViewFeatures("valid")} size="large">
                                    <VisibilityIcon />
                                </IconButton>
                            </div>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                    {(updateExisting || validReplacing?.length > 0) && (
                        <TableRow>
                            <TableCell>Replacing existing</TableCell>
                            <TableCell>
                                <div className={classes.flexCell}>
                                    <span>{validReplacing.length} feature(s)</span>
                                    <IconButton onClick={handleViewFeatures("validReplacing")} size="large">
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    )}
                    {invalidGeometry?.length > 0 && (
                        <TableRow>
                            <TableCell className={classes.invalid}>Invalid geometry</TableCell>
                            <TableCell className={classes.invalid}>
                                <div className={classes.flexCell}>
                                    <span>{invalidGeometry.length} feature(s)</span>
                                    <IconButton onClick={handleViewFeatures("invalidGeometry")} size="large">
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={classes.actions}>
                                    <Button color="primary" variant="contained" onClick={onSkipInvalidGeometries}>
                                        Skip these features
                                    </Button>
                                    <Button color="primary" variant="contained" onClick={onFixInvalidGeometries}>
                                        Try to fix geometries
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {invalidAttributes?.length > 0 && (
                        <TableRow>
                            <TableCell className={classes.invalid}>Invalid attributes</TableCell>
                            <TableCell className={classes.invalid}>
                                <div className={classes.flexCell}>
                                    <span>{invalidAttributes.length} feature(s)</span>
                                    <IconButton onClick={handleViewFeatures("invalidAttributes")} size="large">
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={classes.actions}>
                                    <Button color="primary" variant="contained" onClick={onSkipInvalidAttributes}>
                                        Skip these features
                                    </Button>
                                    <Button color="primary" variant="contained" onClick={onRemoveInvalidAttributes}>
                                        Remove invalid attributes
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {outsideArea?.length > 0 && (
                        <TableRow>
                            <TableCell className={classes.invalid}>Outside workspace area</TableCell>
                            <TableCell className={classes.invalid}>
                                <div className={classes.flexCell}>
                                    <span>{outsideArea.length} feature(s)</span>
                                    <IconButton onClick={handleViewFeatures("outsideArea")} size="large">
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div className={classes.actions}>
                                    <Button color="primary" variant="contained" onClick={onSkipOutsideArea}>
                                        Skip these features
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {skipped?.length > 0 && (
                        <TableRow>
                            <TableCell>Skipped</TableCell>
                            <TableCell>
                                <div className={classes.flexCell}>
                                    <span>{skipped.length} feature(s)</span>
                                    <IconButton onClick={handleViewFeatures("skipped")} size="large">
                                        <VisibilityIcon />
                                    </IconButton>
                                </div>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Dialog fullWidth open={!!featureDetails} onClose={closeDialog} keepMounted={false}>
                <ListResults title={featureDetailsTitle} features={featureDetails} onClose={closeDialog} />
            </Dialog>
        </React.Fragment>
    );
}

export default GeoJSONImportResults;

import { memo, useContext, useMemo } from "react";
import { FormContext } from "./Form";
import { getValidationResult, getValidationsFromSchema } from "./form-utils";

import RenderCount from "../RenderCount";
import BoolField from "./BoolField";
import CalculatedField from "./CalculatedField";
import CustomInputs from "./custom-inputs";
import DateField from "./DateField";
import DateTimeField from "./DateTimeField";
import EnumField from "./EnumField";
import FileField from "./FileField";
import LocationField from "./LocationField";
import NumberField from "./NumberField";
import StringField from "./StringField";

import { makeStyles } from "@mui/styles";

/**
 * Fields use the Controller type (https://react-hook-form.com/api/usecontroller/controller)
 * in order to connect Material-UI's controlled components to react-hook-form's uncontrolled components.
 *
 * They are given a rules prop, which has the following format:
 * {
 *     errorKey: (value) => "Error message" | null,
 *     warningKey: (value) => "Warning message" | null,
 * }
 * where errorKey & warningKey are unique values that must start with
 * either 'error' or 'warning', depending on which validation type it represents.
 *
 * JSONLogic rules in the form schema are automatically added in this component.
 * Rules for specific field types can be added in subcomponents using the
 * 'form-utils/useExtendedRules' hook, see 'NumberField' as an example.
 *
 * Subcomponents can use 'form-utils/getErrorMessages' to get multiple error
 * messages from 'fieldState.error'.
 */

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const getValidationFunctionsOfType = (name, schema, type, getValues, submitWithWarnings) => {
    const validationFunctions = {};
    if (schema.required) {
        validationFunctions.errorRequired = () => {
            if (schema.required) {
                const value = getValues(name);
                if ([null, undefined, ""].includes(value)) {
                    return "Required";
                }
            }
        };
    }
    const validations = getValidationsFromSchema(schema) ?? [];
    return validations
        .filter(validation => validation?.type === type)
        .reduce(
            (validations, validation, index) => ({
                ...validations,
                [`${type}${index}`]: () => {
                    if (submitWithWarnings.current && type === "warning") {
                        // console.log("submitWithWarnings, ignoring.");
                        return;
                    }
                    const values = getValues();
                    // name will be "key.index" if attribute is an array
                    // but this will be run once for every field in the array
                    // so we can overwrite the value to be the current item
                    values[schema.key] = getValues(name);
                    const result = getValidationResult(validation, schema, values);
                    return result;
                }
            }),
            validationFunctions
        );
};

function Field(props) {
    const { name, schema, getValues, disabled } = props;
    const { submitWithWarnings } = useContext(FormContext);
    const classes = useStyles();
    const validationErrors = useMemo(() => {
        return getValidationFunctionsOfType(name, schema, "error", getValues, submitWithWarnings);
    }, [name, schema, getValues, submitWithWarnings]);
    const validationWarnings = useMemo(() => {
        return getValidationFunctionsOfType(name, schema, "warning", getValues, submitWithWarnings);
    }, [name, schema, getValues, submitWithWarnings]);
    const rules = useMemo(
        () => ({ validate: { ...validationErrors, ...validationWarnings } }),
        [validationErrors, validationWarnings]
    );

    let Component;
    if (schema.calculated) {
        Component = CalculatedField;
    } else {
        switch (schema.type) {
            case "decimal":
            case "integer":
                Component = NumberField;
                break;
            case "string":
                Component = StringField;
                break;
            case "date":
                Component = DateField;
                break;
            case "datetime":
                Component = DateTimeField;
                break;
            case "boolean":
                Component = BoolField;
                break;
            case "file":
            case "image":
                Component = FileField;
                break;
            case "enum":
                Component = EnumField;
                break;
            case "location":
                Component = LocationField;
                break;
            case "custom":
                Component = CustomInputs[schema.customType]?.component;
                if (!Component) {
                    console.error(`Could not find custom input "${schema.customType}". Ignoring.`);
                    return null;
                }
                break;
            default:
                console.warn(`Skipping schema type ${schema.type}`);
                return null;
        }
    }

    return (
        <div className={classes.container}>
            <RenderCount label="field" />
            <Component rules={rules} {...props} disabled={disabled || schema.readonly} />
        </div>
    );
}

export default memo(Field);

import React, { useRef } from "react";

import AtlasContextWrapper from "./AtlasContextWrapper";
import AtlasRouter from "./AtlasRouter";
import CopyManager from "./CopyManager";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "./Loader";
import MaterialUIWrapper from "./MaterialUIWrapper";
import UpdateChecker from "./UpdateChecker";

function Atlas({ version }) {
    const errorBoundary = useRef();

    window.__atlas = window.__atlas || {};

    return (
        <MaterialUIWrapper>
            <ErrorBoundary ref={errorBoundary}>
                <Loader>
                    <UpdateChecker version={version}>
                        <AtlasContextWrapper errorBoundary={errorBoundary}>
                            <CopyManager>
                                <AtlasRouter />
                            </CopyManager>
                        </AtlasContextWrapper>
                    </UpdateChecker>
                </Loader>
            </ErrorBoundary>
        </MaterialUIWrapper>
    );
}

export default React.memo(Atlas);

import React, { useState, useEffect } from "react";

import { hexToRgb } from "../../resources/colors";

import { TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    inputContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginTop: "20px",
        marginLeft: "-4px",
        marginRight: "-4px"
    },
    rgbContainer: {
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        justifyContent: "space-between"
    },
    input: {
        width: "75px",
        flexGrow: 1,
        margin: "4px",

        "&.wide": {
            flexGrow: 2,
            width: "90px"
        }
    }
});

function ColorTextInput({ hex, rgb, onChange }) {
    const classes = useStyles();

    const [hexValue, setHexValue] = useState(hex);
    useEffect(() => setHexValue(hex), [hex]);
    const [rgbValue, setRgbValue] = useState(rgb);
    useEffect(() => setRgbValue({ r: rgb.r, g: rgb.g, b: rgb.b }), [rgb.r, rgb.g, rgb.b]);
    const [opacityValue, setOpacityValue] = useState(rgb.a);
    useEffect(() => setOpacityValue(rgb.a), [rgb.a]);

    const handleHexChange = event => {
        const newHex = event.target.value;
        const newRgb = hexToRgb(newHex, false);
        if (newRgb) {
            onChange({ ...newRgb, a: rgb.a });
        } else {
            setHexValue(newHex);
        }
    };
    const handleOpacityChange = event => {
        const newOpacity = event.target.value;
        const number = Number(newOpacity);
        if (!Number.isNaN(number) && number >= 0 && number <= 100) {
            if (event.target.value !== "") {
                const a = number / 100;
                onChange({ ...rgb, a });
            } else {
                setOpacityValue(newOpacity);
            }
        }
    };
    const handleRGBChange = (event, name) => {
        const newRgb = { ...rgbValue, [name]: event.target.value };
        const number = Number(event.target.value);
        const rgbAsNumbers = { ...newRgb, [name]: number };
        if (!Number.isNaN(number) && number >= 0 && number <= 255) {
            if (event.target.value !== "") {
                onChange(rgbAsNumbers);
            } else {
                setRgbValue(newRgb);
            }
        }
    };

    return (
        <div className={classes.inputContainer}>
            <TextField
                className={`${classes.input} wide`}
                variant="outlined"
                label="Hex"
                value={hexValue}
                onChange={handleHexChange}
            />
            <TextField
                className={`${classes.input} wide`}
                type="number"
                variant="outlined"
                label="Opacity"
                value={opacityValue === "" ? "" : Math.round(opacityValue * 100)}
                onChange={handleOpacityChange}
            />
            <div className={classes.rgbContainer}>
                <TextField
                    className={classes.input}
                    type="number"
                    variant="outlined"
                    label="Red"
                    value={rgbValue.r}
                    onChange={e => handleRGBChange(e, "r")}
                />
                <TextField
                    className={classes.input}
                    type="number"
                    variant="outlined"
                    label="Green"
                    value={rgbValue.g}
                    onChange={e => handleRGBChange(e, "g")}
                />
                <TextField
                    className={classes.input}
                    type="number"
                    variant="outlined"
                    label="Blue"
                    value={rgbValue.b}
                    onChange={e => handleRGBChange(e, "b")}
                />
            </div>
        </div>
    );
}

export default ColorTextInput;

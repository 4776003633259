import React from "react";
import ReactDOM from "react-dom";
import Atlas from "./classes/Atlas";
import * as serviceWorker from "./serviceWorker";
import { EventEmitter } from "events";

import "./scss/index.scss";

const version = new EventEmitter();

ReactDOM.render(
    <React.Fragment>
        <Atlas version={version} />
    </React.Fragment>,
    document.getElementById("atlas")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register({
    onSuccess: registration => console.log("onSuccess triggered"),
    onUpdate: registration => version.emit("update")
});

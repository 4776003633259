import { useContext } from "react";
import { AtlasContext } from "../../atlas-context";
import { WorkspaceContext } from "../../workspace/workspace-context";

import { highlightPointFeature } from "../../workspace/map/tools/utility/highlight-point-feature";

import { GeoJSON } from "ol/format";
import { Box, IconButton, Typography } from "@mui/material";
import { MyLocation as MyLocationIcon } from "@mui/icons-material";

const ZOOM_TO_PADDING = 30;

function IDCellRenderer({ value, layer }) {
    const { getMap, configuration } = useContext(WorkspaceContext);
    const { dbManager } = useContext(AtlasContext);

    const zoomToFeature = async () => {
        const geojson = new GeoJSON({ dataProjection: layer.projection || "EPSG:3006" });
        const db = await dbManager.getVectorDB(layer._id, configuration._id);
        const features = await db.query({
            "__atlas.originalId": { $eq: value }
        });
        const feature = features.find(feature => !feature.properties.__atlas.child);
        const geometry = geojson.readGeometry(feature.geometry);
        const map = getMap();

        const currentResolution = map.getView().getResolution();
        const minResolution = Math.min(currentResolution, 0.7);

        map.getView().fit(geometry, {
            duration: 250,
            minResolution,
            padding: [ZOOM_TO_PADDING, ZOOM_TO_PADDING, ZOOM_TO_PADDING, ZOOM_TO_PADDING],
            callback: completed => {
                if (completed && layer?.source?.type === "point") {
                    map.once("rendercomplete", async () => {
                        highlightPointFeature(map, layer._id, feature.id);
                    });
                }
            }
        });
    };

    return (
        <Box>
            <IconButton onClick={zoomToFeature}>
                <MyLocationIcon />
            </IconButton>
            <Typography variant="overline" sx={{ fontFamily: "monospace" }}>
                {value}
            </Typography>
        </Box>
    );
}

export default IDCellRenderer;

import React from "react";

import { Map, Folder, Layers, Check, CloudDownload, Close } from "@mui/icons-material";

function FileItemIcon({ type }) {
    switch (type) {
        case "directory":
            return <Folder />;
        case "workspace":
            return <Map />;
        case "layer":
            return <Layers />;
        case "sync-done":
            return <Check sx={{ color: "white" }} />;
        case "sync-in-progress":
            return <CloudDownload sx={{ color: "lightblue" }} />;
        case "sync-error":
            return <Close sx={{ color: "white" }} />;
        default:
            console.error(`Unknown content type "${type}"`);
            return null;
    }
}

export default FileItemIcon;

import { useEffect } from "react";

export const useAsyncEffect = asyncFunc => {
    useEffect(() => {
        const abortController = new AbortController();
        let cancelled = false;
        const checkIsCancelled = () => cancelled;
        asyncFunc?.(checkIsCancelled, abortController.signal).catch(error => {
            if (error.name === "AbortError") {
                // Cancelled, ignore error
                return;
            }
            throw error;
        });
        return () => {
            cancelled = true;
            abortController.abort();
        };
    }, [asyncFunc]);
};

import React, { useEffect, useState } from "react";
import classNames from "classnames";
import jsonLogic from "../json-logic/json-logic";
import OutlinedContainer from "../OutlinedContainer";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    text: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    null: {
        color: theme.colors.disabled
    },
    error: {
        color: theme.colors.destructive
    }
}));

function CalculatedField({ schema, form, field, attributeDefinitions }) {
    const classes = useStyles();
    const [result, setResult] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const valuesByAttributeName = Object.keys(form.values).reduce((values, key) => {
            const attribute = attributeDefinitions.find(a => a._id === key);
            if (attribute) {
                values[attribute.name] = form.values[key];
            }
            return values;
        }, {});

        try {
            const rule = JSON.parse(schema.calculation);
            const result = jsonLogic.apply(rule, valuesByAttributeName);
            if (Number.isNaN(result)) {
                setResult(null);
            } else {
                setResult(result);
            }
            setError(false);
        } catch (e) {
            console.logError(e, `CalculatedField: Could not parse calculation schema "${schema.calculation}"`);
            setResult("Error: Could not perform calculation");
            setError(true);
        }
    }, [form.values, schema.calculation, attributeDefinitions]);

    const { onChange, name } = field;
    useEffect(() => {
        if (name) {
            const event = {
                target: {
                    name: name,
                    value: result
                }
            };
            onChange(event);
        }
    }, [result, onChange, name]);

    return (
        <OutlinedContainer label={schema.title}>
            <Typography
                className={classNames(classes.text, {
                    [classes.null]: [null, undefined].includes(result),
                    [classes.error]: error
                })}
            >
                {result ?? "NULL"}
            </Typography>
        </OutlinedContainer>
    );
}

export default CalculatedField;

import React from "react";
import FileItemIcon from "./FileItemIcon";

import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    CircularProgress
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

function FileListItem({ disabled, file, onClick, disableMenu, onMenuClick, loading }) {
    const handleClick = file => {
        if (!disabled) {
            onClick(file);
        }
    };

    return (
        <ListItem
            button={!!onClick}
            disabled={disabled}
            onClick={() => {
                handleClick(file);
            }}
        >
            <ListItemAvatar>
                <Avatar>
                    <FileItemIcon type={file.type} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={file.description} />
            {!disableMenu && (
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={event => onMenuClick(event, file)}
                        disabled={loading || disabled}
                        size="large"
                    >
                        {loading ? <CircularProgress size={22} /> : <MoreHoriz />}
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
}

export default FileListItem;

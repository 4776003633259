import React, { memo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../resources/colors";
import { useSpring, animated } from "@react-spring/web";
import { theme } from "../atlas-theme";

const useStyles = makeStyles(theme => ({
    container: {
        zIndex: 100,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "#ffffff",
        borderBottom: `3px solid ${colors.green}`,
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    text: {
        textAlign: "center",
        margin: "0"
    }
}));

const animationProps = {
    to: { backgroundColor: "white" },
    from: { backgroundColor: theme.palette.primary.light },
    config: { duration: 250 },
    reset: true
};

const AnimatedDiv = ({ text, children }) => {
    const springProps = useSpring(animationProps);
    const classes = useStyles();

    return (
        <animated.div className={classes.container} style={springProps}>
            {children}
            {text && <p className={classes.text}>{text}</p>}
        </animated.div>
    );
};

function StatusBar({ text, children }) {
    return <AnimatedDiv text={text}>{children}</AnimatedDiv>;
}

export default memo(StatusBar);

import { useMemo } from "react";
import jsonLogic from "../json-logic/json-logic";

export const useExtendedRules = (rules, key, validation) =>
    useMemo(
        () => ({
            ...(rules ?? {}),
            validate: {
                ...(rules?.validate ?? {}),
                [key]: validation
            }
        }),
        [rules, key, validation]
    );

export const getErrorMessages = fieldStateError => {
    const types = Object.keys(fieldStateError?.types ?? {});
    const errorMessages = types.filter(type => !type.startsWith("warning")).map(key => fieldStateError.types[key]);
    if (errorMessages.length > 0) {
        // Return only error messages if present
        return errorMessages;
    }
    const messages = Object.values(fieldStateError?.types ?? {});
    return messages.length > 0 ? messages : null;
};

export const hasError = fieldStateError => {
    const types = Object.keys(fieldStateError?.types ?? {});
    return types.some(type => !type.startsWith("warning"));
};

export const hasWarning = fieldStateError => {
    const types = Object.keys(fieldStateError?.types ?? {});
    return types.length > 0 && types.every(type => type.startsWith("warning"));
};

export const getValidationsFromSchema = schema => {
    if (Array.isArray(schema?.validate)) {
        return schema.validate;
    } else if (schema?.validate) {
        return [schema.validate];
    }
    return null;
};

export const getValidationResult = (validation, schema, values) => {
    if (values.hasOwnProperty(schema.key)) {
        // Validation is connected to a field value
        const value = values[schema.key];
        // If validation has an enabled rule, that decides if the validation should run.
        // Otherwise, only validate null values if field is required.
        if (!validation.enabled && !schema.required && [null, undefined, ""].includes(value)) {
            return;
        }
    } // else: Validation is connected to a group

    // Check if validation is enabled
    if (validation.enabled && !jsonLogic.apply(validation.enabled, values)) {
        return;
    }

    // Get the validation result
    const result = jsonLogic.apply(validation.rule, values);
    if (!result) {
        return validation.message;
    }
};

/**
 * Map react-hook-form's errors object (from formState) to an array of error types
 */
export const getErrorTypes = errors => {
    const types = [];
    if (typeof errors === "object") {
        for (const key in errors) {
            if (key === "ref") {
                continue;
            }
            if (Array.isArray(errors[key])) {
                for (const object of errors[key]) {
                    types.push(...getErrorTypes(object));
                }
            } else if (typeof errors[key] === "object") {
                types.push(...getErrorTypes(errors[key]));
            } else if (key === "type") {
                types.push(errors[key]);
            }
        }
    }
    return types;
};

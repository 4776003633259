import { useEffect, useState, useContext } from "react";
import { ErrorContext } from "./error-context";
import Api from "../Api";

export const useUserByHostname = hostname => {
    const [user, setUser] = useState(null);
    const { setError } = useContext(ErrorContext);

    useEffect(() => {
        let cancelled = false;
        const fetchUser = async () => {
            try {
                const user = await Api.getUserByHostname(hostname);
                if (!cancelled) {
                    setUser(user);
                }
            } catch (error) {
                setError(error);
            }
        };
        if (hostname) {
            fetchUser();
        }
        return () => {
            cancelled = true;
        };
    }, [hostname, setError]);

    return user;
};

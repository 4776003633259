import React from "react";

import { Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(theme => ({
    paper: {
        margin: 0,
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - ${theme.spacing(2)})`,
            maxWidth: `calc(100% - ${theme.spacing(2)})`
        }
    }
}));

function MobileFriendlyDialog({ children, ...props }) {
    const classes = useStyles();
    return (
        <Dialog
            classes={{
                paper: classes.paper,
                paperFullWidth: classes.paper,
                paperWidthFalse: classes.paper,
                ...(props?.classes ?? {})
            }}
            {...props}
        >
            {children}
        </Dialog>
    );
}

export default MobileFriendlyDialog;

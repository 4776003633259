import React from "react";
import OverrideWrapper from "./OverrideWrapper";
import OutlinedContainer from "../OutlinedContainer";
import InheritIcon from "./InheritIcon";

import { TextField, IconButton, MenuItem } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        position: "relative",
        marginTop: "10px"
    },
    deleteButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: "50%",
        transform: "translateY(-50%)"
    },
    arrowIcon: {
        marginRight: 44, // width of delete button
        paddingRight: theme.spacing(1)
    }
}));

function MultipleChoice({ label, value, defaultValue, choices, onChange }) {
    const classes = useStyles();

    const inherited = value === undefined;
    const Container = inherited ? OutlinedContainer : React.Fragment;
    const containerProps = inherited ? { label } : {};

    return (
        <Container {...containerProps}>
            <OverrideWrapper inherited={inherited} onOverride={() => onChange(defaultValue)}>
                <div className={classes.container}>
                    <TextField
                        variant="outlined"
                        label={label}
                        select
                        fullWidth
                        value={value}
                        onChange={event => onChange(event.target.value)}
                        SelectProps={{
                            classes: {
                                icon: classes.arrowIcon
                            }
                        }}
                    >
                        {choices.map((choice, index) => (
                            <MenuItem key={index} value={choice.value}>
                                {choice.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    {!inherited && (
                        <IconButton className={classes.deleteButton} onClick={() => onChange(undefined)} size="large">
                            <InheritIcon fontSize="small" />
                        </IconButton>
                    )}
                </div>
            </OverrideWrapper>
        </Container>
    );
}

export default React.memo(
    MultipleChoice,
    (prevProps, nextProps) =>
        prevProps.label === nextProps.label &&
        prevProps.value === nextProps.value &&
        prevProps.defaultValue === nextProps.defaultValue &&
        prevProps.choices.map(c => c.value).join() === nextProps.choices.map(c => c.value).join()
);

import React, { Component } from "react";
import { Formik, Form as FormikForm } from "formik";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { findInSchema } from "../workspace/map/tools/FormTool";
import jsonLogic from "../json-logic/json-logic";
import FormWarningDialog from "./FormWarningDialog";

import Group from "./Group";
import Field from "./Field";

import { Button, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";

jsonLogic.add_operation("+", (...values) => {
    if (values.some(v => Number.isNaN(Number(v)) || v === "")) {
        return false;
    }
    return values.reduce((prev, curr) => prev + Number(curr), 0);
});

jsonLogic.add_operation("match", (value, pattern) => {
    return RegExp(pattern).test(value);
});

const styles = {
    form: {
        backgroundColor: "#ffffff",
        padding: "10px",
        width: "100%",
        maxWidth: "1200px",
        marginLeft: "auto",
        marginRight: "auto",
        boxSizing: "border-box"
    },
    header: {
        marginBottom: "15px"
    },
    title: {
        fontWeight: "bold"
    },
    buttons: {
        marginTop: "30px",
        marginBottom: "50px",
        display: "flex",
        justifyContent: "center"
    },
    button: {
        "&:not(:last-child)": {
            marginRight: "20px"
        }
    }
};

class Form extends Component {
    constructor(props) {
        super(props);

        this._renderChildren = this._renderChildren.bind(this);
        this._renderGroup = this._renderGroup.bind(this);
        this._renderField = this._renderField.bind(this);

        this._handleValidate = this._handleValidate.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);

        this._initialValues = this._getInitialValues(props.initialValues, props.schema.children);

        this._addWarning = this._addWarning.bind(this);
        this._removeWarning = this._removeWarning.bind(this);

        this.state = {
            warnings: new Set(),
            warningDialogOpen: false,
            submitWithWarnings: false
        };
    }

    _getInitialValues(initialValues = {}, children = []) {
        let values = { ...initialValues };
        for (const schema of children) {
            if (schema.type === "group") {
                values = { ...values, ...this._getInitialValues(values, schema.children) };
            } else if (schema.key && values[schema.key] === undefined) {
                values[schema.key] = schema.default;
            }
        }
        return values;
    }

    _renderGroup(schema, formik, level) {
        return (
            <Group key={schema.key} schema={schema} formik={formik} level={level}>
                {this._renderChildren(schema.children, formik, level + 1)}
            </Group>
        );
    }

    _renderField(schema, formik) {
        const attribute = this.props.attributeDefinitions.find(a => a._id === schema.key || a.name === schema.key);
        return (
            <Field
                key={schema.key}
                name={schema.key}
                schema={schema}
                formik={formik}
                attribute={attribute}
                onAddWarning={this._addWarning}
                onRemoveWarning={this._removeWarning}
                attributeDefinitions={this.props.attributeDefinitions}
            />
        );
    }

    _renderChildren(children, formik, level = 0) {
        return children.map((schema, index) => {
            let content;
            switch (schema.type) {
                case "group":
                    content = this._renderGroup(schema, formik, level);
                    break;
                default:
                    content = this._renderField(schema, formik);
            }
            return <React.Fragment key={index}>{content}</React.Fragment>;
        });
    }

    _handleValidate(values) {
        let errors = {};

        const { schema } = this.props;
        if (schema.validate) {
            for (const validation of schema.validate) {
                switch (validation.type) {
                    case "error":
                        if (typeof validation.enabled === "object" && !jsonLogic.apply(validation.enabled, values)) {
                            continue;
                        }
                        if (!jsonLogic.apply(validation.rule, values)) {
                            errors[validation.key] = validation;
                        }
                        break;
                    default:
                        console.error(`Unknown validation type "${validation.type}". Skipping.`);
                }
            }
        }

        return errors;
    }

    async _saveFile(file) {
        const _file = file?.file;
        if (_file) {
            console.log("saveFile", _file);
            try {
                console.log(`Saving file "${_file.name}"`);
                return this.props.onUploadFile(_file);
            } catch (error) {
                console.logError(error, `There was an error saving the file "${_file.name}"`);
                throw error;
            }
        }
        return file;
    }

    async _handleSubmit(values, { setSubmitting }) {
        if (this.state.warnings.size > 0 && !this.state.submitWithWarnings) {
            setSubmitting(false);
            this.setState({ warningDialogOpen: true });
            return;
        }
        console.log(`Submitting form${this.state.submitWithWarnings ? " (with warnings)" : ""}`);
        const fileAttributes = this.props.attributeDefinitions.filter(a => ["image", "file"].includes(a.type));
        for (const fileAttribute of fileAttributes) {
            let schema = findInSchema(fileAttribute._id, this.props.schema);
            if (!schema) {
                // attribute referenced by name in schema
                schema = findInSchema(fileAttribute.name, this.props.schema);
            }
            const value = values[schema.key];
            try {
                if (Array.isArray(value)) {
                    values[schema.key] = await Promise.all(value.map(this._saveFile.bind(this)));
                } else {
                    values[schema.key] = await this._saveFile(value);
                }
            } catch (error) {
                console.logError(error, `Form._handleSubmit: error saving file${Array.isArray(value) && "s"}`);
                setSubmitting(false);
                throw error;
            }
        }
        await this.props.onSubmit(values);
        console.log("Form submission saved");
        setSubmitting(false);
    }

    _addWarning(key) {
        this.setState(state => {
            const warnings = new Set(state.warnings);
            warnings.add(key);
            return { warnings };
        });
    }

    _removeWarning(key) {
        this.setState(state => {
            const warnings = new Set(state.warnings);
            warnings.delete(key);
            return { warnings };
        });
    }

    render() {
        const { classes, schema, onCancel } = this.props;

        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Formik
                    initialValues={this._initialValues}
                    validate={this._handleValidate}
                    onSubmit={this._handleSubmit}
                    render={formik => (
                        <FormikForm className={classes.form}>
                            <div className={classes.header}>
                                {schema.title && (
                                    <Typography className={classes.title} variant="h5">
                                        {schema.title}
                                    </Typography>
                                )}
                                {schema.subtitle && <Typography variant="subtitle1">{schema.subtitle}</Typography>}
                            </div>
                            <div>{this._renderChildren(schema.children, formik)}</div>
                            <div className={classes.buttons}>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    onClick={onCancel}
                                    disabled={formik.isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </Button>
                            </div>
                            <FormWarningDialog
                                open={this.state.warningDialogOpen}
                                onCancel={() => this.setState({ warningDialogOpen: false })}
                                onConfirm={() => {
                                    this.setState({ warningDialogOpen: false, submitWithWarnings: true }, () => {
                                        formik.submitForm();
                                    });
                                }}
                            />
                        </FormikForm>
                    )}
                />
            </LocalizationProvider>
        );
    }
}

export default withStyles(styles)(Form);

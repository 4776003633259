import ctc from "copy-to-clipboard";

export const copyToClipboard = async text => {
    console.log(`Copying to clipboard: ${text}`);
    try {
        if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            await navigator.clipboard.writeText(text);
        } else {
            console.log("navigator.clipboard not present, using ctc.");
            ctc(text, { format: "text/plain" });
        }
    } catch (error) {
        console.logError(error, "Could not copy to clipboard");
    }
};

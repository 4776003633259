import { useMemo } from "react";
import AttributeCellEditor from "./editors/AttributeCellEditor";
import AttributeCellRenderer from "./renderers/AttributeCellRenderer";
import IDCellRenderer from "./renderers/IDCellRenderer";

// eslint-disable-next-line no-unused-vars
import { getGridStringOperators, GridColDef } from "@mui/x-data-grid-pro";
import { wrapFilenameOperator } from "./wrapFilenameOperator";

// https://github.com/mui/mui-x/blob/v5.15.3/packages/grid/x-data-grid/src/models/colDef/gridColType.ts
const MUI_CELL_TYPES_MAP = {
    boolean: "boolean",
    custom: "string",
    date: "date",
    datetime: "dateTime",
    decimal: "number",
    file: "string",
    image: "string",
    integer: "number",
    string: "string"
};
const EDITABLE_ATTRIBUTES = ["integer", "decimal", "date", "datetime", "string", "boolean", "file", "image"];

export const useColumnDefs = layer => {
    const columnDefs = useMemo(() => {
        /**
         * @type {Array<GridColDef>}
         */
        const defs = [
            {
                headerName: "id",
                field: "__atlas_original_id",
                width: 250,
                renderCell: params => <IDCellRenderer layer={layer} {...params} />
            }
        ];
        for (const attribute of layer?.attributes ?? []) {
            /** @type GridColDef */
            const def = {
                headerName: attribute.name,
                field: attribute._id,
                type: MUI_CELL_TYPES_MAP[attribute.type],
                width: 150,
                editable: layer.userRights?.includes("editfeatures") && EDITABLE_ATTRIBUTES.includes(attribute.type),
                renderCell: params => <AttributeCellRenderer attribute={attribute} layer={layer} {...params} />,
                renderEditCell: params => <AttributeCellEditor attribute={attribute} layer={layer} {...params} />
            };
            if (["image", "file"].includes(attribute.type)) {
                def.filterOperators = getGridStringOperators().map(wrapFilenameOperator);
            }
            if (attribute.type === "custom" || attribute.array) {
                def.filterOperators = getGridStringOperators().filter(operator =>
                    ["isEmpty", "isNotEmpty"].includes(operator.value)
                );
            }
            if (attribute.array) {
                def.editable = false;
            }
            defs.push(def);
        }

        return defs;
    }, [layer]);

    return columnDefs;
};

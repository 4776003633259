export const getFeatureLabelText = (olFeature, text, attributes) => {
    if (text) {
        let finalText = text;
        const regex = /{{([^}]+)}}/g;
        let match;
        const findAttribute = a => a.name === match[1] || a._id === match[1];
        while ((match = regex.exec(text))) {
            const attribute = attributes.find(findAttribute);
            if (attribute) {
                let value = olFeature.get(attribute._id);
                if ([null, undefined].includes(value)) {
                    value = "";
                } else {
                    switch (attribute.type) {
                        case "date":
                            value = new Date(value).toLocaleDateString();
                            break;
                        case "datetime":
                            value = new Date(value).toLocaleString();
                            break;
                        case "file":
                        case "image":
                            value = value.name;
                            break;
                        default:
                            break;
                    }
                }
                finalText = finalText.replace(`{{${match[1]}}}`, value);
            }
        }
        return finalText || "";
    } else {
        return null;
    }
};

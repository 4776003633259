import React from "react";
import { TextField } from "@mui/material";
import { ErrorMessage } from "../Error";

function StringFormInput(props) {
    const { schema, field, enabled, error, setError } = props;

    field.value = field.value || "";

    const handleChange = event => {
        setError(field.name, null);
        field.onChange(event);
    };

    return (
        <React.Fragment>
            <TextField
                error={!!error}
                type="text"
                variant="outlined"
                label={schema.title}
                fullWidth
                {...field}
                onChange={handleChange}
                disabled={!enabled}
                multiline={!!schema.multiline}
            />
            <ErrorMessage error={error} />
        </React.Fragment>
    );
}

export default StringFormInput;

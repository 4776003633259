import NumberInput from "../../inputs/NumberInput";

function NumberCellEditor({ value, integer, onChange }) {
    return (
        <NumberInput
            variant="outlined"
            step={integer ? 1 : undefined}
            value={value}
            onChange={onChange}
            integer={integer}
            fullWidth
        />
    );
}

export default NumberCellEditor;

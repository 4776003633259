import { transform } from "ol/proj";

import NullableCellRenderer from "./NullableCellRenderer";

const getValues = (value, layer) => {
    if (!value?.longitude || !value?.latitude) {
        return [null];
    }
    let shortValue = `${value.latitude}, ${value.longitude}`;
    let displayValue = shortValue;
    if (layer?.projection && layer?.projection !== "EPSG:4326") {
        const [x, y] = transform([value.longitude, value.latitude], "EPSG:4326", layer.projection);
        shortValue = `${x}, ${y}`;
        displayValue = `Coordinates: ${displayValue}\nX, Y: ${shortValue}`;
    }
    if (value.accuracy) {
        displayValue += `\naccuracy: ${value.accuracy}m`;
    }
    return [displayValue, shortValue];
};

function LocationCellRenderer({ value, layer, ...props }) {
    const [displayValue, shortValue] = getValues(value, layer);
    return <NullableCellRenderer value={displayValue} copyValue={shortValue} shortValue={shortValue} {...props} />;
}

export default LocationCellRenderer;

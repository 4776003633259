import { geom, io } from "jsts";
import { validateGeometries } from "validate-geometries";
import { validateAttributes } from "validate-attributes";

const VALID_GEOJSON_TYPES = {
    polygon: ["Polygon", "MultiPolygon"],
    line: ["LineString", "MultiLineString"],
    point: ["Point", "MultiPoint"]
};

export const touches = (feature1, feature2) => {
    const reader = new io.GeoJSONReader();
    const jstsGeom1 = reader.read(feature1).geometry;
    const jstsGeom2 = reader.read(feature2).geometry;
    return jstsGeom1.touches(jstsGeom2);
};

export const difference = (feature1, feature2) => {
    const reader = new io.GeoJSONReader();
    const writer = new io.GeoJSONWriter();
    const jstsGeom1 = reader.read(feature1).geometry;
    const jstsGeom2 = reader.read(feature2).geometry;
    const difference = jstsGeom1.difference(jstsGeom2);
    const geometry = writer.write(difference);
    return { type: "Feature", geometry };
};

export const readGeoJSONFile = file => {
    return new Promise((resolve, reject) => {
        if (!file || !file.name) {
            reject("File is missing.");
        }
        const reader = new FileReader();
        reader.onload = event => {
            try {
                const geojson = JSON.parse(event.target.result);
                resolve(geojson);
            } catch (error) {
                console.logError(error, "There was en error parsing the file");
                reject(`An error occurred while parsing the file. It may be invalid or too large.`);
            }
        };
        reader.readAsText(file);
    });
};

export const readCrs = geojson => {
    const crs = geojson && geojson.crs && geojson.crs.properties && geojson.crs.properties.name;
    if (typeof crs === "string") {
        const match = crs.match(/epsg::?\d+/i);
        return match && match[0].replace("::", ":");
    }
    return null;
};

export const flattenToSingleMultiPolygon = geojson => {
    const geometry = geojson.type === "Feature" ? geojson.geometry : geojson;
    if (geometry.type === "Polygon") {
        return {
            type: "MultiPolygon",
            coordinates: [geometry.coordinates]
        };
    } else if (geometry.type === "MultiPolygon") {
        return {
            type: "MultiPolygon",
            coordinates: geometry.coordinates
        };
    }
    let geometries;
    if (Array.isArray(geojson)) {
        geometries = geojson.map(featureOrGeometry => {
            if (featureOrGeometry.type === "Feature") {
                return featureOrGeometry.geometry;
            } else {
                return featureOrGeometry;
            }
        });
    } else if (geojson.type === "FeatureCollection") {
        geometries = geojson.features.map(feature => feature.geometry);
    } else if (geojson.type === "GeometryCollection") {
        geometries = geojson.geometries;
    } else {
        return null;
    }
    const geojsonReader = new io.GeoJSONReader();
    let combined = new geom.MultiPolygon([]);
    for (const geometry of geometries) {
        const jstsGeom = geojsonReader.read(geometry);
        combined = combined.union(jstsGeom);
    }
    const geojsonWriter = new io.GeoJSONWriter();
    const mergedGeoJSON = geojsonWriter.write(combined);
    if (mergedGeoJSON.type === "Polygon") {
        mergedGeoJSON.type = "MultiPolygon";
        mergedGeoJSON.coordinates = [mergedGeoJSON.coordinates];
    }
    return mergedGeoJSON;
};

export const getCenter = geometry => {
    if (!geometry) {
        return null;
    }
    try {
        const reader = new io.GeoJSONReader();
        const jstsGeom = reader.read(geometry);
        const centre = jstsGeom.getEnvelopeInternal().centre();
        return [centre.x, centre.y];
    } catch (error) {
        console.logError(error, "Error calculating center");
    }
    return null;
};

const removeEmptyCoordinateArrays = coordinates => {
    if (Array.isArray(coordinates)) {
        const newCoordinates = [...coordinates];
        for (let i = newCoordinates.length - 1; i >= 0; i--) {
            if (Array.isArray(newCoordinates[i])) {
                newCoordinates[i] = removeEmptyCoordinateArrays(newCoordinates[i]);
                if (newCoordinates[i].length === 0) {
                    newCoordinates.splice(i, 1);
                }
            }
        }
        return newCoordinates;
    } else if (coordinates !== null) {
        throw new Error(`Unknown coordinate format: ${coordinates}`);
    }
    return null;
};

const validateFeature = ({ feature, layer, allowedArea, updateExisting }) => {
    try {
        feature.geometry.coordinates = removeEmptyCoordinateArrays(feature.geometry.coordinates);
        validateGeometries([feature], { allowedArea });
        try {
            if (updateExisting) {
                if (!feature.id && feature.properties.id) {
                    feature.id = feature.properties.id;
                }
            } else {
                delete feature.id;
            }
            if (!layer.attributes.find(a => a.name === "id")) {
                delete feature.properties.id;
            }
            validateAttributes(feature.properties, layer.attributes);
            return { type: "valid" };
        } catch (error) {
            return { type: "invalidAttributes", error };
        }
    } catch (error) {
        if (error.name === "OUTSIDE_AREA") {
            return { type: "outsideArea", error };
        } else {
            return { type: "invalidGeometry", error };
        }
    }
};

export const validateFeatures = async ({ features, layer, workspace, updateExisting, dbManager }) => {
    // Convert to Multi-geometries
    features.forEach(f => {
        if (!f.geometry.type.startsWith("Multi")) {
            f.geometry.coordinates = [f.geometry.coordinates];
            f.geometry.type = `Multi${f.geometry.type}`;
        }
    });

    // Check that all features are of the correct type
    const validTypes = VALID_GEOJSON_TYPES[layer.source.type];
    if (features.some(f => !validTypes.includes(f.geometry.type))) {
        throw new Error(`All features must be of type "[${validTypes}]".`);
    }

    // Get the allowed area, if available
    let allowedArea;
    if (Array.isArray(workspace.area) && workspace.area.length > 0) {
        allowedArea = {
            type: "MultiPolygon",
            coordinates: workspace.area
        };
    } else if (workspace.area && workspace.area.type === "Feature") {
        allowedArea = workspace.area.geometry;
    }

    const db = await dbManager.getVectorDB(layer._id, workspace._id);

    // Validate geometries & attributes
    const valid = [];
    const validReplacing = [];
    const invalidGeometry = [];
    const invalidAttributes = [];
    const outsideArea = [];
    for (const feature of features) {
        const result = validateFeature({ feature, layer, allowedArea, updateExisting });
        const error = result?.error;
        if (error) {
            const id = feature.id ? ` ${feature.id}` : "";
            console.logError(error, `Feature${id} failed validation`);
        }
        switch (result?.type) {
            case "invalidAttributes":
                invalidAttributes.push({ feature, error });
                break;
            case "invalidGeometry":
                invalidGeometry.push({ feature, error });
                break;
            case "outsideArea":
                outsideArea.push({ feature, error });
                break;
            case "valid":
                // Make sure attributes are refereced by id
                for (const key of Object.keys(feature.properties)) {
                    const attribute = layer.attributes.find(a => a._id === key || a.name === key);
                    feature.properties[attribute._id] = feature.properties[key];
                    delete feature.properties[attribute.name];
                }

                // Check if feature replaces an existing feature
                if (updateExisting && feature.id) {
                    const existingFeatures = await db.query({
                        "__atlas.originalId": { $eq: feature.id }
                    });
                    const lastExistingVersion = existingFeatures.find(feature => !feature.properties.__atlas.child);
                    if (lastExistingVersion) {
                        validReplacing.push({ feature });
                    } else {
                        valid.push({ feature });
                    }
                } else {
                    valid.push({ feature });
                }

                break;
            default:
                throw new Error(`Unknown import result "${result}"`);
        }
    }
    return { valid, validReplacing, invalidGeometry, invalidAttributes, outsideArea };
};

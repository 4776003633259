import { getCookieByName, getSessionName } from "./cookie";
import { postNativeMessage } from "./Native";

const VERSION = "v1";

class Api {
    static _getUrlBase() {
        return `${window.ATLAS_API_BASE || ""}/api/${VERSION}`;
    }

    static _appendParam(url, name, value) {
        if (value) {
            return `${url}${url.includes("?") ? "&" : "?"}${name}=${value}`;
        }
        return url;
    }

    static _addCredentials(url) {
        let _url = url;
        const session = getCookieByName(getSessionName());
        _url = Api._appendParam(_url, "token", session);
        const hostname = localStorage.getItem("hostname");
        _url = Api._appendParam(_url, "hostname", hostname);
        return _url;
    }

    static async _doFetch(method, url, body, contentType, signal) {
        let apiUrl = `${Api._getUrlBase()}${url.startsWith("/") ? "" : "/"}${url}`;
        apiUrl = Api._addCredentials(apiUrl);

        const headers = {};
        const properMethod = method.toUpperCase();
        if (contentType) {
            headers["Content-Type"] = contentType;
        }
        let response;
        try {
            response = await fetch(apiUrl, {
                method: properMethod,
                headers: headers,
                body,
                signal
            });
        } catch (err) {
            console.logError(err, `Api: fetch rejected (most likely offline): ${method} ${url}`);
            const error = new Error("Request failed: network is unreachable.");
            error.name = "OFFLINE";
            throw error;
        }
        if (response.headers.has("x-session") && window.ATLAS_IS_NATIVE) {
            postNativeMessage("session", {
                value: response.headers.get("x-session")
            })
                .then(result => {
                    console.log("Session update result:" + result);
                })
                .catch(error => {
                    console.logError(error, "Session update error");
                });
        }
        if (!response.ok) {
            let error;
            try {
                const responseBody = await response.json();
                error = new Error(responseBody.error.message);
                error.name = responseBody.error.type;
            } catch (e) {
                error = new Error(e.message);
                error.name = "UnknownError";
            }
            throw error;
        }
        return response;
    }

    static async _getJson(url, signal) {
        const result = await Api._doFetch("GET", url, undefined, undefined, signal);
        return result.json();
    }

    static async _postJson(json, url) {
        const result = await Api._doFetch("POST", url, JSON.stringify(json), "application/json");
        return result.json();
    }

    static async _putJson(json, url) {
        const result = await Api._doFetch("PUT", url, JSON.stringify(json), "application/json");
        return result.json();
    }

    static async _patchJson(json, url) {
        const result = await Api._doFetch("PATCH", url, JSON.stringify(json), "application/json");
        return result.json();
    }

    static async _delete(url) {
        const result = await Api._doFetch("DELETE", url);
        return result.json();
    }

    // Directories

    static async getRoot() {
        const result = await Api._getJson(`/users/root`);
        return result.root;
    }

    static async getDirectory(dirId) {
        const response = await Api._getJson(`/directories/${dirId}`);
        return response.directory;
    }

    static async getBreadcrumbs(dirId) {
        const response = await Api._getJson(`/directories/${dirId}/path`);
        return response.breadcrumbs;
    }

    static async getDirectoryContents(dirId, types) {
        let url = `/directories/${dirId}/contents`;
        if (types) {
            url += `?type=${types.join(",")}`;
        }
        const response = await Api._getJson(url);
        return response.contents;
    }

    static async getParentId(fileId, signal) {
        const response = await Api._getJson(`/directories/parent/${fileId}`, signal);
        return response.id;
    }

    // Workspaces

    static async getWorkspace(workspaceId) {
        const response = await Api._getJson(`/workspaces/${workspaceId}`);
        return response.workspace;
    }

    static async getAllWorkspaces() {
        const response = await Api._getJson(`/workspaces/all`);
        return response.workspaces;
    }

    static async updateWorkspace(id, updates) {
        return Api._patchJson(updates, `/workspaces/${id}`);
    }

    // Layers

    static async getLayer(layerId, workspaceId = null) {
        const result = await Api._getJson(`/layers/${layerId}${workspaceId ? `?workspace=${workspaceId}` : ""}`);
        return result.layer;
    }

    static async updateLayer(id, updates) {
        return Api._patchJson(updates, `/layers/${id}`);
    }

    // Raster/WMS

    static async getTileDescriptions(layerId, workspaceId) {
        const response = await Api._getJson(`/workspaces/${workspaceId}/layers/${layerId}/tiles`);
        return response.tiles;
    }

    static getTileBlobUrl(layerId, workspaceId, x, y, r) {
        const url = `${Api._getUrlBase()}/workspaces/${workspaceId}/layers/${layerId}/tiles/${x}/${y}/${r}/`;
        return Api._addCredentials(url);
    }

    // Geometries

    static async bulkUpdateGeometries(geometries, layerId) {
        return Api._postJson(geometries, `/layers/${layerId}/geometries/bulkupdate`);
    }

    static async getFeatureChanges(
        workspaceId,
        layerId,
        lastFetchDate,
        lastFetchId,
        excludeSelf,
        initTimestamp,
        workspaceAreaId
    ) {
        let url = `/workspaces/${workspaceId}/layers/${layerId}/changes?`;
        if (lastFetchDate) {
            url += `lastFetchDate=${lastFetchDate}&`;
        }
        if (lastFetchId) {
            url += `lastFetchId=${lastFetchId}&`;
        }
        if (excludeSelf) {
            url += `excludeSelf=${excludeSelf}&`;
        }
        if (initTimestamp) {
            url += `initTimestamp=${initTimestamp}&`;
        }
        if (workspaceAreaId) {
            url += `workspaceAreaId=${workspaceAreaId}&`;
        }
        if (url.endsWith("?") || url.endsWith("&")) {
            url = url.slice(0, url.length - 1);
        }
        const response = await Api._getJson(url);
        return response.changes;
    }

    // Users

    static async getHostname() {
        const response = await Api._getJson(`/users/hostname`);
        return response.hostname;
    }

    static async getAllUsers(includeMe = false) {
        const response = await Api._getJson(`/users/all?includeMe=${includeMe}`);
        return response.users;
    }

    static async getAllGroups() {
        const response = await Api._getJson("/users/groups");
        return response.groups;
    }

    static async getUserByHostname(hostname) {
        const response = await Api._getJson(`/users/hostname/${hostname}`);
        return response.user || response;
    }

    static async getCurrentUser() {
        const response = await Api._getJson(`/users/current`);
        return response.user;
    }

    static async getUser(id) {
        const response = await Api._getJson(`/users/${id}`);
        return response.user;
    }

    // Subscribe

    static async subscribe(filter) {
        return Api._postJson(filter, "/init/subscribe");
    }

    static async unsubscribe(filter) {
        return Api._postJson(filter, "/init/unsubscribe");
    }

    // Files

    static async uploadFile(file, id, layerId) {
        const response = await Api._doFetch("POST", `/files?id=${id}&layer=${layerId}&filename=${file.name}`, file);
        return response.json();
    }

    static async downloadFile(fileId, workspaceId) {
        let url = `/files/${fileId}`;
        if (workspaceId) {
            url = Api._appendParam(url, "workspace", workspaceId);
        }
        const response = await Api._doFetch("GET", url);
        return response.blob();
    }

    static getFileURL(fileId, relative = false, workspaceId) {
        const host = relative ? "" : `${window.location.protocol}//${window.location.hostname}`;
        let url = `${host}${Api._getRelativeFileURL(fileId)}`;
        if (workspaceId) {
            url = Api._appendParam(url, "workspace", workspaceId);
        }
        return Api._addCredentials(url);
    }

    static _getRelativeFileURL(fileId) {
        return `${Api._getUrlBase()}/files/${fileId}`;
    }

    // SSE

    static getSSEURL() {
        return Api._addCredentials(`${window.ATLAS_API_BASE || ""}/sse`);
    }

    // Logs

    static uploadReport(message, metadata) {
        return Api._postJson({ message, metadata }, `/logs`);
    }

    static uploadLogs(logs, reportId) {
        return Api._postJson({ logs }, `/logs/${reportId}`);
    }

    static getReports(limit) {
        return Api._getJson(`/logs${limit ? `?limit=${limit}` : ""}`);
    }

    static async getReport(reportId) {
        const { report } = await Api._getJson(`/logs/${reportId}`);
        return report;
    }

    static getLogs(reportId) {
        return Api._getJson(`/logs/${reportId}/logs`);
    }

    static getLogsURL(reportId) {
        return `${Api._getUrlBase()}/logs/${reportId}/logs/download`;
    }
}

export default Api;

import { copyToClipboard } from "../../copyToClipboard";
import { useContext, useState } from "react";
import { ErrorContext } from "../../error-context";
import Linkify from "../../Linkify";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";
import { FileCopy } from "@mui/icons-material";

const getDisplayValue = (value, attribute) => {
    if (["", null, undefined].includes(value)) {
        return "NULL";
    }
    if (attribute?.type === "custom") {
        return "CUSTOM";
    }
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "NULL";
        } else {
            return `[${value.map(v => getDisplayValue(v, attribute)).join(",")}]`;
        }
    }
    if (typeof value === "object") {
        return JSON.stringify(value);
    }
    if (typeof value === "boolean") {
        return String(value);
    }
    return value;
};

function DialogCellText({ value }) {
    let _value = value;
    if (typeof value === "boolean") {
        _value = String(value);
    }
    return <Linkify>{_value}</Linkify>;
}

function DialogCellContent({ value }) {
    if (Array.isArray(value)) {
        return (
            <List>
                {value.map((subvalue, index) => (
                    <Box key={index}>
                        <ListItem>
                            {[null, undefined, ""].includes(subvalue) ? (
                                <ListItemText>NULL</ListItemText>
                            ) : (
                                <ListItemText sx={{ whiteSpace: "pre-wrap" }}>
                                    <DialogCellText value={subvalue} />
                                </ListItemText>
                            )}
                        </ListItem>
                        {index !== value.length - 1 && <Divider component="li" />}
                    </Box>
                ))}
            </List>
        );
    }
    return (
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>
            <DialogCellText value={value} />
        </DialogContentText>
    );
}

function NullableCellRenderer({ title, shortValue, value, copyValue, attribute, disableClick }) {
    const { setError } = useContext(ErrorContext);
    const [dialogOpen, setDialogOpen] = useState(false);

    let _disableClick = disableClick;
    let displayValue = getDisplayValue(value, attribute);
    let isNull = false;
    if (["", null, undefined].includes(value)) {
        isNull = true;
        _disableClick = true;
    }
    if (Array.isArray(displayValue) && displayValue.length === 0) {
        isNull = true;
        _disableClick = true;
    }

    const handleClick = _disableClick ? undefined : () => setDialogOpen(true);
    const handleCopy = async () => {
        try {
            await copyToClipboard(copyValue ?? value);
        } catch (error) {
            setError(error);
        }
    };

    return (
        <>
            <Typography
                variant="body2"
                sx={[
                    isNull && {
                        color: "#aaaaaa",
                        fontStyle: "italic"
                    },
                    !_disableClick && {
                        cursor: "pointer"
                    }
                ]}
                onClick={handleClick}
            >
                {shortValue ?? displayValue}
            </Typography>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
                <DialogTitle>{title ?? attribute?.name}</DialogTitle>
                <DialogContent>
                    <DialogCellContent value={value} />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCopy} startIcon={<FileCopy />}>
                        Copy
                    </Button>
                    <Button variant="outlined" color="primary" onClick={() => setDialogOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default NullableCellRenderer;

import React from "react";
import Color from "./Color";
import Opacity from "./Opacity";
import OutlinedContainer from "../OutlinedContainer";

import { Tabs, Tab } from "@mui/material";

function Fill({ fill, index, onChange }) {
    const handleChange = key => value => onChange(currentFill => ({ ...currentFill, [key]: value }), index);

    return (
        <React.Fragment>
            <OutlinedContainer label="Type">
                <Tabs
                    value={fill.type}
                    onChange={(_, value) => handleChange("type")(value)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="none" value="none" />
                    <Tab label="solid" value="solid" />
                </Tabs>
            </OutlinedContainer>
            {fill.type !== "none" && (
                <React.Fragment>
                    <Color
                        color={fill.color}
                        opacity={fill.opacity}
                        onColorChange={handleChange("color")}
                        onOpacityChange={handleChange("opacity")}
                    />
                    <Opacity opacity={fill.opacity} onChange={handleChange("opacity")} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default React.memo(
    Fill,
    (prevProps, nextProps) => prevProps.fill === nextProps.fill && prevProps.index === nextProps.index
);

import React from "react";
import classNames from "classnames";

import AtlasLogo from "../icons/AtlasLogo";

import { CircularProgress } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    logoContainer: {
        maxWidth: "100px",
        marginBottom: theme.spacing(2)
    },
    logo: {
        width: "100%",
        height: "100%"
    }
}));

function AtlasLoader({ className }) {
    const classes = useStyles();
    return (
        <div className={classNames(classes.container, className)}>
            <div className={classes.logoContainer}>
                <AtlasLogo className={classes.logo} />
            </div>
            <CircularProgress />
        </div>
    );
}

export default AtlasLoader;
